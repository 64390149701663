import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Button,
  withStyles,
  lighten,
  Card,
  CardHeader,
  Typography,
  fade,
} from "@material-ui/core";
import AdminResultsGrid from "../ResultsGrid/AdminResultsGrid";

const H3 = styled.h3`
  text-align: center;
  color: #000000;
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
`;

const ColorButton = withStyles((theme) => ({
  root: {
    width: "20%",
    fontWeight: "600",
    backgroundColor: lighten("#64b5f6", 0.25),
    color: "black",
    borderColor: lighten("#64b5f6", 0.25),
    margin: "20px auto",
    "&:hover": {
      backgroundColor: "#64b5f6",
      borderColor: "#64b5f6",
    },
  },
}))(Button);

const Expansion = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(Accordion);

const PracticeAttempts = (props) => {
  const { t } = useTranslation();
  const { attempts, open, handleOpen } = props;
  console.log(open);

  return (
    <Expansion
      expanded={open}
      onChange={handleOpen}
      style={{ margin: "20px auto", width: "99%" }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel7bh-content"
        id="panel7bh-header"
      >
        <H3>Practice Attempts</H3>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {attempts && attempts.length > 0 ? (
            attempts.map((attempt, index) => {
              let completed = new Date(
                attempt.createdAt.seconds * 1000
              ).toLocaleString();

              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                  }}
                >
                  <AdminResultsGrid
                    key={index}
                    completed={completed}
                    passed={attempt.passed}
                    examTime={attempt.examTime}
                    palpatedCells={attempt.coverageGrid}
                    falsePositives={attempt.falsePositives}
                    tumors={attempt.tumors}
                    percentCoverage={attempt.percentCoverage}
                    pattern={attempt.pattern}
                    playback={attempt.playBackFilename}
                    replayExam={props.replayExam}
                    module={attempt.module}
                    orientation={attempt.orientation}
                    practice={true}
                    {...attempt}
                    noScroll={true}
                  />
                </div>
              );
            })
          ) : (
            <Typography variant="h6">{t("grades.noPractice")}</Typography>
          )}
        </div>
      </AccordionDetails>
    </Expansion>
  );
};

export default PracticeAttempts;
