import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/performance";
import * as ROUTES from "../../constants/routes";
import * as PRESSURES from "../../constants/pressures";
import jszip from "jszip";
import Axios from "axios";

var config =
  process.env.REACT_APP_STAGE == "prod"
    ? {
        apiKey: process.env.REACT_APP_PROD_API_KEY,
        authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
        projectId: process.env.REACT_APP_PROD_PROJECT_ID,
        storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_PROD_APP_ID,
      }
    : {
        apiKey: process.env.REACT_APP_DEV_API_KEY,
        authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
        projectId: process.env.REACT_APP_DEV_PROJECT_ID,
        storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_DEV_APP_ID,
      };

console.log(process.env.REACT_APP_IS_EMULATED);

const isTestRun = window.Cypress;

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.perf = app.performance();
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.functions = app.functions();

    this.fieldValue = app.firestore.FieldValue;
    this.timestamp = app.firestore.Timestamp;
    this.userData = null;
    this.cloudFunctionsURL =
      process.env.REACT_APP_STAGE === "prod"
        ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net"
        : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net";
    this.db.settings({ timestampsInSnapshots: true });

    if (process.env.REACT_APP_IS_EMULATED === "true" || isTestRun) {
      console.log("localhost detected!");
      if (isTestRun) {
        app.firestore().settings({ experimentalAutoDetectLongPolling: true });
      }

      this.db.useEmulator("localhost", 8080);
      this.functions.useEmulator("localhost", 5001);
      this.auth.useEmulator("http://localhost:9099");
      this.cloudFunctionsURL =
        "http://localhost:5001/mammacare-training-app-dev/us-central1";
    }
  }

  // *** Error Handling ***

  handleErrorLogging = (lvl, data, err) => {
    console.log("in handleErrorLogging");
    return new Promise(async (resolve, reject) => {
      console.log("data: ", data);
      console.log("error: ", err);
      let timestamps = await this.getServerTimestamp();
      let message = escape(lvl + "<br />" + data + "<br />" + err);
      console.log("message: ", message);
      let url =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/sendEmail?message=" +
            encodeURI(message)
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/sendEmail?message=" +
            encodeURI(message);

      if (lvl === "high") {
        Axios.get(url);
      }
      this.db
        .collection("errorLogs")
        .add({
          priority: lvl,
          timestamp: timestamps.unixTimestampSeconds,
          error: err,
          data: message,
        })
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject();
        });
    });
  };

  foo = () => {
    let url =
      process.env.REACT_APP_STAGE == "prod"
        ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/fooFunction?uid=" +
          encodeURI("Crlz36StQVTHrfaDHyIpHnPfe3p1")
        : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/fooFunction?uid=" +
          encodeURI("Crlz36StQVTHrfaDHyIpHnPfe3p1");
  };

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSendEmailVerification = () => {
    const url =
      process.env.REACT_APP_STAGE == "prod"
        ? "https://learn.mammacare.org/home"
        : "https://mammacare-training-app-dev.firebaseapp.com/home";
    console.log("continue url: " + url);
    return this.auth.currentUser.sendEmailVerification({
      url: url,
    });
  };

  doSignInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  };

  doSignOut = async () => {
    console.log("signing out...");
    Axios.post(`${this.cloudFunctionsURL}/writeSignoutLog`, {
      uid: this.auth.currentUser.uid,
    });
    this.auth.signOut();
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  verifyUserEmail = async (uid, email, extraUserData) => {
    console.log("verifying user: ", uid);
    return new Promise(async (resolve, reject) => {
      let catchArray = [];
      let baseUrlEndPoint =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";

      let url = baseUrlEndPoint + "verifyUser?uid=" + encodeURI(uid);
      let timestamps = await this.getServerTimestamp();
      try {
        let time = timestamps.unixTimestampSeconds;
        const date = new Date(timestamps.firebaseTimestampDate);
        let response = await Axios.get(url);
        console.log(response);
        if (response.data.code) {
          reject({ status: "fail", message: response.data.message });
        } else {
          // this.writeLog(`User was manually verified by ${email}`);
          this.db
            .collection("users")
            .doc(uid)
            .collection("logs")
            .add({
              timestamp: time,
              displayDate: date.toString(),
              message: `User was manually verified by ${email}`,
              cohortId: extraUserData.cohortId,
              email: extraUserData.email,
              userId: uid,
            });
          resolve({ status: "success", message: "success" });
        }
      } catch (e) {
        console.log("cannot add to email logs...");
        await this.handleErrorLogging(
          "high",
          "Send Email Results Error: ",
          e.message
        );
        reject({ status: "fail", message: e.message });
      }
    });
  };

  toggleUserAuth = async (uid, email) => {
    console.log("disabling user: ", uid, email);
    return new Promise(async (resolve, reject) => {
      let baseUrlEndPoint =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";

      let url = baseUrlEndPoint + "toggleUserAuth?uid=" + encodeURI(uid);
      let response = null;

      try {
        let timestamps = await this.getServerTimestamp();
        const date = new Date(timestamps.firebaseTimestampDate);
        response = await Axios.get(url);
        console.log("RESPONSE: ", response);
        if (response.data.status === "success") {
          await this.db
            .collection("users")
            .doc(uid)
            .collection("logs")
            .add({
              displayDate: date.toString(),
              message: `User was disabled/enabled by ${email}`,
              timestamp: timestamps.unixTimestampSeconds,
            });
          resolve({ status: "success", message: "success" });
        } else {
          reject({ status: "fail", message: response.data.message });
        }
      } catch (e) {
        console.log("cannot add to email logs...");
        await this.handleErrorLogging(
          "high",
          `Error when trying to disable user ${uid} `,
          e.message
        );
        reject({ status: "fail", message: e.message });
      }
    });
  };

  disableCohort = (cohortId) => {
    return new Promise(async (resolve, reject) => {
      let catchArray = [];
      let baseUrlEndPoint =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";

      let url =
        baseUrlEndPoint + "disableCohort?cohortId=" + encodeURI(cohortId);
      try {
        let axiosResponse = await Axios.get(url);
        let axiosData = axiosResponse.data;
        console.log(axiosData);
        if (axiosData.catchArray.length > 0) {
          console.log("Disable cohort catch array: ", axiosData.catchArray);
          resolve({
            status: "warning",
            title: "Warning",
            message:
              "The cohort has been disabled, but there was an issue disabling some users.",
          });
        } else {
          resolve({
            status: "success",
            title: "Success",
            message: "Cohort and users successfully disabled.",
          });
        }
      } catch (e) {
        //console.log("cannot add to email logs...");
        reject({ status: "error", title: "Error", message: e.message });
      }
    });
  };

  enableCohort = (cohortId) => {
    return new Promise(async (resolve, reject) => {
      let catchArray = [];
      let baseUrlEndPoint =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";

      let url =
        baseUrlEndPoint + "enableCohort?cohortId=" + encodeURI(cohortId);
      try {
        let axiosResponse = await Axios.get(url);
        let axiosData = axiosResponse.data;
        console.log(axiosData);
        if (axiosData.catchArray.length > 0) {
          console.log("Disable cohort catch array: ", axiosData.catchArray);
          resolve({
            status: "warning",
            title: "Warning",
            message:
              "The cohort has been enabled, but there was an issue enabling some users.",
          });
        } else {
          resolve({
            status: "success",
            title: "Success",
            message: "Cohort and users successfully enabled.",
          });
        }
      } catch (e) {
        //console.log("cannot add to email logs...");
        reject({ status: "error", title: "Error", message: e.message });
      }
    });
  };

  reenrollUsers = (cohortFrom, cohortTo) => {
    return new Promise(async (resolve, reject) => {
      console.log("in firebase reenroll");
      let catchArray = [];
      let baseUrlEndPoint =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";

      let url = `${baseUrlEndPoint}reenrollUsers?cohortFrom=${encodeURI(
        cohortFrom
      )}&cohortTo=${encodeURI(cohortTo)}`;
      try {
        let axiosResponse = await Axios.get(url);
        let axiosData = axiosResponse.data;
        console.log(axiosData);
        if (axiosData.status === "fail") {
          resolve({
            status: "warning",
            title: "Warning",
            message: "A problem occurred. No users were reenrolled.",
          });
        } else {
          resolve({
            status: "success",
            title: "Success",
            message: `${axiosData.size} users were reenrolled from ${cohortFrom} to ${cohortTo}`,
          });
        }

        // if (axiosData.catchArray.length > 0) {
        //   console.log("Disable cohort catch array: ", axiosData.catchArray);
        //   resolve({
        //     status: "warning",
        //     title: "Warning",
        //     message:
        //       "The cohort has been enabled, but there was an issue enabling some users.",
        //   });
        // } else {
        //   resolve({
        //     status: "success",
        //     title: "Success",
        //     message: "Cohort and users successfully enabled.",
        //   });
        // }
      } catch (e) {
        //console.log("cannot add to email logs...");
        reject({ status: "error", title: "Error", message: e.message });
      }
    });
  };

  updateUserEmail = (uid, email, newEmail) => {
    console.log("updating user email");
    return new Promise(async (resolve, reject) => {
      let catchArray = [];
      let baseUrlEndPoint =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";

      let url =
        baseUrlEndPoint +
        "updateEmail?uid=" +
        encodeURI(uid) +
        "&newEmail=" +
        encodeURI(newEmail);
      try {
        let axiosResponse = await Axios.get(url);
        let axiosData = axiosResponse.data;
        console.log(axiosData);
        if (Object.keys(axiosData).includes("code")) {
          reject({ status: "fail", message: axiosData.message });
        }
      } catch (e) {
        console.log("cannot add to email logs...");
        reject({ status: "fail", message: e.message });
      }

      let timestamps = await this.getServerTimestamp();
      const date = new Date(timestamps.firebaseTimestampDate);
      this.db
        .collection("users")
        .doc(uid)
        .collection("logs")
        .add({
          displayDate: date.toString(),
          message: `User email was manually updated by ${email}`,
          timestamp: timestamps.unixTimestampSeconds,
        })
        .then(() => {
          resolve({ status: "success", message: "success" });
        })
        .catch((e) => {
          resolve({
            status: "success",
            message: "Email was updated but logs failed to write",
          });
        });

      // let status = catchArray.filter((el) => el.status === "fail");
      // if (status.length > 0) {
      //   await await this.handleErrorLogging(
      //     "high",
      //     "Send Email Results Error: ",
      //     status[0].message
      //   );
      //   reject({ status: "fail", message: catchArray });
      // } else {
      //   resolve({ status: "success", message: catchArray });
      // }
    });
  };

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            if (dbUser === undefined) return null;

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        this.userData = null;
        fallback();
      }
    });

  //GENERAL DOC FETCH
  getDocByIdFrom = (collectionName, docId) =>
    this.db.collection(collectionName).doc(docId);

  getDocsFromCollection = (collection) => this.db.collection(collection);

  getOrderedDocsFromSubcollection = async (
    collection,
    uid,
    subCollection,
    orderBy
  ) => {
    const snapshot = await this.db
      .collection(collection)
      .doc(uid)
      .collection(subCollection)
      .orderBy(orderBy)
      .get();

    let data = snapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    return data;
  };

  getDocsFromUserSubcollection = async (
    uid,
    collection,
    orderBy,
    direction
  ) => {
    console.log("users/" + uid + "/" + collection);
    let snapshot = null;
    if (orderBy) {
      snapshot = await this.db
        .collection("users")
        .doc(uid)
        .collection(collection)
        .orderBy(orderBy, direction)
        .get();
    } else {
      snapshot = await this.db
        .collection("users")
        .doc(uid)
        .collection(collection)
        .get();
    }

    return snapshot.docs.map((doc) => doc.data());
  };

  getDocsFromTourUserSubcollection = async (uid, collection) => {
    const snapshot = await this.db
      .collection("tourUsers")
      .doc(uid)
      .collection(collection)
      .get();

    console.log(snapshot.docs);

    return snapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
  };

  // *** User API ***

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");

  userByEmail = async (email) => {
    let userDocs = await this.db
      .collection("users")
      .where("email", "==", email)
      .get();
    let users = [];
    for (let doc of userDocs.docs) {
      users.push({ id: doc.id, ...doc.data() });
    }
    return users;
  };

  testUpdate = (uid) => {};

  userGrades = (userId, courseCode, term) =>
    this.db
      .collection("users")
      .doc(userId)
      .collection("courses")
      .doc(courseCode + "_" + term)
      .collection("grades");

  tourUserGrades = (userId, courseCode, term) =>
    this.db
      .collection("tourUsers")
      .doc(userId)
      .collection("courses")
      .doc(courseCode + "_" + term)
      .collection("grades");

  userGradesDocs = async (userId, courseCode, term) => {
    console.log(userId, courseCode, term);
    const snapshot = await this.db
      .collection("users")
      .doc(userId)
      .collection("courses")
      .doc(courseCode + "_" + term)
      .collection("grades")
      .get();

    console.log(snapshot);
    return snapshot.docs.map((doc) => doc.data());
  };

  getGradeHistory = (userId) =>
    this.db
      .collection("users")
      .doc(userId)
      .collection("grades")
      .orderBy("createdAt");

  getEnrolledUsersByCohortId = async (
    cohortId,
    excludedUsers = [],
    subSection = null
  ) => {
    console.log(excludedUsers);
    let excludedIds = excludedUsers.map((el) => el.id);
    let userDocs = null;
    if (subSection) {
      userDocs = await this.db
        .collection("users")
        .where("cohortHistory", "array-contains", cohortId)
        .where("subSection", "==", subSection)
        .get();
    } else {
      userDocs = await this.db
        .collection("users")
        .where("cohortHistory", "array-contains", cohortId)
        .get();
    }
    let users = userDocs.docs
      .map((doc) => {
        return { id: doc.id, ...doc.data() };
      })
      .filter((el) => !excludedIds.includes(el.id));

    return users;
  };

  getGradesFromCohort = async (cohortId, activityIds) => {
    return new Promise(async (res, rej) => {
      let gradeDocs = [];
      for (let id of activityIds) {
        let query = await this.db
          .collectionGroup("grades")
          .where("cohortId", "==", cohortId)
          .where("activityId", "==", id)
          .get()
          .catch((e) => {
            rej({
              status: "error",
              message:
                "An error occurred while attempting to download activity data for this cohort.",
              data: [],
            });
          });
        gradeDocs = [...gradeDocs, ...query.docs];
      }
      if (gradeDocs.length === 0) {
        res({
          status: "warning",
          message:
            "There is currently no data for these activities in this cohort",
          data: gradeDocs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          }),
        });
      }
      res({
        status: "success",
        message: "Successfully fetched cohort activity data.",
        data: gradeDocs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        }),
      });
    });
  };

  maintenanceModeEnabled = async () => {
    const settingsRef = this.getDocByIdFrom("systemSettings", "global");
    const settingsDoc = await settingsRef.get();
    if (settingsDoc.exists) {
      console.log("mm: ", settingsDoc.data().maintenanceModeEnabled);
      return settingsDoc.data().maintenanceModeEnabled;
    } else {
      return false;
    }
  };

  saveResults = async (
    operation,
    uid,
    {
      activityObj = null,
      gradeObj = null,
      moduleObj = null,
      manualExamObj = null,
      emails = null,
      initiator = null,
      minPassingGrade = null,
    } = {}
  ) => {
    return new Promise(async (resolve, reject) => {
      console.log("trying new saveResults");
      console.log(uid);
      console.log(activityObj);
      console.log(gradeObj);
      console.log(moduleObj);
      console.log(manualExamObj);
      console.log(emails);
      console.log(initiator);
      let emailContent = "";

      let url = `${this.cloudFunctionsURL}/saveActivityResults`;
      console.log(url);

      try {
        let response = await Axios.post(url, {
          operation,
          uid,
          activityObj,
          gradeObj,
          moduleObj,
          manualExamObj,
          initiator,
          emails,
          emailContent,
          minPassingGrade,
        });
        console.log(response);
        resolve({
          ...response.data,
        });
      } catch (e) {
        console.log(e);
        resolve({
          status: "fail",
          message: "An error has occurred",
          alert:
            "A problem occurred while saving your grade. Please check your internet connection and wait a few minutes before attempting the activity again.",
        });
      }
    });
  };

  saveGrade = async (userObj, gradeObj, emails, emailData, minPassingGrade) => {
    console.log("Saving grade...");
    console.log(gradeObj);

    const timestamps = await this.getServerTimestamp();

    // reference a grade doc by id for the current enrolled course
    let courseGradesCollection = this.db
      .collection("users")
      .doc(userObj.uid)
      .collection("courses")
      .doc(userObj.courseCode + "_" + userObj.term)
      .collection("grades");

    let gradeDocRef = this.db
      .collection("users")
      .doc(userObj.uid)
      .collection("courses")
      .doc(userObj.courseCode + "_" + userObj.term)
      .collection("grades")
      .doc(gradeObj.activityId);

    let userRef = this.db.collection("users").doc(userObj.uid);

    let gradeDoc = null;

    try {
      gradeDoc = await gradeDocRef.get();

      if (gradeDoc.exists) {
        console.log("grade doc exists", gradeDoc.data(), gradeObj);

        //update grade, date, attempts array, json
        let submission = [];
        if (gradeObj.json) {
          try {
            submission = await JSON.parse(gradeObj.json);
          } catch {
            submission = [];
          }
        } else {
          submission = [];
        }
        await gradeDocRef
          .update({
            grade: gradeObj.grade,
            json: gradeObj.json,
            attempts: [
              ...gradeDoc.data().attempts,
              {
                grade: gradeObj.grade,
                json: gradeObj.json,
                submission: submission,
                submittedAt: timestamps.unixTimestampSeconds,
                duration: gradeObj.duration,
              },
            ],
            submittedAt: timestamps.unixTimestampSeconds,
          })
          .catch(async (e) => {
            await this.handleErrorLogging(
              "high",
              "Unable to update existing grade doc. ",
              e.message
            );
            return {
              status: "error",
              message: "Unable to update existing grade doc. " + e.message,
            };
          });

        // user sections array
        const userDoc = await userRef.get().catch(async (e) => {
          await this.handleErrorLogging(
            "high",
            "Unable to retrieve user doc. ",
            e.message
          );
          return {
            status: "error",
            message: "Unable to retrieve user doc. " + e.message,
          };
        });

        const userCourses = await this.updateUserSectionsArray(
          userDoc,
          gradeObj,
          minPassingGrade
        );

        await userRef.update({ courses: userCourses }).catch(async (e) => {
          await this.handleErrorLogging(
            "high",
            "Unable to update the user's course sections array.  ",
            e.message
          );
          return {
            status: "error",
            message:
              "Unable to update the user's course sections array. " + e.message,
          };
        });

        this.writeLog(
          gradeObj.title +
            " [" +
            gradeObj.activityId +
            "] " +
            " was completed with a grade value of " +
            gradeObj.grade
        );

        //only email if passed
        if (gradeObj.grade === "Complete" || gradeObj.grade > minPassingGrade) {
          console.log("EMAILS", emails);
          if (emails) {
            if (emails.length > 0 && emailData !== undefined) {
              //let splitData = emailData.split("[")[0];
              console.log("EMAILS: ", emails);

              await this.sendResults(
                emails,
                userObj.email + " completed " + gradeObj.title,
                emailData,
                userObj.uid
              ).catch(async (e) => {
                await this.handleErrorLogging(
                  "medium",
                  "Unable to send results email.  ",
                  e.message
                );
                return {
                  status: "error",
                  message: "Unable to send results email. " + e.message,
                };
              });
            }
          }
          if (
            userObj.courseCode === "gabccp" &&
            (gradeObj.activityId === "t4NCSSGAcE1mPnrqnEKO" ||
              gradeObj.activityId === "Qjrw0OnkzMYAD6nMnE3n")
          ) {
            let response = await this.processGeorgiaRegistrationData(
              userObj.uid,
              `${userObj.courseCode}_${userObj.term}`,
              gradeObj.activityId
            ).catch((err) => {
              console.log(err);
            });
          }
        }

        return {
          status: "success",
          message:
            "New grade doc created and user's course section array updated.",
        };
      } else {
        let submission = [];
        if (gradeObj.json) {
          try {
            submission = await JSON.parse(gradeObj.json);
          } catch {
            submission = [];
          }
        } else {
          submission = [];
        }
        courseGradesCollection.doc(gradeObj.activityId).set({
          ...gradeObj,
          userId: userObj.uid,
          cohortId: userObj.cohortId,
          id: gradeObj.activityId,
          activityId: gradeObj.activityId,
          term: userObj.term,
          courseCode: userObj.courseCode,
          templateId: userObj.templateId,
          title: gradeObj.title,
          grade: gradeObj.grade,
          duration: gradeObj.duration,
          minPassingGrade: minPassingGrade,
          json: gradeObj.json,
          attempts: [
            {
              grade: gradeObj.grade,
              json: gradeObj.json,
              submission: submission,
              submittedAt: timestamps.unixTimestampSeconds,
              duration: gradeObj.duration,
            },
          ],
          submittedAt: timestamps.unixTimestampSeconds,
        });

        const userDoc = await userRef.get().catch(async (e) => {
          await this.handleErrorLogging(
            "high",
            "Unable to retrieve user doc. ",
            e.message
          );
          return {
            status: "error",
            message: "Unable to retrieve user doc. " + e.message,
          };
        });
        const userCourses = await this.updateUserSectionsArray(
          userDoc,
          gradeObj,
          minPassingGrade
        );

        await userRef.update({ courses: userCourses }).catch(async (e) => {
          await this.handleErrorLogging(
            "high",
            "Unable to update the user's course sections array.  ",
            e.message
          );
          return {
            status: "error",
            message:
              "Unable to update the user's course sections array. " + e.message,
          };
        });

        this.writeLog(
          gradeObj.title +
            " [" +
            gradeObj.activityId +
            "] " +
            " was completed with a grade value of " +
            gradeObj.grade
        );

        //only email if passed
        if (gradeObj.grade === "Complete" || gradeObj.grade > minPassingGrade) {
          if (emails) {
            if (emails.length > 0 && emailData !== undefined) {
              //let splitData = emailData.split("[")[0];
              await this.sendResults(
                emails,
                userObj.email + " completed " + gradeObj.title,
                emailData,
                userObj.uid
              ).catch(async (e) => {
                await this.handleErrorLogging(
                  "medium",
                  "Unable to send results email.  ",
                  e.message
                );
                return {
                  status: "error",
                  message: "Unable to send results email. " + e.message,
                };
              });
            }
          }
          if (
            userObj.courseCode === "gabccp" &&
            (gradeObj.activityId === "t4NCSSGAcE1mPnrqnEKO" ||
              gradeObj.activityId === "Qjrw0OnkzMYAD6nMnE3n")
          ) {
            let response = await this.processGeorgiaRegistrationData(
              userObj.uid,
              `${userObj.courseCode}_${userObj.term}`,
              gradeObj.activityId
            ).catch((err) => {
              console.log(err);
            });
          }
        }

        // to do if successful redirect to home page ELSE redirect to error page
        return {
          status: "success",
          message:
            "new grade doc created and user's course section array updated.",
        };
      }
    } catch (e) {
      //console.error(e);
      await this.handleErrorLogging(
        "high",
        `${userObj.email}\n${gradeObj.title}\nGrade: ${gradeObj.grade}\nUnhandled error:`,
        e.message
      );
      return { status: "error", message: e.message };
    }
  };

  updateModuleVideoIndex = (userId, moduleId) => {
    return new Promise(async (res, rej) => {
      let moduleVideosDoc = await this.db
        .collection("moduleVideos")
        .doc("en")
        .get()
        .catch(() =>
          rej({
            status: "error",
            message: "An error occured while attempting to skip module videos",
          })
        );
      let moduleVideos = { ...moduleVideosDoc.data() };
      let maxIndex = moduleVideos[moduleId].length;
      this.user(userId)
        .update({
          [`${moduleId}VideoIndex`]: maxIndex,
        })
        .then(() =>
          res({
            status: "success",
            message: "Module videos succesfully skipped",
          })
        )
        .catch(() =>
          rej({
            status: "error",
            message: "An error occured while attempting to skip module videos",
          })
        );
    });
  };

  updateUserSectionsArray = async (userDoc, gradeObj, minPassingGrade) => {
    const userCourses = [...userDoc.data().courses];
    let sections = userCourses[userCourses.length - 1].sections;

    const index = sections.findIndex((item) => item.id == gradeObj.activityId);
    if (index != -1) {
      console.log("Found matching activity to update in user sections");
      sections[index].grade =
        gradeObj.grade === "Complete" || gradeObj.grade >= minPassingGrade
          ? gradeObj.grade
          : "--";
      userCourses[userCourses.length - 1].sections = sections;
      return userCourses;
    } else {
      await this.handleErrorLogging(
        "high",
        "Unable to update the user's course sections array after marking complete.",
        "Unable to update user " +
          userDoc.id +
          "'s sections for activity " +
          gradeObj.title +
          ": " +
          gradeObj.grade
      );
      return userCourses;
    }
  };

  resetGrade = (
    userId,
    courseCodeTerm,
    gradeDocId,
    activityId,
    activityObj,
    resetBy
  ) => {
    console.log(
      "Looking for grade doc id: ",
      gradeDocId + " with " + activityId
    );
    console.log(
      "Resetting grade value for user " +
        userId +
        " in " +
        courseCodeTerm +
        " for grade doc id " +
        gradeDocId
    );
    const userRef = this.db.collection("users").doc(userId);
    let user = null;
    userRef.get().then((doc) => (user = doc.data()));

    const gradesCollectionRef = this.db
      .collection("users")
      .doc(userId)
      .collection("courses")
      .doc(courseCodeTerm)
      .collection("grades")
      .doc(gradeDocId);
    let getDoc = gradesCollectionRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          alert("Unable to reset grade on a document that does not exist!");
        } else {
          gradesCollectionRef
            .update({ grade: "--", markedCompleteBy: null })
            .then(() => {
              console.log(user);
              let index = user.courses[
                user.courses.length - 1
              ].sections.findIndex((item) => item.id == activityId);
              if (index != -1) {
                //update user sections array
                let coursesCopy = [...user.courses];
                let activeCourseIndex = coursesCopy.length - 1;
                coursesCopy[activeCourseIndex].sections[index].grade = "--";
                userRef
                  .update({ courses: coursesCopy, isEnrolled: true })
                  .then(() => {
                    if (activityId.includes("module")) {
                      let num = activityId.slice(-1);
                      userRef.update({
                        [`module${num}VideoIndex`]: 0,
                      });
                    }
                  });
                this.writeGradeResetLog(userId, activityObj, resetBy);
              } else
                alert(
                  "Unable to update the user sections array for activityId: " +
                    activityId
                );
            });
        }
      })
      .catch((err) => {
        alert("Error getting document", err);
      });
  };

  markComplete = async (
    userId,
    userObj,
    templateId,
    activityObj,
    markedCompleteBy
  ) => {
    console.log("FIRING MARKCOMPLETE IN FIREBASE");
    console.log(userId, userObj, templateId, activityObj, markedCompleteBy);

    return new Promise(async (resolve, reject) => {
      const timestamps = await this.getServerTimestamp();

      let userRef = this.user(userId);
      let courseInfo = await this.course(userObj.cohortId, templateId).get();
      courseInfo = courseInfo.data();

      const courseLastUpdatedAt = courseInfo.updatedAt;
      let userCourseSections =
        userObj.courses[userObj.courses.length - 1].sections;
      const userLastUpdatedAt =
        userObj.courses[userObj.courses.length - 1].courseLastUpdatedAt.seconds;

      if (courseLastUpdatedAt.seconds != userLastUpdatedAt) {
        let courseSections = courseInfo.sections;

        if (userCourseSections.length == 0)
          userCourseSections = [...courseSections];
        courseSections.forEach((courseSection) => {
          let index = userCourseSections.findIndex(
            (item) => item.id == courseSection.id
          );
          if (index != -1) {
            if (userCourseSections[index].grade != undefined) {
              courseSection.grade = userCourseSections[index].grade;
            } else {
              courseSection.grade = "--";
            }
          } else {
            courseSection.grade = "--";
          }
        });

        let userCourses = [...userObj.courses];
        userCourses[userCourses.length - 1].courseLastUpdatedAt =
          courseLastUpdatedAt;
        userCourses[userCourses.length - 1].sections = courseSections;

        await this.user(userId)
          .update({ courses: userCourses })
          .catch((e) => {
            this.props.firebase.handleErrorLogging(
              "high",
              "Unable to update user sections in markComplete function" +
                this.context.email,
              e.message
            );
            reject({
              status: "fail",
              message: `Failed to mark ${activityObj.title} as complete`,
            });
          });
      }

      const userDoc = await userRef.get();
      const userCourses = [...userDoc.data().courses];
      let sections = userCourses[userCourses.length - 1].sections;
      console.log("Firebase user sections: ", sections);
      //update grade value for this activity in the sections array
      const index = sections.findIndex(
        (item) => item.id == activityObj.activityId
      );
      if (index != -1) {
        console.log("Found matching activity to update in user sections");
        sections[index].grade = "Complete";
        userCourses[userCourses.length - 1].sections = sections;
        await userRef.update({ courses: userCourses }).catch((e) => {
          reject({
            status: "fail",
            message: `Failed to mark ${activityObj.title} as complete`,
          });
        });
      } else {
        console.log(
          "Unable to update user " +
            userId +
            "'s sections for activity " +
            activityObj.title +
            "."
        );
      }

      let gradesCollectionRef = this.db
        .collection("users")
        .doc(userId)
        .collection("courses")
        .doc(userObj.courseCode + "_" + userObj.term)
        .collection("grades");
      gradesCollectionRef
        .where("activityId", "==", activityObj.activityId)
        .get()
        .then(async (snapshot) => {
          if (snapshot.empty) {
            await this.db
              .collection("users")
              .doc(userId)
              .collection("courses")
              .doc(userObj.courseCode + "_" + userObj.term)
              .collection("grades")
              .doc(activityObj.activityId)
              .set({
                activityId: activityObj.activityId,
                id: activityObj.activityId,
                term: userObj.term,
                courseCode: userObj.courseCode,
                templateId: templateId,
                grade: activityObj.type == "lesson" ? 100 : "Complete",
                minPassingGrade: 75,
                title: activityObj.title,
                type: activityObj.type,
                userId: userId,
                email: userObj.email,
                markedCompleteBy: markedCompleteBy,
                createdAt: timestamps.unixTimestampSeconds,
                submittedAt: timestamps.unixTimestampSeconds,
                attempts: [],
                cohortId: userObj.cohortId,
              })
              .catch((e) => {
                reject({
                  status: "fail",
                  message: `Failed to mark ${activityObj.title} as complete`,
                });
              });
          } else {
            snapshot.docs.forEach(async (doc) => {
              if (doc.exists) {
                console.log(doc.id);
                await this.db
                  .collection("users")
                  .doc(userId)
                  .collection("courses")
                  .doc(userObj.courseCode + "_" + userObj.term)
                  .collection("grades")
                  .doc(doc.id)
                  .update({
                    markedCompleteBy: markedCompleteBy,
                    grade: activityObj.type == "lesson" ? 100 : "Complete",
                    submittedAt: timestamps.unixTimestampSeconds,
                    markedCompleteBy: markedCompleteBy,
                  })
                  .catch((e) => {
                    reject({
                      status: "fail",
                      message: `Failed to mark ${activityObj.title} as complete`,
                    });
                  });
              }
            });
          }
        });
      this.writeMarkCompleteLog(userId, activityObj, markedCompleteBy);
      resolve({
        status: "success",
        message: `${activityObj.title} was marked complete`,
      });
    });
  };

  /*createReportByCourseCodeAndTerm = async (courseCode, term) => {
    console.log("Processing tasks...");
    let grades = [];
    let gradesRef = this.db.collectionGroup('grades');
    let templateRef = await gradesRef.where('courseCode', '==', courseCode).where('term', '==', term).get();
    for (let grade of templateRef.docs) {
      console.log("Grade ID: " + grade.id);
      grades.push(grade.data());
    }
    console.log("Found " + grades.length + " grades!");
    return grades;
  }*/

  saveManualExam = async (data) => {
    return new Promise(async (resolve, reject) => {
      console.log("Saving manual exam: ", data);
      const self = this;
      const modelId = data.modelId;

      console.log("model ID: " + modelId);
      let collection = "manualExams";
      const timestamps = await this.getServerTimestamp();

      //handle training exam (2,3,4) vs pretest ("p2",etc.)
      this.db
        .collection("users")
        .doc(data.uid)
        .collection(collection)
        .add({ ...data, createdAt: timestamps.unixTimestampSeconds })
        .then((docRef) => {
          console.log(modelId + " exam saved as document ID: " + docRef.id);
          self.writeLog("Saved " + modelId + " exam to doc ID " + docRef.id);
        })
        .then(async () => {
          let userDoc = await this.db.collection("users").doc(data.uid).get();
          let user = userDoc.data();
          this.db
            .collection(collection)
            .doc(data.uid)
            .set({
              subSection: user.subSection ? user.subSection : null,
              email: user.email,
              term: user.term,
              courseCode: user.courseCode,
              cohortId: user.cohortId,
              type: "manualExam",
              createdAt: timestamps.unixTimestampSeconds,
              ...data,
            })
            .then(() => {
              self.writeLog("Saved " + modelId + " pretest");
              resolve();
            });
        })
        .catch(async (error) => {
          console.error("Error saving pretest document: ", error);
          self.writeLog("Error saving " + modelId + " pretest: ", error);
          let data =
            "uid: " +
            data.uid +
            " | Error saving " +
            modelId +
            " pretest document.";
          await this.handleErrorLogging("high", data, error);
          reject();
        });
    });
  };

  saveModuleAttempt = async (userId, attemptData) => {
    console.log("Saving attemptData: ", attemptData);
    let success = false;
    const self = this;
    const moduleId = attemptData.moduleId;
    const valid = attemptData.isValidAttempt;

    console.log("moduleId: " + moduleId);
    let collection = null;
    switch (moduleId) {
      case "p2Pretraining":
        collection = "p2PretrainingAttempts";
        break;
      case "p2PostTraining":
        collection = "p2PostTrainingAttempts";
        break;
      case 1:
        collection = valid ? "module1Attempts" : "module1InvalidAttempts";
        break;
      case 2:
        collection = valid ? "module2Attempts" : "module2InvalidAttempts";
        break;
      case 3:
        collection = valid ? "module3Attempts" : "module3InvalidAttempts";
        break;
      case 4:
        collection = valid ? "module4Attempts" : "module4InvalidAttempts";
        break;
      case "p2": //p2 pretest
        collection = "p2PreTests";
        break;
      default:
        console.log("Not saving results for module id " + moduleId);
        let data = "uid: " + userId + " | Module: " + moduleId + " attempt.";
        await this.handleErrorLogging(
          "high",
          data,
          "Not saving results for module id " + moduleId
        );
        return false;
    }
    //handle training exam (2,3,4) vs pretest ("p2",etc.)
    if (Number.isInteger(moduleId)) {
      this.db
        .collection("users")
        .doc(userId)
        .collection(collection)
        .add(attemptData)
        .then((docRef) => {
          console.log("Module Attempt saved as document ID: " + docRef.id);
          self.writeLog(
            "Saved module " + moduleId + " attempt to doc ID " + docRef.id
          );
          return true;
        })
        .catch(async (error) => {
          console.error("Error saving module attempt document: ", error);
          self.writeLog(
            "Error saving module " + moduleId + " attempt: ",
            error
          );
          let data =
            "uid: " +
            userId +
            " | Error saving module " +
            moduleId +
            " attempt document attempt.";
          await this.handleErrorLogging("high", data, error);
          return false;
        });
    } else {
      this.db
        .collection("users")
        .doc(userId)
        .collection(collection)
        .add(attemptData)
        .then((docRef) => {
          console.log(moduleId + " exam saved as document ID: " + docRef.id);
          self.writeLog("Saved " + moduleId + " exam to doc ID " + docRef.id);
        })
        .then(async () => {
          let userDoc = await this.db.collection("users").doc(userId).get();
          let user = userDoc.data();
          this.db
            .collection(collection)
            .doc(userId)
            .set({
              subSection: user.subSection ? user.subSection : null,
              email: user.email,
              training_facility: "n/a",
              term: user.term,
              courseCode: user.courseCode,
              cohortId: attemptData.cohortId,
              attempt_1_module_id: attemptData.moduleId,
              attempt_1_passed: attemptData.passed,
              attempt_1_createdAt: attemptData.createdAt,
              attempt_1_lumps_found: attemptData.totalTumorsFound,
              attempt_1_false_positives: attemptData.falsePositives,
              attempt_1_percent_palpated: attemptData.percentCoverage,
              attempt_1_exam_time: attemptData.examTime,
              attempt_1_tumors: attemptData.tumors,
            })
            .then(() => {
              self.writeLog("Saved " + moduleId + " pretest");
              return true;
            });
        })
        .catch(async (error) => {
          console.error("Error saving pretest document: ", error);
          self.writeLog("Error saving " + moduleId + " pretest: ", error);
          let data =
            "uid: " +
            userId +
            " | Error saving " +
            moduleId +
            " pretest document.";
          await this.handleErrorLogging("high", data, error);
          return false;
        });
    }
  };

  savePracticeAttempt = async (userId, attemptData) => {
    console.log("Saving attemptData: ", attemptData);
    const self = this;

    this.db
      .collection("users")
      .doc(userId)
      .collection("practiceAttempts")
      .add(attemptData)
      .then((docRef) => {
        console.log("Module Attempt saved as document ID: " + docRef.id);
        self.writeLog("Saved practice attempt to doc ID " + docRef.id);
      })
      .catch(async (error) => {
        console.error("Error saving practice attempt document: ", error);
        self.writeLog("Error saving practice attempt: ", error);
        let data =
          "uid: " +
          userId +
          " | Error saving practice attempt document attempt.";
        await this.handleErrorLogging("high", data, error);
      });
  };

  approveModuleData = async (userId, allAttempts) => {
    return new Promise(async (resolve, reject) => {
      console.log(userId, allAttempts);
      const timestamps = await this.getServerTimestamp();
      if (allAttempts.length > 0 && allAttempts.length < 4) {
        //auto approve and write to ModuleXResults
        //Todo prevent duplicate attempts
        console.log("AUTO APPROVED ATTEMPTS!");
        const moduleId = allAttempts[0].moduleId;
        const collection = "module" + moduleId + "Results";
        let finalDoc = {
          uid: userId,
          email: allAttempts[0].email,
          training_facility: "n/a",
          term: allAttempts[0].term,
          courseCode: allAttempts[0].courseCode,
          cohortId: allAttempts[0].cohortId,
          manuallyApproved: true,
          approvedBy: this.auth.currentUser.email,
          approvedOn: timestamps.unixTimestampSeconds,
        };
        allAttempts.forEach((attempt, index) => {
          finalDoc[`attempt_${index + 1}_module_id`] = attempt.moduleId;
          finalDoc[`attempt_${index + 1}_passed`] = attempt.passed;
          finalDoc[`attempt_${index + 1}_createdAt`] = attempt.createdAt;
          finalDoc[`attempt_${index + 1}_lumps_found`] =
            attempt.totalTumorsFound;
          finalDoc[`attempt_${index + 1}_false_positives`] =
            attempt.falsePositives;
          finalDoc[`attempt_${index + 1}_percent_palpated`] =
            attempt.percentCoverage;
          finalDoc[`attempt_${index + 1}_exam_time`] = attempt.examTime;
          finalDoc[`attempt_${index + 1}_tumors`] = attempt.tumors;
        });

        this.db
          .collection(collection)
          .add(finalDoc)
          .then((newDoc) => {
            console.log(
              "Saved new results to " + collection + " for user " + userId
            );
            const newCollection = "module" + moduleId + "Errors";
            this.db
              .collection(newCollection)
              .doc(userId)
              .delete()
              .then(() => {
                console.log("Document Successfully Deleted!");
                resolve(true);
              });
          })
          .catch(async (error) => {
            console.log(
              "ERROR writing to " + collection + " for user " + userId + ": ",
              error
            );
            let data =
              "uid: " +
              userId +
              " | ERROR writing to " +
              collection +
              " for user " +
              userId;
            await this.handleErrorLogging("high", data, error);
            reject(false);
          });
      }
    });
  };

  /*removeModuleErrorsByUserAndModule = (userId, moduleId) => {
    const collection = "module" + moduleId + "Results";
    let errors = this.db.collection(collection).where('uid', '==', userId);
    errors.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });
  
  }*/

  moduleErrors = (moduleId) => {
    const collection = "module" + moduleId + "Errors";
    return this.db.collection(collection);
  };

  loadManualExamAttempts = (uId, moduleId) => {
    console.log(uId, moduleId);
    const userId = uId.trim();

    let collection = "manualExams";

    console.log(
      "retrieving docs for uId, module, collection ",
      uId,
      moduleId,
      collection
    );

    return this.db
      .collection("users")
      .doc(userId)
      .collection(collection)
      .where("modelId", "==", moduleId)
      .orderBy("createdAt", "asc");
  };

  getManualExamsFromCohort = async (cohortId, userIds) => {
    //let manualExams = ["test", "test", "test", "test", "test"];
    let manualExamAttempts = await Promise.all(
      userIds.map(async (id) => {
        let query = await this.db
          .collection("users")
          .doc(id)
          .collection("manualExams")
          .orderBy("createdAt", "asc")
          .get();
        return await Promise.all(
          query.docs.map(async (doc) => {
            let obj = { id: doc.id, ...doc.data() };
            obj.json = await JSON.parse(obj.json);
            return obj;
          })
        );
      })
    );
    return manualExamAttempts.filter((el) => el.length > 0).flat();
  };

  loadMyModuleAttempts = (uId, moduleId, cohortId) => {
    console.log(uId, moduleId, cohortId);
    uId = uId.trim();

    let collection = null;

    switch (moduleId) {
      case "p2Pretraining":
        collection = "p2PretrainingAttempts";
        break;
      case "p2PostTraining":
        collection = "p2PostTrainingAttempts";
        break;
      case 2:
        collection = "module2Attempts";
        break;
      case "2invalid":
        collection = "module2InvalidAttempts";
        break;
      case 3:
        collection = "module3Attempts";
        break;
      case "3invalid":
        collection = "module3InvalidAttempts";
        break;
      case 4:
        collection = "module4Attempts";
        break;
      case "4invalid":
        collection = "module4InvalidAttempts";
        break;
      case "practice":
        collection = "practiceAttempts";
        break;
      default:
        console.log("Unknown collection:", moduleId);
        break;
    }

    console.log(
      "retrieving docs for uId, module, collection ",
      uId,
      moduleId,
      collection
    );

    return moduleId === "practice"
      ? this.db
          .collection("users")
          .doc(uId)
          .collection(collection)
          .where("cohortId", "==", cohortId)
      : this.db
          .collection("users")
          .doc(uId)
          .collection(collection)
          .where("cohortId", "==", cohortId)
          .orderBy("createdAt", "asc");
  };

  loadTourModuleAttempts = (uId, moduleId) => {
    uId = uId.trim();

    let collection = null;

    switch (moduleId) {
      case "p2Pretraining":
        collection = "p2PretrainingAttempts";
        break;
      case "p2PostTraining":
        collection = "p2PostTrainingAttempts";
        break;
      case 2:
        collection = "module2Attempts";
        break;
      case 3:
        collection = "module3Attempts";
        break;
      case 4:
        collection = "module4Attempts";
        break;
      default:
        console.log("Unknown collection:", moduleId);
        return;
        break;
    }

    // console.log(
    //   "retrieving docs for uId, module, collection ",
    //   uId,
    //   moduleId,
    //   collection
    // );

    return this.db
      .collection("tourUsers")
      .doc(uId)
      .collection(collection)
      .orderBy("createdAt", "asc");
  };

  logs = (uId) => {
    uId = uId.trim();
    return this.db
      .collection("users")
      .doc(uId)
      .collection("logs")
      .orderBy("timestamp", "desc");
  };

  courseTemplateLogs = (templateId) => {
    templateId = templateId.trim();
    return this.db
      .collection("courseTemplates")
      .doc(templateId)
      .collection("logs")
      .orderBy("time", "desc");
  };

  writeLog = async (message) => {
    return new Promise(async (resolve, reject) => {
      let data = null;
      if (this.userData) {
        console.log(
          "don't need to get userData in writeLog this time, already got it on sign in"
        );
        data = this.userData;
      } else {
        console.log("getting userData in writeLog");
        let docRef = await this.db
          .collection("users")
          .doc(this.auth.currentUser.uid)
          .get();

        this.userData = { id: docRef.id, ...docRef.data() };
        data = { id: docRef.id, ...docRef.data() };
      }
      const timestamps = await this.getServerTimestamp();
      const date = new Date(timestamps.firebaseTimestampDate);
      this.db
        .collection("users")
        .doc(this.auth.currentUser.uid)
        .collection("logs")
        .add({
          timestamp: timestamps.unixTimestampSeconds,
          displayDate: date.toString(),
          message: message,
          cohortId: data.cohortId,
          email: data.email,
          userId: this.auth.currentUser.uid,
        })
        .then((docRef) => {
          console.log("Added Log ", docRef.id);
          resolve(true);
        })
        .catch(async (error) => {
          console.error("Error writing log", error);
          let data =
            "uid: " +
            this.auth.currentUser.uid +
            " | Error writing log message: " +
            message;
          await this.handleErrorLogging("high", data, error);
          reject(false);
        });
    });
  };

  writeMarkCompleteLog = async (userId, activityObj, markedCompleteBy) => {
    const timestamps = await this.getServerTimestamp();
    const date = new Date(timestamps.firebaseTimestampDate);
    const message =
      activityObj.title + " was marked complete by " + markedCompleteBy;

    this.db
      .collection("users")
      .doc(userId)
      .collection("logs")
      .add({
        timestamp: timestamps.unixTimestampSeconds,
        displayDate: date.toString(),
        message: message,
      })
      .then((docRef) => {
        console.log("Added Log ", docRef.id);
      })
      .catch(async (error) => {
        console.error("Error writing log", error);
        let data =
          "uid: " +
          this.auth.currentUser.uid +
          " | Error writing log message: " +
          message;
        await this.handleErrorLogging("high", data, error);
      });
  };

  writeGradeResetLog = async (userId, activityObj, resetBy) => {
    const timestamps = await this.getServerTimestamp();
    const date = new Date(timestamps.firebaseTimestampDate);
    const message = activityObj.title + " was reset by " + resetBy;

    this.db
      .collection("users")
      .doc(userId)
      .collection("logs")
      .add({
        timestamp: timestamps.unixTimestampSeconds,
        displayDate: date.toString(),
        message: message,
      })
      .then((docRef) => {
        console.log("Added Log ", docRef.id);
      })
      .catch(async (error) => {
        console.error("Error writing log", error);
        let data =
          "uid: " +
          this.auth.currentUser.uid +
          " | Error writing log message: " +
          message;
        await this.handleErrorLogging("high", data, error);
      });
  };

  writeReenrollmentLog = async (uid, message) => {
    let timestamps = await this.getServerTimestamp();

    const date = new Date(timestamps.firebaseTimestampDate);
    this.db
      .collection("users")
      .doc(uid)
      .collection("logs")
      .add({
        timestamp: timestamps.unixTimestampSeconds,
        displayDate: date.toString(),
        message: message,
      })
      .then((docRef) => {
        console.log("Added Log ", docRef.id);
      })
      .catch(async (error) => {
        console.error("Error writing log", error);
        let data =
          "uid: " +
          uid +
          " | Message: " +
          message +
          "| Error writing writeReenrollmentLog log";
        await this.handleErrorLogging("high", data, error);
      });
  };

  // *** DOMAIN FUNCTIONS ***
  domain = (uid) => this.db.doc(`approvedDomains/${uid}`);

  domains = () => this.db.collection("approvedDomains");

  newCohort = async (props) => {
    return new Promise(async (resolve, reject) => {
      const timestamps = await this.getServerTimestamp();
      let numAccessCodes = props.numAccessCodes;
      let idOfCopiedCohort = props.idOfCopiedCohort;
      delete props.numAccessCodes;
      delete props.idOfCopiedCohort;
      let potentialFaculty = props.faculty;
      let potentialEmails = props.enrollmentEmailRecipients;
      let faculty = [];
      let emailRecipients = [];

      console.log(potentialFaculty, potentialEmails);

      if (
        (potentialFaculty && potentialFaculty.length > 0) ||
        (potentialEmails && potentialEmails.length > 0)
      ) {
        faculty = await Promise.all(
          potentialFaculty.map(async (fac) => {
            console.log(fac);
            if (fac.id && fac.email) {
              return fac;
            } else {
              console.log("missing id");
              const users = await this.userByEmail(fac.email);
              console.log(users);
              if (users.length === 1) {
                let userRef = this.getDocByIdFrom("users", users[0].id);
                let cohortRef = this.getDocByIdFrom("cohorts", props.id);
                await userRef
                  .update({
                    facultyFor: this.fieldValue.arrayUnion(cohortRef.id),
                    roles: this.fieldValue.arrayUnion("FACULTY"),
                  })
                  .catch((e) =>
                    console.log(`Problem updating user ${users[0].id}`)
                  );

                return { email: fac.email, id: users[0].id };
              }
            }
          })
        );
        console.log(faculty);
        emailRecipients = await Promise.all(
          potentialEmails.map(async (el) => {
            if (el.id && el.email) {
              return el;
            } else {
              const users = await this.userByEmail(el.email);
              if (users.length === 1) {
                let userRef = this.getDocByIdFrom("users", users[0].id);

                return { email: el.email, id: users[0].id };
              }
            }
          })
        );
      }

      await this.db
        .collection("cohorts")
        .doc(props.id)
        .set({
          ...props,
          createdAt: timestamps.unixTimestampSeconds,
          enrollmentEmailRecipients: emailRecipients,
          faculty: faculty,
          signUps: 0,
          disabled: false,
        })
        .then(async () => {
          let courseTemplate = null;
          if (idOfCopiedCohort) {
            courseTemplate = await this.course(
              idOfCopiedCohort,
              props.templateId
            ).get();
          } else {
            courseTemplate = await this.db
              .collection("courseTemplates")
              .doc(props.templateId)
              .get();
          }

          this.db
            .collection("cohorts")
            .doc(props.id)
            .collection("courseTemplates")
            .doc(props.templateId)
            .set({ ...courseTemplate.data() });
        })
        .catch((error) => {
          reject({ status: "fail", message: error.message });
        });
      if (numAccessCodes > 0) {
        let accessCodes = [];
        let now = timestamps.unixTimestampSeconds;
        for (let i = 0; i < numAccessCodes; i++) {
          accessCodes.push({
            cohortId: props.id,
            hasToPay: props.accessCodeHasToPay,
            hasToShip: props.accessCodeHasToShip,
            createdAt: now,
          });
        }
        accessCodes.forEach(async (code) => {
          let docRef = await this.db.collection("accessCodes").add(code);
          await docRef.update({ accessCode: docRef.id });
        });
      }

      resolve({ status: "success", message: "success" });
    });
  };

  editCohort = (id, cohort, updates) => {
    return new Promise(async (resolve, reject) => {
      let cohortDocRef = this.db.collection("cohorts").doc(id);
      let cohortDoc = await cohortDocRef.get();
      let cohortDocData = cohortDoc.data();
      const timestamps = await this.getServerTimestamp();

      if (updates.numAccessCodes && updates.numAccessCodes > 0) {
        let numAccessCodes = updates.numAccessCodes;
        let accessCodes = [];
        let now = timestamps.unixTimestampSeconds;
        delete updates.numAccessCodes;

        for (let i = 0; i < numAccessCodes; i++) {
          accessCodes.push({
            cohortId: cohort.id,
            hasToPay: cohort.accessCodeHasToPay,
            hasToShip: cohort.accessCodeHasToShip,
            createdAt: now,
          });
        }
        accessCodes.forEach(async (code) => {
          let docRef = await this.db.collection("accessCodes").add(code);
          await docRef.update({ accessCode: docRef.id });
        });
      }

      if (updates.faculty && updates.faculty.length > 0) {
        let passedFaculty = [...updates.faculty];
        let finalFaculty = [];

        for (let faculty of passedFaculty) {
          if (faculty.email && faculty.id) {
            finalFaculty.push(faculty);
          } else {
            const users = await this.userByEmail(faculty.email);
            if (users.length === 1) {
              let userRef = this.getDocByIdFrom("users", users[0].id);
              let cohortRef = this.getDocByIdFrom("cohorts", id);
              await userRef
                .update({
                  facultyFor: this.fieldValue.arrayUnion(cohortRef.id),
                  roles: this.fieldValue.arrayUnion("FACULTY"),
                })
                .catch((e) =>
                  console.log(`Problem updating user ${users[0].id}`)
                );

              finalFaculty.push({ email: faculty.email, id: users[0].id });
            }
          }
        }
        updates.faculty = [...finalFaculty];
      }

      if (updates.removeFaculty && updates.removeFaculty.length > 0) {
        let passedRemoval = [...updates.removeFaculty];
        let finalFaculty = [...cohortDocData.faculty];

        for (let faculty of passedRemoval) {
          console.log(faculty);
          let index = finalFaculty.findIndex(
            (el) => el.id === faculty.id && el.email === faculty.email
          );
          finalFaculty.splice(index, 1);
          let userRef = this.db.collection("users").doc(faculty.id);
          let userDoc = await userRef.get();
          let userDocData = userDoc.data();
          console.log(userDocData);

          let newFacultyFor = [];
          if (userDocData.facultyFor && userDocData.facultyFor.length > 0) {
            newFacultyFor = userDocData.facultyFor.filter(
              (cohort) => cohort !== cohortDoc.id
            );
            console.log(newFacultyFor);
            if (newFacultyFor.length === 0) {
              newFacultyFor = [];
            }
          }

          let userUpdateObj = {
            facultyFor: newFacultyFor,
            roles:
              newFacultyFor && newFacultyFor.length > 0
                ? userDocData.roles
                : this.fieldValue.arrayRemove("FACULTY"),
          };
          console.log(userUpdateObj);
          userRef.update(userUpdateObj);
        }
        updates.faculty = [...finalFaculty];
      }

      if (
        updates.enrollmentEmailRecipients &&
        updates.enrollmentEmailRecipients.length > 0
      ) {
        let passedRecipients = [...updates.enrollmentEmailRecipients];
        let finalRecipients = [];

        for (let rec of passedRecipients) {
          if (rec.email && rec.id) {
            finalRecipients.push(rec);
          } else {
            const users = await this.userByEmail(rec.email);
            if (users.length === 1) {
              finalRecipients.push({ email: rec.email, id: users[0].id });
            }
          }
        }
        updates.enrollmentEmailRecipients = finalRecipients;
      }

      if (updates.removeRecipients && updates.removeRecipients.length > 0) {
        let passedRemoval = [...updates.removeRecipients];
        let finalRecipients = [...cohortDocData.enrollmentEmailRecipients];

        for (let rec of passedRemoval) {
          let index = finalRecipients.findIndex(
            (el) => el.id === rec.id && el.email === rec.email
          );
          finalRecipients.splice(index, 1);
        }
        updates.enrollmentEmailRecipients = finalRecipients;
      }

      console.log("UPDATES: ", updates);

      await cohortDocRef
        .update(updates)
        .catch((error) => reject({ status: "fail", message: error.message }));

      resolve({ status: "success", message: "success" });
    });
  };

  generateAccounts = async (cohortId, state) => {
    console.log("params made it to firebase: ", cohortId, state);
    let baseUrlEndPoint =
      process.env.REACT_APP_STAGE == "prod"
        ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
        : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";
    let siteUrl =
      process.env.REACT_APP_STAGE == "prod"
        ? "https://learn.mammacare.org/"
        : "https://mammacare-training-app-dev.firebaseapp.com/";

    let url =
      baseUrlEndPoint +
      "createGenericUserAccounts?cohortId=" +
      encodeURI(cohortId) +
      "&state=" +
      encodeURI(state);
    try {
      let response = await Axios.post(url, {
        cohortId: cohortId,
        state: state,
      });
      console.log("REPONSE IN FIREBASE: ", response);
      let data = response.data;
      if (data.status === "success") {
        let accounts = JSON.parse(data.message);
        if (accounts.length > 0) {
          let json = JSON.parse(state);
          let split = cohortId.split("_");
          let downloadStr = `Course Code|${split[1]}\nTerm|${split[2]}\n\nVisit learn.mammacare.org and click "Sign In" to use these accounts\n\nEmail|Password|Trainee\n`;
          accounts.forEach((acc) => {
            downloadStr += `${acc.email}|${acc.password}|\n`;
          });
          downloadStr += "\n";
          if (accounts.length === parseInt(json.numAccounts)) {
            return {
              status: "success",
              title: "Success",
              message: `${accounts.length} accounts successfully created`,
              download: downloadStr,
            };
          } else {
            return {
              status: "warning",
              title: "Warning",
              message: `${accounts.length} accounts were created instead of ${json.numAccounts} because some of the requested accounts already exist.`,
              download: downloadStr,
            };
          }
        } else {
          return {
            status: "warning",
            title: "Warning",
            message: `No accounts were created. Maybe you were trying to create accounts with emails that were already used?`,
          };
        }
      }
    } catch (e) {
      return {
        status: "error",
        title: "Error",
        message: e.message,
      };
    }
  };

  importClassRoster = async (cohortId, roster, password, shouldIncrement) => {
    return new Promise(async (resolve, reject) => {
      console.log(roster, password, shouldIncrement);
      let url = `${this.cloudFunctionsURL}/createUsersFromClassRoster`;
      console.log(url);

      try {
        let response = await Axios.post(url, {
          cohortId: cohortId,
          roster: roster,
          password: password,
          shouldIncrement: shouldIncrement,
        });

        console.log(response);

        resolve({
          status: "success",
          title: "Success",
          message: response.data,
        });
      } catch (e) {
        console.log("caught an error");
        resolve({
          status: "warning",
          title: "Warning",
          message: `An error occurred while importing users.`,
        });
      }
    });
  };

  getCohortsByCourseCodeAndTerm = async (courseCode, term) => {
    try {
      let cohortDocs = await this.db
        .collection("cohorts")
        .where("courseCode", "==", courseCode)
        .where("term", "==", term)
        .get();
      if (cohortDocs.metadata.fromCache) {
        throw new Error(
          "Error getting cohorts by course code and term because client cannot contact database."
        );
      }
      let cohorts = [];
      for (let doc of cohortDocs.docs) {
        cohorts.push({ id: doc.id, ...doc.data() });
      }
      return cohorts;
    } catch (e) {
      console.log(e);
      throw new Error(
        e.message || "Error fetching cohorts by course code and term."
      );
    }
  };

  getCohortsByDomain = (domain) =>
    this.db.collection("cohorts").where("domain", "==", domain);

  getCohortFromCourseCodeAndTerm = async (courseCode, term) => {
    let cohortsRef = this.db.collectionGroup("cohorts");
    let templateRef = await cohortsRef
      .where("courseCode", "==", courseCode)
      .where("term", "==", term)
      .get();
    let cohortDoc = templateRef.docs[0];
    let cohort = { id: cohortDoc.id, ...cohortDoc.data() };

    return cohort;
  };

  getCourseFromCourseCodeAndTerm = async (courseCode, term) => {
    let coursesRef = this.db.collectionGroup("courses");
    let templateRef = await coursesRef
      .where("courseCode", "==", courseCode)
      .where("term", "==", term)
      .get();
    for (let course of templateRef.docs) {
      console.log(
        "Found matching course Code and retrieved templateId: " +
          course.data().templateId
      );
      console.log(course.data());
      return course.data();
    }
  };

  // *** COURSES ***
  courseTemplates = () => this.db.collection(`courseTemplates`);

  //{id, title, shortCode, sections, "updatedAt": timestamp });
  saveCourseTemplate = async (courseTemplateData) => {
    const timestamps = await this.getServerTimestamp();
    //update exisitng template
    if (courseTemplateData.id !== "" && courseTemplateData.id !== undefined) {
      const templateRef = this.db
        .collection("courseTemplates")
        .doc(courseTemplateData.id);
      if (templateRef.exists) {
        let update = templateRef
          .update({
            title: courseTemplateData.title,
            shortCode: courseTemplateData.shortCode,
            sections: courseTemplateData.sections,
            parcels: courseTemplateData.parcels,
            updatedAt: timestamps.unixTimestampSeconds,
          })
          .then(() => {
            return "success";
          })
          .catch(async (error) => {
            console.log(error);
            let data =
              "courseTemplateId: " +
              courseTemplateData.id +
              " | Title: " +
              courseTemplateData.title;
            await this.handleErrorLogging("high", data, error);
          });
      } else {
        console.log(
          "ERROR course template doc with id: " +
            courseTemplateData.id +
            " does not exist!"
        );
        let data =
          "courseTemplateId: " +
          courseTemplateData.id +
          " | Title: " +
          courseTemplateData.title;
        await this.handleErrorLogging(
          "high",
          data,
          courseTemplateData.id + " does not exist!"
        );
        return courseTemplateData.id + " does not exist!";
      }
    } else {
      //create new template
      this.db
        .collection("courseTemplates")
        .add({
          title: courseTemplateData.title,
          shortCode: courseTemplateData.shortCode,
          sections: courseTemplateData.sections,
          parcels: courseTemplateData.parcels,
          createdAt: timestamps.unixTimestampSeconds,
          updatedAt: timestamps.unixTimestampSeconds,
        })
        .then((docRef) => {
          //update saved id
          let update = docRef.update({ id: docRef.id });

          console.log("Updated course template id to " + docRef.id);
          return "success";
        })
        .catch(async (error) => {
          console.error("Error updating new course template id:", error);
          let data =
            "courseTemplateId: " +
            courseTemplateData.id +
            " | Title: " +
            courseTemplateData.title;
          await this.handleErrorLogging("high", data, error);
          return error.message;
        });
    }
  };

  course = (cohortId, templateId) =>
    this.db
      .collection("cohorts")
      .doc(cohortId)
      .collection("courseTemplates")
      .doc(templateId);

  // *** ACTIVITIES ***
  courseActivities = () => this.db.collection(`courseActivities`);

  saveActivity = (activityData) => {
    //update exisitng activity
    console.log("ACTIVITY DATA: ", activityData);
    if (activityData.id !== "") {
      /*const activityRef = this.db.collection('courseActivities').doc(activityData.id);*/
      const activityRef = this.getDocByIdFrom(
        "courseActivities",
        activityData.id
      );
      activityRef
        .update({
          mammacareId: activityData.mammacareId,
          title: activityData.title,
          label: activityData.label,
          type: activityData.type,
          quiz: activityData.quiz,
          pdf: activityData.pdf,
          instructions: activityData.instructions,
          video: activityData.video,
          completionTime: activityData.completionTime,
          excludeFromAnalytics: activityData.excludeFromAnalytics,
        })
        .then(() => {
          return "success";
        })
        .catch(async (error) => {
          console.log(error);
          let data =
            "activityId: " +
            activityData.id +
            " | Title: " +
            activityData.title +
            " | PDF: " +
            activityData.pdf +
            " | Video: " +
            activityData.video;
          await this.handleErrorLogging("high", data, error);
        });
      /*else {
        console.log("ERROR doc with id: " + activityData.id + " does not exist!");
        let data = activityData.id + " does not exist!";
        await this.handleErrorLogging("high", data, data);
        return activityData.id + " does not exist!";
      }*/
    } else {
      //create new activity
      this.db
        .collection("courseActivities")
        .add({
          mammacareId: activityData.mammacareId,
          title: activityData.title,
          label: activityData.label,
          type: activityData.type,
          quiz: activityData.quiz,
          pdf: activityData.pdf,
          instructions: activityData.instructions,
          video: activityData.video,
          completionTime: activityData.completionTime,
          excludeFromAnalytics: activityData.excludeFromAnalytics,
        })
        .then((docRef) => {
          //update saved id
          let update = docRef.update({ id: docRef.id });

          console.log("Updated activity id to " + docRef.id);
          return "success";
        })
        .catch(async (error) => {
          console.error("Error updating new activity id:", error);
          let data = "Error updating activity ID: " + activityData.id;
          await this.handleErrorLogging("high", data, error);
          return error.message;
        });
    }
  };

  getActivityDocs = (activityIds) => {
    return new Promise(async (res, rej) => {
      let activityDocs = [];
      for (let id of activityIds) {
        let query = await this.db
          .collection("courseActivities")
          .doc(id)
          .get()
          .catch((e) => {
            rej({
              status: "error",
              message:
                "An error occurred while attempting to download activity data for this cohort.",
              data: [],
            });
          });
        activityDocs.push({ id: query.id, ...query.data() });
      }
      res({
        status: "success",
        message: "Successfully fetched cohort activity data.",
        data: activityDocs,
      });
    });
  };

  // **** COURSE RESOURCES
  createResource = (newResource) =>
    this.db.collection(`courseResources`).add(newResource);
  courseResources = () => this.db.collection(`courseResources`);

  // **** EMAILS
  sendResults = async (to, subject, message, userId) => {
    return new Promise(async (resolve, reject) => {
      let catchArray = [];
      let counter = 0;

      const timestamps = await this.getServerTimestamp();

      let baseUrlEndPoint =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";
      let siteUrl =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://learn.mammacare.org/"
          : "https://mammacare-training-app-dev.firebaseapp.com/";
      message =
        message + "<br /><br />User Grades: " + siteUrl + "grades/" + userId;

      while (counter < to.length) {
        console.log("Emailing " + to[counter]);
        let url =
          baseUrlEndPoint +
          "sendResults?to=" +
          encodeURI(to[counter]) +
          "&subject=" +
          encodeURI(subject) +
          "&message=" +
          encodeURI(message);
        try {
          await Axios.get(url);
          await this.db.collection("emailLogs").add({
            timestamp: timestamps.unixTimestampSeconds,
            to: to[counter],
            message: message,
          });
          catchArray.push({ status: "success", message: to[counter] });
        } catch (e) {
          catchArray.push({ status: "fail", message: e.message });
        }
        counter++;
      }

      let status = catchArray.filter((el) => el.status === "fail");
      if (status.length > 0) {
        await await this.handleErrorLogging(
          "high",
          "Send Email Results Error: ",
          status[0].message
        );
        reject({ status: "fail", message: catchArray });
      } else {
        resolve({ status: "success", message: catchArray });
      }
    });
  };

  sendEmail = async (to, subject, message) => {
    return new Promise(async (resolve, reject) => {
      if (to.length === 0) {
        reject({
          status: "fail",
          message: "No emails were supplied to sendEmail",
        });
      }
      let catchArray = [];
      let counter = 0;
      const timestamps = await this.getServerTimestamp();

      let baseUrlEndPoint =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";
      let siteUrl =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://learn.mammacare.org/"
          : "https://mammacare-training-app-dev.firebaseapp.com/";

      while (counter < to.length) {
        console.log("Emailing " + to[counter]);
        let url =
          baseUrlEndPoint +
          "sendResults?to=" +
          encodeURI(to[counter]) +
          "&subject=" +
          encodeURI(subject) +
          "&message=" +
          encodeURI(message);
        try {
          await Axios.get(url);
          await this.db.collection("emailLogs").add({
            timestamp: timestamps.unixTimestampSeconds,
            to: to[counter],
            message: message,
          });
          catchArray.push({ status: "success", message: to[counter] });
        } catch (e) {
          console.log("cannot add to email logs...");
          catchArray.push({ status: "fail", message: e.message });
        }
        counter++;
      }

      let status = catchArray.filter((el) => el.status === "fail");
      if (status.length > 0) {
        await await this.handleErrorLogging(
          "high",
          "Send Email Results Error: ",
          status[0].message
        );
        reject({ status: "fail", message: catchArray });
      } else {
        resolve({ status: "success", message: catchArray });
      }
    });
  };

  sendShippingEmail = async (email, message) => {
    return new Promise(async (resolve, reject) => {
      console.log(email);
      let counter = 0;
      let to = ["accounts@mammacare.org, lucas@mammacare.org"];
      const timestamps = await this.getServerTimestamp();

      let url = `${this.cloudFunctionsURL}/sendShippingEmail`;

      while (counter < to.length) {
        console.log("Emailing " + to[counter]);
        // let url =
        //   baseUrlEndPoint +
        //   "sendShippingEmail?to=" +
        //   encodeURI(to[counter]) +
        //   "&message=" +
        //   encodeURI(message);
        try {
          await Axios.post(url, {
            to: to[counter],
            message,
          });
          await this.db.collection("emailLogs").add({
            timestamp: timestamps.unixTimestampSeconds,
            to: to[counter],
            message: message,
          });
          resolve();
        } catch (e) {
          await this.handleErrorLogging(
            "high",
            `Error sending shipping information email for ${email}. Check the 'Generated Shipping Labels of their user information for the address and parcel to be shipped.`,
            e.message
          );
          reject();
        }
        counter++;
      }
    });
  };

  // **** ACCESS CODES
  redeemAccessCode = async (userId, accessCode, cohortId) => {
    let baseUrlEndPoint =
      process.env.REACT_APP_STAGE == "prod"
        ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
        : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";
    let siteUrl =
      process.env.REACT_APP_STAGE == "prod"
        ? "https://learn.mammacare.org/"
        : "https://mammacare-training-app-dev.firebaseapp.com/";

    let url =
      baseUrlEndPoint +
      "onRedeemAccessCode?userId=" +
      encodeURI(userId) +
      "&accessCode=" +
      encodeURI(accessCode) +
      "&cohortId=" +
      encodeURI(cohortId);
    try {
      return await Axios.get(url);
    } catch (e) {
      return e.message;
    }
  };

  // **** STRIPE PRODUCTS
  stripeProducts = () => this.db.collection("stripeProducts");

  updateAvailableSeats = async (cohortId, seatAdjustment) => {
    //seatAdjust can be + or -
    const cohortRef = this.getDocByIdFrom("cohorts", cohortId);
    const cohort = await cohortRef.get().catch(async (error) => {
      await this.handleErrorLogging(
        "high",
        "Unable to get cohort with id " + cohortId,
        error.message
      );
      return false;
    });

    if (cohort.exists) {
      if (cohort.data().availableSeats === undefined) return true;

      //update the number of available seats
      const currentSeats = cohort.data().availableSeats;
      const newSeatsAvailable = currentSeats + seatAdjustment;
      if (newSeatsAvailable >= 0) {
        await cohortRef
          .update({ availableSeats: this.fieldValue.increment(seatAdjustment) }) //atomic counter
          .catch(async (error) => {
            await this.handleErrorLogging(
              "high",
              "Unable to update available seats in cohort with id " + cohortId,
              error.message
            );
            return false;
          });
        return true;
      } else {
        await cohortRef.update({ availableSeats: 0 }).catch(async (error) => {
          await this.handleErrorLogging(
            "high",
            "Unable to update available seats in cohort with id " + cohortId,
            error.message
          );
          return false;
        });
        return true;
      }
    } else return false;
  };

  updateCohortSignUps = async (cohortId) => {
    //only update signUps counter atomically
    const cohortRef = this.getDocByIdFrom("cohorts", cohortId);
    await cohortRef
      .update({ signUps: this.fieldValue.increment(1) })
      .catch(async (error) => {
        await this.handleErrorLogging(
          "high",
          "Unable to update signUps in cohort id " + cohortId,
          error.message
        );
        return false;
      });
    return true;
  };

  updateCohortSettings = async (cohortId, newSettings) => {
    console.log("Updating cohort id " + cohortId + " settings: ", newSettings);
    const cohortRef = this.getDocByIdFrom("cohorts", cohortId);
    await cohortRef.update({ ...newSettings }).catch(async (error) => {
      await this.handleErrorLogging(
        "high",
        "Unable to update cohort settings for cohort id " + cohortId,
        error.message
      );
    });
  };

  createShippingLabel = (user, address, parcelData) => {
    console.log("firebase createShippingLabel:: ", address, parcelData);
    const self = this;
    return new Promise(function (resolve, reject) {
      let url = `${self.cloudFunctionsURL}/createShippingLabel`;
      // let url =
      //   process.env.REACT_APP_STAGE == "prod"
      //     ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/createShippingLabel"
      //     : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/createShippingLabel?address=" +
      //       encodeURI("1017 NW 11th Ave Gaiensville FL 32601");

      console.log(url);

      Axios.post(url, {
        data: {
          address: address,
          parcel: parcelData,
        },
      })
        .then(function (response) {
          console.log(response);
          //add cohortId and user info to new shipment doc
          const shipmentObj = {
            data: response.data,
            cohortId: user.cohortId,
            userId: user.uid,
            parcel: parcelData,
          };

          if (response.data.id === undefined) {
            console.log(
              "Firebase response is an invalid address: ",
              shipmentObj
            );
            reject(shipmentObj);
          } else {
            //create new shipment doc
            console.log("Saving new shipment obj: ", shipmentObj);
            let userRef = self.db.collection("users").doc(user.uid);
            userRef
              .collection("shipments")
              .doc(response.data.id)
              .set({
                ...shipmentObj,
              })
              .then(function () {
                console.log("Shipment Document successfully written!");
                resolve(response);
              })
              .catch(function (error) {
                console.error("Error writing shipment document: ", error);
                reject(error);
              });
          }
        })
        .catch(function (error) {
          console.log("rejecting on line 924: ", error);
          reject(error);
        });
    });
  };

  buyShippingLabel = (uid, email, shipmentId) => {
    console.log("firebase buyShippingLabel:: ", shipmentId);
    const self = this;
    return new Promise(function (resolve, reject) {
      let url =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/buyShippingLabel"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/buyShippingLabel";

      Axios.post(url, {
        data: {
          shipmentId: shipmentId,
        },
      })
        .then(async (response) => {
          console.log("firebase response: ", response);
          console.log("shipment created: ", response.data);
          console.log("Saving tracking information");
          if (response.data.error) {
            console.log("error in response data");
            await self.handleErrorLogging(
              "high",
              "Failed to purchase shipping label",
              "Failed to purchase shipping label for " + email
            );
            reject({
              error: "purchaseError",
              message: "Could not purchase shipping label",
            });
          } else {
            let userRef = self.db.collection("users").doc(uid);
            userRef.update({ trackerId: response.data.tracker.id });
            userRef
              .collection("trackers")
              .doc(shipmentId)
              .set({
                ...response.data,
              })
              .then(async () => {
                console.log("Tracker Document successfully written!");
                const subject = "Print UPS Label for " + email;
                const message = response.data.postage_label.label_url;
                await self.sendResults(
                  ["webmaster@mammacare.org", "accounts@mammacare.org"],
                  subject,
                  message,
                  uid
                );
                resolve(response.data);
              })
              .catch(async (error) => {
                console.error("Error writing Tracker document: ", error);
                await this.handleErrorLogging(
                  "high",
                  JSON.stringify(response.data),
                  "Failed to write tracking data to firebase for " + email
                );
                reject({
                  error: "databaseError",
                  message: "Could not write tracking information to database",
                });
              });
          }
        })
        .catch(async (error) => {
          console.error("Error buying shipment label: ", error);
          await this.handleErrorLogging(
            "high",
            "Failed to purchase shipping label",
            "Failed to purchase shipping label for " + email
          );
          reject({
            error: "purchaseError",
            message: "Could not purchase shipping label",
          });
        });
    });
  };

  getTrackingId = async (userId) => {
    const user = await this.db.collection("users").doc(userId).get();
    return user.data().trackerId;
  };

  loadTrackingData = (trackerId) => {
    console.log("firebase loadTrackingData:: ", trackerId);
    const self = this;
    return new Promise(function (resolve, reject) {
      let url =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/getTrackingData"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/getTrackingData";

      Axios.post(url, {
        data: {
          trackerId: trackerId,
        },
      })
        .then(async (response) => {
          console.log("firebase tracker response: ", response);
          resolve(response);
        })
        .catch(function (error) {
          console.error("Error loading tracking data: ", error);
          reject(error);
        });
    });
  };

  processActivityData = (cohortId, userIds) => {
    //console.log(userIds);
    return new Promise(async (resolve, reject) => {
      let url =
        process.env.REACT_APP_STAGE == "prod"
          ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/processActivities"
          : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/processActivities";

      let res = await Axios.post(url, {
        data: {
          cohortId: cohortId,
          userIds: userIds,
        },
      }).catch((e) => {
        reject({ status: "fail", message: e.message });
      });

      resolve(res);
    });
  };

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      console.log(e);
      return false;
    }
    return true;
  }

  processGeorgiaRegistrationData = async (uid, course, docId) => {
    let baseUrlEndPoint =
      process.env.REACT_APP_STAGE == "prod"
        ? "https://us-central1-mamamcare-pad-firebase.cloudfunctions.net/"
        : "https://us-central1-mammacare-training-app-dev.cloudfunctions.net/";

    //let url = baseUrlEndPoint + "processGeorgiaRegistrationData";
    let url = `${baseUrlEndPoint}processGeorgiaRegistrationData?uid=${encodeURI(
      uid
    )}&course=${encodeURI(course)}&docId=${encodeURI(docId)}`;

    let response = await Axios.get(url);

    return response;
  };

  processOldExamDataForPattern = async () => {
    console.log(
      "Preparing to process all old exam attempts to store pattern data..."
    );
    const batchArray = [this.db.batch()];
    let batchIndex = 0;
    let counter = 0;
    let index = 0;

    const mod2Ref = this.db.collectionGroup("module2Attempts");

    // let module2Docs = await mod2Ref.where("playBackFilename", "!=", "").get();

    // let module3Docs = await this.db
    //   .collectionGroup("module3Attempts")
    //   .where("playBackFilename", "!=", "")
    //   .get();

    // let module4Docs = await this.db
    //   .collectionGroup("module4Attempts")
    //   .where("playBackFilename", "!=", "")
    //   .get();

    let posttrainingDocs = await this.db
      .collectionGroup("p2PostTrainingAttempts")
      .where("playBackFilename", "!=", "")
      .get();

    // let pretrainingDocs = await this.db
    //   .collectionGroup("p2PretrainingAttempts")
    //   .where("playBackFilename", "!=", "")
    //   .get();

    let attemptDocs = [...posttrainingDocs.docs];
    console.log("Initial Length: ", attemptDocs.length);

    for (let doc of attemptDocs) {
      console.log(`Processing ${index + 1} of ${attemptDocs.length}`);
      index++;
      const board = [];
      let midiNum = 28;
      for (let x = 0; x < 9; x++) {
        board[x] = [];
        for (let y = 0; y < 8; y++) {
          board[x][y] = {
            x: x,
            y: y,
            active: false,
            midiNum: midiNum,
            light: false,
            medium: false,
            deep: false,
            velocity: 0,
          };
          midiNum++;
        }
      }

      let data = doc.data();

      let childRef = this.storage.ref(data.playBackFilename);
      let url = await childRef.getDownloadURL().catch((e) => {
        return null;
      });

      if (!url) continue;

      // console.log(
      //   `Email ${data.email} Module ${data.moduleId} DocID ${doc.id}`
      // );
      let file;
      try {
        file = await (await fetch(url)).blob();
      } catch {
        break;
      }

      let zip = new jszip();
      let extracted = await zip.loadAsync(file);
      let str = await extracted.file("exam.json").async("string");
      let json = JSON.parse(str);
      let palpationHistory;

      if (json.current) palpationHistory = json.current;
      else if (json.palpationStream) palpationHistory = json.palpationStream;
      else palpationHistory = json;

      let pollingCounter = palpationHistory.length / 72;
      let currentIteration = 0;
      let palpations = [];
      let currentPalpation = [];

      while (currentIteration < pollingCounter) {
        for (
          let i = 72 * currentIteration;
          i < 72 * currentIteration + 72;
          i++
        ) {
          let cell = palpationHistory[i];
          cell.maxPalpationVelocity = cell.velocity;
          board[cell.x][cell.y] = cell;
        }

        //get all active cells within the board for rendering
        let cells = [];
        for (let x = 0; x < 9; x++) {
          for (let y = 0; y < 8; y++) {
            if (board[x][y].active) {
              cells.push({
                active: board[x][y].active,
                midiNum: board[x][y].midiNum,
                x: x,
                y: y,
                light: board[x][y].light,
                medium: board[x][y].medium,
                deep: board[x][y].deep,
                velocity: board[x][y].velocity,
                maxPalpationVelocity: board[x][y].maxPalpationVelocity,
              });
            }
          }
        }

        if (cells.filter((el) => el.velocity >= 36).length === 0) {
          if (currentPalpation.length > 0) {
            let current = [...currentPalpation];
            const deepCells = current.filter(
              (c) => c.velocity >= PRESSURES.DEEPPRESSUREMIN
            );
            //console.log("deepCells: ", deepCells);

            // v2 calc center of cluster
            let xSum = Object.entries(deepCells).reduce(function (
              total,
              props
            ) {
              return total + props[1].x;
            },
            0);
            let ySum = Object.entries(deepCells).reduce(function (
              total,
              props
            ) {
              return total + props[1].y;
            },
            0);
            //console.log("xSum: ", xSum, " ySum: ", ySum);
            if (deepCells.length > 0) {
              const xCenter = xSum === 0 ? 0 : xSum / deepCells.length;
              const yCenter = ySum === 0 ? 0 : ySum / deepCells.length;
              const centroid = {
                x: xCenter,
                y: yCenter,
                text: palpations.length + 1,
              };
              palpations.push(centroid);
            }
          }
          currentPalpation = [];
        } else {
          currentPalpation = [...currentPalpation, ...cells].reduce(
            (newArray, item) => {
              let index = newArray.findIndex(
                (el) => el.midiNum === item.midiNum
              );
              if (index !== -1) {
                if (newArray[index].velocity < item.velocity) {
                  newArray[index].velocity = item.velocity;
                  return newArray;
                } else {
                  return newArray;
                }
              } else {
                return [...newArray, item];
              }
            },
            []
          );
        }
        currentIteration += 1;
      }

      batchArray[batchIndex].update(doc.ref, {
        pattern: palpations,
        processed: true,
      });
      counter++;
      if (counter === 499) {
        console.log(`${batchArray.length} batches full`);
        batchArray.push(this.db.batch());
        batchIndex++;
        counter = 0;
      }
    } // end for loop

    console.log("Total batches: " + batchIndex + 1);

    batchArray.forEach(async (batch, index) => {
      console.log("Committing batch #" + index + 1 + " / " + batchIndex + 1);
      await batch.commit();
    });
  };

  bulkWriteStats = async (cohortId) => {
    return new Promise(async (resolve, reject) => {
      let url = `${this.cloudFunctionsURL}/reprocessStatsByCohortId`;
      console.log(url);
      console.log(cohortId);

      try {
        let response = await Axios.post(url, {
          cohortId: cohortId,
        });
        console.log(response);
        if (response.data.status === "fail") {
          resolve({
            status: "error",
            title: "Error",
            message: "An error occurred.",
          });
        } else {
          resolve({
            status: "success",
            title: "Success",
            message: "Bulk processing completed.",
          });
        }
      } catch (e) {
        console.log(e);
        resolve({
          status: "error",
          title: "Error",
          message: "An error occurred.",
        });
      }
    });
  };

  registerShareableBreastModel = async (id, type) => {
    console.log(`Registering model type ${type} with ID: ${id}`);

    const timestamps = await this.getServerTimestamp();
    const date = new Date(timestamps.firebaseTimestampDate);

    const docRef = this.db.collection("sharedBreastModels").doc(id);

    return new Promise((resolve, reject) => {
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // Set the "capital" field of the city 'DC'
            docRef
              .update({
                registeredAt: date,
                type: type,
              })
              .then(() => {
                console.log("Model registration complete.");
                resolve(true);
              })
              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Failed to register model: ", error);
                resolve(false);
              });
          } else {
            console.log(`Doc ID ${id} does not exist.`);
            resolve(false);
          }
        })
        .catch((error) => {
          console.log(`Error getting document (${id}): ${error}`);
          resolve(false);
        });
    });
  };

  addSharedModelUsage = async (user, modelData, expectedModelType) => {
    return new Promise(async (resolve, reject) => {
      try {
        const timestamps = await this.getServerTimestamp();
        if (modelData.type === expectedModelType) {
          await this.db.collection("sharedBreastModelsUsageStats").add({
            checkInDate: new Date(timestamps.firebaseTimestampDate),
            modelId: modelData.id,
            type: modelData.type,
            uid: user.uid,
            email: user.email,
            roles: user.roles[0] || "user",
            cohortId: user.cohortId,
          });
          resolve();
        } else {
          reject("Incorrect model type scanned");
        }
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  };

  getServerTimestamp = async () => {
    return new Promise(async (res, rej) => {
      let url = `${this.cloudFunctionsURL}/returnServerTimestamp`;
      console.log(url);
      try {
        let response = await Axios.get(url);
        console.log(response);
        //Returns an object with two properties
        //1. firebaseTimestamp: the original firebase timestamp object
        //2. unixTimestampSeconds: the seconds property extracted from the firebase timestamp object
        res(response.data);
      } catch (e) {
        console.log(e);
        rej(e);
      }
    });
  };
}

export default Firebase;
