import React, { useContext } from "react";
import { AuthUserContext } from "../Session";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ResultsGrid from "../ResultsGrid/ResultsGrid";
import AdminResultsGrid from "../ResultsGrid/AdminResultsGrid";
import styled from "styled-components";

import { withStyles, lighten } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const ColorButton = withStyles((theme) => ({
  root: {
    width: "20%",
    fontWeight: "600",
    backgroundColor: lighten("#64b5f6", 0.25),
    color: "black",
    borderColor: lighten("#64b5f6", 0.25),
    margin: "20px auto",
    "&:hover": {
      backgroundColor: "#64b5f6",
      borderColor: "#64b5f6",
    },
  },
}))(Button);

const ModuleAttempts = (props) => {
  const context = useContext(AuthUserContext);
  const { t } = useTranslation();
  const { attempts, user } = props;
  console.log(
    "MODULE ATTEMPT PROPS: ",
    attempts.filter((el) => el.processed)
  );
  return (
    <>
      {attempts &&
        attempts.map((attempt, index) => {
          console.log(attempt.createdAt);
          let completed = attempt.createdAt.seconds
            ? new Date(attempt.createdAt.seconds * 1000).toLocaleString()
            : new Date(attempt.createdAt * 1000).toLocaleString();

          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
              }}
            >
              {attempt.pattern &&
              (context.roles.includes("ADMIN") ||
                context.roles.includes("STAFF")) ? (
                <AdminResultsGrid
                  key={index}
                  completed={completed}
                  passed={attempt.passed}
                  examTime={attempt.examTime}
                  palpatedCells={attempt.coverageGrid}
                  falsePositives={attempt.falsePositives}
                  tumors={attempt.tumors}
                  percentCoverage={attempt.percentCoverage}
                  pattern={attempt.pattern}
                  playback={attempt.playBackFilename}
                  replayExam={props.replayExam}
                  {...attempt}
                  noScroll={true}
                />
              ) : (
                <>
                  <ResultsGrid
                    key={index}
                    completed={completed}
                    passed={attempt.passed}
                    examTime={attempt.examTime}
                    palpatedCells={attempt.coverageGrid}
                    falsePositives={attempt.falsePositives}
                    tumors={attempt.tumors}
                    percentCoverage={attempt.percentCoverage}
                    {...attempt}
                    noScroll={true}
                  />
                  {attempt.playBackFilename ? (
                    <ColorButton
                      variant="outlined"
                      onClick={() =>
                        props.replayExam(
                          attempt.playBackFilename,
                          attempt.tumors,
                          attempt.moduleId,
                          attempt.coverageGrid,
                          {
                            percentCoverage: attempt.percentCoverage,
                            examTime: attempt.examTime,
                            userEmail: user.email,
                            passed: attempt.passed,
                          }
                        )
                      }
                    >
                      {t("grades.replayExam")}
                    </ColorButton>
                  ) : null}
                </>
              )}
            </div>
          );
        })}
    </>
  );
};

export default ModuleAttempts;
