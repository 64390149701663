import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Link, withRouter } from "react-router-dom"
import { compose } from "recompose"
import { AuthUserContext, withAuthorization } from "../Session"
//import { withFirebase } from '../Firebase';
import * as Survey from "survey-react"
import "survey-react/survey.css"

import { withFirebase } from "../Firebase"
import * as ROUTES from "../../constants/routes"
import * as ROLES from "../../constants/roles"

class MammaSurvey extends Component {
  constructor(props) {
    super(props)
    this.state = {
      json: null,
      title: null,
      loading: true,
      mode: "edit",
      validQuestions: [],
    }
  }

  componentDidMount() {
    console.log(this.props.completed)
    let json = this.props.json
    console.log("SURVEY JSON:" + json)
    this.setState({ json: json })
    /*
    // Load the survey
    this.props.firebase
      .survey(surveyId)
      .get()
      .then((survey) => {
        if (survey.exists) {
          console.log("Document data:", survey.data());
          this.setState({title: survey.data().title});
          this.setState({json: survey.data().json});
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!",survey);
        }
      })
      .catch(error => {
        console.log(error);
      });
  */
  }

  //Define a callback methods on survey complete
  onComplete = (survey, options) => {
    //Write survey results into database
    //console.log("Survey results: ", survey.data);
    const selectedAnswers = Object.values(survey.data) //array of strings
    this.setState({ mode: "display" })
    console.log("All questions", survey.getAllQuestions())

    //create an array of questions, answers and correct bool
    let allQuestions = survey.getAllQuestions()
    let validQuestions = []

    let finalResults = [] //array of objects

    //return survey info
    if (this.props.hasFormTitle) finalResults.push({ formTitle: survey.title })

    for (let i = 0; i < allQuestions.length; i++) {
      let answer = survey.getValue(allQuestions[i].name)
      finalResults.push({
        name: allQuestions[i].name,
        title: allQuestions[i].title,
        correctAnswer: allQuestions[i].correctAnswer,
        userAnswer: answer,
      })
      // if (!allQuestions[i].name.includes("question")) { //skip questions that have the word question...those represent html information
      //   let answer = survey.getValue(allQuestions[i].name);
      //   finalResults.push({ name: allQuestions[i].name, title: allQuestions[i].title, correctAnswer: allQuestions[i].correctAnswer, userAnswer: answer });
      // }
    }
    this.props.completed(finalResults)
  }

  //QuizResults = ({results}) =>{

  render() {
    console.log("Rendering survey...")
    //Create the model and pass it into react Survey component
    //You may create survey model outside the render function and use it in your App or component
    //The most model properties are reactive, on their change the component will change UI when needed.
    var model = new Survey.Model(this.state.json)
    return (
      <Survey.Survey
        model={model}
        onComplete={this.onComplete}
        mode={this.state.mode}
      />
    )
    /*
    //The alternative way. react Survey component will create survey model internally
    return (<Survey.Survey json={this.json} onComplete={this.onComplete}/>);
    */
    //You may pass model properties directly into component or set it into model
    // <Survey.Survey model={model} mode="display"/>
    //or
    // model.mode="display"
    // <Survey.Survey model={model}/>
    // You may change model properties outside render function.
    //If needed react Survey Component will change its behavior and change UI.
  }
}

MammaSurvey.contextType = AuthUserContext

const condition = (authUser) => !!authUser

export default compose(withAuthorization(condition), withFirebase)(MammaSurvey)
