import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const H1 = styled.div`
  text-align: center;
  color: #ffffff;
  font-size:2em;
  font-weight: bold;
  background-color: #680000;
  padding: 20px;
`;

const Div = styled.div`
  background-color:#ffffff;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`;

const InnerDiv = styled.div`
  background-color:#ffffff;
  padding-bottom: 25px;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

/*display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;*/
/*div style={{ width: '80%', marginLeft: '10%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid black'*/

const PasswordForgetPage = () => {
  const { t } = useTranslation();
  return (
    <Div>
      <H1>{t('signinPage.forgotPassword')}</H1>
      <PasswordForgetForm />
    </Div>
  );
} 

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <InnerDiv>
        <p>Please enter the email address associated with the account you are trying to recover.</p>

        <form onSubmit={this.onSubmit} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <input
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
            style={{ width: "200px" }}
          />
          <button style={{ marginLeft: '10px' }} disabled={isInvalid} type="submit">
            Reset My Password
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </InnerDiv>
    );
  }
}

const PasswordForgetLink = () => {
  const {t} = useTranslation(); 
  return (
    <Link to={ROUTES.PASSWORD_FORGET}>{t('signinPage.forgotPassword')}</Link>
  );
}

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
