import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { red } from "@material-ui/core/colors";
import { Divider, IconButton, Tooltip, Fade, fade } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import MammaPDF from "../MammaPDF/index";
import ReactPlayer from "react-player";
import MammaSpinner from "../Generic/MammaSpinner";
import { VideoStatTracker } from "../VideoStats/videoStatTrackerClass";
import VideoAnalyzer from "../VideoStats/videoAnalyzer";

const SubTable = withStyles((theme) => ({
  root: {
    border: 0,
    width: "100%",
    margin: "20px 0",
  },
}))(Table);

const SubTableCell = withStyles((theme) => ({
  body: {
    border: 0,
    padding: theme.spacing(1, 0, 1, 2),
    backgroundColor: "transparent",
  },
}))(TableCell);

const SubTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      borderBottom: "1px solid #000",
    },
    "&:nth-of-type(even)": {
      borderBottom: "1px solid #000",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(TableRow);

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  typography: {
    fontSize: "1.2em",
    fontWeight: "500",
    padding: 0,
  },
  smallTable: {
    padding: 0,
  },
  answer: {
    padding: "0 5px",
    margin: "0 1%",
  },
  correctAnswer: {
    border: "1px solid green",
    borderRadius: "2.5px",
    padding: "0 5px",
    margin: "0 1%",
    backgroundColor: "lightgreen",
  },
  incorrectAnswer: {
    border: "1px solid red",
    borderRadius: "2.5px",
    padding: "0 5px",
    margin: "0 1%",
    backgroundColor: red[300],
  },
  highlighted: {
    backgroundColor: `${fade(theme.palette.warning.light, 0.3)} !important`,
  },
  transparent: {
    backgroundColor: "transparent",
  },
}));

const Attempts = (props) => {
  const { gradeItem, materials } = props;
  const classes = useRowStyles();
  const [waiting, setWaiting] = useState(false);
  const [show, setShow] = useState(false);
  const [local, setLocal] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  //console.log(materials);

  useEffect(() => {
    processResponseJson(gradeItem.attempts.length - 1);
  }, [props]);

  const onRender = () => {};

  const fetch = async () => {
    setWaiting(true);
    let response = await props.fetchMaterials(gradeItem.activityId);
    setWaiting(false);
  };

  const processResponseJson = (index) => {
    setShow(false);
    setCurrentIndex(index);

    let json = null;
    try {
      json = JSON.parse(gradeItem.attempts[index].json);
    } catch (error) {
      json = null;
    }
    if (json) {
      json.forEach((el) => {
        if (Array.isArray(el.userAnswer)) {
          console.log(el.userAnswer);
          let ui = (
            <div style={{ width: "100%" }}>
              {el.userAnswer.map((ans, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <RemoveIcon />
                  <Typography
                    variant="body1"
                    className={
                      el.userAnswer === el.correctAnswer
                        ? classes.correctAnswer
                        : classes.incorrectAnswer
                    }
                    style={
                      el.correctAnswer == "" || el.correctAnswer == null
                        ? {
                            border: 0,
                            backgroundColor: "transparent",
                            width: "100%",
                          }
                        : { width: "100%" }
                    }
                  >
                    {ans}
                  </Typography>
                </div>
              ))}
            </div>
          );
          el.userAnswer = { answer: el.userAnswer, jsx: ui };
        } else {
          let ui = (
            <div>
              <Typography
                variant="body1"
                className={
                  el.userAnswer === el.correctAnswer
                    ? classes.correctAnswer
                    : classes.incorrectAnswer
                }
                style={
                  el.correctAnswer == "" || el.correctAnswer == null
                    ? {
                        border: 0,
                        backgroundColor: "transparent",
                        width: "100%",
                      }
                    : { width: "100%" }
                }
              >
                {el.userAnswer}
              </Typography>
            </div>
          );
          el.userAnswer = { answer: el.userAnswer, jsx: ui };
        }
      });
      setLocal(json);
    }
    setShow(true);
  };

  const processDuration = (duration) => {
    if (duration >= 60) {
      let minutes = Math.floor(duration / 60);
      let seconds = Math.round(duration % 60);
      return `${minutes}m ${seconds > 9 ? seconds : "0" + seconds}s`;
    } else {
      let seconds = Math.round(duration);
      return `${seconds} seconds`;
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6" gutterBottom component="div">
        {t("grades.attemptHistory")}
      </Typography>

      <SubTable size="small" aria-label="purchases">
        <TableBody>
          <SubTableRow>
            <SubTableCell>
              <Typography variant="body1" className={classes.smallTable}>
                {t("grades.grade")}
              </Typography>
            </SubTableCell>
            <SubTableCell>
              <Typography variant="body1" className={classes.smallTable}>
                {t("grades.attemptDate")}
              </Typography>
            </SubTableCell>
            <SubTableCell>
              <Typography variant="body1" className={classes.smallTable}>
                Duration
              </Typography>
            </SubTableCell>
            <SubTableCell>
              <Typography variant="body1" className={classes.smallTable}>
                Video Stats
              </Typography>
            </SubTableCell>
            <SubTableCell />
          </SubTableRow>
          {gradeItem.attempts.map((attempt, index) => {
            console.log(
              index,
              currentIndex,
              currentIndex === index ? "highlighted" : "transparent"
            );
            return (
              <SubTableRow
                key={index}
                className={clsx({
                  [classes.highlighted]: currentIndex === index,
                  [classes.transparent]: currentIndex !== index,
                })}
              >
                <SubTableCell id="grade">
                  <Typography
                    key={index}
                    variant="body1"
                    className={classes.smallTable}
                  >
                    {attempt.grade}
                  </Typography>
                </SubTableCell>
                <SubTableCell id="date">
                  <Typography
                    key={index}
                    variant="body1"
                    className={classes.smallTable}
                  >
                    {new Date(attempt.submittedAt * 1000).toLocaleString()}
                  </Typography>
                </SubTableCell>
                <SubTableCell id="duration">
                  <Typography
                    key={index}
                    variant="body1"
                    className={classes.smallTable}
                  >
                    {attempt.duration
                      ? processDuration(attempt.duration)
                      : "--"}
                  </Typography>
                </SubTableCell>
                <SubTableCell id="analyzer">
                  {currentIndex === index && attempt.videoStats ? (
                    <VideoAnalyzer stats={attempt.videoStats} />
                  ) : (
                    "--"
                  )}
                </SubTableCell>
                <SubTableCell>
                  {currentIndex === index ? null : (
                    <Tooltip title="Review this attempt" arrow>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => processResponseJson(index)}
                      >
                        <ExitToAppIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </SubTableCell>
              </SubTableRow>
            );
          })}
        </TableBody>
      </SubTable>

      {local != null && local.length > 0 && local[0].userAnswer ? (
        <>
          {/* <Divider style={{ marginBottom: "8px" }} />
          <Typography variant="h6" gutterBottom component="div">
            Latest Attempt
          </Typography> */}
          <Fade in={show}>
            <Paper style={{ padding: "10px 1%", marginBottom: "20px" }}>
              {local.map((el, index) => (
                <Fragment key={index}>
                  {(el.correctAnswer != "" && el.correctAnswer != null) ||
                  (el.userAnswer != "" && el.userAnswer != null) ? (
                    <>
                      <Typography variant="h6">{el.title}</Typography>
                      {el.userAnswer != "" && el.userAnswer != null ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0",
                          }}
                        >
                          {el.correctAnswer != "" &&
                          el.correctAnswer != null &&
                          el.userAnswer.answer != "" &&
                          el.userAnswer.answer != null ? (
                            el.userAnswer.answer === el.correctAnswer ? (
                              <CheckOutlinedIcon style={{ color: "green" }} />
                            ) : (
                              <CloseOutlinedIcon style={{ color: "red" }} />
                            )
                          ) : null}
                          {el.userAnswer.jsx}
                        </div>
                      ) : null}
                      {el.correctAnswer != "" &&
                      el.correctAnswer != null &&
                      el.userAnswer.answer !== el.correctAnswer ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0",
                          }}
                        >
                          <ArrowForwardIosIcon fontSize="small" />
                          <Typography
                            variant="body1"
                            className={classes.answer}
                          >
                            {t("grades.correctAnswer")}: {el.correctAnswer}
                          </Typography>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </Fragment>
              ))}
            </Paper>
          </Fade>
        </>
      ) : null}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={fetch}
        >
          <Typography variant="h6" gutterBottom component="div">
            {t("grades.reviewMaterials")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {waiting ? (
            <MammaSpinner loading={waiting} />
          ) : materials ? (
            <>
              {materials.video ? (
                <div style={{ marginBottom: "40px" }}>
                  <ReactPlayer
                    url={materials.video}
                    controls={true}
                    onError={() => console.log("error playing media!")}
                  />
                </div>
              ) : null}
              {materials.pdf ? (
                <MammaPDF
                  pdf={materials.pdf}
                  viewedLastPage={null}
                  onRender={onRender}
                  noScroll={true}
                />
              ) : null}
            </>
          ) : (
            <Typography variant="h6" gutterBottom component="div">
              {t("grades.noMaterials")}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Attempts;
