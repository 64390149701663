import React, { useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Document } from "react-pdf";
import Logo from "../Navigation/mammacare-symbol.png";
import Signature from "./signature.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  fade,
  TextField,
  FormControl,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import styled from "styled-components";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";

const Message = styled.div`
  text-align: center;
  color: #680000;
  font-size: 2em;
  font-weight: bold;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  border: 2px solid #680000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const useStyles = makeStyles((theme) => ({
  textFormControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectFormControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  outerBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `6px solid ${fade("#680000", 0.8)}`,
    width: "100%",
    height: "100%",
  },
  innerBox: {
    width: `calc(100% - 32px)`,
    height: `calc(100% - 32px)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `6px solid ${fade("#680000", 0.5)}`,
    margin: "10px",
  },
  header: {
    fontFamily: "Georgia, serif",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    marginTop: "20px",
    fontSize: "40px",
    fontFamily: "Georgia, serif",
    color: "#680000",
  },
  nameLine: {
    width: "80%",
    borderBottom: "1px solid black",
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
  },
  name: {
    fontFamily: "Georgia, serif",
    fontSize: "48px",
  },
  body: {
    fontFamily: "Georgia, serif",
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#680000",
    borderColor: "#680000",
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(20),
    height: "4rem",
    "&:hover": {
      backgroundColor: "#680000",
      color: "#ffffff",
    },
  },
}))(Button);

// const printDocument = () => {
//   // const doc = new pdfkit();
//   // const stream = doc.pipe(blobStream());
//   // doc.text("Lorem Ipsum");
//   // doc.end();
//   //stream.on("finish", () => {
//   // const blob = stream.toBlobURL("application/pdf");
//   // console.log(blob);
//   //});
//   // const input = document.getElementById("divToPrint");
//   // window.scrollTo(0, 0);
//   // html2canvas(input).then((canvas) => {
//   //   const options = {
//   //     orientation: "l",
//   //     unit: "mm",
//   //     format: "a4",
//   //     putOnlyUsedFonts: true,
//   //   };
//   //   const imgData = canvas.toDataURL("image/png");
//   //   const pdf = new jsPDF(options);
//   //   pdf.addImage(imgData, "JPEG", 0, 0);
//   //   pdf.save("download.pdf");
//   // });
//   const doc = new jsPDF("landscape");
//   doc.text("Hello World!", 10, 10);
//   //doc.save("a4.pdf");
//   console.log(doc.output('datauri'))
// };

const MammaCertificate = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [uri, setUri] = useState(null);

  const processToday = () => {
    let date = new Date();
    let dd = date.getDate();
    let mm = date.getMonth() + 1;

    const yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return mm + "/" + dd + "/" + yyyy;
  };
  const processCompleted = (completedAt) => {
    console.log(completedAt);
    let date = new Date(completedAt * 1000);
    let dd = date.getDate();
    let mm = date.getMonth() + 1;

    const yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return mm + "/" + dd + "/" + yyyy;
  };

  const printDocument = () => {
    //an A4 sheet of paper is 210mm wide x 297mm tall (reverse these dimensions for landscape)
    var doc = new jsPDF({
      orientation: "landscape",
      format: "A4",
      unit: "mm",
    });

    if (props.isCourse) {
      let today = processToday();
      doc.setLineWidth(2);
      doc.setDrawColor(175, 0, 0);
      doc.rect(10, 10, 277, 190).stroke("#680000");

      doc.setLineWidth(2);
      doc.setDrawColor(225, 150, 150);
      doc.rect(15, 15, 267, 180).stroke("#680000");

      doc.addImage(Logo, "PNG", 134, 25, 29, 29);

      doc.setFontSize(20);
      doc.text("The MammaCare Foundation", 100, 61, { maxWidth: 93 });

      doc.setFontSize(36);
      doc.text("Certificate of Completion", 79, 85, { maxWidth: 139 });

      doc.setFontSize(15);
      doc.text("This is to confirm that", 121, 98, { maxWidth: 55 });

      doc.setFontSize(35);
      doc.text(props.displayName, 148.5, 117, {
        align: "center",
        maxWidth: 197,
      });

      doc.setLineWidth(0.1);
      doc.setDrawColor(0);
      doc.line(45, 120, 252, 120);

      doc.setFontSize(15);
      doc.text(`has successfully completed the course`, 148.5, 132, {
        align: "center",
        maxWidth: 91,
      });

      doc.setFontSize(20);
      doc.text(`"${props.courseTitle}"`, 148.5, 145, {
        align: "center",
        maxWidth: 213,
      });

      doc.setLineWidth(0.1);
      doc.setDrawColor(0);
      doc.line(45, 172, 110, 172);
      doc.line(187, 172, 252, 172);

      doc.setFontSize(12);
      doc.text("Date", 78, 179, { align: "center" });
      doc.text("Director of Education", 220, 179, { align: "center" });

      doc.setFontSize(15);
      doc.text(today, 78, 170, { align: "center" });

      doc.addImage(Signature, "PNG", 190, 162, 60, 17);
      doc.line(187, 172, 252, 172);
      doc.setFontSize(12);
      doc.text("Director of Education", 220, 179, { align: "center" });

      doc.save(`${props.fName}_${props.lName}_Completion_Certificate.pdf`);
    } else {
      let completed = processCompleted(props.completedAt);

      doc.setLineWidth(2);
      doc.setDrawColor(175, 0, 0);
      doc.rect(10, 10, 277, 190).stroke("#680000");

      doc.setLineWidth(2);
      doc.setDrawColor(225, 150, 150);
      doc.rect(15, 15, 267, 180).stroke("#680000");

      doc.addImage(Logo, "PNG", 134, 25, 29, 29);

      doc.setFontSize(20);
      doc.text("The MammaCare Foundation", 100, 61, { maxWidth: 93 });

      doc.setFontSize(20);
      doc.text("This is to confirm that", 148.5, 80, { align: "center" });

      doc.setFontSize(35);
      doc.text(props.displayName, 148.5, 110, {
        align: "center",
        maxWidth: 197,
      });

      doc.setLineWidth(0.1);
      doc.setDrawColor(0);
      doc.line(45, 113, 252, 113);

      doc.setFontSize(15);
      doc.text(`has successfully completed`, 148.5, 130, {
        align: "center",
        maxWidth: 91,
      });

      doc.setFontSize(20);
      doc.text(props.activityTitle, 148.5, 150, {
        align: "center",
        maxWidth: 213,
      });

      doc.setLineWidth(0.1);
      doc.setDrawColor(0);
      doc.line(116, 172, 181, 172);

      doc.setFontSize(12);
      doc.text("Date", 148.5, 179, { align: "center" });

      doc.setFontSize(15);
      doc.text(completed, 148.5, 170, { align: "center" });

      doc.save(
        `${props.fName}_${props.lName}_${props.activityTitle
          .split(" ")
          .join("_")}_Completion_Certificate.pdf`
      );
    }
  };

  return props.isCourse ? (
    <ColorButton
      variant="outlined"
      onClick={() => printDocument()}
      startIcon={<CloudDownloadIcon style={{ fontSize: "24px" }} />}
    >
      {t("homePage.downloadCertificate")}
    </ColorButton>
  ) : (
    <IconButton onClick={printDocument} disabled={props.disabled}>
      <PrintIcon style={{ color: props.disabled ? null : "#680000" }} />
    </IconButton>
  );
};

const EditableCertificate = (props) => {
  const [state, setState] = useState({
    templateId: "",
    title: "Certification",
    line1: "MammaCare Clinical Breast Examiner",
    name: "Zachary R. DeLand, M.S.",
    line2:
      "Has met all standards of excellence required for recognition as proficinet in the MammaCare Methof of Clinical Breast Examination",
    awardedOn: `Awarded on this _____ day of _____ , _____ , by authority of the officers and directors of The MammaCare Foudnation.`,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    console.log(state);
  };

  const createCohort = () => {
    let stateCopy = { ...state };
    delete stateCopy.possibleGrades;
    props.createCohort(stateCopy);
  };

  console.log(state);

  return (
    <>
      <div>
        <div
          id="divToPrint"
          style={{
            backgroundColor: "#ffffff",
            width: "296mm",
            height: "210mm",
            marginTop: "20px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              width: "294mm",
              height: "210mm",
              border: "3px solid #680000",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: "100%",
                margin: "10mm 0 0 0",
              }}
            >
              <img src={Logo} />
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "68px",
                  fontFamily: "Tangerine, serif",
                  textAlign: "center",
                  margin: 0,
                }}
              >
                {state.title}
              </h2>
              <img src={Logo} />
            </div>

            {/* <h2 style={{fontSize: '18px'}}>MammaCare</h2> */}

            <p
              style={{
                textAlign: "center",
                fontSize: "48px",
                fontFamily: "Tangerine, serif",
                margin: "5mm 0 10mm 0",
              }}
            >
              {state.line1}
            </p>
            <h3
              style={{
                fontSize: "36px",
                textDecoration: "underline",
                margin: 0,
              }}
            >
              {state.name}
            </h3>
            <div style={{ margin: "40px 10%" }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "40px",
                  fontFamily: "Tangerine, serif",
                  margin: "0 0 20px 0",
                }}
              >
                {state.line2}
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "40px",
                  fontFamily: "Tangerine, serif",
                  margin: "10px 0 0 0",
                }}
              >
                {state.awardedOn}
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                margin: "0 0 40px 0",
              }}
            >
              <img src={Signature} />
              <img src={Signature} />
            </div>
          </div>
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          {/* <button style={{ margin: "20px" }} onClick={() => printDocument()}>
            Download/Print Certificate
          </button> */}
        </div>
      </div>
      <div>
        <NewCohortForm
          data={{ ...state }}
          handleChange={handleChange}
          createCohort={createCohort}
        />
      </div>
    </>
  );
};

const NewCohortForm = (props) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormControl className={classes.textFormControl}>
            <TextField
              required
              label="Certificate Title"
              variant="outlined"
              size="small"
              value={props.data.title}
              onChange={props.handleChange}
              name="title"
            />
          </FormControl>
          <FormControl className={classes.textFormControl}>
            <TextField
              required
              label="Line 1"
              variant="outlined"
              size="small"
              value={props.data.line1}
              onChange={props.handleChange}
              name="line1"
            />
          </FormControl>
          <FormControl className={classes.textFormControl}>
            <TextField
              required
              label="Full Name & Credentials"
              variant="outlined"
              size="small"
              value={props.data.name}
              onChange={props.handleChange}
              name="name"
            />
          </FormControl>
          <FormControl className={classes.textFormControl}>
            <TextField
              required
              label="Line 2"
              variant="outlined"
              size="small"
              value={props.data.line2}
              onChange={props.handleChange}
              name="line2"
            />
          </FormControl>
          <FormControl className={classes.textFormControl}>
            <TextField
              required
              label="Awarded On"
              variant="outlined"
              size="small"
              value={props.data.awardedOn}
              onChange={props.handleChange}
              name="awardedOn"
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <ColorButton variant="outlined" onClick={props.createCohort}>
          Create Certificate
        </ColorButton>
      </DialogActions>
    </>
  );
};

export { EditableCertificate };
export default MammaCertificate;
