import React, { Component, Fragment } from "react"
import { Link, withRouter } from "react-router-dom"
import styles from "./SignUpAlt.module.css"
import { withFirebase } from "../Firebase"
import * as ROUTES from "../../constants/routes"
import * as ROLES from "../../constants/roles"
import styled from "styled-components"
import MammaSpinner from "../Generic/MammaSpinner"

const Div = styled.div`
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: 000000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`

const H1 = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
  background-color: #680000;
  padding: 20px;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`

const AltSignUpPage = () => (
  <div>
    <H1>Sign Up</H1>
    <Div>
      <AltSignUpForm />
    </Div>
  </div>
)

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  licenseType: "",
  state: "",
  licenseNum: "",
  passwordOne: "",
  passwordTwo: "",
  courseCode: "",
  term: "",
  getLicense: false,
  certified: false,
  warned: false,
  error: null,
  submitted: false,
  showForm: true,
}

class AltSignUpFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.props.history.push(`/signup/ceu/${new Date(Date.now()).getFullYear()}`)
    if (
      this.props.match.params.coursecode != null ||
      this.props.match.params.coursecode != ""
    ) {
      if (
        this.props.match.params.term != null ||
        this.props.match.params.term != ""
      ) {
        this.setState({
          courseCode: this.props.match.params.coursecode,
          term: this.props.match.params.term,
        })
      } else {
        this.props.history.push("/landing")
      }
    } else {
      this.props.history.push("/landing")
    }
  }

  enrollUser = async (
    firstName,
    lastName,
    email,
    domain,
    passwordOne,
    courseCode,
    userTerm,
    cohortObj,
    submitButton,
    licenseType,
    licenseNum,
    phoneNum,
    state
  ) => {
    let emailRecipients = []
    if (cohortObj.enrollmentEmailRecipients != null) {
      cohortObj.enrollmentEmailRecipients.forEach((rec) => {
        emailRecipients.push(rec.email)
      })
      console.log(emailRecipients)
    }

    const timestamps = await this.props.firebase.getServerTimestamp()

    let authUser = null
    //store createdAt timestampsInSnapshots
    const signupDate = timestamps.firebaseTimestamp.toDate()
    const signupYear = signupDate.getFullYear()

    const course = await this.props.firebase.course(cohortObj.templateId).get()

    if (course.exists) {
      //create a new account in firebase
      authUser = await this.props.firebase
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .catch((error) => {
          this.setState({ loading: false, message: error.message })
          submitButton.disabled = false
          console.log("last: ", error)
        })

      await this.props.firebase
        .user(authUser.user.uid)
        .set({
          licenseType: licenseType,
          licenseNum: licenseNum,
          state: state,
          phoneNum: phoneNum,
          approvedDomain: cohortObj.domain, //could be * or another approved domain value
          domain: domain, //this is the user's actual email address domain
          cohortId: cohortObj.id,
          courseCode: courseCode,
          templateId: cohortObj.templateId,
          term: userTerm,
          signupYear: signupYear,
          email: email,
          firstName: firstName,
          lastName: lastName,
          roles: [],
          courses: this.props.firebase.fieldValue.arrayUnion({
            cohort: cohortObj.id,
            templateId: cohortObj.templateId,
            courseCode: courseCode,
            term: userTerm,
            enrolledAt: timestamps.unixTimestampSeconds,
            courseLastUpdatedAt: timestamps.unixTimestampSeconds,
            sections: [],
          }),
          createdAt: timestamps.unixTimestampSeconds,
          module1VideoIndex: 0,
          module2VideoIndex: 0,
          module3VideoIndex: 0,
          module4VideoIndex: 0,
          nextActivityIndex: 0,
          isEnrolled: true,
          hasToPay: cohortObj.directPayment == true ? true : false,
          hasToShip: cohortObj.directShipment == true ? true : false,
          homeStatus: "enrolling",
        })
        .catch((error) => {
          this.setState({ loading: false, message: error.message })
          submitButton.disabled = false
        })

      let cohortRef = await this.props.firebase.getDocByIdFrom(
        "cohorts",
        cohortObj.id
      )
      let cohortDoc = await cohortRef.get()
      console.log(cohortDoc)
      if (cohortDoc.data().availableSeats != null) {
        await this.props.firebase.updateCohortSettings(cohortObj.id, {
          signUps: this.props.firebase.fieldValue.increment(1),
          availableSeats: this.props.firebase.fieldValue.increment(-1),
        })
      } else {
        await this.props.firebase.updateCohortSignUps(cohortObj.id)
      }

      await this.props.firebase.writeLog("Created account.")
      await this.props.firebase.doSendEmailVerification()
      if (emailRecipients.length > 0) {
        await this.props.firebase.sendResults(
          emailRecipients,
          `${email} signed up for ${cohortObj.id}`,
          `Name: ${firstName} ${lastName}
        Phone Number: ${phoneNum}
        License Type: ${licenseType}
        License Number: ${licenseNum}
        State: ${state}`,
          authUser.user.uid
        )
      }

      this.setState({
        loading: false,
        message: (
          <VerifyEmail resend={this.props.firebase.doSendEmailVerification} />
        ),
        showForm: false,
      })
    } else {
      this.setState({ loading: false, message: "No such course exists!" })
      submitButton.disabled = false
      console.log("No such course exists!")
    }
  }

  onSubmit = async (event) => {
    this.setState({ loading: true })
    const submitButton = event.target
    submitButton.disabled = true

    const {
      firstName,
      lastName,
      email,
      passwordOne,
      passwordTwo,
      courseCode,
      term,
      licenseType,
      licenseNum,
      phone,
      state,
    } = this.state

    const domain = this.getDomainFromEmail(email)

    if (passwordOne === passwordTwo) {
      const userTerm = term.trim().toLowerCase()
      const userCourseCode = courseCode.trim()
      const potentialCohort = domain + "_" + userCourseCode + "_" + userTerm

      let cohorts = await this.props.firebase.getCohortsByCourseCodeAndTerm(
        userCourseCode,
        userTerm
      )

      switch (cohorts.length) {
        case 0:
          this.setState({
            loading: false,
            message:
              "No course matches the course ID and or term values you have entered.",
          })
          submitButton.disabled = false
          break
        case 1:
          console.log("Found a single matching cohort: ", cohorts[0])
          if (cohorts[0].domain == "*") {
            //chec if there is a seat available
            if (this.seatAvailable(cohorts[0]))
              this.enrollUser(
                firstName,
                lastName,
                email,
                domain,
                passwordOne,
                userCourseCode,
                userTerm,
                cohorts[0],
                submitButton,
                licenseType,
                licenseNum,
                phone,
                state
              )
            else {
              this.setState({
                loading: false,
                message:
                  "The course is full at this time. Please contact your instructor to add additional seats.",
              })
              submitButton.disabled = false
            }
          } else if (cohorts[0].id == potentialCohort) {
            if (this.seatAvailable(cohorts[0]))
              this.enrollUser(
                firstName,
                lastName,
                email,
                domain,
                passwordOne,
                userCourseCode,
                userTerm,
                cohorts[0],
                submitButton,
                licenseType,
                licenseNum,
                phone,
                state
              )
            else {
              this.setState({
                loading: false,
                message:
                  "The course is full at this time. Please contact your instructor to add additional seats.",
              })
              submitButton.disabled = false
            }
          } else {
            this.setState({
              loading: false,
              message:
                "The email you entered is not valid for this course. Please try your institution/university email address.",
            })
            submitButton.disabled = false
          }
          break
        default:
          let cohortIndex = this.getCohortIndex(cohorts, potentialCohort)
          if (cohortIndex == -1) {
            this.setState({
              loading: false,
              message:
                "The email you entered is not valid for this course. Please try your institution/university email address.",
            })
            submitButton.disabled = false
          } else {
            this.enrollUser(
              email,
              domain,
              passwordOne,
              userCourseCode,
              userTerm,
              cohorts[cohortIndex],
              submitButton
            )
          }
      }

      /*if (cohortObj === undefined) {
        this.setState({ loading: false, message: "No course matches the course ID and or term values you have entered." });
        submitButton.disabled = false;
        console.log("ln 182")
      } else {
        let enrolled = this.enrollUser(email, passwordOne, userCourseCode, roles, userTerm, cohortObj)
        if (!enrolled) {
          submitButton.disabled = false;
        }
        else {
          this.setState({ submitted: true, showForm: false })
        }
      }*/

      event.preventDefault()
    } else {
      this.setState({
        loading: false,
        message: "Passwords do not match. Please check and try again.",
      })
      submitButton.disabled = false
    }
  }

  seatAvailable = (cohortObj) => {
    if (cohortObj.availableSeats === undefined) return true

    if (cohortObj.availableSeats > 0) return true
    else return false
  }

  getCohortIndex = (cohorts, potentialCohort) => {
    let index = cohorts.findIndex((cohort) => cohort.id == potentialCohort)
    return index
  }

  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  onChange = (event) => {
    const field = event.target.name
    let eventValue = event.target.value
    console.log(field, ": ", eventValue)

    if (field === "licenseType") {
      let licenseType = eventValue
      let getLicense = false
      switch (eventValue) {
        case "--":
          getLicense = false
          break
        case "RN":
          getLicense = true
          break
        case "PN":
          getLicense = true
          break
        case "CNP":
          getLicense = true
          break
        case "CNRA":
          getLicense = true
          break
        case "CNS":
          getLicense = true
          break
        case "CNM":
          getLicense = true
          break
        case "RT":
          getLicense = true
          break
        default:
          getLicense = false
          break
      }
      this.setState({
        [field]: licenseType,
        getLicense: getLicense,
        certified: false,
      })
    } else if (field.includes("state")) {
      this.setState({ state: this.capitalize(eventValue) })
    } else if (!field.includes("Name") && !field.includes("password"))
      //do not lowercase first and last name fields
      this.setState({ [field]: eventValue.toLowerCase() })
    else this.setState({ [field]: eventValue })
  }

  checkWarned = () => {
    this.setState({ warned: !this.state.warned })
  }

  checkCertify = () => {
    this.setState({ certified: !this.state.certified })
  }

  getDomainFromEmail = (email) => {
    return email.split("@")[1]
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phone,
      state,
      licenseType,
      licenseNum,
      passwordOne,
      passwordTwo,
      courseCode,
      term,
      getLicense,
      warned,
      certified,
      error,
    } = this.state

    console.log(this.state)

    let isInvalid = true

    if (getLicense) {
      isInvalid =
        passwordOne === "" ||
        passwordTwo === "" ||
        email === "" ||
        firstName === "" ||
        lastName === "" ||
        courseCode === "" ||
        term === "" ||
        state === "" ||
        (licenseType == "RT" ? false : licenseNum === "") ||
        certified === false
    } else {
      isInvalid =
        passwordOne === "" ||
        passwordTwo === "" ||
        email === "" ||
        firstName === "" ||
        lastName === "" ||
        courseCode === "" ||
        licenseType === "" ||
        licenseType == "--" ||
        term === ""
    }

    const showDisclaimer =
      licenseType != "" &&
      licenseType != "--" &&
      state.length > 0 &&
      (licenseType != "RT" ? licenseNum.length > 0 : true)

    let message =
      this.state.loading && !this.state.message ? (
        <MammaSpinner loading={this.state.loading} />
      ) : (
        this.state.message
      )

    return (
      <div>
        <div style={{ paddingBottom: "15px", fontWeight: "bold" }}>
          {message}
        </div>
        {/*this.state.submitted ? null : <CourseIntro />*/}
        {!this.state.submitted && this.state.showForm ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <form
              onSubmit={this.onSubmit}
              style={{ width: "20%", display: "flex", flexDirection: "column" }}
            >
              <input
                name="email"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
              />
              <input
                name="phone"
                value={phone}
                onChange={this.onChange}
                type="tel"
                placeholder="Phone #"
              />
              <input
                name="firstName"
                value={firstName}
                onChange={this.onChange}
                type="text"
                placeholder="First Name"
              />
              <input
                name="lastName"
                value={lastName}
                onChange={this.onChange}
                type="text"
                placeholder="Last Name"
              />
              <select
                name="licenseType"
                onChange={this.onChange}
                placeholder="License type"
              >
                <option value={"--"}>-Select license type-</option>
                <option value={"RN"}>RN</option>
                <option value={"PN"}>PN</option>
                <option value={"CNP"}>CNP</option>
                <option value={"CRNA"}>CRNA</option>
                <option value={"CNS"}>CNS</option>
                <option value={"CNM"}>CNM</option>
                <option value={"RT"}>RT</option>
              </select>
              {getLicense ? (
                <Fragment>
                  <input
                    name="state"
                    value={state}
                    onChange={this.onChange}
                    type="text"
                    placeholder="State"
                    style={{ marginLeft: "5%" }}
                  />
                  {licenseType != "--" && licenseType != "RT" ? (
                    <input
                      name="licenseNum"
                      value={licenseNum}
                      onChange={this.onChange}
                      type="text"
                      placeholder="License #"
                      style={{ marginLeft: "5%" }}
                    />
                  ) : null}
                  {showDisclaimer ? (
                    <div
                      style={{
                        paddingBottom: "10px",
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "5%",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={certified}
                        onChange={this.checkCertify}
                        style={{
                          alignSelf: "center",
                          transform: "scale(2)",
                          marginRight: "5%",
                          marginLeft: "2%",
                        }}
                      />
                      <div style={{ textAlign: "left", fontWeight: "bold" }}>
                        I certify that I am{" "}
                        {!licenseType.startsWith("R", 0) ? "a" : "an"}{" "}
                        {licenseType} from {state}{" "}
                        {licenseNum != "" &&
                        licenseType != "" &&
                        licenseType != "RT"
                          ? `with the license number: ${licenseNum}`
                          : null}{" "}
                      </div>
                    </div>
                  ) : null}
                </Fragment>
              ) : null}
              <input
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
              <input
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Confirm Password"
              />
              {/* <input
              name="courseCode"
              value={courseCode}
              onChange={this.onChange}
              type="text"
              placeholder="Course Code"
            />
            <input
              name="term"
              value={term}
              onChange={this.onChange}
              type="text"
              placeholder="Term"
            /> */}

              {/* <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'row' }}>
              <input type="checkbox" onChange={this.checkWarned} style={{ alignSelf: 'center', transform: "scale(2)", marginRight: '5%', marginLeft: '2%' }} />
              <div style={{ textAlign: 'left', fontWeight: 'bold' }}>I agree that I will use Google Chrome to access this training program.</div>
            </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  disabled={isInvalid}
                  type="button"
                  onClick={this.onSubmit}
                  style={{ width: "50%" }}
                >
                  Sign Up
                </button>
              </div>
              {error && <p>{error.message}</p>}
            </form>
          </div>
        ) : null}
      </div>
    )
  }
}

const AltSignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
)

const CourseIntro = () => (
  <Fragment>
    <img src="https://firebasestorage.googleapis.com/v0/b/mammacare-training-app-dev.appspot.com/o/parcelImages%2Fstudent-and-ceu-models.jpg?alt=media&token=803ca5b0-ea4e-464f-99fc-dfaedb9ebe75" />
    <p>
      CBE Standards and Practice is an e-learning course that introduces
      students’ hands and eyes to the elements of clinical breast examination.
      Each student receives a standard set of MammaCare® breast models
      synchronized with exercises that teach proper palpation techniques.
      Instructional videos and knowledge-based content guide students through
      the course. Instructors receive student’s completed results.
    </p>
    <h3>Total: $169</h3>
  </Fragment>
)

const VerifyEmail = ({ resend }) => {
  return (
    <p>
      Please verify your email. Check your inbox for a verification link. <br />{" "}
      or{" "}
      <a
        href="#"
        onClick={() => {
          resend()
        }}
      >
        resend verification email
      </a>
    </p>
  )
}
const AltSignUpForm = withRouter(withFirebase(AltSignUpFormBase))
export default AltSignUpPage
export { AltSignUpForm, AltSignUpLink }
