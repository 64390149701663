import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import MoreVert from "@material-ui/icons/MoreVert"
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import { red } from "@material-ui/core/colors"
import { Divider, FormHelperText } from "@material-ui/core"

import Attempts from "./Attempts"
import ModuleAttempts from "./ModuleAttempts"
import ManualExamAttempts from "./ManualExamAttempts"
import { GradebookCertificate, GAGradebookCertificate } from "./Certificates"
import { find } from "lodash"

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
    border: 0,
    padding: theme.spacing(1),
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: 0,
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
      borderBottom: "1px solid #ddd",
      borderTop: 0,
    },
  },
}))(TableRow)

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip)

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  typography: {
    fontSize: "1.2em",
    fontWeight: "500",
    padding: 0,
  },
  smallTable: {
    padding: 0,
  },
  answer: {
    padding: "0 5px",
  },
  correctAnswer: {
    border: "1px solid green",
    borderRadius: "2.5px",
    padding: "0 5px",
    backgroundColor: "lightgreen",
  },
  incorrectAnswer: {
    border: "1px solid red",
    borderRadius: "2.5px",
    padding: "0 5px",
    backgroundColor: red[300],
  },
})

const GradeItem = (props) => {
  const { t } = useTranslation()
  const { gradeItem, moduleAttempts, manualExamAttempts } = props
  //console.log("GRADE TIEM: ",gradeItem);
  const classes = useRowStyles()
  let json = null
  try {
    json = JSON.parse(gradeItem.attempts[gradeItem.attempts.length - 1].json)
  } catch (error) {
    json = null
  }

  const handleOpen = () => {
    console.log(gradeItem)
    props.handleOpen(gradeItem.id)
  }

  const getAttemptContent = () => {
    if (
      (gradeItem.id.includes("module") && gradeItem.id !== "module1") ||
      gradeItem.id.includes("p2")
    ) {
      return (
        <ModuleAttempts
          attempts={moduleAttempts[gradeItem.id]}
          replayExam={props.replayExam}
          user={props.user}
        />
      )
    }

    if (gradeItem.type && gradeItem.type.includes("manualExam")) {
      console.log("rendering manual exam attempts for gradeItem:", gradeItem)
      return (
        <ManualExamAttempts
          attempts={manualExamAttempts[gradeItem.modelId]}
          gradeItem={gradeItem}
        />
      )
    }

    if (
      gradeItem.title &&
      gradeItem.title.includes("Certificate") &&
      gradeItem.attempts.length > 0
    ) {
      let submission =
        gradeItem.attempts[gradeItem.attempts.length - 1].submission
      let name = find(
        submission,
        (el) => el.title?.includes("name") || el.question === "name"
      )
      let state = find(
        submission,
        (el) => el.title?.includes("state") || el.question === "state"
      )
      let licenseNum = find(
        submission,
        (el) => el.title?.includes("number") || el.question === "licenseNum"
      )

      if (gradeItem.title.includes("Georgia")) {
        return <GAGradebookCertificate name={name.answer} cred={null} />
      }
      return (
        <GradebookCertificate
          name={name.userAnswer || name.answer}
          state={state.userAnswer || state.answer}
          licenseNum={licenseNum.userAnswer || licenseNum.answer}
        />
      )
    }

    return (
      <Attempts
        gradeItem={gradeItem}
        materials={props.materials}
        fetchMaterials={props.fetchMaterials}
      />
    )
  }

  return (
    <>
      <StyledTableRow className={classes.root}>
        <StyledTableCell align="center">
          {props.isReviewable && gradeItem.attempts.length > 0 ? (
            <StyledTooltip
              title={
                props.open === gradeItem.id
                  ? t("grades.hideAttempts")
                  : t("grades.viewAttempts")
              }
              arrow
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={handleOpen}
              >
                {props.open === gradeItem.id ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <AssignmentOutlinedIcon />
                )}
              </IconButton>
            </StyledTooltip>
          ) : gradeItem.markedCompleteBy && gradeItem.grade !== "--" ? (
            <StyledTooltip title={t("grades.markedComplete")} arrow>
              <InfoOutlinedIcon style={{ fontSize: "1.35em" }} />
            </StyledTooltip>
          ) : null}
        </StyledTableCell>
        <StyledTableCell>
          <Typography className={classes.typography}>
            {gradeItem.title.replace(/ *\([^)]*\) */g, "")}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center">
          {/* {gradeItem.markedCompleteBy && gradeItem.grade !== "--" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <InfoOutlinedIcon
                style={{ fontSize: "1em", marginRight: "5%" }}
              />
              
                <Typography className={classes.typography}>
                  {gradeItem.grade}
                </Typography>
          ) : ( */}
          <Typography className={classes.typography}>
            {!!parseFloat(gradeItem.grade)
              ? `${gradeItem.grade.toFixed(2).replace(/\.?0+$/, "")}%`
              : t(`grades.${gradeItem.grade}`)}
          </Typography>
        </StyledTableCell>
        {props.showCell ? (
          <StyledTableCell style={{ textAlign: "center" }}>
            {props.children}
          </StyledTableCell>
        ) : null}
        {props.showContext ? (
          <StyledTableCell style={{ textAlign: "center", padding: "8px 0" }}>
            <IconButton
              style={{ padding: "12px 0", backgroundColor: "transparent" }}
              disableRipple
              disabled={props.disableContext}
              onClick={props.handleContextMenu}
            >
              <MoreVert />
            </IconButton>
          </StyledTableCell>
        ) : null}
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={6}
        >
          <Collapse
            in={props.open === gradeItem.id}
            timeout="auto"
            unmountOnExit
          >
            <Divider variant="middle" />
            <Box
              style={{
                width: "95%",
                margin: "10px auto",
              }}
            >
              {getAttemptContent()}
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}

export default GradeItem
