import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Card, CardContent, CssBaseline } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ReactPlayer from "react-player";
import Collapse from "@material-ui/core/Collapse";
import "./override.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 0,
    textAlign: "center",
    borderBottom: "1px solid #680000",
    backgroundColor: "#680000",
    color: "#ffffff",
  },
  parentHeading: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 0,
    textAlign: "center",
    borderBottom: "1px solid #680000",
    backgroundColor: "#680000",
    color: "#ffffff",
    width: "100%",
  },
  mobileHeading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    borderBottom: "1px solid #680000",
    backgroundColor: "#680000",
    color: "#ffffff",
  },
  titleDivider: {
    margin: theme.spacing(1, 0, 2, 0),
    color: "#680000",
  },
  panel: {
    textAlign: "left",
  },
  testimonialText: {
    fontStyle: "oblique",
  },
  testimonialName: {
    fontWeight: "bold",
  },
  mobileTestimonialName: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(12),
  },
  imageGrid: {
    marginTop: "30px",
  },
  imageGridItem: {
    display: "flex",
    justifyContent: "center",
  },
  videoGridItem: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2, 0),
  },
  btnGridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },
  learnMore: {
    width: "fit-content",
    margin: "0 auto",
    textDecoration: "underline",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
    color: "#757575",
    cursor: "pointer",
  },
  learnMoreButton: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid #494949",
    backgroundColor: "white",
    color: "#757575",
    "&:hover": {
      color: "white",
      backgroundColor: "#757575",
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#680000",
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(24),
    border: "1px solid #680000",
    "&:hover": {
      color: "white",
      backgroundColor: "#680000",
    },
  },
}))(Button);

const MobileColorButton = withStyles((theme) => ({
  root: {
    color: "#680000",
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #680000",
    "&:hover": {
      color: "white",
      backgroundColor: "#680000",
    },
  },
}))(Button);

const CourseListing = (props) => {
  console.log(props);
  const classes = useStyles();
  const { course } = props;

  const [open, setOpen] = useState(course.defaultOpen ? true : false);

  useEffect(() => {
    const updateOpen = () => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      if (props.open) {
        setOpen(true);
      }
    };
    updateOpen();
  }, [props]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <CssBaseline />
      {props.isParent ? (
        <Paper
          style={{
            margin: "2%",
            border: `1px solid #680000`,
          }}
          elevation={0}
          square={false}
        >
          <Typography className={classes.heading}>{course.title}</Typography>
          {course.videos ? (
            <Grid container justify="center" spacing={3}>
              {course.videos.map((video, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  style={{
                    margin: "20px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <ReactPlayer url={video.url} light={video.img} /> */}
                  <ReactPlayer url={video.url} controls />
                </Grid>
              ))}
            </Grid>
          ) : null}
          <div
            style={{
              padding: "2%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {course.headingImages ? (
              <Grid
                className={classes.imageGrid}
                container
                spacing={3}
                justify="center"
              >
                {course.headingImages.map((img, index) => (
                  <Grid
                    key={index}
                    item
                    xs={img.xs}
                    className={classes.imageGridItem}
                  >
                    <img src={img.img} height={img.height} width={img.width} />
                  </Grid>
                ))}
              </Grid>
            ) : null}
            <div style={{ textAlign: "left" }}>{course.kicker}</div>

            {/* <Typography
              onClick={() => props.selectPageContent(course.id)}
              className={classes.learnMore}
            >
              Click here for more information
            </Typography> */}
            <Button
              onClick={() => props.selectPageContent(course.id)}
              variant="outlined"
              disableRipple
              className={classes.learnMoreButton}
              style={{
                alignSelf: "center",
                color: "#680000",
                fontWeight: "bold",
              }}
            >
              Learn More
            </Button>
          </div>
        </Paper>
      ) : (
        <Paper
          style={{
            padding: "2%",
            textAlign: "left",
            maxWidth: "100%",
          }}
          elevation={0}
          square={false}
        >
          <Typography className={classes.heading}>{course.title}</Typography>
          {course.videos ? (
            <Grid container justify="center" spacing={3}>
              {course.videos.map((video, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  style={{
                    margin: "20px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <ReactPlayer url={video.url} light={video.img} /> */}
                  <ReactPlayer url={video.url} controls />
                </Grid>
              ))}
            </Grid>
          ) : null}
          {course.headingImages ? (
            <Grid
              className={classes.imageGrid}
              container
              spacing={3}
              justify="center"
            >
              {course.headingImages.map((img, index) => (
                <Grid
                  key={index}
                  item
                  xs={img.xs}
                  className={classes.imageGridItem}
                >
                  <img src={img.img} height={img.height} width={img.width} />
                </Grid>
              ))}
            </Grid>
          ) : null}
          {/* {open ? null : course.kicker}
        <Collapse in={open} timeout="auto"> */}
          {course.kicker}
          {course.description}
          {course.images ? (
            <Grid
              className={classes.imageGrid}
              container
              spacing={3}
              justify="center"
            >
              {course.images.map((img, index) => (
                <Grid
                  key={index}
                  item
                  xs={img.xs}
                  className={classes.imageGridItem}
                >
                  <img src={img.img} height={img.height} width={img.width} />
                </Grid>
              ))}
            </Grid>
          ) : null}
          {props.isParent && course.preview ? null : course.preview}
          {course.feedback ? (
            <>
              <Divider variant="middle" style={{ margin: "20px 0" }} />
              <Grid container spacing={3} justify="center">
                {course.feedback.map((el, index) => (
                  <Grid item xs={4} key={index}>
                    <Card
                      style={{
                        maxWidth: 300,
                        backgroundColor: "#eee",
                        margin: "0 auto",
                      }}
                    >
                      <CardContent>
                        <Typography className={classes.testimonialText}>
                          "{el.text}"
                        </Typography>
                        <Typography className={classes.testimonialName}>
                          {el.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : null}
          <Divider variant="middle" style={{ margin: "20px 0" }} />
          {course.buttons ? (
            <Grid
              className={classes.imageGrid}
              container
              spacing={3}
              justify="center"
            >
              {course.buttons.map((btn, index) => (
                <Grid key={index} item xs={4} className={classes.btnGridItem}>
                  {btn.desc}
                  {btn.btn}
                </Grid>
              ))}
            </Grid>
          ) : null}
          {/* </Collapse> */}
          {/* <div style={{ textAlign: "center", marginTop: "35px" }}>
          <ColorButton onClick={handleClick}>
            {open ? "Show Less" : "Show More"}
          </ColorButton>
        </div> */}
        </Paper>
      )}
    </>
  );
};

const MobileCourseListing = (props) => {
  //console.log(props);
  const classes = useStyles();
  const { course } = props;

  const [open, setOpen] = useState(course.defaultOpen ? true : false);

  useEffect(() => {
    const updateOpen = () => {
      if (props.open) {
        setOpen(true);
      }
    };
    updateOpen();
  }, [props]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <CssBaseline />
      {props.isParent ? (
        <Paper
          style={{
            margin: "2%",
            border: `1px solid #680000`,
          }}
          elevation={0}
          square={false}
        >
          <Typography className={classes.mobileHeading}>
            {course.title}
          </Typography>
          {course.videos ? (
            <Grid container justify="center" spacing={0}>
              {course.videos.map((video, index) => (
                <Grid
                  item
                  xs={12}
                  className={classes.videoGridItem}
                  key={index}
                >
                  <ReactPlayer
                    url={video.url}
                    width="95%"
                    height="150px"
                    controls
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
          {course.headingImages ? (
            <Grid
              className={classes.imageGrid}
              container
              justify="center"
              spacing={1}
            >
              {course.headingImages.map((img, index) => (
                <Grid
                  key={index}
                  item
                  xs={img.xs}
                  className={classes.imageGridItem}
                >
                  <img
                    src={img.img}
                    style={{
                      objectFit: "scale-down",
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}

          {course.kicker}
          {course.description}
          {course.images ? (
            <Grid
              className={classes.imageGrid}
              container
              justify="center"
              spacing={1}
            >
              {course.images.map((img, index) => (
                <Grid
                  key={index}
                  item
                  xs={img.xs}
                  className={classes.imageGridItem}
                >
                  <img
                    src={img.img}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}

          {course.feedback ? (
            <>
              <Divider variant="middle" style={{ margin: "20px 0" }} />
              <Grid container spacing={3} justify="center">
                {course.feedback.map((el, index) => (
                  <Grid item xs={12} key={index}>
                    <Card
                      style={{
                        minWidth: "80%",
                        maxWidth: "90%",
                        backgroundColor: "#eee",
                        margin: "0 auto",
                      }}
                    >
                      <CardContent>
                        <Typography className={classes.testimonialText}>
                          "{el.text}"
                        </Typography>
                        <Typography className={classes.mobileTestimonialName}>
                          {el.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Divider variant="middle" style={{ margin: "20px 0" }} />
            </>
          ) : null}
          {course.buttons ? (
            <Grid container justify="center">
              {course.buttons.map((btn, index) => (
                <Grid key={index} item xs={8} className={classes.btnGridItem}>
                  {btn.btn}
                </Grid>
              ))}
            </Grid>
          ) : null}
        </Paper>
      ) : (
        <div>
          <Typography className={classes.mobileHeading}>
            {course.title}
          </Typography>
          {course.videos ? (
            <Grid container justify="center" spacing={1}>
              {course.videos.map((video, index) => (
                <Grid
                  item
                  xs={12}
                  className={classes.videoGridItem}
                  key={index}
                >
                  <ReactPlayer
                    url={video.url}
                    width="95%"
                    height="150px"
                    controls
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
          {course.headingImages ? (
            <Grid
              className={classes.imageGrid}
              container
              justify="center"
              spacing={3}
            >
              {course.headingImages.map((img, index) => (
                <Grid
                  key={index}
                  item
                  xs={img.xs}
                  className={classes.imageGridItem}
                >
                  <img
                    src={img.img}
                    style={{
                      objectFit: "scale-down",
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}

          {course.kicker}
          {course.description}
          {course.images ? (
            <Grid
              className={classes.imageGrid}
              container
              justify="center"
              spacing={3}
            >
              {course.images.map((img, index) => (
                <Grid
                  key={index}
                  item
                  xs={img.xs}
                  className={classes.imageGridItem}
                >
                  <img
                    src={img.img}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
          {props.isParent && course.preview ? null : course.preview}
          {course.feedback ? (
            <>
              <Divider variant="middle" style={{ margin: "20px 0" }} />
              <Grid container spacing={3} justify="center">
                {course.feedback.map((el, index) => (
                  <Grid item xs={12} key={index}>
                    <Card
                      style={{
                        minWidth: "80%",
                        maxWidth: "90%",
                        backgroundColor: "#eee",
                        margin: "0 auto",
                      }}
                    >
                      <CardContent>
                        <Typography className={classes.testimonialText}>
                          "{el.text}"
                        </Typography>
                        <Typography className={classes.mobileTestimonialName}>
                          {el.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Divider variant="middle" style={{ margin: "20px 0" }} />
            </>
          ) : null}
          {course.buttons ? (
            <Grid container justify="center">
              {course.buttons.map((btn, index) => (
                <Grid key={index} item xs={8} className={classes.btnGridItem}>
                  {btn.btn}
                </Grid>
              ))}
            </Grid>
          ) : null}
        </div>
      )}
    </>
  );
};

export { CourseListing, MobileCourseListing };
