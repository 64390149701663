import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const fallbackLng = ["en"];
const availableLanguages = ["en", "fr"];
let currentLng = "en";

i18n
  .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

  //.use(LanguageDetector) // detect user language, comment out lng below in init

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: true,
    whitelist: availableLanguages,
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

const toggleLanguage = (lng) => {
  console.log("toggling...");
  i18n.changeLanguage(lng);
  currentLng = lng;
};

export { toggleLanguage, availableLanguages, currentLng };

export default i18n;
