import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  withStyles,
  makeStyles,
  lighten,
  Button,
  Typography,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { ManualGridResults } from "../ManualGridPage/ManualGrid";

const SubTable = withStyles((theme) => ({
  root: {
    border: 0,
    width: "40%",
    margin: "20px 0",
  },
}))(Table);

const SubTableCell = withStyles((theme) => ({
  body: {
    border: 0,
    padding: theme.spacing(1, 0, 1, 2),
  },
}))(TableCell);

const SubTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      borderBottom: "1px solid #000",
      backgroundColor: "transparent",
    },
    "&:nth-of-type(even)": {
      borderBottom: "1px solid #000",
      backgroundColor: "transparent",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  typography: {
    fontSize: "1.2em",
    fontWeight: "500",
    padding: 0,
  },
  smallTable: {
    padding: 0,
  },
  answer: {
    padding: "0 5px",
    margin: "0 1%",
  },
  correctAnswer: {
    border: "1px solid green",
    borderRadius: "2.5px",
    padding: "0 5px",
    margin: "0 1%",
    backgroundColor: "lightgreen",
  },
  incorrectAnswer: {
    border: "1px solid red",
    borderRadius: "2.5px",
    padding: "0 5px",
    margin: "0 1%",
    backgroundColor: red[300],
  },
});

const ManualExamAttempts = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [parsedJsonAttempts, setParsedJsonAttempts] = useState([]);
  const [readyToRender, setReadyToRender] = useState(false);
  console.log("manual exam attempts props: ", props);
  const { gradeItem } = props;

  useEffect(() => {
    const parseJSON = async () => {
      let updatedParsedJsonAttempts = [];
      for (let attempt of props.attempts) {
        let parsedJson = null;
        try {
          parsedJson = await JSON.parse(attempt.json);
        } catch (error) {
          parsedJson = null;
        }
        if (parsedJson) {
          console.log("parsedJson: ", parsedJson);
          updatedParsedJsonAttempts.push(parsedJson);
        }
      }
      console.log("ATTEMPTS AFTER PARSING: ", updatedParsedJsonAttempts);
      setParsedJsonAttempts(updatedParsedJsonAttempts);
    };
    if (props.attempts.length > 0) parseJSON();
  }, []);

  useEffect(() => {
    //ready to render whenever attempts finihsed updating state
    console.log("parsedJsonAttempts state has updated: ", parsedJsonAttempts);
    if (parsedJsonAttempts.length > 0) setReadyToRender(true);
  }, [parsedJsonAttempts]);

  if (!readyToRender) return <p>{t("common.loading")}...</p>;
  else
    return (
      <>
        <Typography variant="h6" gutterBottom component="div">
          {t("grades.attemptHistory")}
        </Typography>

        <SubTable size="small" aria-label="purchases">
          <TableBody>
            <SubTableRow>
              <SubTableCell>
                <Typography variant="body1" className={classes.smallTable}>
                  {t("grades.grade")}
                </Typography>
              </SubTableCell>
              <SubTableCell>
                <Typography variant="body1" className={classes.smallTable}>
                  {t("grades.attemptDate")}
                </Typography>
              </SubTableCell>
            </SubTableRow>
            {gradeItem.attempts.map((attempt, index) => (
              <SubTableRow key={index}>
                <SubTableCell>
                  <Typography
                    key={index}
                    variant="body1"
                    className={classes.smallTable}
                  >
                    {attempt.grade}
                  </Typography>
                </SubTableCell>
                <SubTableCell>
                  <Typography
                    key={index}
                    variant="body1"
                    className={classes.smallTable}
                  >
                    {new Date(attempt.submittedAt * 1000).toLocaleString()}
                  </Typography>
                </SubTableCell>
              </SubTableRow>
            ))}
          </TableBody>
        </SubTable>
        {props.attempts.map((attempt, index) => {
          console.log("attempt: ", attempt);
          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  margin: "20px 0",
                  width: "95%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">Exam Results</Typography>
                <ManualGridResults
                  activityData={null}
                  detections={attempt.detections}
                  tumors={attempt.tumors}
                  resultsGrid={attempt.resultsGrid}
                  formResults={attempt.formResults}
                />

                {parsedJsonAttempts.length > 0 && (
                  <SurveySubmission items={parsedJsonAttempts} />
                )}
              </div>
            </div>
          );
        })}
      </>
    );
};

const SurveySubmission = (props) => {
  const { items } = props;
  console.log(items);

  return (
    <>
      <Divider style={{ marginBottom: "8px" }} />
      {items.map((item, index) => {
        return (
          <Paper
            key={index}
            style={{ padding: "10px 1%", marginBottom: "20px" }}
          >
            {item.map((el, i) => (
              <Fragment key={i}>
                <Typography>
                  {i + 1}) {el.title}
                </Typography>
                {el.userAnswer ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "1%",
                    }}
                  >
                    <ArrowForwardIosIcon
                      style={{ marginRight: "5px" }}
                      fontSize="small"
                    />
                    {Array.isArray(el.userAnswer) ? (
                      <Typography variant="body2">
                        {el.userAnswer.join(", ")}
                      </Typography>
                    ) : (
                      <Typography variant="body2">{el.userAnswer}</Typography>
                    )}
                  </div>
                ) : null}
              </Fragment>
            ))}
          </Paper>
        );
      })}
    </>
  );
};

export default ManualExamAttempts;
