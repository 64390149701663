import React, { Component } from 'react';

class Cell extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cell_size: 50,
			green: '#33cc33',
			yellow: '#ebf442',
			red: '#c11707',
			black: '#000000'
		};
	}

	render() {
		const cell = this.props;
		//console.log("Inside Cell",cell);
		let greenOrBlack = this.state.black;
		let yellowOrBlack = this.state.black;
		let redOrBlack = this.state.black;
		//console.log("Rendering cell "+cell.x+", "+cell.y+" light: "+cell.light+ " medium: "+cell.medium+" deep: "+cell.deep);
		return (
			<div>
				{cell.light ? <div
					className='Cell'
					style={{
						left: `${this.state.cell_size * cell.y}px`,
						top: `${this.state.cell_size * cell.x}px`,
						width: `${(50 / 3)}px`,
						height: `${50 - 1}px`,
						background: this.state.green
					}}
				/> : null}

				{cell.medium ? <div
					className='Cell'
					style={{
						left: `${this.state.cell_size * cell.y + (50 / 3)}px`,
						top: `${this.state.cell_size * cell.x}px`,
						width: `${(50 / 3)}px`,
						height: `${50 - 1}px`,
						background: this.state.yellow
					}}
				/> : null}

				{cell.deep ? <div
					className='Cell'
					style={{
						left: `${this.state.cell_size * cell.y + (100 / 3)}px`,
						top: `${this.state.cell_size * cell.x}px`,
						width: `${(50 / 3)}px`,
						height: `${50 - 1}px`,
						background: this.state.red
					}}
				/> : null}

			</div>
		);
	}
}

export default Cell;
