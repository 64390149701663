import React, {
  Fragment,
  Component,
  Suspense,
  useState,
  useEffect,
  useContext,
} from "react";
import { withFirebase } from "../Firebase";
import { withRouter, Link, useHistory } from "react-router-dom";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Zoom,
  useScrollTrigger,
  Slide,
  ListItemIcon,
  ListItemText,
  Input,
  InputAdornment,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";

import LanguageSelector from "../Generic/LanguageSelector";

const MammaNav = styled.div`
  color: #000000;
  background-color: #ffffff;
  padding: 1% 2.5%;
  color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const H1 = styled.div`
  color: #680000;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0 0 0 10px;
  width: fit-content;
`;

const H3 = styled.div`
  color: #680000;
  font-size: 1.75em;
  font-weight: bold;
  margin-left: 10px;
`;

const NavLink = styled.p`
  display: block;
  color: #680000;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  font-weight: bold;
  text-decoration: none;
`;

const NavUL = styled.ul`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    backgroundColor: "transparent",
    fontWeight: 400,
  },
  title: {
    marginLeft: "0.5%",
    fontWeight: 500,
  },
  envInfo: {
    color: "black",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(22),
    border: "2px solid black",
  },
  menuLink: {
    color: "#680000",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
  mobileMenuItem: {
    color: "white",
    fontSize: theme.typography.pxToRem(18),
  },
  Toolbar: {
    paddingLeft: "16px",
  },
}));

const MammaAppBar = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    color: "#680000",
    padding: 0,
  },
}))(AppBar);

const MenuButton = withStyles((theme) => ({
  root: {
    height: "56px",
  },
}))(IconButton);

const NavMenu = withStyles((theme) => ({
  paper: {
    width: "40%",
    backgroundColor: "#494949",
    left: "59% !important",
    opacity: "0.99 !important",
  },
}))(Menu);

const NavBar = (props) => {
  const context = useContext(AuthUserContext);
  const [cohort, setCohort] = useState(null);

  useEffect(() => {
    if (context) {
      let cohortDoc = props.firebase
        .getDocByIdFrom("cohorts", context.cohortId)
        .get()
        .then((doc) => {
          console.log(doc);
          setCohort({ ...doc.data() });
          console.log("Cohort in navbar: ", cohort);
        });
    }
  }, [context]);

  // let navLinks = (
  //   <PublicNavLinks firebase={props.firebase} history={props.history} />
  // );

  let navLinks = null;

  if (context !== null && context.roles.includes("FACULTY")) {
    navLinks = (
      <FacultyNavLinks
        authUser={context}
        history={props.history}
        firebase={props.firebase}
        cohort={cohort}
      />
    );
  } else if (context !== null && context.roles.includes("ADMIN")) {
    navLinks = (
      <AdminNavLinks
        authUser={context}
        history={props.history}
        firebase={props.firebase}
      />
    );
  } else if (context !== null && context.roles.includes("STAFF")) {
    navLinks = (
      <StaffNavLinks
        authUser={context}
        history={props.history}
        firebase={props.firebase}
      />
    );
  } else if (context !== null && context.roles.length === 0) {
    navLinks = (
      <UserNavLinks
        authUser={context}
        history={props.history}
        firebase={props.firebase}
        cohort={cohort}
      />
    );
  }
  return <NavTemplate>{navLinks}</NavTemplate>;
};

const NavTemplate = (props) => {
  const classes = useStyles();
  return (
    <>
      <MammaAppBar elevation={1} position="sticky">
        <Toolbar disableGutters className={classes.Toolbar}>
          <img
            src="https://mammacare.org/wp-content/uploads/2016/04/mammacare-symbol.png"
            style={{ maxWidth: "48px", maxHeight: "48px" }}
          />
          <Typography variant="h4" className={classes.title}>
            The MammaCare Foundation
          </Typography>
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {props.children}
          </div>
        </Toolbar>
      </MammaAppBar>
      {/* <Toolbar /> */}
    </>
  );
};

const PublicNavLinks = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Button
        id="nav_signin"
        onClick={() => history.push(ROUTES.SIGN_IN)}
        className={classes.menuLink}
      >
        {t("nav.signIn")}
      </Button>
      <Button
        id="nav_signup"
        onClick={() => history.push("/signup")}
        className={classes.menuLink}
      >
        {t("nav.signUp")}
      </Button>
    </>
  );
};

const FacultyNavLinks = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { authUser, history, firebase, cohort } = props;
  const [showPractice, setShowPractice] = useState(false);

  useEffect(() => {
    console.log(cohort);
    if (cohort?.allowPractice === "always") {
      setShowPractice(true);
      return;
    }

    if (cohort?.allowPractice === "complete") {
      let currentCourse = _.find(authUser.courses, {
        cohort: authUser.cohortId,
      });
      if (!_.find(currentCourse.sections, { grade: "--" })) {
        setShowPractice(true);
        return;
      }
    }
  }, [props]);

  return (
    <>
      <Button
        onClick={() => history.push(ROUTES.MYCOHORTS)}
        className={classes.menuLink}
      >
        {t("nav.cohorts")}
      </Button>

      {showPractice ? (
        <Button
          onClick={() => history.push(ROUTES.PRACTICEEXAM)}
          className={classes.menuLink}
        >
          Practice
        </Button>
      ) : null}

      <MenuDropdown firebase={firebase} history={history} />
    </>
  );
};

const AdminNavLinks = (props) => {
  const classes = useStyles();
  const { authUser, history, firebase } = props;
  const { t } = useTranslation();
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [courseComplete, setCourseComplete] = useState(false);
  const optionsOpen = !!optionsAnchorEl;

  const handleOptionsMenu = (event) => setOptionsAnchorEl(event.currentTarget);
  const handleOptionsClose = () => setOptionsAnchorEl(null);
  const navigate = (route) => {
    history.push(route);
    handleOptionsClose();
  };

  return (
    <>
      <Typography className={classes.envInfo}>
        {`${process.env.REACT_APP_STAGE === "dev" ? "Dev" : "Prod"} |
        ${process.env.NODE_ENV === "development" ? "localhost" : "deployed"}`}
      </Typography>

      <UserLookup firebase={firebase} />

      <Button
        onClick={() => history.push(ROUTES.PRACTICEEXAM)}
        className={classes.menuLink}
      >
        Practice
      </Button>
      <Button
        onClick={() => history.push(ROUTES.MYCOHORTS)}
        className={classes.menuLink}
      >
        {t("nav.cohorts")}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.COURSETEMPLATES)}
        className={classes.menuLink}
      >
        {t("nav.courses")}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.ADMIN)}
        className={classes.menuLink}
      >
        {t("nav.admin")}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.QRMODELSCANNERPAGE)}
        className={classes.menuLink}
      >
        {t("nav.QRModelScanner")}
      </Button>
      <MenuDropdown firebase={firebase} history={history} />
    </>
  );
};

const StaffNavLinks = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { authUser, history, firebase } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = (route) => {
    history.push(route);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={() => history.push(ROUTES.PRACTICEEXAM)}
        className={classes.menuLink}
      >
        Practice
      </Button>
      <Button
        onClick={() => history.push(ROUTES.MYCOHORTS)}
        className={classes.menuLink}
      >
        {t("nav.cohorts")}
      </Button>
      <MenuDropdown firebase={firebase} history={history} />
    </>
  );
};

const UserNavLinks = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { authUser, history, firebase, cohort } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPractice, setShowPractice] = useState(false);
  const open = !!anchorEl;

  useEffect(() => {
    console.log(cohort);
    if (cohort?.allowPractice === "always") {
      setShowPractice(true);
      return;
    }

    if (cohort?.allowPractice === "complete") {
      let currentCourse = _.find(authUser.courses, {
        cohort: authUser.cohortId,
      });
      if (!_.find(currentCourse.sections, { grade: "--" })) {
        setShowPractice(true);
        return;
      }
    }
  }, [props]);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = (route) => {
    history.push(route);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={() => history.push(ROUTES.HOME)}
        className={classes.menuLink}
      >
        {t("nav.course")}
      </Button>
      <Button
        onClick={() => history.push("/grades/" + authUser.uid)}
        className={classes.menuLink}
      >
        {t("nav.grades")}
      </Button>
      {showPractice ? (
        <Button
          onClick={() => history.push(ROUTES.PRACTICEEXAM)}
          className={classes.menuLink}
        >
          Practice
        </Button>
      ) : null}
      <Button
        onClick={() => history.push(ROUTES.MYRESOURCES)}
        className={classes.menuLink}
      >
        {t("nav.resources")}
      </Button>

      <MenuDropdown firebase={firebase} history={history} />
    </>
  );
};

const MobileNavBar = (props) => {
  const context = useContext(AuthUserContext);
  let navLinks = <MobilePublicNavLinks history={props.history} />;

  if (context !== null && context.roles.includes("FACULTY")) {
    navLinks = <MobileFacultyNavLinks authUser={context} />;
  } else if (context !== null && context.roles.includes("ADMIN")) {
    navLinks = <MobileAdminNavLinks authUser={context} />;
  } else if (context !== null && context.roles.length === 0) {
    navLinks = <MobileUserNavLinks authUser={context} />;
  }
  return (
    <MobileNavTemplate context={context} history={props.history}>
      {navLinks}
    </MobileNavTemplate>
  );
};

const MobileNavTemplate = (props) => {
  const classes = useStyles();
  return (
    <>
      <HideOnScroll {...props}>
        <MammaAppBar
          elevation={0}
          style={{ borderBottom: "1px solid #680000" }}
        >
          <Toolbar>
            <div
              onClick={() => props.history.push("/")}
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                src="https://mammacare.org/wp-content/uploads/2016/04/mammacare-symbol.png"
                style={{ maxWidth: "36px", maxHeight: "36" }}
              />
              <Typography variant="body1" className={classes.title}>
                The MammaCare Foundation
              </Typography>
            </div>
          </Toolbar>
        </MammaAppBar>
      </HideOnScroll>
      <Toolbar />
    </>
  );
};

const MobilePublicNavLinks = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = (route) => {
    history.push(route);
    handleClose();
  };

  return (
    <>
      {/* <Button
        onClick={() => history.push(ROUTES.SIGN_IN)}
        className={classes.menuLink}
      >
        {t("nav.signIn")}
      </Button>
      <Button
        onClick={() => history.push("/signup")}
        className={classes.menuLink}
      >
        {t("nav.signUp")}
      </Button> */}
      <MenuButton onClick={handleMenu}>
        {open ? (
          <MenuOpenIcon style={{ color: "#680000" }} />
        ) : (
          <MenuIcon style={{ color: "#680000" }} />
        )}
      </MenuButton>
      <NavMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        elevation={1}
        TransitionComponent={Zoom}
      >
        <MenuItem
          className={classes.mobileMenuItem}
          onClick={() => navigate(ROUTES.SIGN_IN)}
        >
          {t("nav.signIn")}
        </MenuItem>
        <MenuItem
          className={classes.mobileMenuItem}
          onClick={() => navigate("/signup")}
        >
          {t("nav.signUp")}
        </MenuItem>
      </NavMenu>
    </>
  );
};

const MobileFacultyNavLinks = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { authUser, history, firebase } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = (route) => {
    history.push(route);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={() => history.push(ROUTES.MYCOHORTS)}
        className={classes.menuLink}
      >
        {t("nav.cohorts")}
      </Button>

      <Button
        onClick={() => history.push(ROUTES.PERFORMANCEREPORT)}
        className={classes.menuLink}
      >
        {t("nav.performanceReport")}
      </Button>

      <div>
        <IconButton onClick={handleMenu}>
          <MenuIcon fontSize="large" style={{ color: "#680000" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate(ROUTES.ACCOUNT)}>
            {t("nav.account")}
          </MenuItem>
          <MenuItem onClick={firebase.doSignOut}>{t("nav.signOut")}</MenuItem>
        </Menu>
      </div>
    </>
  );
};

const MobileAdminNavLinks = (props) => {
  const classes = useStyles();
  const { authUser, history, firebase } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = (route) => {
    history.push(route);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={() => history.push(ROUTES.MYCOHORTS)}
        className={classes.menuLink}
      >
        {t("nav.cohorts")}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.COURSETEMPLATES)}
        className={classes.menuLink}
      >
        {t("nav.courses")}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.PERFORMANCEREPORT)}
        className={classes.menuLink}
      >
        {t("nav.performanceReport")}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.ADMIN)}
        className={classes.menuLink}
      >
        {t("nav.admin")}
      </Button>

      <div>
        <IconButton onClick={handleMenu}>
          <MenuIcon fontSize="large" style={{ color: "#680000" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate(ROUTES.ACCOUNT)}>
            {t("nav.account")}
          </MenuItem>
          <MenuItem onClick={firebase.doSignOut}>{t("nav.signOut")}</MenuItem>
        </Menu>
      </div>
    </>
  );
};

const MobileStaffNavLinks = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { authUser, history, firebase } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = (route) => {
    history.push(route);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={() => history.push(ROUTES.MYCOHORTS)}
        className={classes.menuLink}
      >
        {t("nav.cohorts")}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.PERFORMANCEREPORT)}
        className={classes.menuLink}
      >
        {t("nav.performanceReport")}
      </Button>

      <div>
        <IconButton onClick={handleMenu}>
          <MenuIcon fontSize="large" style={{ color: "#680000" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate(ROUTES.ACCOUNT)}>
            {t("nav.account")}
          </MenuItem>
          <MenuItem onClick={firebase.doSignOut}>{t("nav.signOut")}</MenuItem>
        </Menu>
      </div>
    </>
  );
};

const MobileUserNavLinks = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { authUser, history, firebase } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = (route) => {
    history.push(route);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={() => history.push(ROUTES.HOME)}
        className={classes.menuLink}
      >
        {t("nav.course")}
      </Button>
      <Button
        onClick={() => history.push("/grades/" + authUser.uid)}
        className={classes.menuLink}
      >
        {t("nav.grades")}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.MYRESOURCES)}
        className={classes.menuLink}
      >
        {t("nav.resources")}
      </Button>

      <div>
        <IconButton onClick={handleMenu}>
          <MenuIcon fontSize="large" style={{ color: "#680000" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate(ROUTES.ACCOUNT)}>
            {t("nav.account")}
          </MenuItem>
          <MenuItem onClick={firebase.doSignOut}>{t("nav.signOut")}</MenuItem>
        </Menu>
      </div>
    </>
  );
};

const MenuDropdown = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { authUser, history, firebase } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = (route) => {
    history.push(route);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleMenu}>
        <MenuIcon fontSize="large" style={{ color: "#680000" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate(ROUTES.ACCOUNT)}>
          <ListItemIcon>
            <AccountCircleIcon style={{ color: "#680000" }} />
          </ListItemIcon>
          <ListItemText primary={t("nav.account")} />
        </MenuItem>
        <LanguageSelector component="menu" handleParentClose={handleClose} />
        <MenuItem onClick={firebase.doSignOut}>
          <ListItemIcon>
            <ExitToAppIcon style={{ color: "#680000" }} />
          </ListItemIcon>
          <ListItemText primary={t("nav.signOut")} />
        </MenuItem>
      </Menu>
    </div>
  );
};

const UserLookup = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { firebase } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [email, setEmail] = useState("");
  const open = !!anchorEl;

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setEmail("");
    setAnchorEl(null);
  };

  const isDisabled = () => {
    let reg = new RegExp(/^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/);

    let val = reg.test(email);
    return !val;
  };

  const lookup = async (e) => {
    e.preventDefault();
    console.log("submitted");
    let userList = await firebase.users().where("email", "==", email).get();
    console.log(userList.docs.length);
    if (userList.docs.length > 0) {
      history.push({
        pathname: `/grades/${userList.docs[0].id}`,
      });
      window.location.reload(false);
      handleClose();
    } else {
      handleClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handling submit");
    lookup();
  };

  return (
    <div>
      <IconButton onClick={handleMenu}>
        <SearchIcon style={{ color: "#680000" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <form onSubmit={lookup}>
            <Input
              type="text"
              value={email}
              onChange={(e) => {
                console.log(e.target.value);
                setEmail(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton disabled={isDisabled()} type="submit">
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            {/* <input type="submit" style={{ display: "none" }} /> */}
          </form>
        </MenuItem>
      </Menu>
    </div>
  );
};
const HideOnScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const MobileNav = compose(withFirebase, withRouter)(MobileNavBar);
const Nav = compose(withFirebase, withRouter)(NavBar);
export { MobileNav, Nav };
