import React, { Fragment } from "react";

import nsfLogo from "./images/nsf-logo-small.png";
import participatingUniversities from "./images/participating universities 12.3.20.jpg";
import participatingUniversities_mobile from "./mobileImages/participating universities 12.3.20 - mobile.jpg";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  AppBar,
  List,
  ListItem,
  Divider,
  Paper,
  ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    height: "100%",
    width: "80%",
    border: `1px solid ${fade("#680000", 0.5)}`,
    margin: "0 auto",
    "& h2": {
      textAlign: "center",
      marginTop: "15px",
    },
    "& p": {
      textAlign: "left",
    },
    "& hr": {
      width: "80%",
      border: 0,
      borderTop: "5px dashed #8c8c8c",
      textAlign: "center",
    },
  },
  bigText: {
    fontSize: "28px",
    color: "#680000",
    paddingBottom: 0,
    // fontWeight: 600,
  },
  smallText: {
    paddingTop: 0,
  },
  media: {
    maxHeight: "100%",
    maxWidth: "100%",
    //margin: "0 auto",
  },
  gridItem: {
    width: "40%",
  },
  cardImg: {
    maxHeight: "160px",
    width: "100%",
  },
  MobileCardImg: {
    maxHeight: "100%",
    width: "100%",
  },
  mobileFeedback: {
    width: "100%",
  },
  banner: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    border: "1px solid #d1d2d1",
    backgroundColor: "#f7f7f7",
  },
  statsList: {
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    //paddingLeft: 0,
    textAlign: "center",
    borderBottom: "1px solid #680000",
    marginBottom: "1%",
    width: "80%",
  },
  mobileHeading: {
    //fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    borderBottom: "1px solid #680000",
    marginBottom: "2%",
    width: "80%",
  },
}));

const PartnerFooter = () => {
  const classes = useStyles();

  return (
    <Paper
      style={{
        margin: "20px 0",
        padding: "1%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
      square={false}
    >
      {/* <Typography className={classes.heading}>
        The MammaCare Method<sup>&reg;</sup> of Breast Examination Taught Here
      </Typography> */}
      <img src={participatingUniversities} width="75%" />
      <Grid container spacing={3} justify="center">
        <Grid item container xs={4} justify="center">
          <List>
            {[
              "Angelo State University",
              "Beth Israel Deaconess Medical Center",
              "Binghamton University",
              "Carlow University",
              "Case Western Reserve University",
              "Central Michigan University",
              "FSU College of Medicine",
              "Georgia College",
              "Grand Valley State University",
              "James Madison University",
            ].map((el, index) => (
              <ListItem key={index}>
                <Typography
                  variant="h6"
                  style={{ margin: "0 auto", textAlign: "center" }}
                >
                  {el}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Divider variant="middle" orientation="vertical" flexItem />
        <Grid item container xs={3} justify="center">
          <List>
            {[
              "Liberty University",
              "Mayo Clinic",
              "Mercy Health Partners",
              "Mount Carmel Health",
              "Philadelphia College of Osteopathic Medicine",
              "Shenandoah University",
              "Southeastern Louisiana Univ.",
              "Southern Adventist University",
              "SUNY Downstate Medical Center",
              "Texas Christian University",
            ].map((el, index) => (
              <ListItem key={index}>
                <Typography
                  variant="h6"
                  style={{ margin: "0 auto", textAlign: "center" }}
                >
                  {el}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Divider variant="middle" orientation="vertical" flexItem />
        <Grid item container xs={4} justify="center">
          <List>
            {[
              "Texas Tech University",
              "University of Alaska",
              "University of Cincinnati",
              "University of Florida College of Medicine",
              "University of Pittsburgh at Bradford",
              "University of San Francisco",
              "University of South Florida Health Science Center",
              "University of Vermont",
              "University of Wisconsin – Milwaukee",
              "Western Kentucky University",
            ].map((el, index) => (
              <ListItem key={index}>
                <Typography
                  variant="h6"
                  style={{ margin: "0 auto", textAlign: "center" }}
                >
                  {el}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          container
          style={{
            marginTop: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Supported in part by</Typography>
          <img src={nsfLogo} />
        </Grid>
      </Grid>
    </Paper>
  );
};

const MobilePartnerFooter = () => {
  const classes = useStyles();

  return (
    <Paper
      style={{
        margin: "20px 0",
        padding: "1%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
      square={false}
    >
      {/* <Typography variant="h5" className={classes.mobileHeading}>
        The MammaCare Method<sup>&reg;</sup> Taught Here
      </Typography>
      <Typography>Join these and other prestigious institutions!</Typography> */}
      <img src={participatingUniversities_mobile} width="95%" />
      <Typography variant="h6" noWrap>
        Supported in part by
      </Typography>
      <img src={nsfLogo} />
      {/* <Grid container spacing={3} justify="center">
        <Grid item container xs={4} justify="center">
          <List>
            {[
              "Angelo State University",
              "Beth Israel Deaconess Medical Center",
              "Binghamton University",
              "Carlow University",
              "Case Western Reserve University",
              "Central Michigan University",
              "FSU College of Medicine",
              "Georgia College",
              "Grand Valley State University",
              "James Madison University",
            ].map((el, index) => (
              <ListItem key={index}>
                <Typography
                  variant="h6"
                  style={{ margin: "0 auto", textAlign: "center" }}
                >
                  {el}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Divider variant="middle" orientation="vertical" flexItem />
        <Grid item container xs={3} justify="center">
          <List>
            {[
              "Liberty University",
              "Mayo Clinic",
              "Mercy Health Partners",
              "Mount Carmel Health",
              "Philadelphia College of Osteopathic Medicine",
              "Shenandoah University",
              "Southeastern Louisiana Univ.",
              "Southern Adventist University",
              "SUNY Downstate Medical Center",
              "Texas Christian University",
            ].map((el, index) => (
              <ListItem key={index}>
                <Typography
                  variant="h6"
                  style={{ margin: "0 auto", textAlign: "center" }}
                >
                  {el}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Divider variant="middle" orientation="vertical" flexItem />
        <Grid item container xs={4} justify="center">
          <List>
            {[
              "Texas Tech University",
              "University of Alaska",
              "University of Cincinnati",
              "University of Florida College of Medicine",
              "University of Pittsburgh at Bradford",
              "University of San Francisco",
              "University of South Florida Health Science Center",
              "University of Vermont",
              "University of Wisconsin – Milwaukee",
              "Western Kentucky University",
            ].map((el, index) => (
              <ListItem key={index}>
                <Typography
                  variant="h6"
                  style={{ margin: "0 auto", textAlign: "center" }}
                >
                  {el}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          container
          style={{
            marginTop: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Supported in part by</Typography>
          <img src={nsfLogo} />
        </Grid>
      </Grid> */}
    </Paper>
  );
};

export { PartnerFooter, MobilePartnerFooter };
