import React, { useState, useRef, useEffect, useCallback } from "react";
import { Typography, makeStyles, Tooltip } from "@material-ui/core";
import useWindowSize from "../Generic/useWindowSize";
import modelImg from "../ExamGrid/P1.jpg";

const useStyles = makeStyles((theme) => ({
  canvas: {
    border: "1px solid black",
    width: "calc(100% - 2px)",
    position: "absolute",
    top: 0,
    left: 0,
  },
  wrapper: {
    position: "relative",
    width: "100%",
    maxHeight: "75%",
  },
}));

const CoverageCanvas = (props) => {
  const CW = 50 / 3;
  const CH = 50;
  const windowSize = useWindowSize();
  const classes = useStyles();
  const [height, setHeight] = useState(0);
  const canvasRef = useRef(null);
  const gridRef = useRef(null);
  let timer = null;

  useEffect(() => {
    let bh = 450;
    let bw = 400;
    let d = 50;
    let x = 0,
      y = 0;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d", { alpha: false });
    ctx.clearRect(0, 0, "400px", "450px");
    props.palpatedCells.forEach((cell, index) => {
      if (cell.light) {
        ctx.beginPath();
        ctx.fillStyle = "#33cc33";
        ctx.fillRect(cell.y * 50, cell.x * 50, CW, 50);
      }
      if (cell.medium) {
        ctx.beginPath();
        ctx.fillStyle = "#ebf442";
        ctx.fillRect(cell.y * 50 + CW, cell.x * 50, CW, CH);
      }
      if (cell.deep) {
        ctx.beginPath();
        ctx.fillStyle = "#c11707";
        ctx.fillRect(cell.y * 50 + 2 * CW, cell.x * 50, CW, CH);
      }
    });

    const grid = gridRef.current;
    const gtx = grid.getContext("2d");
    gtx.strokeStyle = "#333";
    gtx.strokeWidth = 1;
    gtx.beginPath();

    while (y < bh) {
      y += d;
      gtx.moveTo(x, y + 0.5);
      gtx.lineTo(bw, y + 0.5);
      gtx.stroke();
    }

    y = 0;
    while (x < bw) {
      x += d;
      gtx.moveTo(x + 0.5, y);
      gtx.lineTo(x + 0.5, bh);
      gtx.stroke();
    }

    //console.log(canvas.getBoundingClientRect());
    setHeight(`${canvas.getBoundingClientRect().height}px`);
  }, [canvasRef.current]);

  useEffect(() => {
    if (canvasRef.current) {
      setHeight(`${canvasRef.current.getBoundingClientRect().height}px`);
    }
  }, [windowSize]);

  return (
    <div className={classes.wrapper}>
      <canvas
        ref={canvasRef}
        width={"400px"}
        height={"450px"}
        className={classes.canvas}
        style={{ zIndex: 0 }}
      />
      <canvas
        ref={gridRef}
        width={"400px"}
        height={"450px"}
        className={classes.canvas}
        style={{ zIndex: 1 }}
      />
      <div style={{ height: height }} />
    </div>
  );
};

export default CoverageCanvas;
