import React, { Component, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography, withStyles } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// const H3 = styled.h3`
//   text-align: center;
//   color: #000000;
//   font-size: 1.5em;
//   font-weight: bold;
//   padding: 10px;
// `;

const MammaTableCell = withStyles({
  head: {
    backgroundColor: "#ffffff",
  },
})(TableCell);

const Expansion = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(Accordion);

const ExpansionSummary = withStyles((theme) => ({
  content: {
    margin: 0,
    display: "flex",
  },
}))(AccordionSummary);

const UserInfo = (props) => {
  console.log("USER INFO PROPS: ", props);
  const { t } = useTranslation();
  const { user, payments, shipments, trackers } = props;
  const [paymentsOpen, setOpenPayments] = useState(false);
  const [shipmentsOpen, setOpenShipments] = useState(false);

  let subSection = null;
  if (user.district || user.subSection) {
    subSection = (
      <ListItem divider>
        <ListItemText
          primary={<Typography variant="subtitle1">Health District</Typography>}
          secondary={
            <Typography variant="h5">
              {user.subSection ? user.subSection : user.district}
            </Typography>
          }
        />
      </ListItem>
    );
  }

  return (
    <Expansion style={{ margin: "0 auto", width: "99%" }} square={true}>
      <ExpansionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel7bh-content"
        id="panel7bh-header"
        style={{ margin: 0 }}
      >
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          {t("common.userInformation")}
        </Typography>
      </ExpansionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <List dense={true} style={{ width: "100%" }}>
            <ListItem divider>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    {t("common.name")}
                  </Typography>
                }
                secondary={
                  <Typography variant="h5">
                    {user.firstName} {user.lastName}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    {t("common.email")}
                  </Typography>
                }
                secondary={<Typography variant="h5">{user.email}</Typography>}
              />
            </ListItem>
            {user.phoneNum != null ? (
              <ListItem divider>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1">
                      {t("common.phoneNumber")}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="h5">{user.phoneNum}</Typography>
                  }
                />
              </ListItem>
            ) : null}
            <ListItem divider>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    {t("common.currentCohort")}
                  </Typography>
                }
                secondary={
                  <Typography variant="h5">{user.cohortId}</Typography>
                }
              />
            </ListItem>
            {subSection}
            {user.licenseType != null ? (
              <>
                <ListItem divider>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">License</Typography>
                    }
                    secondary={
                      <Typography variant="h5">
                        {user.licenseType} {user.licenseNum}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem divider>
                  <ListItemText
                    primary={<Typography variant="subtitle1">State</Typography>}
                    secondary={
                      <Typography variant="h5">{user.state}</Typography>
                    }
                  />
                </ListItem>
              </>
            ) : null}
            <ListItem
              divider={
                (payments && payments.length > 0) ||
                (shipments && shipments.length > 0)
              }
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    {t("common.signupDate")}
                  </Typography>
                }
                secondary={
                  <Typography variant="h5">
                    {new Date(user.createdAt * 1000).toLocaleString()}
                  </Typography>
                }
              />
            </ListItem>
            {payments && payments.length > 0 ? (
              <>
                <ListItem
                  divider={shipments.length > 0 && !paymentsOpen}
                  onClick={() => setOpenPayments(!paymentsOpen)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h5">
                        {t("common.payments")}
                      </Typography>
                    }
                  />
                  {paymentsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={paymentsOpen} timeout="auto">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <MammaTableCell>{t("common.product")}</MammaTableCell>
                        <MammaTableCell align="right">
                          {t("common.price")}
                        </MammaTableCell>
                        <MammaTableCell align="right">
                          {t("common.quantity")}
                        </MammaTableCell>
                        <MammaTableCell align="right">
                          {t("common.paymentMethod")}
                        </MammaTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payments.map((payment, index) => (
                        <TableRow key={index}>
                          <MammaTableCell>
                            {
                              payment.object.display_items[0].sku.attributes
                                .name
                            }
                          </MammaTableCell>
                          <MammaTableCell align="right">
                            {`$${payment.object.display_items[0].amount
                              .toString()
                              .slice(
                                0,
                                -2
                              )}.${payment.object.display_items[0].amount
                              .toString()
                              .slice(-2)}`}
                          </MammaTableCell>
                          <MammaTableCell align="right">
                            {payment.object.display_items[0].quantity}
                          </MammaTableCell>
                          <MammaTableCell align="right">
                            {payment.object.payment_method_types[0]}
                          </MammaTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Collapse>
              </>
            ) : null}
            {shipments && shipments.length > 0 ? (
              <>
                <ListItem onClick={() => setOpenShipments(!shipmentsOpen)}>
                  <ListItemText
                    primary={
                      <Typography variant="h5">
                        {t("common.shipments")}
                      </Typography>
                    }
                  />
                  {shipmentsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={shipmentsOpen} timeout="auto">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <MammaTableCell>Address</MammaTableCell>
                        <MammaTableCell align="right">
                          {t("common.status")}
                        </MammaTableCell>
                        <MammaTableCell align="right">
                          {t("common.trackingNum")}
                        </MammaTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {trackers.map((tracker, index) => {
                        const arrIndex = shipments.findIndex((shipment) =>
                          shipment.data != null
                            ? shipment.data.id === tracker.id
                            : shipment.id === tracker.id
                        );
                        const shipment = shipments[arrIndex];
                        console.log(shipment);
                        const address =
                          shipment.data != null
                            ? shipment.data.to_address
                            : shipment.to_address;
                        const url =
                          "https://www.ups.com/track?loc=en_US&tracknum=" +
                          tracker.tracking_code;
                        return (
                          <TableRow key={index}>
                            <MammaTableCell>
                              {address.name}
                              <br />
                              {address.street1} {address.street2} <br />
                              {address.city} {address.state} {address.zip}
                              <br />
                              {address.country}
                            </MammaTableCell>
                            <MammaTableCell align="right">
                              {tracker.status}
                            </MammaTableCell>
                            <MammaTableCell align="right">
                              <a href={url} target="_blank">
                                {tracker.tracking_code}
                              </a>
                            </MammaTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Collapse>
              </>
            ) : null}
          </List>
        </div>
      </AccordionDetails>
    </Expansion>
  );
};

export default UserInfo;
