import React from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {
  useTheme,
  useMediaQuery,
  makeStyles,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  Typography,
  Link,
} from "@material-ui/core"

import moment from "moment-timezone"

const H3 = styled.h3`
  text-align: center;
  color: #000000;
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
`

const Expansion = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.action.hover,
    textAlign: "left",
  },
}))(Accordion)

const TextButton = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(14),
    color: "#680000",
  },
}))(Button)

const useStyles = makeStyles((theme) => ({
  logText: {
    width: "fit-content",
  },
}))

const Logs = (props) => {
  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const { logs, open, handleOpen, reviewMore, disablePagination, cohort } =
    props
  const showAsList = useMediaQuery(theme.breakpoints.down("md"))

  const nyTimeszone = (ts) => {
    console.log(ts)
    const formattedTime = moment
      .unix(ts.seconds || ts)
      .tz("America/New_York")
      .format("LLLL")
    return formattedTime
  }

  return (
    <Expansion
      expanded={open}
      onChange={handleOpen}
      style={{ margin: "20px auto", width: "99%" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel7bh-content"
        id="panel7bh-header"
      >
        <H3>{t("common.logs")}</H3>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} style={{ width: "100%" }}>
            {showAsList ? (
              <List dense={true} style={{ width: "100%" }}>
                {logs.map((log, index) => {
                  let split = null
                  if (log.cohortId) {
                    split = log.cohortId.split("_")
                  }
                  return (
                    <ListItem key={index} divider={true}>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography variant="subtitle2">
                            {nyTimeszone(log.timestamp)}
                          </Typography>
                        }
                        secondary={
                          <Grid container spacing={3}>
                            <Grid item xs={6} sm={5}>
                              {cohort ? (
                                <Link
                                  component="button"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/grades/" + log.userId,
                                      state: {
                                        cohortId: log.cohortId,
                                        courseCode: split[1],
                                        term: split[2],
                                      },
                                    })
                                  }
                                  variant="subtitle2"
                                  underline="none"
                                >
                                  <Typography className={classes.logText}>
                                    {log.email}
                                  </Typography>
                                </Link>
                              ) : log.cohortId ? (
                                <Link
                                  component="button"
                                  onClick={() =>
                                    history.push(`/report/${log.cohortId}`)
                                  }
                                  variant="subtitle2"
                                  underline="none"
                                >
                                  <Typography className={classes.logText}>
                                    {log.cohortId}
                                  </Typography>
                                </Link>
                              ) : (
                                <Typography className={classes.logText}>
                                  --
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={6} sm={7}>
                              <Typography variant="body1">
                                {log.message}
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                  )
                })}
              </List>
            ) : (
              <Grid
                container
                spacing={3}
                direction="column"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  style={{ borderBottom: "2px solid black" }}
                >
                  <Grid item lg={4}>
                    <Typography variant="h6" className={classes.logText}>
                      Timestamp
                    </Typography>
                  </Grid>
                  <Grid item lg={3}>
                    <Typography variant="h6" className={classes.logText}>
                      {cohort ? "Email" : "Cohort"}
                    </Typography>
                  </Grid>
                  <Grid item lg={5}>
                    <Typography variant="h6" className={classes.logText}>
                      Message
                    </Typography>
                  </Grid>
                </Grid>
                {logs.map((log, index) => {
                  let split = null
                  if (log.cohortId) {
                    split = log.cohortId.split("_")
                  }
                  return (
                    <Grid
                      item
                      container
                      style={{ borderBottom: "1px solid #ccc" }}
                    >
                      <Grid item lg={4}>
                        <Typography className={classes.logText}>
                          {nyTimeszone(log.timestamp)}
                        </Typography>
                      </Grid>
                      <Grid item lg={3}>
                        {cohort ? (
                          <Link
                            component="button"
                            onClick={() =>
                              history.push({
                                pathname: "/grades/" + log.userId,
                                state: {
                                  cohortId: log.cohortId,
                                  courseCode: split[1],
                                  term: split[2],
                                },
                              })
                            }
                            variant="subtitle2"
                            underline="none"
                          >
                            <Typography className={classes.logText}>
                              {log.email}
                            </Typography>
                          </Link>
                        ) : log.cohortId ? (
                          <Link
                            component="button"
                            onClick={() =>
                              history.push(`/report/${log.cohortId}`)
                            }
                            variant="subtitle2"
                            underline="none"
                          >
                            <Typography className={classes.logText}>
                              {log.cohortId}
                            </Typography>
                          </Link>
                        ) : (
                          <Typography className={classes.logText}>
                            --
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={5}>
                        <Typography className={classes.logText}>
                          {log.message}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} container justify="center">
            <TextButton onClick={reviewMore} disabled={disablePagination}>
              Show More
            </TextButton>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Expansion>
  )
}

export default Logs
