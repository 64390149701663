import React, { useState, useRef, useEffect } from "react";
import { Typography, makeStyles, withStyles, Tooltip } from "@material-ui/core";
import useWindowSize from "../Generic/useWindowSize";
import modelImg from "../ExamGrid/P1.jpg";

const FoundTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#4286f4",
    color: "white",
    width: "fit-content",
    zIndex: theme.zIndex.appBar - 10,
    border: "1px solid black",
  },
  arrow: {
    color: "#4286f4",
    "&::before": {
      border: "1px solid black",
    },
  },
}))(Tooltip);

const MissedTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#888",
    color: "white",
    width: "fit-content",
    zIndex: theme.zIndex.appBar - 10,
    border: "1px solid black",
  },
  arrow: {
    color: "#888",
    "&::before": {
      border: "1px solid black",
    },
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  canvas: {
    width: "calc(100% - 2px)",
    border: "1px solid black",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundImage: `url(${modelImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  wrapper: {
    position: "relative",
    width: "100%",
    maxHeight: "75%",
  },
  Tooltip: {
    zIndex: theme.zIndex.appBar - 100,
  },
}));

const sizes = {
  p2Pretraining: [
    { id: 1, size: 5 },
    { id: 2, size: 7 },
    { id: 3, size: 3 },
  ],
  p2PostTraining: [
    { id: 1, size: 3 },
    { id: 2, size: 7 },
    { id: 3, size: 5 },
  ],
  2: [
    { id: 1, size: 5 },
    { id: 2, size: 10 },
  ],
  3: [
    { id: 1, size: 5 },
    { id: 2, size: 7 },
    { id: 3, size: 3 },
  ],
  4: [
    { id: 1, size: 5 },
    { id: 2, size: 5 },
    { id: 3, size: 3 },
  ],
};

const DetectionsCanvas = (props) => {
  const windowSize = useWindowSize();
  const classes = useStyles();
  const [spans, setSpans] = useState([]);
  const [height, setHeight] = useState(0);
  const canvasRef = useRef(null);
  let timer = null;

  useEffect(() => {
    let bh = 450;
    let bw = 400;
    let d = 50;
    let x = 0;
    let y = 0;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, "400px", "450px");
    ctx.strokeStyle = "black";
    ctx.strokeWidth = 1;

    while (y < bh) {
      y += d;
      ctx.moveTo(x, y + 0.5);
      ctx.lineTo(bw, y + 0.5);
      ctx.stroke();
    }

    y = 0;
    while (x < bw) {
      x += d;
      ctx.moveTo(x + 0.5, y);
      ctx.lineTo(x + 0.5, bh);
      ctx.stroke();
    }

    props.tumors.forEach((tumor) => {
      const moduleTumor = sizes[props.moduleId.toString()];
      const size = moduleTumor[tumor.id - 1].size;
      let r = 0;
      switch (size) {
        case 3:
          r = 8;
          break;
        case 5:
          r = 12;
          break;
        case 7:
          r = 16;
          break;
        case 10:
          r = 22;
          break;
      }
      //console.log(tumor.x * 50 + 25, tumor.y * 50 + 25);
      ctx.beginPath();
      ctx.strokeWidth = 1;
      ctx.arc(tumor.y * 50 + 25, tumor.x * 50 + 25, r, 0, 2 * Math.PI);
      ctx.fillStyle = tumor.detected ? "#4286f4" : "#888";
      ctx.fill();
      ctx.stroke();
    });
  }, [canvasRef.current]);

  useEffect(() => {
    if (spans) setSpans(null);
    clearTimeout(timer);
    if (canvasRef.current) {
      timer = setTimeout(() => {
        setSpans(
          props.tumors.map((tumor, index) => {
            if (canvasRef.current) {
              let cWidth = canvasRef.current.clientWidth;
              let cHeight = canvasRef.current.clientHeight;
              let left = ((tumor.y * 50 + 25) / 400) * cWidth;
              let top = ((tumor.x * 50 + 25) / 450) * cHeight;
              return tumor.detected ? (
                <FoundTooltip
                  key={index}
                  title={
                    <>
                      <Typography>Lump Found</Typography>
                    </>
                  }
                  open={true}
                  arrow
                >
                  <span
                    style={{ position: "absolute", top: top, left: left }}
                  />
                </FoundTooltip>
              ) : (
                <MissedTooltip
                  key={index}
                  title={
                    <>
                      <Typography>Lump Missed</Typography>
                    </>
                  }
                  open={true}
                  arrow
                >
                  <span
                    style={{ position: "absolute", top: top, left: left }}
                  />
                </MissedTooltip>
              );
            } else return null;
          })
        );
        setHeight(`${canvasRef.current.getBoundingClientRect().height}px`);
      }, 500);
    }
  }, [windowSize]);

  return (
    <div className={classes.wrapper}>
      <canvas
        ref={canvasRef}
        width={"400px"}
        height={"450px"}
        className={classes.canvas}
      />
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        {spans}
      </div>
      <div style={{ height: height }} />
    </div>
  );
};

export default DetectionsCanvas;
