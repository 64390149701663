import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { Link, withRouter } from "react-router-dom";
import MammaSpinner from "../Generic/MammaSpinner";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import styled from "styled-components";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Button,
  Snackbar,
  CircularProgress,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";

import GradeBook from "./GradeBook";
import GradeItem from "./GradeItem";
import UserInfo from "./UserInfo";
import CourseInfo from "./CourseInfo";
import Logs from "./Logs";
import PracticeAttempts from "./PracticeAttempts";
import ConfirmationDialog from "../Generic/ConfirmationDialog";
import MammaCertificate from "../MammaCareCertificate";

const Div = styled.div`
  background-color: #ffffff;
  padding: 20px;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const H1 = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
  background-color: #680000;
  padding: 20px;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

// const H3 = styled.h3`
//   color: #000000;
//   font-size: 1.5em;
//   font-weight: bold;
//   padding: 10px;
//   margin-left: 30px;
// `;

const ColorButton = withStyles((theme) => ({
  root: {
    width: "100%",
    fontWeight: "600",
    color: "#680000",
    borderColor: "#680000",
    margin: "5px 1.5%",
    "&:hover": {
      backgroundColor: "#680000",
      color: "#ffffff",
    },
  },
}))(Button);

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

const ButtonProgress = withStyles({
  root: {
    padding: "12px 14px 11px 14px",
    color: "#680000",
  },
})(CircularProgress);

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "99%",
    margin: "0 auto",
    backgroundColor: theme.palette.action.hover,
  },
}));

const Grades = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const authUser = useContext(AuthUserContext);
  const [errorPresent, setErrorPresent] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
  });
  const [openSnack, setOpenSnack] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [cohortSelectors, setCohortSelectors] = useState(null);
  const [selectedCohort, setSelectedCohort] = useState(null);
  const [userGrades, setUserGrades] = useState([]);
  const [moduleAttempts, setModuleAttempts] = useState({
    p2PostTraining: null,
    p2Pretraining: null,
    p2PretrainingModel: null,
    module2: null,
    module3: null,
    module4: null,
  });
  const [manualExamAttempts, setManualExamAttempts] = useState({
    s2: null,
  });
  const [courseMaterials, setCourseMaterials] = useState(null);
  const [actions, setActions] = useState([]);
  const [open, setOpen] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const [openPracticeAttempts, setOpenPracticeAttempts] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [funcToConfirm, setFuncToConfirm] = useState(null);
  const [funcParam, setFuncParam] = useState(null);
  const [dialogContents, setDialogContents] = useState(null);
  const [logs, setLogs] = useState([]);
  const [practiceAttempts, setPracticeAttempts] = useState([]);
  const [info, setInfo] = useState({
    payments: null,
    shipments: null,
    trackers: null,
  });
  const [avgGrade, setAvgGrade] = useState("--");
  const [disablePagination, setDisablePagination] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [limit, setLimit] = useState(10);
  const [contextMenuContents, setContextMenuContents] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openContext = !!anchorEl;

  const handleMenu = (event, id) => {
    console.log("Handling menu!", event.currentTarget, id);
    setAnchorEl(event.currentTarget);
    setContextMenuContents(
      <MenuItem onClick={() => skipVideos(id)}>Skip Videos</MenuItem>
    );
  };
  const handleClose = () => {
    setAnchorEl(null);
    setContextMenuContents(null);
  };
  const unsubscribe = useRef(null);

  useEffect(() => {
    const getUserGrades = async () => {
      console.log("AUTHUSER: ", authUser);
      console.log(props.match.params.id, authUser.uid);
      if (
        authUser.roles.length === 0 &&
        authUser.uid !== props.match.params.id
      ) {
        props.history.push("/home");
      }
      let userDoc = await props.firebase.user(props.match.params.id).get();
      if (userDoc.exists) {
        let userData = { id: userDoc.id, ...userDoc.data() };
        setUser(userData);
        let localCohortSelectors = {
          cohortId: userData.cohortId,
          courseCode: userData.courseCode,
          term: userData.term,
        };
        if (props.location.state) {
          localCohortSelectors = { ...props.location.state };
        }
        const cohortDoc = await props.firebase
          .getDocByIdFrom("cohorts", localCohortSelectors.cohortId)
          .get();
        setSelectedCohort(cohortDoc.data());
        setCohortSelectors(localCohortSelectors);

        //console.log(user.cohortId, selectedCohort.id);
        let userGrades = [];

        listenForGradeDocs(
          userData,
          localCohortSelectors.cohortId,
          localCohortSelectors.courseCode,
          localCohortSelectors.term
        );
      }
    };

    const allowActions = () => {
      if (
        authUser.roles.includes("ADMIN") ||
        authUser.roles.includes("FACULTY")
      )
        setActions([
          {
            title: "Reset Grade",
            func: resetGrade,
            icon: <ReplayOutlinedIcon />,
          },
          {
            title: "Mark Complete",
            func: markComplete,
            icon: <CheckOutlinedIcon />,
          },
        ]);
    };
    const cleanUp = () => {
      if (unsubscribe.current) {
        unsubscribe.current();
      }
    };

    allowActions();
    getUserGrades();

    return cleanUp;
  }, []);

  const listenForGradeDocs = (user, cohortId, courseCode, term) => {
    console.log("listening for user grades: ", user.id, courseCode, term);
    unsubscribe.current = props.firebase
      .userGrades(user.id, courseCode, term)
      .onSnapshot(async (snapshot) => {
        let grades = [];
        let sortedGrades = [];

        snapshot.forEach((doc) => grades.push({ ...doc.data(), key: doc.id }));
        console.log(grades);
        if (user.courses) {
          //let activeCourseId = user.courses[user.courses.length - 1].templateId;
          console.log(cohortId);
          let activeCourseId = user.courses.filter(
            (el) => el.cohort === cohortId
          )[0].templateId;

          let activeCourse = await props.firebase
            .course(cohortId, activeCourseId)
            .get();

          sortedGrades = await sortGrades(
            user,
            activeCourseId,
            activeCourse.data().sections,
            grades
          );

          let materials = sortedGrades.map((grade) => ({
            id: grade.activityId,
            materials: null,
          }));
          console.log(materials);

          setUserGrades(sortedGrades);
          setCourseMaterials(materials);
          setLoading(false);
        }
      });
    //console.log(unsubscribe.current);
  };

  useEffect(() => {
    const getAvgGrade = () => {
      let numericGrades = [];
      let avg = null;
      console.log(userGrades);
      let grades = userGrades.filter(
        (item) => item.grade !== "Complete" && item.grade !== "--"
      );
      console.log(grades);

      if (grades.length > 0) {
        avg = (grades.reduce((a, b) => a + b.grade, 0) / grades.length).toFixed(
          2
        );
        setAvgGrade(avg);
      }
    };
    getAvgGrade();
  }, [userGrades]);

  const reviewResults = async (id, type, modelId) => {
    console.log("reviewing results for type: ", id, type);

    if (!type)
      //handles undefined type vvalues from older module grade docs
      type = id;

    if (type.includes("manualExam")) {
      console.log(id, type, modelId);
      if (manualExamAttempts[modelId] === null) {
        const retrievedAttempts = await loadManualExamAttempts(modelId);
        console.log("Setting manual exam attempts: ", retrievedAttempts);
        setManualExamAttempts((manualExamAttempts) => ({
          ...manualExamAttempts,
          [modelId]: retrievedAttempts,
        }));
        setOpen(id);
      }
      setOpen(open === id ? false : id);
    } else if (!type.includes("module") && !type.includes("p2")) {
      setOpen(open === id ? false : id);
    } else {
      if (moduleAttempts[id] === null) {
        const retrievedAttempts = await loadModuleAttempts(id);
        console.log("Setting module attemtps: ", retrievedAttempts);
        setModuleAttempts((moduleAttempts) => ({
          ...moduleAttempts,
          [id]: retrievedAttempts,
        }));
        setOpen(id);
      } else {
        setOpen(open === id ? false : id);
      }
    }
  };

  const fetchMaterials = (activityId) => {
    return new Promise(async (resolve, reject) => {
      console.log("fetching materials: ", activityId);
      let element = courseMaterials.filter((el) => el.id === activityId)[0];
      console.log(element);
      if (!element.materials) {
        let material = await loadActivityMaterial(activityId);
        console.log("FETCHED MATERIALS: ", material);
        if (material) {
          let courseMaterialsCopy = [...courseMaterials];
          let index = courseMaterialsCopy.findIndex(
            (el) => el.id === activityId
          );
          courseMaterialsCopy[index].materials = material;
          console.log(courseMaterialsCopy);
          setCourseMaterials(courseMaterialsCopy);
        }
      }
      resolve(true);
    });
  };

  const reviewLogs = async () => {
    if (logs.length === 0) {
      let rawLogs = await props.firebase
        .logs(props.match.params.id)
        .limit(limit)
        .get();
      let logs = rawLogs.docs.map((log) => {
        return { id: log.id, ...log.data() };
      });
      setLastDoc(rawLogs.docs[rawLogs.docs.length - 1]);
      setDisablePagination(rawLogs.docs.length < limit ? true : false);
      setLogs(logs);
      setOpenLogs(true);
    } else {
      setOpenLogs(!openLogs);
    }
  };

  const reviewMore = async () => {
    let rawLogs = await await props.firebase
      .logs(props.match.params.id)
      .startAfter(lastDoc)
      .limit(limit)
      .get();
    let moreLogs = rawLogs.docs.map((log) => {
      return { id: log.id, ...log.data() };
    });

    setDisablePagination(rawLogs.docs.length < limit ? true : false);
    setLastDoc(
      rawLogs.docs.length === 0 ? null : rawLogs.docs[rawLogs.docs.length - 1]
    );
    setLogs([...logs, ...moreLogs]);
  };

  const reviewPracticeAttempts = async () => {
    if (practiceAttempts.length === 0) {
      let attempts = await getPracticeAttempts();
      setPracticeAttempts(attempts);
      setOpenPracticeAttempts(true);
    } else {
      setOpenPracticeAttempts(!openPracticeAttempts);
    }
  };

  const openDialog = (props) => {
    setFuncToConfirm(props.func);
    setFuncParam(props.funcParam);
    setDialogContents({ title: props.title, text: props.text });
    setConfirm(true);
  };

  const closeDialog = () => {
    setFuncToConfirm(null);
    setDialogContents(null);
    setConfirm(false);
  };

  const resetGrade = async (gradeItem) => {
    console.log("resetting grade");
    console.log(gradeItem);
    // props.firebase.resetGrade(
    //   props.match.params.id,
    //   `${gradeItem.courseCode}_${gradeItem.term}`,
    //   gradeItem.key,
    //   gradeItem.activityId,
    //   gradeItem,
    //   authUser.email
    // );
    let response = await props.firebase.saveResults("reset", user.id, {
      gradeObj: {
        activityId: gradeItem.activityId,
        title: gradeItem.title,
        type: gradeItem.type,
      },
      initiator: authUser.email,
    });
    if (response.status === "fail") {
      setErrorPresent(true);
      setAlert({ message: response.message });
      setWaiting(false);
      setOpenSnack(true);
    } else {
      setErrorPresent(false);
      setAlert({ message: response.message });
      setWaiting(false);
      setOpenSnack(true);
    }
  };

  const markComplete = async (gradeItem) => {
    setWaiting(true);
    console.log("marking complete");
    console.log(gradeItem);
    let response = await props.firebase.saveResults("markComplete", user.id, {
      gradeObj: {
        activityId: gradeItem.activityId,
        title: gradeItem.title,
        type: gradeItem.type,
      },
      initiator: authUser.email,
    });
    if (response.status === "fail") {
      setErrorPresent(true);
      setAlert({ message: response.message });
      setWaiting(false);
      setOpenSnack(true);
    } else {
      setErrorPresent(false);
      setAlert({ message: response.message });
      setWaiting(false);
      setOpenSnack(true);
    }
  };

  const loadModuleAttempts = async (id) => {
    console.log(id);
    let module = null;
    if (id === "p2Pretraining" || id === "p2PretrainingModel")
      module = "p2Pretraining";
    else if (id === "p2PostTraining") module = "p2PostTraining";
    else {
      module = parseInt(id.slice(-1));
    }

    console.log(module);
    let attempts = [];
    console.log(selectedCohort);
    let moduleAttempts = await props.firebase
      .loadMyModuleAttempts(props.match.params.id, module, selectedCohort.id)
      .get();
    console.log(moduleAttempts);

    moduleAttempts.docs.forEach((doc) =>
      attempts.push({ ...doc.data(), uid: doc.id })
    );
    console.log("Module Attempts: ", attempts);

    return attempts;
  };

  const loadManualExamAttempts = async (id) => {
    console.log("loading manual exam attempts for id:", id);
    let module = id;

    console.log(module);
    let attempts = [];
    let manualExamAttempts = await props.firebase
      .loadManualExamAttempts(user.id, module)
      .get();
    manualExamAttempts.docs.forEach((doc) =>
      attempts.push({ ...doc.data(), uid: doc.id })
    );

    console.log("Manual Exam Attempts: ", attempts);
    return attempts;
  };

  const replayExam = (
    filePath,
    tumors,
    moduleId,
    coverageGrid,
    attemptData
  ) => {
    console.log(attemptData);
    props.history.push({
      pathname: "/replayexam",
      state: {
        examPath: filePath,
        tumors: tumors,
        moduleId: moduleId,
        coverageGrid: coverageGrid,
        attemptData: attemptData,
      },
    });
  };

  const loadActivityMaterial = async (id) => {
    console.log("loading course materials");
    let activity = await props.firebase
      .getDocByIdFrom("courseActivities", id)
      .get();
    let pdf = activity.data().pdf;
    let video = activity.data().video;
    if (pdf || video) {
      return { pdf: pdf, video: video };
    } else {
      return null;
    }
  };

  const getPracticeAttempts = async () => {
    let attempts = await props.firebase.getDocsFromUserSubcollection(
      props.match.params.id,
      "practiceAttempts",
      "createdAt",
      "asc"
    );
    console.log("ATTEMPTS: ", attempts);
    return attempts.map((attempt) => ({
      ...attempt,
      createdAtMillis: attempt.createdAt.toMillis(),
    }));
  };

  const sortGrades = async (user, templateId, sections, grades) => {
    let sortedGrades = [];
    const defaultGradeDocFields = {
      activityId: "",
      attempts: [],
      cohortId: "",
      courseCode: "",
      courseTitle: "",
      createdAt: null,
      emails: [],
      grade: "--",
      id: "",
      json: "",
      submittedAt: null,
      templateId: null,
      term: "",
      title: "",
      type: "",
      userId: "",
    };
    for (let i = 0; i < sections.length; i++) {
      let gradeIndex = grades.findIndex(
        (el) => el.activityId === sections[i].id
      );
      if (gradeIndex !== -1) sortedGrades.push(grades[gradeIndex]);
      else {
        let newGradeDoc = { ...defaultGradeDocFields, ...sections[i] };
        newGradeDoc.activityId = sections[i].id;
        newGradeDoc.cohortID = user.cohortId;
        newGradeDoc.courseCode = user.courseCode;
        newGradeDoc.term = user.term;
        sortedGrades.push(newGradeDoc);
      }
    }
    return sortedGrades;
  };

  const isReviewable = (id, grade, attempts) => {
    //console.log(id);
    if (
      (id === "p2PretrainingModel" ||
        id === "p2Pretraining" ||
        id === "p2PostTraining") &&
      authUser.roles.length === 0
    ) {
      return false;
    } else if (grade === "--") {
      if (attempts > 0) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const disableReset = (gradeItem) => {
    if (
      gradeItem.grade < gradeItem.minPassingGrade ||
      gradeItem.grade === "--"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const disableMark = (gradeItem) => {
    if (
      gradeItem.grade !== "--" ||
      gradeItem.grade >= gradeItem.minPassingGrade
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSelectedDifferentCohort = async (cohort) => {
    console.log(unsubscribe.current);
    setOpen(false);
    setModuleAttempts({
      p2PostTraining: null,
      p2Pretraining: null,
      p2PretrainingModel: null,
      module2: null,
      module3: null,
      module4: null,
    });
    setManualExamAttempts({
      s2: null,
    });
    setCourseMaterials(null);
    unsubscribe.current();
    const cohortDoc = await props.firebase
      .getDocByIdFrom("cohorts", cohort.id)
      .get();
    setSelectedCohort(cohortDoc.data());
    setCohortSelectors(cohort);
    listenForGradeDocs(user, cohort.id, cohort.courseCode, cohort.term);
  };

  const handleSnackClose = () => {
    console.log("firing handleSnackClose");
    setOpenSnack(false);
  };

  const skipVideos = async (id) => {
    let response = await props.firebase.updateModuleVideoIndex(user.id, id);
    setErrorPresent(response.status === "error" ? true : false);
    setAlert({ message: response.message });
    setOpenSnack(true);
    handleClose();
  };

  return (
    <>
      {loading ? (
        <MammaSpinner loading={loading} />
      ) : (
        <>
          <H1>
            {t("grades.grades")}: {user.email}
            {authUser.roles.includes("ADMIN") ||
            (authUser.roles.includes("STAFF") && !loading) ? (
              <LinkToEditUser id={props.match.params.id} />
            ) : null}
          </H1>
          {dialogContents ? (
            <ConfirmationDialog
              open={confirm}
              contents={dialogContents}
              handleClose={closeDialog}
              funcToConfirm={funcToConfirm}
              funcParam={funcParam}
            />
          ) : null}
          <Div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "0 auto 10px auto",
              }}
            >
              <div style={{ width: "49%" }}>
                <CourseInfo
                  avgGrade={avgGrade}
                  cohort={selectedCohort}
                  canAccessDropdown={authUser.roles.length > 0}
                  cohortList={user.cohortHistory.map((c) => {
                    let split = c.split("_");
                    return { id: c, courseCode: split[1], term: split[2] };
                  })}
                  onSelectedDifferentCohort={onSelectedDifferentCohort}
                />
              </div>
              <div style={{ width: "49%" }}>
                {authUser.roles.length > 0 ? (
                  <UserInfo
                    user={user}
                    payments={info.payments}
                    shipments={info.shipments}
                    trackers={info.trackers}
                  />
                ) : null}
              </div>
            </div>
            <GradeBook
              showCell={true}
              showContext={authUser.roles.includes("ADMIN")}
            >
              {userGrades.map((gradeItem, index) => (
                <GradeItem
                  key={index}
                  user={user}
                  gradeItem={gradeItem}
                  moduleAttempts={moduleAttempts}
                  manualExamAttempts={manualExamAttempts}
                  open={open}
                  handleOpen={() =>
                    reviewResults(
                      gradeItem.id,
                      gradeItem.type,
                      gradeItem.modelId
                    )
                  }
                  isReviewable={isReviewable(
                    gradeItem.id,
                    gradeItem.grade,
                    gradeItem.attempts.length
                  )}
                  replayExam={replayExam}
                  materials={
                    courseMaterials
                      ? courseMaterials[
                          courseMaterials.findIndex(
                            (el) => el.id === gradeItem.activityId
                          )
                        ].materials
                      : null
                  }
                  fetchMaterials={fetchMaterials}
                  showCell={true}
                  showContext={authUser.roles.includes("ADMIN")}
                  disableContext={
                    !gradeItem.activityId.includes("module") ||
                    gradeItem.grade !== "--"
                  }
                  handleContextMenu={(e) => handleMenu(e, gradeItem.id)}
                >
                  {actions.length > 0 ? (
                    gradeItem.grade === "--" ? (
                      waiting ? (
                        <ButtonProgress size={20} />
                      ) : (
                        <StyledTooltip title={t("grades.markComplete")}>
                          <IconButton
                            onClick={() =>
                              openDialog({
                                title: "Confirm Mark Complete",
                                text: `Do you want to mark ${gradeItem.title} as complete?`,
                                func: () => markComplete,
                                funcParam: gradeItem,
                              })
                            }
                            disabled={
                              selectedCohort.id !== user.cohortId ||
                              (!authUser.roles.includes("ADMIN") &&
                                !authUser.roles.includes("FACULTY")) ||
                              waiting
                            }
                          >
                            <CheckOutlinedIcon
                              style={
                                selectedCohort.id !== user.cohortId ||
                                (!authUser.roles.includes("ADMIN") &&
                                  !authUser.roles.includes("FACULTY")) ||
                                waiting
                                  ? null
                                  : { color: "#680000" }
                              }
                            />
                          </IconButton>
                        </StyledTooltip>
                      )
                    ) : waiting ? (
                      <ButtonProgress size={20} />
                    ) : (
                      <StyledTooltip title={t("grades.resetActivity")} arrow>
                        <IconButton
                          onClick={() =>
                            openDialog({
                              title: t("grades.resetActivityTitle"),
                              text: t("grades.resetActivityText", {
                                title: gradeItem.title,
                              }),
                              func: () => resetGrade,
                              funcParam: gradeItem,
                            })
                          }
                          disabled={
                            selectedCohort.id !== user.cohortId ||
                            (!authUser.roles.includes("ADMIN") &&
                              !authUser.roles.includes("FACULTY"))
                          }
                        >
                          <ReplayOutlinedIcon
                            style={
                              selectedCohort.id !== user.cohortId ||
                              (!authUser.roles.includes("ADMIN") &&
                                !authUser.roles.includes("FACULTY"))
                                ? null
                                : { color: "#680000" }
                            }
                          />
                        </IconButton>
                      </StyledTooltip>
                    )
                  ) : (
                    <MammaCertificate
                      fName={user.firstName}
                      lName={user.lastName}
                      displayName={`${user.firstName} ${user.lastName}`}
                      courseTitle={null}
                      activityTitle={gradeItem.title.replace(
                        / *\([^)]*\) */g,
                        ""
                      )}
                      completedAt={gradeItem.submittedAt}
                      isCourse={false}
                      disabled={gradeItem.grade === "--"}
                    />
                  )}
                </GradeItem>
              ))}
            </GradeBook>
            {(authUser.roles.includes("ADMIN") ||
              authUser.roles.includes("STAFF") ||
              authUser.roles.includes("FACULTY")) &&
            selectedCohort.allowPractice ? (
              <PracticeAttempts
                replayExam={replayExam}
                attempts={practiceAttempts}
                handleOpen={reviewPracticeAttempts}
                open={openPracticeAttempts}
              />
            ) : null}
            {authUser.roles.includes("ADMIN") ||
            authUser.roles.includes("STAFF") ? (
              <Logs
                cohort={false}
                logs={logs}
                open={openLogs}
                handleOpen={reviewLogs}
                reviewMore={reviewMore}
                disablePagination={disablePagination}
              />
            ) : null}
            <Snackbar
              open={openSnack}
              autoHideDuration={errorPresent ? null : 5000}
              onClose={handleSnackClose}
            >
              <Alert
                onClose={handleSnackClose}
                severity={errorPresent ? "error" : "success"}
                variant="filled"
              >
                {alert.message}
              </Alert>
            </Snackbar>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openContext}
              onClose={handleClose}
              getContentAnchorEl={null}
            >
              {contextMenuContents}
            </Menu>
          </Div>
        </>
      )}
    </>
  );
};

const LinkToEditUser = ({ id }) => (
  <Tooltip title={<Typography>View/Edit User</Typography>} arrow>
    <Link to={`/editUser/${id}`}>
      <IconButton aria-label="edit">
        <OpenInNewIcon fontSize="large" style={{ color: "white" }} />
      </IconButton>
    </Link>
  </Tooltip>
);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withRouter
)(Grades);
