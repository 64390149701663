import React, { Component } from "react";
import * as PRESSURES from "../../constants/pressures";

class Cell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cell_size: 50,
    };
  }

  colorSwitch = (active, velocity) => {
    const light1_width = 10;
    const light1_height = 10;
    const light2_width = 17;
    const light2_height = 17;
    const light3_width = 20;
    const light3_height = 20;

    const medium1_width = 20;
    const medium1_height = 20;
    const medium2_width = 27;
    const medium2_height = 27;
    const medium3_width = 30;
    const medium3_height = 30;

    const deep1_width = 30;
    const deep1_height = 30;
    const deep2_width = 40;
    const deep2_height = 40;
    const deep3_width = 50;
    const deep3_height = 50;

    let inset = 0;
    let final_w = light1_width;
    let final_h = light1_height;
    const green = "#33cc33";
    const yellow = "#ebf442";
    const red = "#c11707";
    const black = "#000000";
    let color = black;

    if (!active)
      return {
        width: deep3_height,
        height: deep3_height,
        inset: 0,
        color: black,
      };

    if (
      velocity >= PRESSURES.LIGHTPRESSUREMIN &&
      velocity <= PRESSURES.LIGHTPRESSUREMED
    ) {
      final_w = light1_width;
      final_h = light1_height;
      color = green;
      inset = 20;
    } else if (
      velocity > PRESSURES.LIGHTPRESSUREMED &&
      velocity <= PRESSURES.LIGHTPRESSUREMAX
    ) {
      final_w = light2_width;
      final_h = light2_height;
      color = green;
      inset = 15;
    } else if (
      velocity >= PRESSURES.LIGHTPRESSUREMAX &&
      velocity <= PRESSURES.MEDPRESSUREMIN
    ) {
      final_w = light3_width;
      final_h = light3_height;
      color = green;
      inset = 13;
      //END LIGHT PRESSURES
    } else if (
      velocity >= PRESSURES.MEDPRESSUREMIN &&
      velocity <= PRESSURES.MEDPRESSUREMED
    ) {
      final_w = medium1_width;
      final_h = medium1_height;
      color = yellow;
      inset = 13;
    } else if (
      velocity > PRESSURES.MEDPRESSUREMED &&
      velocity <= PRESSURES.MEDPRESSUREMAX
    ) {
      final_w = medium2_width;
      final_h = medium2_height;
      color = yellow;
      inset = 10;
    } else if (
      velocity >= PRESSURES.MEDPRESSUREMAX &&
      velocity <= PRESSURES.DEEPPRESSUREMIN
    ) {
      final_w = medium3_width;
      final_h = medium3_height;
      color = yellow;
      inset = 10;
      // END MEDIUM PRESSURES
    }
    if (
      velocity >= PRESSURES.DEEPPRESSUREMIN &&
      velocity <= PRESSURES.DEEPPRESSUREMED
    ) {
      final_w = deep1_width;
      final_h = deep1_height;
      color = red;
      inset = 10;
    } else if (
      velocity > PRESSURES.DEEPPRESSUREMED &&
      velocity <= PRESSURES.DEEPPRESSUREMAX
    ) {
      final_w = deep2_width;
      final_h = deep2_height;
      color = red;
      inset = 5;
    } else if (velocity >= PRESSURES.DEEPPRESSUREMAX) {
      final_w = deep3_width;
      final_h = deep3_height;
      color = red;
      inset = 0;
      // END DEEP PRESSURES
    } else
      return { width: final_w, height: final_h, inset: inset, color: color };

    return { width: final_w, height: final_h, inset: inset, color: color };
  };

  render() {
    const { cell } = this.props;

    if (cell.active == false) return;

    let colorObj = this.colorSwitch(cell.active, cell.velocity);

    return (
      <div
        className="Cell"
        style={{
          left: `${this.state.cell_size * cell.y + 1 + colorObj.inset}px`,
          top: `${this.state.cell_size * cell.x + 1 + colorObj.inset}px`,
          width: `${colorObj.width - 1}px`,
          height: `${colorObj.height - 1}px`,
          background: colorObj.color,
        }}
      />
    );
  }
}

class SmallCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cell_size: 21,
      green: "#33cc33",
      yellow: "#ebf442",
      red: "#c11707",
      black: "#000000",
    };
  }

  render() {
    const cell = this.props;
    //console.log("Inside Small Cell", cell);
    let greenOrBlack = this.state.black;
    let yellowOrBlack = this.state.black;
    let redOrBlack = this.state.black;
    //console.log("Rendering cell "+cell.x+", "+cell.y+" light: "+cell.light+ " medium: "+cell.medium+" deep: "+cell.deep);
    return (
      <div>
        {cell.light ? (
          <div
            className="Cell"
            style={{
              left: `${this.state.cell_size * cell.y}px`,
              top: `${this.state.cell_size * cell.x}px`,
              width: `${7}px`,
              height: `${21}px`,
              background: this.state.green,
            }}
          />
        ) : null}

        {cell.medium ? (
          <div
            className="Cell"
            style={{
              left: `${this.state.cell_size * cell.y + 7}px`,
              top: `${this.state.cell_size * cell.x}px`,
              width: `${7}px`,
              height: `${21}px`,
              background: this.state.yellow,
            }}
          />
        ) : null}

        {cell.deep ? (
          <div
            className="Cell"
            style={{
              left: `${this.state.cell_size * cell.y + 14}px`,
              top: `${this.state.cell_size * cell.x}px`,
              width: `${7}px`,
              height: `${21}px`,
              background: this.state.red,
            }}
          />
        ) : null}
      </div>
    );
  }
}

export { SmallCell };

export default Cell;
