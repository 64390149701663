import React, { useState, Fragment } from "react";
import MammaSurvey from "../MammaSurvey";
import MammaSpinner from "../Generic/MammaSpinner";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    margin: theme.spacing(2),
    borderBottom: "2px solid #680000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function FormDialog(props) {
  const [open, setOpen] = useState(false);
  //console.log("MODAL PROPS: ", props);

  // let form = null;
  // if (props.json) {
  //   form = props.loading ? (
  //     <span style={{ margin: "100px" }}>
  //       <MammaSpinner loading={true} />
  //     </span>
  //   ) : (
  //     <MammaSurvey
  //       hasFormTitle={true}
  //       json={props.json}
  //       completed={props.onComplete}
  //     />
  //   );
  // }

  // if(props.loading)
  // {
  //   return( <span style={{ margin: "100px" }}>
  //     <MammaSpinner loading={true} />
  //   </span>);
  // }
  // else{
  return (
    <>
      <Dialog
        maxWidth={props.maxWidth ? props.maxWidth : "sm"}
        fullWidth={props.fullWidth ? props.fullWidth : false}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={props.loading}
        disableEscapeKeyDown={props.loading}
      >
        {props.loading ? null : (
          <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
            {props.title}
          </DialogTitle>
        )}
        {props.children}
      </Dialog>
    </>
  );
}

// return (
//   <Fragment>
//     <Dialog
//       maxWidth={props.maxWidth ? props.maxWidth : "sm"}
//       fullWidth={props.fullWidth ? props.fullWidth : false}
//       open={props.open}
//       onClose={props.handleClose}
//       aria-labelledby="form-dialog-title"
//     >
//       <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
//         {props.title}
//       </DialogTitle>
//       {form}
//       {props.children}
//     </Dialog>
//   </Fragment>
// );
