import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useTranslation, withTranslation } from "react-i18next";
import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import styled from "styled-components";
import {
  withStyles,
  Typography,
  TextField,
  Tooltip,
  IconButton,
  InputAdornment,
  Button,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import LanguageSelector from "../Generic/LanguageSelector";

const CustomTextField = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    "& label.Mui-focused": {
      color: "#680000",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#680000",
      },
      "&:hover fieldset": {
        borderColor: "#680000",
      },
    },
  },
}))(TextField);

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#680000",
    border: "1.5px solid #680000",
    fontWeight: 550,
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(1, 0),
    "&:hover": {
      backgroundColor: "#680000",
      color: "#ffffff",
    },
  },
}))(Button);

const Div = styled.div`
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: 000000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const H1 = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
  background-color: #680000;
  padding: 20px;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const SignInPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <H1>{t("signinPage.signin")}</H1>
      <Div>
        {/* <LanguageSelector /> */}
        <SignInForm />
      </Div>
    </div>
  );
};

const MobileSignInPage = () => {
  const { t } = useTranslation();
  return (
    <div style={{ width: "90%", margin: "20px auto" }}>
      <Typography variant="h5">
        The MammaCare <sup>&reg;</sup> {t("signinPage.noMobile")}
      </Typography>
    </div>
  );
};

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  showPassword: false,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email.toLowerCase().trim(), password.trim())
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.firebase.writeLog("Signed in.");
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        let message = "";
        console.log(error);
        switch (error.code) {
          case "auth/user-disabled":
            message = this.props.t("signinPage.disabledUser");
            break;
          case "user-not-found":
            message = this.props.t("userNotFound");
            break;
          case "invalid-email":
            message = this.props.t("signinPage.invalidEmail");
            break;
          case "wrong-password":
            message = this.props.t("signinPage.wrongPassword");
            break;
        }
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";
    const t = this.props.t;

    return (
      <div>
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            margin: "15px auto",
          }}
        >
          <CustomTextField
            required
            variant="outlined"
            name="email"
            value={email}
            size="small"
            onChange={this.onChange}
            label={t("signinPage.emailAddress")}
          />
          <CustomTextField
            required
            variant="outlined"
            size="small"
            name="password"
            value={password}
            onChange={this.onChange}
            type={this.state.showPassword ? "text" : "password"}
            label={t("signinPage.password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      this.state.showPassword ? (
                        <>{this.props.t("signupPage.hidePassword")}</>
                      ) : (
                        <>{this.props.t("signupPage.showPassword")}</>
                      )
                    }
                  >
                    <IconButton
                      tabIndex="-1"
                      onClick={() =>
                        this.setState({
                          showPassword: !this.state.showPassword,
                        })
                      }
                      style={{ padding: 0 }}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <ColorButton
          disabled={isInvalid}
          onClick={this.onSubmit}
          variant="outlined"
          id="signin"
        >
          {t("signinPage.signin")}
        </ColorButton>

        {error && <p>{error.message}</p>}
      </div>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
  withTranslation()
)(SignInFormBase);

export { SignInPage, MobileSignInPage };

export { SignInForm };
