import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import ReactPlayer from "react-player";
import {
  withAuthorization,
  AuthUserContext,
  withEmailVerification,
} from "../Session";
import * as ROLES from "../../constants/roles";
import { GradeBookTour } from "../Grades";
import { ReplayExamGridTour } from "../ReplayExamPage/ReplayExamGrid";
import styled from "styled-components";
import introGraphic from "./intro2.jpg";
import p0gif from "./p0gif.gif";
import p1gif from "./p1gif.gif";
import tpfpGif from "./false_true_gif.gif";
import coverageGif from "./coveragegif5.gif";
import attemptGraphic1 from "./resultsGraphicFailed1.JPG";
import attemptGraphic1Mobile from "./resultsGraphicFailed1_mobile.jpg";
import attemptGraphic2 from "./resultsGraphicFailed2.JPG";
import attemptGraphic2Mobile from "./resultsGraphicFailed2_mobile.jpg";
import attemptGraphic3 from "./resultsGraphicPassed1.JPG";
import attemptGraphic3Mobile from "./resultsGraphicPassed1_mobile.jpg";
import hearResultsGraphic1 from "./hear_results_1.jpg";
import hearResultsGraphic1Mobile from "./hear_results_1_mobile.jpg";
import hearResultsGraphic2 from "./hear_results_2.jpg";
import hearResultsGraphic2Mobile from "./hear_results_2_mobile.jpg";
import hearResultsGraphic3 from "./hear_results_3.jpg";
import hearResultsGraphic3Mobile from "./hear_results_3_mobile.jpg";
import journalMedEd from "./journal-grad-medical-ed.gif";
import pressuresGraphic from "./pressures.PNG";
import statsGraphic from "./sampleCohortOverview.PNG";
import statsGraphicMobile from "./sampleCohortOverview_mobile.png";
import chartsGraphic from "./original-charts.jpg";
import charts1Mobile from "./original-charts-1-mobile.jpg";
import charts2Mobile from "./original-charts-2-mobile.jpg";
import charts3Mobile from "./original-charts-3-mobile.jpg";

import universitiesGraphic from "./participating universities 8.17.20 v2.jpg";
import lumpsGraphic from "./lumps.PNG";
import perimeterGraphic from "./perimeter.PNG";
import tumorLocationGraphic from "./tumorLocations.PNG";
import dualPalpation_mobile from "../Landing/mobileImages/dualPalpation_mobile.jpg";
import nsfLogo from "../Landing/images/nsf-logo-small.png";
import beginExamGif from "./begin_exam.gif";
import "./overlay.css";

//card imports
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  AppBar,
  List,
  ListItem,
  Divider,
  Paper,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Sound from "react-sound";
import attempt1Audio from "../ExamGrid/GridIntro.mp3";
import attempt2Audio from "../ExamGrid/FalsePositiveFailCondition.mp3";
import attempt3Audio from "../ExamGrid/module3Success.mp3";
// import attempt3Audio from './attempt3Audio.mp3';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  media: {
    //maxHeight: "100%",
    //maxWidth: "100%",
    //margin: "0 auto",
  },
  gridItem: {
    width: "100%",
  },
  cardImg: {
    //maxHeight: "160px",
    width: "100%",
  },
  MobileCardImg: {
    maxHeight: "100%",
    width: "100%",
  },
  mobileFeedback: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 0,
    textAlign: "center",
    borderBottom: "1px solid #680000",
  },
  mobileHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    borderBottom: "1px solid #680000",
  },
  biggerText: {
    fontSize: theme.typography.pxToRem(18),
    paddingLeft: 0,
  },
}));

const MammaAppBar = withStyles((theme) => ({
  root: {
    backgroundColor: "#680000",
    color: "white",
  },
}))(AppBar);

const MammaCardMedia = withStyles((theme) => ({
  media: {
    maxWidth: "100%",
    margin: "0 auto",
    width: "auto",
  },
}))(CardMedia);

const H1 = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
  background-color: #680000;
  padding: 20px;
`;

const P = styled.p`
  color: #000000;
  font-size: 1.25em;
  font-weight: bold;
`;

const Div = styled.div`
  background-color: #ffffff;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;
const MobileDiv = styled.div`
  background-color: #ffffff;
  padding-bottom: 5px;
  color: white;
`;

const MobileH1 = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 1em;
  font-weight: bold;
  background-color: #680000;
  padding: 5px 0;
`;

const MobileActivityCard = styled.div`
  text-align: center;
  color: #000000;
  background-color: #ffffff;
  padding: 0 20px;
`;

const TourPage = (props) => {
  const classes = useStyles();
  const [users, setUsers] = useState(null);
  const [gradebookUserId, setGradebookUserId] = useState(
    "ewaallNcGPNXSIsNb5eTFlVZMTF2"
  );
  const [moduleAttempts, setModuleAttempts] = useState([]);
  const [replayFilePath, setReplayFilePath] = useState(
    "/profileDocs/ewaallNcGPNXSIsNb5eTFlVZMTF2/module2/module2_passed_1592340400559.zip"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props]);

  return (
    <Div>
      <H1>Evidence-Based Breast Exam Training</H1>

      <Grid container justify="center" spacing={3}>
        <Grid item xs={6} justify="center" style={{ padding: "2%" }}>
          <ReactPlayer
            url="https://vimeo.com/437984486"
            light={dualPalpation_mobile}
            style={{ margin: "0 auto" }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>
            The MammaCare CBE Simulator-Trainer is a self-administered,
            palpation training platform that produces and validates breast
            examination skill. The computer’s operating program is based on
            published quality-standard examination protocols.
          </Typography>
        </Grid>
      </Grid>

      <SimulatorPreview />

      {/* {replayFilePath && (
        // <div
        //   style={{
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        // >
        <>
          <div style={{ textAlign: "center" }}>
            <h2>Palpation Exam Replay</h2>
          </div>
          <ReplayExamGridTour examPath={replayFilePath} />
        </>
      )} */}

      <Paper
        style={{
          margin: "20px 0",
          padding: "1%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        elevation={0}
        square={false}
      >
        <Typography className={classes.heading}>
          The MammaCare Method&reg; of Breast Examination Taught Here
        </Typography>
        <img src={universitiesGraphic} width="50%" />
        <Grid container spacing={3} justify="center">
          <Grid item container xs={4} justify="center">
            <List>
              {[
                "Angelo State University",
                "Beth Israel Deaconess Medical Center",
                "Binghamton University",
                "Carlow University",
                "Case Western Reserve University",
                "Central Michigan University",
                "FSU College of Medicine",
                "Georgia College",
                "Grand Valley State University",
                "James Madison University",
              ].map((el) => (
                <ListItem>
                  <Typography
                    variant="h6"
                    style={{ margin: "0 auto", textAlign: "center" }}
                  >
                    {el}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Divider variant="middle" orientation="vertical" flexItem />
          <Grid item container xs={3} justify="center">
            <List>
              {[
                "Liberty University",
                "Mayo Clinic",
                "Mercy Health Partners",
                "Mount Carmel Health",
                "Philadelphia College of Osteopathic Medicine",
                "Shenandoah University",
                "Southeastern Louisiana Univ.",
                "Southern Adventist University",
                "SUNY Downstate Medical Center",
                "Texas Christian University",
              ].map((el) => (
                <ListItem>
                  <Typography
                    variant="h6"
                    style={{ margin: "0 auto", textAlign: "center" }}
                  >
                    {el}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Divider variant="middle" orientation="vertical" flexItem />
          <Grid item container xs={4} justify="center">
            <List>
              {[
                "Texas Tech University",
                "University of Alaska",
                "University of Cincinnati",
                "University of Florida College of Medicine",
                "University of Pittsburgh at Bradford",
                "University of San Francisco",
                "University of South Florida Health Science Center",
                "University of Vermont",
                "University of Wisconsin – Milwaukee",
                "Western Kentucky University",
              ].map((el) => (
                <ListItem>
                  <Typography
                    variant="h6"
                    style={{ margin: "0 auto", textAlign: "center" }}
                  >
                    {el}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid
            item
            container
            style={{
              marginTop: "25px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">Supported in part by</Typography>
            <img src={nsfLogo} />
          </Grid>
        </Grid>
      </Paper>
      <Divider />
      <div style={{ display: "flex" }}>
        <Typography variant="h6" style={{ flexGrow: 1, padding: "10px" }}>
          &copy; The MammaCare Foundation 2020
        </Typography>
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            textAlign: "right",
          }}
        >
          <Typography style={{ padding: 0 }}>930 NW 8th Avenue</Typography>
          <Typography style={{ padding: 0 }}>
            Gainesville, Florida 32601
          </Typography>
          <Typography style={{ padding: 0 }}>United States</Typography>
          <Typography style={{ paddingRight: 0, fontWeight: "bold" }}>
            (352) 375-0607
          </Typography>
        </div>
      </div>
    </Div>
  );
};

const SimulatorPreview = () => {
  const classes = useStyles();
  const [selectedGraphic, setSelectedGraphic] = useState(null);
  const [resutlsAttempt1, setResutlsAttempt1] = useState(attempt1Audio);
  const [isPlaying, setIsPlaying] = useState(Sound.status.STOPPED);
  const [audioUrl, setAudioUrl] = useState("");
  const toggleResultsGraphic = (attemptId) => {
    console.log(`Toggling attempt ${attemptId} graphic`);
    setSelectedGraphic(selectedGraphic === attemptId ? null : attemptId);

    if (selectedGraphic === attemptId) {
      setSelectedGraphic(null);
      setAudioUrl("");
      setIsPlaying(Sound.status.STOPPED);
    } else {
      setSelectedGraphic(attemptId);
      setAudioUrl(() => {
        switch (attemptId) {
          case 1:
            return attempt1Audio;
            break;
          case 2:
            return attempt2Audio;
            break;
          case 3:
            return attempt3Audio;
            break;
        }
      });
      setIsPlaying(Sound.status.PLAYING);
    }
  };

  const onAudioEnded = () => {
    console.log("audio ended.");
    setIsPlaying(Sound.status.STOPPED);
    setSelectedGraphic(null);
    setAudioUrl("");
  };
  return (
    <>
      <div style={{ padding: "0.5% 0 3% 0", textAlign: "center" }}>
        {/* <h2 style={{ marginBottom: "2%" }}>Class Performance Analytics</h2> */}
        <div style={{ width: "75%", margin: "0 auto" }}>
          <Typography
            className={classes.heading}
            style={{ marginBottom: "2%" }}
          >
            Class Performance Analytics
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <img src={statsGraphic} /> */}
          <img src={chartsGraphic} />
        </div>
      </div>

      <div style={{ width: "75%", margin: "0 auto" }}>
        <Typography className={classes.heading}>Training Features</Typography>
      </div>
      <Grid
        container
        direction="column"
        alignItems="center"
        // justify="space-evenly"
        spacing={3}
        style={{ marginTop: "2.5%" }}
      >
        <Grid item xs={12} className={classes.gridItem}>
          <ModuleCard
            title={"Module 1"}
            img={p0gif}
            content={
              <>
                <Typography
                  variant="h5"
                  style={{ color: "black", marginBottom: "10px" }}
                >
                  Learn Correct Palpation Technique
                </Typography>
                <Typography variant="h5">
                  Visually and <u>tactually</u> detect the presence and feeling
                  of suspicious tumors at each level of pressure.
                </Typography>
                {/* <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <div style={{ width: "95%" }}>
                      <img src={pressuresGraphic} className={classes.cardImg} />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h5">
                      Visually confirm the feeling of suspicious tumors with
                      three discrete levels of pressure.
                    </Typography>
                  </Grid>
                </Grid> */}
              </>
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <ModuleCard
            title={"Module 2"}
            img={coverageGif}
            content={
              <>
                <Typography
                  variant="h5"
                  style={{ color: "black", marginBottom: "10px" }}
                >
                  Practice Search Pattern & Perimeter
                </Typography>
                <Typography variant="h5">
                  Examine breast tissue in the correct search pattern for
                  suspicious tumors.
                </Typography>
                {/* <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <div style={{ width: "95%" }}>
                      <img src={perimeterGraphic} className={classes.cardImg} />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h5">
                      Examine breast tissue in three dimensions while searching
                      for suspicious tumors.
                    </Typography>
                  </Grid>
                </Grid> */}
              </>
            }
          />
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <ModuleCard
            title={"Module 3"}
            img={tpfpGif}
            content={
              <>
                <Typography
                  variant="h5"
                  style={{ color: "black", marginBottom: "10px" }}
                >
                  Reduce False Positive Detections
                </Typography>
                <Typography variant="h5">
                  Discriminate between normal, nodular breast tissue and small,
                  early-stage “tumors” in complex breast models.
                </Typography>
                {/* <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <div style={{ width: "95%" }}>
                      <img
                        src={tumorLocationGraphic}
                        className={classes.cardImg}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h5">
                      Discriminate between normal, nodular breast tissue and
                      smaller, early-stage tumors in a complex breast model.
                    </Typography>
                  </Grid>
                </Grid> */}
              </>
            }
          />
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <ModuleCard
            title={"Module 4"}
            img={p1gif}
            content={
              <>
                <Typography
                  variant="h5"
                  style={{ color: "black", marginBottom: "10px" }}
                >
                  Meet Standards for Evidence-based Breast Examination*
                </Typography>
                <Typography variant="h5">
                  Perform evidence-based exams using the principles of the
                  MammaCare Method to meet recognized standards of sensitivity
                  and specificity.
                </Typography>
                {/* <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <div style={{ width: "95%" }}>
                      <img src={lumpsGraphic} className={classes.cardImg} />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h5">
                      Perform an evidence-based exam using the MammaCare Method
                      to meet standards of sensitivity and specificity.
                    </Typography>
                  </Grid>
                </Grid> */}
              </>
            }
          />
        </Grid>
      </Grid>

      <div style={{ marginBottom: "2%" }}>
        <div style={{ textAlign: "center", margin: "2% auto" }}>
          <div style={{ width: "75%", margin: "0 auto" }}>
            <Typography className={classes.heading}>
              Performance Feedback
            </Typography>
          </div>
        </div>
        <Sound
          url={audioUrl}
          playStatus={isPlaying}
          onFinishedPlaying={onAudioEnded}
        />
        <Grid
          container
          spacing={2}
          justify="space-evenly"
          style={{ padding: "1%" }}
        >
          <Grid item xs={4} container justify="center">
            <Typography variant="h5" style={{ marginBottom: "1%" }}>
              Exam Coverage
            </Typography>
            <img
              src={
                selectedGraphic === 1 ? attemptGraphic1 : hearResultsGraphic1
              }
              onClick={() => {
                toggleResultsGraphic(1);
              }}
              width="100%"
            />
          </Grid>
          <Grid item xs={4} container justify="center">
            <Typography variant="h5" style={{ marginBottom: "1%" }}>
              False Positives Detected
            </Typography>
            <img
              src={
                selectedGraphic === 2 ? attemptGraphic2 : hearResultsGraphic2
              }
              onClick={() => {
                toggleResultsGraphic(2);
              }}
              width="100%"
            />
          </Grid>
          <Grid item xs={4} container justify="center">
            <Typography variant="h5" style={{ marginBottom: "1%" }}>
              True Positives Detected
            </Typography>
            <img
              src={
                selectedGraphic === 3 ? attemptGraphic3 : hearResultsGraphic3
              }
              onClick={() => {
                toggleResultsGraphic(3);
              }}
              width="100%"
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ padding: "0.5% 0 3% 0" }}>
        {/* <h2 style={{ marginBottom: "2%" }}>Class Performance Analytics</h2> */}
        <div style={{ width: "75%", margin: "0 auto" }}>
          <Typography
            className={classes.heading}
            style={{ marginBottom: "2%" }}
          >
            *Selected and Annotated Published Reports
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "75%",
            margin: "0 auto",
          }}
        >
          <a href="https://mammacare.org/research/">MammaCare Research</a>
          <br />
          <a
            target="_blank"
            href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C10&q=mammacare+clinical&btnG="
          >
            Clinical Breast Exam Competencies
          </a>
          <br />
          <a
            target="_blank"
            href="https://www.thieme-connect.com/products/ejournals/abstract/10.1055/s-0032-1313415"
          >
            Abstract: Improved Clinical Breast Examination Competencies
          </a>
          <br />
          <a
            target="_blank"
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4477569/"
          >
            <img src={journalMedEd} />
          </a>
          An Effective Multimodal Curriculum to Teach Internal Medicine
          Residents Evidence-Based Breast Health <br />
          Jennifer Corbelli, MD, MS, Rachel Bonnema, MD, MS, Doris Rubio, PhD,
          Diane Comer, BS, and Melissa McNeil, MD, MPH <br />
          <a
            target="_blank"
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4477569/"
          >
            J Grad Med Educ. 2014 Dec; 6(4): 721–725.
          </a>
        </div>
      </div>
    </>
  );
};

const MobileSimulatorPreview = () => {
  const classes = useStyles();
  const [selectedGraphic, setSelectedGraphic] = useState(null);
  const [resutlsAttempt1, setResutlsAttempt1] = useState(attempt1Audio);
  const [isPlaying, setIsPlaying] = useState(Sound.status.STOPPED);
  const [audioUrl, setAudioUrl] = useState("");
  const toggleResultsGraphic = (attemptId) => {
    console.log(`Toggling attempt ${attemptId} graphic`);
    setSelectedGraphic(selectedGraphic === attemptId ? null : attemptId);

    if (selectedGraphic === attemptId) {
      setSelectedGraphic(null);
      setAudioUrl("");
      setIsPlaying(Sound.status.STOPPED);
    } else {
      setSelectedGraphic(attemptId);
      setAudioUrl(() => {
        switch (attemptId) {
          case 1:
            return attempt1Audio;
            break;
          case 2:
            return attempt2Audio;
            break;
          case 3:
            return attempt3Audio;
            break;
        }
      });
      setIsPlaying(Sound.status.PLAYING);
    }
  };

  const onAudioEnded = () => {
    console.log("audio ended.");
    setIsPlaying(Sound.status.STOPPED);
    setSelectedGraphic(null);
    setAudioUrl("");
  };
  return (
    <>
      <div style={{ padding: "0.5% 0 3% 0", textAlign: "center" }}>
        {/* <h2 style={{ marginBottom: "2%" }}>Class Performance Analytics</h2> */}
        <div style={{ width: "75%", margin: "0 auto" }}>
          <Typography
            className={classes.heading}
            style={{ marginBottom: "2%" }}
          >
            Class Performance Analytics
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <img src={statsGraphic} /> */}
          <img src={charts1Mobile} />
          <img src={charts2Mobile} />
          <img src={charts3Mobile} />
        </div>
      </div>

      <div style={{ width: "75%", margin: "0 auto" }}>
        <Typography className={classes.heading}>Training Features</Typography>
      </div>
      <Grid
        container
        direction="column"
        alignItems="center"
        // justify="space-evenly"
        spacing={3}
        style={{ marginTop: "2.5%" }}
      >
        <Grid item xs={12} className={classes.gridItem}>
          <MobileModuleCard
            title={"Module 1"}
            img={p0gif}
            content={
              <>
                <Typography
                  variant="h5"
                  style={{ color: "black", marginBottom: "10px" }}
                >
                  Learn Correct Palpation Technique
                </Typography>
                <Typography variant="h5">
                  Visually and <u>tactually</u> detect the presence and feeling
                  of suspicious tumors at each level of pressure.
                </Typography>
                {/* <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <div style={{ width: "95%" }}>
                      <img src={pressuresGraphic} className={classes.cardImg} />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h5">
                      Visually confirm the feeling of suspicious tumors with
                      three discrete levels of pressure.
                    </Typography>
                  </Grid>
                </Grid> */}
              </>
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <MobileModuleCard
            title={"Module 2"}
            img={coverageGif}
            content={
              <>
                <Typography
                  variant="h5"
                  style={{ color: "black", marginBottom: "10px" }}
                >
                  Practice Search Pattern & Perimeter
                </Typography>
                <Typography variant="h5">
                  Examine breast tissue in the correct search pattern for
                  suspicious tumors.
                </Typography>
                {/* <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <div style={{ width: "95%" }}>
                      <img src={perimeterGraphic} className={classes.cardImg} />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h5">
                      Examine breast tissue in three dimensions while searching
                      for suspicious tumors.
                    </Typography>
                  </Grid>
                </Grid> */}
              </>
            }
          />
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <MobileModuleCard
            title={"Module 3"}
            img={tpfpGif}
            content={
              <>
                <Typography
                  variant="h5"
                  style={{ color: "black", marginBottom: "10px" }}
                >
                  Reduce False Positive Detections
                </Typography>
                <Typography variant="h5">
                  Discriminate between normal, nodular breast tissue and small,
                  early-stage “tumors” in complex breast models.
                </Typography>
                {/* <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <div style={{ width: "95%" }}>
                      <img
                        src={tumorLocationGraphic}
                        className={classes.cardImg}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h5">
                      Discriminate between normal, nodular breast tissue and
                      smaller, early-stage tumors in a complex breast model.
                    </Typography>
                  </Grid>
                </Grid> */}
              </>
            }
          />
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <MobileModuleCard
            title={"Module 4"}
            img={p1gif}
            content={
              <>
                <Typography
                  variant="h5"
                  style={{ color: "black", marginBottom: "10px" }}
                >
                  Meet Standards for Evidence-based Breast Examination*
                </Typography>
                <Typography variant="h5">
                  Perform evidence-based exams using the principles of the
                  MammaCare Method to meet recognized standards of sensitivity
                  and specificity.
                </Typography>
                {/* <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <div style={{ width: "95%" }}>
                      <img src={lumpsGraphic} className={classes.cardImg} />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h5">
                      Perform an evidence-based exam using the MammaCare Method
                      to meet standards of sensitivity and specificity.
                    </Typography>
                  </Grid>
                </Grid> */}
              </>
            }
          />
        </Grid>
      </Grid>

      <div style={{ marginBottom: "2%" }}>
        <div style={{ textAlign: "center", margin: "2% auto" }}>
          <div style={{ width: "75%", margin: "0 auto" }}>
            <Typography className={classes.heading}>
              Performance Feedback
            </Typography>
          </div>
        </div>
        <Sound
          url={audioUrl}
          playStatus={isPlaying}
          onFinishedPlaying={onAudioEnded}
        />
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          style={{ padding: "1%" }}
        >
          <Grid item xs={10} container justify="center">
            <Typography variant="h5" style={{ marginBottom: "1%" }}>
              Exam Coverage
            </Typography>
            <img
              src={
                selectedGraphic === 1 ? attemptGraphic1 : hearResultsGraphic1
              }
              onClick={() => {
                toggleResultsGraphic(1);
              }}
              width="100%"
            />
          </Grid>
          <Grid item xs={10} container justify="center">
            <Typography variant="h5" style={{ marginBottom: "1%" }}>
              False Positives Detected
            </Typography>
            <img
              src={
                selectedGraphic === 2 ? attemptGraphic2 : hearResultsGraphic2
              }
              onClick={() => {
                toggleResultsGraphic(2);
              }}
              width="100%"
            />
          </Grid>
          <Grid item xs={10} container justify="center">
            <Typography variant="h5" style={{ marginBottom: "1%" }}>
              True Positives Detected
            </Typography>
            <img
              src={
                selectedGraphic === 3 ? attemptGraphic3 : hearResultsGraphic3
              }
              onClick={() => {
                toggleResultsGraphic(3);
              }}
              width="100%"
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ padding: "0.5% 0 3% 0" }}>
        {/* <h2 style={{ marginBottom: "2%" }}>Class Performance Analytics</h2> */}
        <div style={{ width: "90%", margin: "0 auto" }}>
          <Typography
            className={classes.mobileHeading}
            style={{ marginBottom: "2%" }}
          >
            *Selected and Annotated Published Reports
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "95%",
            margin: "0 auto",
          }}
        >
          <a href="https://mammacare.org/research/">MammaCare Research</a>
          <br />
          <a
            target="_blank"
            href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C10&q=mammacare+clinical&btnG="
          >
            Clinical Breast Exam Competencies
          </a>
          <br />
          <a
            target="_blank"
            href="https://www.thieme-connect.com/products/ejournals/abstract/10.1055/s-0032-1313415"
          >
            Abstract: Improved Clinical Breast Examination Competencies
          </a>
          <br />
          <a
            target="_blank"
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4477569/"
          >
            <img src={journalMedEd} width="90%" style={{ margin: "0 auto" }} />
          </a>
          An Effective Multimodal Curriculum to Teach Internal Medicine
          Residents Evidence-Based Breast Health <br />
          Jennifer Corbelli, MD, MS, Rachel Bonnema, MD, MS, Doris Rubio, PhD,
          Diane Comer, BS, and Melissa McNeil, MD, MPH <br />
          <a
            target="_blank"
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4477569/"
          >
            J Grad Med Educ. 2014 Dec; 6(4): 721–725.
          </a>
        </div>
      </div>
    </>
  );
};

const MobileTourPage = (props) => {
  const trueMobile = useMediaQuery("(max-width: 500px)");
  console.log(trueMobile);
  const classes = useStyles();

  const [selectedGraphic, setSelectedGraphic] = useState(null);
  const [isPlaying, setIsPlaying] = useState(Sound.status.STOPPED);
  const [audioUrl, setAudioUrl] = useState("");

  const toggleResultsGraphic = (attemptId) => {
    console.log(`Toggling attempt ${attemptId} graphic`);
    setSelectedGraphic(selectedGraphic === attemptId ? null : attemptId);

    if (selectedGraphic === attemptId) {
      setSelectedGraphic(null);
      setAudioUrl("");
      setIsPlaying(Sound.status.STOPPED);
    } else {
      setSelectedGraphic(attemptId);
      setAudioUrl(() => {
        switch (attemptId) {
          case 1:
            return attempt1Audio;
            break;
          case 2:
            return attempt2Audio;
            break;
          case 3:
            return attempt3Audio;
            break;
        }
      });
      setIsPlaying(Sound.status.PLAYING);
    }
  };

  const onAudioEnded = () => {
    console.log("audio ended.");
    setIsPlaying(Sound.status.STOPPED);
    setSelectedGraphic(null);
    setAudioUrl("");
  };

  return (
    <MobileDiv>
      <MammaAppBar position="sticky">
        <MobileH1>Evidence-Based Breast Exam Training</MobileH1>
      </MammaAppBar>
      <AppBar />

      <MobileActivityCard>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          justify="space-evenly"
          spacing={3}
          style={{ marginTop: "1.5%" }}
        >
          <Grid item xs={12} className={classes.gridItem}>
            <MobileModuleCard
              title={"Module 1"}
              img={p0gif}
              content={
                <>
                  <Typography
                    variant="h6"
                    style={{ color: "black", marginBottom: "10px" }}
                  >
                    Learn Palpation Technique
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={3}>
                      <div style={{ width: "95%" }}>
                        <img
                          src={pressuresGraphic}
                          className={classes.MobileCardImg}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body1">
                        Visually confirm the feeling of suspicious tumors with
                        three discrete levels of pressure.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <MobileModuleCard
              title={"Module 2"}
              img={coverageGif}
              content={
                <>
                  <Typography
                    variant="h6"
                    style={{ color: "black", marginBottom: "10px" }}
                  >
                    Practice Search Pattern & Perimeter
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={3}>
                      <div style={{ width: "95%" }}>
                        <img
                          src={perimeterGraphic}
                          className={classes.cardImg}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body1">
                        Examine breast tissue in three dimensions while
                        searching for suspicious tumors.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <MobileModuleCard
              title={"Module 3"}
              img={tpfpGif}
              content={
                <>
                  <Typography
                    variant="h6"
                    style={{ color: "black", marginBottom: "10px" }}
                  >
                    Reduce False Positive Detections
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={3}>
                      <div style={{ width: "95%" }}>
                        <img
                          src={tumorLocationGraphic}
                          className={classes.cardImg}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body1">
                        Discriminate between normal, nodular breast tissue and
                        smaller, early-stage tumors in a complex breast model.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <MobileModuleCard
              title={"Module 4"}
              img={p1gif}
              content={
                <>
                  <Typography
                    variant="h6"
                    style={{ color: "black", marginBottom: "10px" }}
                  >
                    Increase Sensitivity and Specificity
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={3}>
                      <div style={{ width: "95%" }}>
                        <img src={lumpsGraphic} className={classes.cardImg} />
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body1">
                        Perform an evidence-based exam using the MammaCare
                        Method to meet standards of sensitivity and specificity.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </Grid>
        </Grid>

        <div style={{ margin: "2% 0" }}>
          <div style={{ textAlign: "center", margin: "2% auto" }}>
            <h2>Breast Exam Performance Feedback</h2>
            <h3>
              Exam Coverage, True Positives Detected, False Positives Detected
            </h3>
          </div>
          <Sound
            url={audioUrl}
            playStatus={isPlaying}
            onFinishedPlaying={onAudioEnded}
          />
          <Grid
            container
            spacing={2}
            justify="space-evenly"
            style={{ padding: "1%" }}
          >
            <Grid item xs={12} container justify="center">
              <img
                src={
                  selectedGraphic === 1
                    ? trueMobile
                      ? attemptGraphic1Mobile
                      : attemptGraphic1
                    : trueMobile
                    ? hearResultsGraphic1Mobile
                    : hearResultsGraphic1
                }
                onClick={() => {
                  toggleResultsGraphic(1);
                }}
                // width="100%"
                className={classes.mobileFeedback}
              />
            </Grid>
            <Grid item xs={12} container justify="center">
              <img
                src={
                  selectedGraphic === 2
                    ? trueMobile
                      ? attemptGraphic2Mobile
                      : attemptGraphic2
                    : trueMobile
                    ? hearResultsGraphic2Mobile
                    : hearResultsGraphic2
                }
                onClick={() => {
                  toggleResultsGraphic(2);
                }}
                // width="100%"
                className={classes.mobileFeedback}
              />
            </Grid>
            <Grid item xs={12} container justify="center">
              <img
                src={
                  selectedGraphic === 3
                    ? trueMobile
                      ? attemptGraphic3Mobile
                      : attemptGraphic3
                    : trueMobile
                    ? hearResultsGraphic3Mobile
                    : hearResultsGraphic3
                }
                onClick={() => {
                  toggleResultsGraphic(3);
                }}
                // width="100%"
                className={classes.mobileFeedback}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: "0.5% 0 3% 0", textAlign: "center" }}>
          <h2 style={{ marginBottom: "2%" }}>Class Performance Analytics</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={statsGraphicMobile} className={classes.mobileFeedback} />
          </div>
        </div>
      </MobileActivityCard>
    </MobileDiv>
  );
};

const ModuleCard = (props) => {
  const classes = useStyles();

  // return (
  //   <Card className={classes.root}>
  //     <MammaCardMedia
  //       className={classes.media}
  //       image={props.img}
  //       title={props.title}
  //       component="img"
  //     />
  //     <CardContent>{props.content}</CardContent>
  //   </Card>
  // );

  return (
    <Paper elevation={0} style={{ border: "1px solid #ccc", display: "flex" }}>
      <div style={{ width: "40%", display: "flex", justifyContent: "center" }}>
        <img src={props.img} style={{ maxWidth: "100%", margin: "0 auto" }} />
      </div>
      <div
        style={{
          width: "59.5%",
          paddingLeft: "2%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {props.content}
      </div>
    </Paper>
  );
};

const MobileModuleCard = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.mobileRoot}>
      <CardMedia
        className={classes.mobileMedia}
        image={props.img}
        title={props.title}
        component="img"
      />
      <CardContent>{props.content}</CardContent>
    </Card>
  );
};

// const condition = (authUser) =>
//   authUser && authUser.roles.includes(ROLES.TOUR);

export { MobileTourPage, SimulatorPreview, MobileSimulatorPreview };

export default compose(withFirebase)(TourPage);
