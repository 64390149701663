export const LANDING = "/page/:category?/:course?";
export const SIGN_UP = "/signup/:courseCode?/:term?/:facultyCode?";
export const SIGN_UP_ALT = "/signupalt/:coursecode/:term";
export const SIGN_IN = "/adminlogin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const ACCOUNTACTIONS = "/accountactions";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const REPORT = "/report/:id";
export const PERFORMANCEREPORT = "/performancereport";
export const PRETRAININGMODULE = "/pretraining";
export const POSTTRAININGMODULE = "/posttraining";
export const MODULE1 = "/module1";
export const MODULE2 = "/module2";
export const MODULE3 = "/module3";
export const MODULE4 = "/module4";
export const EXAMPAGE = "/exam/:id";
export const VIEWMODULE2RESULTS = "/viewmodule2results/:id";
export const VIEWMODULE3RESULTS = "/viewmodule3results/:id";
export const VIEWMODULE4RESULTS = "/viewmodule4results/:id";
export const MODULEERRORS = "/moduleerrors";
export const COURSEACTIVITIES = "/courseactivities";
export const SHOWACTIVITY = "/showActivity/:id";
export const EDITACTIVITY = "/editActivity/:id";
export const CREATEACTIVITY = "/createActivity";
export const COURSETEMPLATES = "/courseTemplates";
export const SHOWCOURSETEMPLATE = "/showCourseTemplate/:id";
export const COURSERESOURCES = "/courseResources";
export const SHOWRESOURCE = "/showResource/:id";
export const EDITRESOURCE = "/editResource/:id";
export const MYRESOURCES = "/myResources";
export const CREATERESOURCE = "/createResource";
export const GRADES = "/grades/:id";
export const RAWOUTPUT = "/rawoutput";
export const VERIFYEMAIL = "/verify";
export const MANAGEUSERS = "/manageUsers";
export const EDITUSER = "/editUser/:id";
export const MANAGECOHORTS = "/managecohorts";
export const MYCOHORTS = "/mycohorts";
export const EXPORTREPORT = "/exportreport";
export const PRETEST = "/pretest";
export const COURSEPREVIEW = "/coursePreview";
export const CONTACTFORM = "/contactForm";
export const REPLAYEXAM = "/replayexam";
export const DOCEDITOR = "/doceditor";
export const SIMULATORPREVIEW = "/preview/cbesimulator";
export const MANUALGRID = "/manualexam/:id";
export const PRACTICEEXAM = "/practice";
export const ANALYTICS = "/analytics";
export const TELETRAININGLAUNCHPOINT = "/teletraining";
export const RATINGSPAGE = "/ratings";
export const QRMODELSCANNERPAGE = "/qrmodelScanner";
export const QRGENERATOR = "/qrgenerator";
export const MODELCHECKIN = "/checkin";
export const CERTIFICATEPREVIEW = "/certificatePreview";
export const GACERTIFICATEPREVIEW = "/gacertificatePreview";
export const LICENSEMANAGER = "/licensemanager";
