import React, { useState, Fragment } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MammaSpinner from "./MammaSpinner";

const ColorButton = withStyles((theme) => ({
  root: {
    fontWeight: "600",
    color: "#680000",
    borderColor: "#680000",
    margin: "0 1.5%",
    "&:hover": {
      backgroundColor: "#680000",
      color: "#ffffff",
    },
    "&:focus": {
      backgroundColor: "#680000",
      color: "#ffffff",
    },
  },
}))(Button);

const ColorButton2 = withStyles((theme) => ({
  root: {
    fontWeight: "600",
    backgroundColor: "#680000",
    color: "#ffffff",
    margin: "0 1.5%",
  },
}))(Button);

const ConfirmationDialog = (props) => {
  const [waiting, setWaiting] = useState(false);
  const passedFunction = () => {
    //console.log("in passedFunction", props.funcParam);
    props.funcToConfirm(props.funcParam);
    props.handleClose();
  };

  const asyncPassedFunction = async () => {
    console.log("handling async function in confirmation dialog");
    setWaiting(true);
    const response = await props.funcToConfirm(props.funcParam);
    console.log("RESPONSE IN DIALOG: ", response);

    setWaiting(false);
    props.handleClose();
  };

  //console.log(props);

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="customized-dialog-title">
          {props.contents.title}
        </DialogTitle>
        {waiting ? (
          <MammaSpinner loading={waiting} />
        ) : (
          <DialogContent>{props.contents.text}</DialogContent>
        )}

        <DialogActions>
          <ColorButton
            variant="outlined"
            onClick={props.async ? asyncPassedFunction : passedFunction}
          >
            {props.buttonText?.confirm || "Confirm"}
          </ColorButton>
          <ColorButton2
            variant="outlined"
            autoFocus
            onClick={props.handleClose}
          >
            {props.buttonText?.cancel || "Cancel"}
          </ColorButton2>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ConfirmationDialog;
