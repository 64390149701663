import React, { Component } from "react";
import styles from "./MammaPDF.module.css";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { AuthUserContext, withAuthorization } from "../Session";

class MammaPDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
      pdf: null,
      showButtons: false,
    };
  }

  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    console.log("MammaPDF PROPS: ", this.props);
    const pdf = this.props.pdf;

    if (pdf != undefined) {
      this.setState({ pdf: pdf });
    } else {
      console.log("PDF prop is undefined!");
    }
  }

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
      pageNumber: 1,
    });
  };

  changePage = (offset) => {
    this.setState((prevState) => ({
      pageNumber: prevState.pageNumber + offset,
    }));
    if (!this.props.noScroll) window.scrollTo(0, 0); //scroll to top of page
  };

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  render() {
    const { numPages, pageNumber } = this.state;

    const pageNumbers = (
      <p>
        Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
      </p>
    );

    return (
      <React.Fragment>
        <Document
          file={this.state.pdf}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            width={900}
            onRenderSuccess={() => {
              this.props.onRender();
              this.setState({ showButtons: true });
            }}
          />
        </Document>
        {this.state.showButtons ? (
          <div>
            {numPages > 1 ? pageNumbers : null}
            {numPages == 1 ? null : (
              <div>
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#680000",
                    textAlign: "center",
                    padding: "12px",
                    textDecoration: "none",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    margin: "20px",
                    width: "100px",
                  }}
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={this.previousPage}
                >
                  Previous
                </button>
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#680000",
                    textAlign: "center",
                    padding: "12px",
                    textDecoration: "none",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    margin: "20px",
                    width: "100px",
                  }}
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={this.nextPage}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(MammaPDF);
