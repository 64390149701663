import React from "react";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  List,
  ListItem,
  Paper,
  ListItemText,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import MammaCareLogo from "../Navigation/mammacare-symbol.png";
import { PartnerFooter, MobilePartnerFooter } from "./PartnerFooter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "2.5%",
  },
  paper: {
    height: "100%",
    width: "80%",
    border: `1px solid ${fade("#680000", 0.75)}`,
    margin: "0 auto",
    "& h2": {
      textAlign: "center",
      marginTop: "15px",
    },
    "& p": {
      textAlign: "left",
    },
    "& hr": {
      width: "80%",
      border: 0,
      borderTop: "5px dashed #8c8c8c",
      textAlign: "center",
    },
  },
  bigText: {
    fontSize: "28px",
    color: "#680000",
    paddingBottom: 0,
    // fontWeight: 600,
  },
  smallText: {
    paddingTop: 0,
  },
  media: {
    maxHeight: "100%",
    maxWidth: "100%",
    //margin: "0 auto",
  },
  gridItem: {
    width: "40%",
  },
  cardImg: {
    maxHeight: "160px",
    width: "100%",
  },
  MobileCardImg: {
    maxHeight: "100%",
    width: "100%",
  },
  mobileFeedback: {
    width: "100%",
  },
  banner: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    border: "1px solid #d1d2d1",
    backgroundColor: "#f7f7f7",
    marginBottom: "1%",
  },
  statsList: {
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    borderBottom: "1px solid #680000",
    marginBottom: "1%",
    width: "80%",
  },
  mobileBanner: {
    textAlign: "center",
    margin: "0 auto 10px auto",
    padding: "20px 5px 10px 5px",
    backgroundColor: "#f7f7f7",
    border: "1px solid #d1d2d1",
  },
  mobileBannerHeader: {
    color: "#680000",
    fontSize: "2.05rem",
  },
  mobileListItem: {
    padding: "4px",
  },
  gridHeader: {
    fontWeight: 600,
  },
}));

const Banner = (props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.banner}>
        <h5>
          <span style={{ color: "#680000" }}>
            The MammaCare<sup>&reg;</sup> Method
          </span>{" "}
          is the recognized standard for <br />
          performing and teaching clinical and personal breast examination
        </h5>
      </div>
      <Typography variant="h5" style={{ textAlign: "center" }}>
        To learn more about the MammaCare<sup>&reg;</sup> Foundation visit{" "}
        <a target="_blank" href="https://mammacare.org/">
          mammacare.org
        </a>
      </Typography>
    </>
  );
};

const MobileBanner = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.mobileBanner}>
        <img src={MammaCareLogo} />
        <Typography variant="h4" className={classes.mobileBannerHeader}>
          The MammaCare<sup>&reg;</sup> Method
        </Typography>
        <Typography variant="h6">
          The recognized standard for performing and teaching clinical and
          personal breast examination.
        </Typography>
      </div>
      <Typography style={{ textAlign: "center", padding: "0px 5px" }}>
        To learn more about the MammaCare<sup>&reg;</sup> Foundation visit{" "}
        <a target="_blank" href="https://mammacare.org/">
          mammacare.org
        </a>
      </Typography>
    </>
  );
};

const Stats = (props) => {
  const classes = useStyles();
  return (
    <Paper
      elevation={0}
      style={{
        margin: "20px 0",
        padding: "1%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography className={classes.heading}>A Proven Track Record</Typography>
      <Grid container className={classes.root} alignItems="stretch">
        <Grid key={"training"} item xs={4}>
          <Paper className={classes.paper} elevation={0} square={false}>
            <h2>Trainings</h2>
            <hr />

            <p>
              <span className={classes.bigText}>3,300+</span> <br /> Medical,
              Nursing, and Midwifery Students
            </p>
            <p>
              <span className={classes.bigText}>1,700+</span> <br /> Women
              Veteran Healthcare Providers
            </p>
            <p>
              <span className={classes.bigText}>300+</span> <br /> BCCCP Womens'
              Healthcare Providers
            </p>
            {/* <p><span className={classes.bigText}>40+</span> <br /> U.S. Colleges of Nursing</p> */}
            <p>
              <span className={classes.bigText}>2</span> <br /> International
              Medical Missions
            </p>
          </Paper>
        </Grid>
        <Grid key={"certification"} item xs={4}>
          <Paper className={classes.paper} elevation={0} square={false}>
            <h2>Certifications</h2>
            <hr />
            <p>
              <span className={classes.bigText}>1,100+</span> <br /> Clinical
              Breast Examiners
            </p>
            <p>
              <span className={classes.bigText}>800+</span> <br /> Breast
              Self-Exam Instructors
            </p>
            {/* <p><span className={classes.bigText}>200+</span> <br /> Specialists</p> */}
            <p>
              <span className={classes.bigText}>47</span> <br /> U.S. Training
              or Practice Locations
            </p>
            <p>
              <span className={classes.bigText}>8</span> <br /> Countries{" "}
            </p>
          </Paper>
        </Grid>
        <Grid key={"research"} item xs={4}>
          <Paper className={classes.paper} elevation={0} square={false}>
            <h2>Research</h2>
            <hr />
            <p>
              <span className={classes.bigText}>400+</span> <br />
              Publications Referencing the MammaCare<sup>&reg;</sup> Method{" "}
              <br />
              <a
                href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C10&as_vis=1&q=mammacare++improved+clinical++medical+%22clinical+breast%22+surgery+education+&btnG="
                target="_blank"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Google Scholar <OpenInNewIcon size="small" />
              </a>
            </p>
            {/* <p>
            <span className={classes.bigText}>3+</span> <br /> Breast self-exam
            publications
          </p> */}
            <p>
              <span className={classes.bigText}>2</span> <br /> National Science
              Foundation Grants
            </p>
            {/* <p><span className={classes.bigText}>2+</span> <br />  Patents </p> */}
            <p>
              <span className={classes.bigText}>5</span> <br /> NIH / NCI Grants
            </p>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

const MobileStats = () => {
  const classes = useStyles();
  return (
    <Paper
      elevation={0}
      style={{
        margin: "20px auto",
        padding: "1%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #d1d2d1",
        backgroundColor: "#f7f7f7",
      }}
      square={true}
    >
      <Typography variant="h4" style={{ margin: "10px auto 10px auto" }}>
        A Proven Track Record
      </Typography>
      <Paper
        elevation={0}
        style={{
          border: "1px solid #680000",
          width: "95%",
          textAlign: "left",
          backgroundColor: "transparent",
          margin: "10px 0",
        }}
      >
        <div style={{ margin: "2%" }}>
          <Typography variant="h5">Trainings</Typography>
          <Grid container spacing={1} justify="space-evenly">
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                3,300+
              </Typography>
              <Typography variant="subtitle1">
                Medical, Nursing, and Midwifery Students
              </Typography>
            </Grid>
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                1,700+
              </Typography>
              <Typography variant="subtitle1">
                Women Veteran Healthcare Providers
              </Typography>
            </Grid>
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                300+
              </Typography>
              <Typography variant="subtitle1">
                BCCCP Womens' Healthcare Providers
              </Typography>
            </Grid>
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                2
              </Typography>
              <Typography variant="subtitle1">
                International Medical Missions
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <Paper
        elevation={0}
        style={{
          border: "1px solid #680000",
          width: "95%",
          textAlign: "left",
          backgroundColor: "transparent",
          margin: "10px 0",
        }}
      >
        <div style={{ margin: "2%" }}>
          <Typography variant="h5">Certifications</Typography>
          <Grid container spacing={1} justify="space-evenly">
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                1,100+
              </Typography>
              <Typography variant="subtitle1">
                Clinical Breast Examiners
              </Typography>
            </Grid>
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                800+
              </Typography>
              <Typography variant="subtitle1">
                Breast Self-Exam Instructors
              </Typography>
            </Grid>
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                47
              </Typography>
              <Typography variant="subtitle1">
                U.S.-Based Training or Practice Locations
              </Typography>
            </Grid>
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                8
              </Typography>
              <Typography variant="subtitle1">
                Countries With Training Programs
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <Paper
        elevation={0}
        style={{
          border: "1px solid #680000",
          width: "95%",
          textAlign: "left",
          backgroundColor: "transparent",
          margin: "10px 0",
        }}
      >
        <div style={{ margin: "2%" }}>
          <Typography variant="h5">Research</Typography>
          <Grid container spacing={1} justify="space-evenly">
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                5
              </Typography>
              <Typography variant="subtitle1">NIH / NCI Grants</Typography>
            </Grid>
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                2
              </Typography>
              <Typography variant="subtitle1">
                National Science Foundation Grants
              </Typography>
            </Grid>
            <Grid item container xs={12} direction="column">
              <Typography variant="h6" className={classes.gridHeader}>
                400+
              </Typography>
              <Typography variant="subtitle1">
                Publications Referencing the MammaCare Method<sup>&reg;</sup>
              </Typography>
              <a
                href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C10&as_vis=1&q=mammacare++improved+clinical++medical+%22clinical+breast%22+surgery+education+&btnG="
                target="_blank"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Google Scholar <OpenInNewIcon size="small" />
              </a>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Paper>
  );
};

const DefaultContent = (props) => {
  const handleChange = (event, newValue) => {
    if (props.tabs.length === 1) return;
    props.setTab(newValue);
  };

  const classes = useStyles();

  return (
    <>
      <Banner />
      {/* <Stats /> */}
      <Typography
        variant="h5"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "red",
          marginTop: "2rem",
        }}
      >
        System Maintenance Scheduled for Dec. 21, 2023 - Jan. 7th, 2024. Access
        to the LMS will be unavailable during this time.
      </Typography>
      <PartnerFooter />
    </>
  );
};

const MobileDefaultContent = (props) => {
  const handleChange = (event, newValue) => {
    if (props.tabs.length === 1) return;
    props.setTab(newValue);
  };

  const classes = useStyles();

  return (
    <>
      <MobileBanner />
      {/* <MobileStats /> */}
      <MobilePartnerFooter />
    </>
  );
};

export { DefaultContent, MobileDefaultContent };
