import React, { useState, useEffect } from "react"
import styled from "styled-components"
import {
  makeStyles,
  withStyles,
  TextField,
  Select,
  Radio,
  RadioGroup,
  FormGroup,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormLabel,
  fade,
  Typography,
  InputAdornment,
  Input,
  OutlinedInput,
  FormHelperText,
  MenuItem,
} from "@material-ui/core"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

const FormDiv = styled.div`
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#680000",
    borderColor: "#680000",
    margin: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#680000",
      color: "#ffffff",
    },
  },
}))(Button)

const ColorTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#680000",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#680000",
      },
      "&:hover fieldset": {
        borderColor: "#680000",
      },
    },
  },
})(TextField)

const ColorRadio = withStyles({
  root: {
    "&$checked": {
      color: "#680000",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const ColorCheckbox = withStyles({
  root: {
    "&$checked": {
      color: fade("#680000", 0.8),
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const ColorFormControl = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#680000",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#680000",
      },
      "&:hover fieldset": {
        borderColor: "#680000",
      },
    },
  },
})(FormControl)

const useStyles = makeStyles((theme) => ({
  textFormControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  passwordFormControl: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },
  selectFormControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  normalRadio: {},
  questionRadio: {
    color: "black",
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
  },
}))

const DynamicForm = (props) => {
  const classes = useStyles()
  const { json, title } = props
  const [finalElements, setFinalElements] = useState([])
  const [state, setState] = useState([...json])
  const [valuesUpdated, setValuesUpdated] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [btnText, setBtnText] = useState("")
  const [error, setError] = useState(false)

  useEffect(() => {
    const setFormElements = async () => {
      //console.log(props.errors.filter((el) => !!el));
      setBtnText(props.btnText ? props.btnText : "submit")
      console.log("DYNAMIC FORM STATE: ", state)
      let final = state.map(
        (el, index) => {
          let propCopy = { ...el }
          let options = null
          if (propCopy.options) {
            options = propCopy.options.map((op) => {
              return (
                <MenuItem key={op.value} value={op.value}>
                  {op.value}
                </MenuItem>
              )
            })
            delete propCopy.options
          }
          delete propCopy.value
          // delete propCopy.defaultValue
          switch (el.type) {
            case "text":
              //console.log(propCopy.name);
              return (
                <ColorTextField
                  key={propCopy.name}
                  className={classes.textFormControl}
                  variant="outlined"
                  onChange={onChange}
                  value={state[index].value ? state[index].value : ""}
                  {...propCopy}
                />
              )
            case "email":
              //console.log(propCopy.name);
              return (
                <ColorTextField
                  key={propCopy.name}
                  className={classes.textFormControl}
                  variant="outlined"
                  onChange={onChange}
                  value={state[index].value ? state[index].value : ""}
                  {...propCopy}
                />
              )
            case "password":
              //console.log(propCopy);
              return (
                <ColorFormControl
                  variant="outlined"
                  key={propCopy.name}
                  className={classes.passwordFormControl}
                >
                  <InputLabel>{propCopy.label}</InputLabel>
                  <OutlinedInput
                    error={error}
                    onChange={onChange}
                    value={state[index].value ? state[index].value : ""}
                    name={propCopy.name}
                    type={showPassword ? "text" : "password"}
                    label={propCopy.label}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    //{...propCopy}
                  />
                  <FormHelperText>
                    {error
                      ? "Password must be at least 6 characters long"
                      : null}
                  </FormHelperText>
                </ColorFormControl>
              )
            case "select":
              console.log(propCopy.defaultValue)
              // console.log(options)
              return (
                <ColorFormControl
                  variant="outlined"
                  key={propCopy.name}
                  required={propCopy.required}
                  className={classes.selectFormControl}
                >
                  <InputLabel>{propCopy.label}</InputLabel>
                  <Select
                    id={el.name}
                    onChange={onChange}
                    value={
                      state[index].value
                        ? state[index].value
                        : propCopy.defaultValue
                    }
                    {...propCopy}
                  >
                    {options}
                  </Select>
                </ColorFormControl>
              )
            case "radio":
              //console.log(propCopy);
              return (
                <FormControl
                  component="fieldset"
                  key={propCopy.name}
                  style={{ width: "100%", margin: "2% 0" }}
                >
                  <FormLabel
                    className={
                      propCopy.version === "question"
                        ? classes.questionRadio
                        : classes.normalRadio
                    }
                  >
                    {propCopy.label}
                  </FormLabel>
                  <RadioGroup
                    value={
                      state[index].value
                        ? state[index.value]
                        : propCopy.defaultValue
                    }
                    onChange={onChange}
                    name={propCopy.name}
                    {...propCopy}
                  >
                    {propCopy.values.map((val, index) => (
                      <FormControlLabel
                        key={index}
                        value={val.value}
                        control={<ColorRadio />}
                        label={val.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )
            case "check": {
              return (
                <ColorFormControl key={propCopy.name} style={propCopy.style}>
                  <FormControlLabel
                    control={
                      <ColorCheckbox
                        onChange={onChange}
                        checked={state[index].checked}
                        name={propCopy.name}
                      />
                    }
                    label={
                      <Typography align="left">{propCopy.label}</Typography>
                    }
                  />
                </ColorFormControl>
              )
            }
            case "checkgroup": {
              return (
                <FormControl
                  component="fieldset"
                  key={propCopy.name}
                  style={{ width: "100%", margin: "2% 0" }}
                >
                  <FormLabel
                    className={
                      propCopy.version === "question"
                        ? classes.questionRadio
                        : classes.normalRadio
                    }
                  >
                    {propCopy.label}
                  </FormLabel>
                  <FormGroup>
                    {propCopy.elements.map((el, index) => (
                      <FormControlLabel
                        control={
                          <ColorCheckbox
                            onChange={onChange}
                            checked={el.checked}
                            name={el.name}
                          />
                        }
                        label={<Typography align="left">{el.label}</Typography>}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
                // <ColorFormControl key={propCopy.name}>
                //   <FormControlLabel
                //     control={
                //       <ColorCheckbox
                //         onChange={onChange}
                //         checked={state[index].checked}
                //         name={propCopy.name}
                //       />
                //     }
                //     label={
                //       <Typography align="left">{propCopy.label}</Typography>
                //     }
                //   />
                // </ColorFormControl>
              )
            }
            case "button": {
              return (
                <ColorButton
                  variant="outlined"
                  onClick={propCopy.onClick}
                  disabled={propCopy.disabled}
                >
                  {propCopy.label}
                </ColorButton>
              )
            }
            default:
              break
          }
        },
        [props]
      )
      setFinalElements(final)
      //setLoading(false);
    }
    setFormElements()
    const log = () => {
      //console.log("MOUNTING");
      //console.log(props);
      setValuesUpdated(true)
    }
  }, [state, showPassword])

  useEffect(() => {
    const cleanUp = () => {
      //console.log("CLEANING UP ON UNMOUNT");
      setState(null)
      setFinalElements(null)
      setValuesUpdated(false)
    }
    //log();
    return cleanUp
  }, [])

  useEffect(() => {
    const log = () => {
      //console.log(state);
    }
    const updateDisabled = () => {
      let disabled = false
      //console.table(state);
      state.forEach((el) => {
        if (el.required) {
          if (!el.value || el.value === "") {
            if (!el.checked) {
              disabled = true
            }
          }
        }
      })
      setDisabled(disabled)
    }

    //log();
    updateDisabled()
  }, [state])

  const onSubmit = (event) => {
    event.preventDefault()
    //console.log("SUBMITTED FORM: ", state);
    props.onComplete(title, state)
  }

  const onChange = (e) => {
    console.log("ON CHANGE")
    let stateCopy = [...state]
    let index = stateCopy.findIndex((el) => el.name === e.target.name)
    let elem = stateCopy[index]
    console.log(elem)
    console.log(e.target.checked, e.target.value)
    if (e.target.value || e.target.value === "") {
      console.log("setting value")
      elem.value = e.target.value
    } else if (e.target.checked !== null) {
      console.log("setting checked")
      elem.checked = e.target.checked
    } else {
      console.log("in else")
    }
    // if (e.target.checked) {
    //   elem.checked = e.target.checked;
    // } else {
    //   elem.value = e.target.value;
    // }
    //console.log(elem);
    stateCopy[index] = elem
    console.log(stateCopy[index])
    setState(stateCopy)
  }

  const togglePassword = () => {
    //console.log("toggling");
    //console.log(showPassword);
    if (showPassword) {
      console.log("setting false")
      setShowPassword(false)
    } else {
      console.log("setting true")
      setShowPassword(true)
    }
  }

  //console.log("DYNAMIC FORM STATE", state);
  //console.log("FINALELEMENTS: ", finalElements);
  //console.log(btnText);

  return (
    <FormDiv>
      {loading ? null : (
        <>
          {finalElements}
          {props.showButton ? (
            <ColorButton
              variant="outlined"
              onClick={onSubmit}
              disabled={disabled}
            >
              {btnText}
            </ColorButton>
          ) : null}
        </>
      )}
    </FormDiv>
  )
}

export default DynamicForm
