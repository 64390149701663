import React, { Component } from 'react';
import styled from 'styled-components';
import { ClassicSpinner } from 'react-spinners-kit';

const Container = styled.div`
    padding: 8px;  
    margin-top: 10%;
    display: flex;
    justify-content: center;  
`;

class MammaSpinner extends Component {
    render() {
        return (
            <Container index={this.props.index} draggable onDragStart={this.onDragStarted} onDragOver={this.props.onDragOver} onDragEnd={this.props.onDragEnd} >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {this.props.message != null ? <h2 style={{ marginBottom: '15px' }}>{this.props.message}</h2> : null}
                    <ClassicSpinner size={50} color={"#680000"} loading={this.props.loading} />
                </div>
            </Container>
        );
    }
}

export default MammaSpinner;