import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import "./ResultsGrid.css";
import Cell from "./Cell";
import Tumor, { Tumor3mm, Tumor5mm, Tumor7mm, Tumor10mm } from "./Tumor";
import styles from "./ResultsGrid.css";
import {
  MODULE2_CONFIGS,
  MODULE3_CONFIGS,
  MODULE4_CONFIGS,
  PRETRAININGMODULE_CONFIGS,
  POSTTRAININGMODULE_CONFIGS,
} from "../../constants/examConfigs";
import {
  Typography,
  makeStyles,
  withStyles,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Tooltip,
  Card,
  CardHeader,
  fade,
  lighten,
  Button,
} from "@material-ui/core";
import modelImg from "../ExamGrid/P1.jpg";
import PatternCanvas from "../RawOutput/PatternCanvas";
import DetectionsCanvas from "./DetectionsCanvas";
import CoverageCanvas from "./CoverageCanvas";
import useWindowSize from "../Generic/useWindowSize";
import { CallMadeSharp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  bigCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "transparent",
    margin: "3% auto",
  },
  bigCardHeader: {
    width: "100%",
    marginBottom: ".5%",
  },
  card: {
    padding: "0.5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    minWidth: "250px",
  },
  gridContainer: {
    maxWidth: "400px",
    minHeight: "25%",
    margin: "auto",
  },
  gridItem: {
    marginTop: "2%",
    width: "fit-content",
  },
  title: {
    width: "70%",
    marginBottom: "1%",
    textAlign: "center",
  },
  legend: {
    height: "fit-content",
    marginLeft: theme.spacing(1),
    textAlign: "center",
  },
  block: {
    minWidth: "8px",
    maxWidth: "17px",
    minHeight: "25px",
    maxHeight: "50px",
    width: "4.25%",
  },
  canvas: {
    width: "calc(100% - 2px)",
    border: "1px solid black",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundImage: `url(${modelImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  wrapper: {
    position: "relative",
    width: "100%",
    maxHeight: "75%",
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    width: "20%",
    fontWeight: "600",
    backgroundColor: lighten("#64b5f6", 0.25),
    color: "black",
    borderColor: lighten("#64b5f6", 0.25),
    margin: "20px auto",
    "&:hover": {
      backgroundColor: "#64b5f6",
      borderColor: "#64b5f6",
    },
  },
}))(Button);

const sizes = {
  p2Pretraining: [
    { id: 1, size: 5 },
    { id: 2, size: 7 },
    { id: 3, size: 3 },
  ],
  p2PostTraining: [
    { id: 1, size: 3 },
    { id: 2, size: 7 },
    { id: 3, size: 5 },
  ],
  2: [
    { id: 1, size: 5 },
    { id: 2, size: 10 },
  ],
  3: [
    { id: 1, size: 5 },
    { id: 2, size: 7 },
    { id: 3, size: 3 },
  ],
  4: [
    { id: 1, size: 5 },
    { id: 2, size: 5 },
    { id: 3, size: 3 },
  ],
};

const AdminResultsGrid = (props) => {
  const windowSize = useWindowSize();
  const classes = useStyles();
  const { t } = useTranslation();

  const [config, setConfig] = useState({ passConditions: {} });

  useEffect(() => {
    switch (props.moduleId) {
      case "p2Pretraining":
        setConfig(PRETRAININGMODULE_CONFIGS);
        break;
      case "p2PostTraining":
        setConfig(POSTTRAININGMODULE_CONFIGS);
        break;
      case 2:
        setConfig(MODULE2_CONFIGS);
        break;
      case 3:
        setConfig(MODULE3_CONFIGS);
        break;
      case 4:
        setConfig(MODULE4_CONFIGS);
        break;
    }
  }, [props]);
  //console.log("PROPS TUMORS: ", props.tumors);

  const tumorLocations = props.tumors.map((tumor) => {
    return (
      <Tumor
        key={[tumor.x, tumor.y]}
        x={tumor.x}
        y={tumor.y}
        found={tumor.detected}
        moduleId={props.moduleId}
        tumorId={tumor.id}
        open={true}
      />
    );
  });

  let time = props.examTime;
  //console.log("TIME:  ", time);
  //console.log(typeof time);

  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time - hours * 3600) / 60);
  let seconds = Math.floor(time - hours * 3600 - minutes * 60);
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  //console.log("HOURS:  ", hours);
  //console.log("MINUTES:  ", minutes);
  //console.log("SECONDS:  ", seconds);

  //let flattenedCellsArray = props.palpatedCells.flat();

  //console.log("FLATTENED CELLS ARRAY: ", flattenedCellsArray);

  // let cells = flattenedCellsArray.map((cell) => {
  //   return (
  //     <Cell
  //       x={cell.x}
  //       y={cell.y}
  //       key={cell.midiNum}
  //       light={cell.light}
  //       medium={cell.medium}
  //       deep={cell.deep}
  //       midiNum={cell.midiNum}
  //     />
  //   );
  // });

  const sizesPresent = [
    ...new Set(
      props.tumors.map((tumor) => {
        const moduleTumor = sizes[props.moduleId.toString()];
        //console.log(moduleTumor);
        const size = moduleTumor[tumor.id - 1].size;
        return size;
      })
    ),
  ].sort((a, b) => a - b);

  const percentCoverage = (props.percentCoverage * 100).toFixed(2);

  const getBackgroundColor = (passed, valid, moduleId) => {
    //console.log(props);
    //console.log(passed, valid, moduleId);
    if (moduleId === "p2Pretraining" || moduleId === "p2Posttraining") {
      return "#f57c00";
    }
    if (valid) {
      if (passed) {
        return "#66bb6a";
      } else {
        return "#ef5350";
      }
    } else {
      return "#f57c00";
    }
  };

  const parseTime = (time) => {
    return new Date(time * 1000).toISOString().substr(11, 8);
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  //console.log("CONFIG: ", config);

  return (
    <Card
      style={{
        border: `3px solid ${getBackgroundColor(
          props.passed,
          props.isValidAttempt,
          props.moduleId
        )}`,
      }}
      className={classes.bigCard}
      variant="outlined"
      elevation={0}
    >
      <CardHeader
        style={{
          backgroundColor: fade(
            getBackgroundColor(
              props.passed,
              props.isValidAttempt,
              props.moduleId
            ),
            0.7
          ),
          borderBottom: `1px solid ${getBackgroundColor(
            props.passed,
            props.isValidAttempt,
            props.moduleId
          )}`,
          padding: "4px",
        }}
        className={classes.bigCardHeader}
        title={
          props.practice ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 1%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h5">{`Module ${props.moduleId}`}</Typography>
                {props.orientation ? (
                  <Typography variant="h5">
                    Breast Model Orientation:{" "}
                    {props.orientation === "rotated" ? (
                      <>180&deg;</>
                    ) : (
                      <>0&deg;</>
                    )}
                  </Typography>
                ) : null}
              </div>
              <Typography variant="h5">
                {props.moduleId === "p2Pretraining"
                  ? null
                  : props.passed
                  ? t("resultsGrid.passed")
                  : t("resultsGrid.failed")}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="h5">
                  {`${t("resultsGrid.examTime")}: ${parseTime(props.examTime)}`}
                </Typography>
                <Typography variant="h5">{props.completed}</Typography>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 1%",
              }}
            >
              <Typography variant="h5">
                {`${t("resultsGrid.examTime")}: ${parseTime(props.examTime)}`}
              </Typography>
              <Typography variant="h5">
                {props.moduleId === "p2Pretraining"
                  ? null
                  : props.passed
                  ? t("resultsGrid.passed")
                  : t("resultsGrid.failed")}
              </Typography>
              <Typography variant="h5">{props.completed}</Typography>
            </div>
          )
        }
      ></CardHeader>
      <Grid container spacing={1} direction="column">
        {/* {props.practice ? (
          <Grid item container justify="center" sm={12} md={6} lg={4}>
            <Grid item>
              <Typography variant="h5">{`Module ${props.moduleId}`}</Typography>
            </Grid>

            {props.orientation ? (
              <Grid item>
                <Typography variant="h5">
                  {`${capitalize(props.orientation)} Orientation`}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        ) : null} */}

        {/* <Grid item container spacing={0} justify="space-evenly">
          <Grid item container xs={12} md={4} justify="center">
            <Typography
              variant="h5"
              style={{ width: "fit-content" }}
            >{`Module ${props.moduleId}`}</Typography>
          </Grid>
          {props.orientation ? (
            <Grid item container xs={12} md={4} justify="center">
              <Typography variant="h5" style={{ width: "fit-content" }}>
                {`${capitalize(props.orientation)} Orientation`}
              </Typography>
            </Grid>
          ) : null}
        </Grid> */}
        <Grid item container spacing={1} justify="center">
          <Grid item container justify="center" sm={12} md={6} lg={4}>
            <Paper className={classes.card}>
              <Typography variant="h5" className={classes.title}>
                Tumor Detections
              </Typography>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <DetectionsCanvas
                    moduleId={props.moduleId}
                    tumors={props.tumors}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  className={classes.gridItem}
                  justify="center"
                >
                  <Typography variant="h6" className={classes.legend}>
                    False Positives: {props.falsePositives} (
                    {config.passConditions.falsePositivesAllowed === -1
                      ? `Unlimited allowed`
                      : `${config.passConditions.falsePositivesAllowed} allowed`}
                    )
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.gridItem}
                  xs={12}
                  justify="center"
                >
                  <Typography variant="h6" className={classes.legend}>
                    {`Sensitivity: ${
                      props.tumors.filter((t) => t.detected).length
                    } /
                ${props.tumors.length}`}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item container justify="center" xs={12} sm={12} md={6} lg={4}>
            <Paper className={classes.card}>
              <Typography variant="h5" className={classes.title}>
                Percent Examined: {percentCoverage}%
              </Typography>
              <Grid
                container
                className={classes.gridContainer}
                justify="center"
              >
                <Grid item xs={12}>
                  <div style={{ width: "100%", height: "100%" }}>
                    <CoverageCanvas palpatedCells={props.palpatedCells} />
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.gridItem}
                  alignItems="center"
                  xs={6}
                >
                  <div
                    style={{
                      width: `${50 / 3}px`,
                      height: `50px`,
                      background: `#000000`,
                      border: `1px solid black`,
                    }}
                    //className={classes.block}
                  />
                  <Typography className={classes.legend} variant="h6">
                    No Press.
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.gridItem}
                  alignItems="center"
                  xs={5}
                >
                  <div
                    style={{
                      width: `${50 / 3}px`,
                      height: `50px`,
                      background: `#33cc33`,
                      border: `1px solid black`,
                    }}
                    //className={classes.block}
                  />
                  <Typography className={classes.legend} variant="h6">
                    {t("resultsGrid.lightPressure")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.gridItem}
                  alignItems="center"
                  xs={6}
                >
                  <div
                    style={{
                      width: `${50 / 3}px`,
                      height: `50px`,
                      background: `#ebf442`,
                      border: `1px solid black`,
                    }}
                    //className={classes.block}
                  />
                  <Typography className={classes.legend} variant="h6">
                    {t("resultsGrid.mediumPressure")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.gridItem}
                  alignItems="center"
                  xs={5}
                >
                  <div
                    style={{
                      width: `${50 / 3}px`,
                      height: `50px`,
                      background: `#c11707`,
                      border: `1px solid black`,
                    }}
                    //className={classes.block}
                  />
                  <Typography className={classes.legend} variant="h6">
                    {t("resultsGrid.deepPressure")}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item container justify="center" xs={12} sm={12} md={6} lg={4}>
            <Paper className={classes.card}>
              <Typography variant="h5" className={classes.title}>
                Pattern (Beta)
              </Typography>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <PatternCanvas pattern={props.pattern} />
                </Grid>
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  xs={12}
                >
                  <Grid
                    item
                    container
                    className={classes.gridItem}
                    alignItems="center"
                    direction="column"
                    xs={4}
                  >
                    <div
                      style={{
                        width: `${50 / 3}px`,
                        height: `${50 / 3}px`,
                        borderRadius: `50%`,
                        background: `green`,
                        border: `1px solid black`,
                      }}
                    />
                    <Typography variant="h6" className={classes.legend}>
                      First Palp
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    className={classes.gridItem}
                    alignItems="center"
                    direction="column"
                    xs={3}
                  >
                    <div
                      style={{
                        width: `${50 / 3}px`,
                        height: `${50 / 3}px`,
                        borderRadius: `50%`,
                        background: `purple`,
                        border: `1px solid black`,
                      }}
                    />
                    <Typography variant="h6" className={classes.legend}>
                      Palp
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    className={classes.gridItem}
                    alignItems="center"
                    direction="column"
                    xs={4}
                  >
                    <div
                      style={{
                        width: `${50 / 3}px`,
                        height: `${50 / 3}px`,
                        borderRadius: `50%`,
                        background: `red`,
                        border: `1px solid black`,
                      }}
                    />
                    <Typography variant="h6" className={classes.legend}>
                      Last Palp
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item className={classes.gridItem}>
                  <Typography variant="h6" className={classes.legend}>
                    Palpations per Minute:{" "}
                    {(props.pattern.length / (props.examTime / 60)).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* </div> */}
        </Grid>
      </Grid>
      {props.playback ? (
        <ColorButton
          variant="outlined"
          onClick={() =>
            props.replayExam(
              props.playBackFilename,
              props.tumors,
              props.moduleId,
              props.coverageGrid,
              {
                percentCoverage: props.percentCoverage,
                examTime: props.examTime,
                userEmail: props.email,
                passed: props.passed,
              }
            )
          }
        >
          {t("grades.replayExam")}
        </ColorButton>
      ) : null}
    </Card>
  );
};
export default AdminResultsGrid;
