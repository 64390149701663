import * as PRESSURES from "./pressures";

export const MODULE2_CONFIGS = {
  module: 2,
  message: "",
  playerUrl: "",
  playing: true,
  played: 0,
  loaded: 0,
  volume: 0.8,
  autoPlay: false,
  isPaused: false,
  videoNotReady: true,
  videoStatus: "",
  currentRequirement: null,
  currentRequirementMet: false,
  endModule: false,
  prevBtnDisabled: true,
  nextBtnDisabled: true,
  videoClips: [],
  currentVideoClipIndex: 0,
  palpatedCells: [],
  tumors: [
    {
      x: 1,
      y: 4,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [31, 32, 33, 39, 40, 41, 47, 48, 49],
      id: 1,
    },
    {
      x: 5,
      y: 3,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [62, 63, 64, 70, 71, 72, 78, 79, 80],
      id: 2,
    },
  ],
  rotatedTumors: [
    {
      x: 3,
      y: 4,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [47, 48, 49, 55, 56, 57, 63, 64, 65],
      id: 2,
    },
    {
      x: 7,
      y: 3,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [78, 79, 80, 86, 87, 88, 94, 95, 96],
      id: 1,
    },
  ],
  showGrid: true,
  startExam: false,
  results: null,
  passConditions: {
    totalTumors: 2,
    falsePositivesAllowed: -1,
    percentCoverage: 0.9,
  },
  languages: [
    {
      lng: "en",
      title: "Module 2 Practice",
      label: "Module 2 Practice",
      instructions:
        "Place the model labeled Module 2 on the P.A.D. with the label at the top.",
      completionTime: "<10 minutes",
      img: "",
    },
    {
      lng: "fr",
      title: "Module 2 Pratique",
      label: "Module 2 Pratique",
      instructions:
        "Placez le modèle étiqueté Module 2 sur le P.A.D. avec l'étiquette en haut.",
      completionTime: "<10 minutes",
      img: "",
    },
  ],
  rotatedLanguages: [
    {
      lng: "en",
      title: "Module 2 Practice",
      label: "Module 2 Practice",
      instructions:
        "Place the model labeled Module 2 on the P.A.D. with the label at the bottom.",
      completionTime: "<10 minutes",
      img: "",
    },
    {
      lng: "fr",
      title: "Module 2 Pratique",
      label: "Module 2 Pratique",
      instructions:
        "Placez le modèle étiqueté Module 2 sur le P.A.D. avec l'étiquette en bas.",
      completionTime: "<10 minutes",
      img: "",
    },
  ],
};

export const MODULE3_CONFIGS = {
  module: 3,
  message: "",
  playerUrl: "",
  playing: true,
  played: 0,
  loaded: 0,
  volume: 0.8,
  autoPlay: false,
  isPaused: false,
  videoNotReady: true,
  videoStatus: "",
  currentRequirement: null,
  currentRequirementMet: false,
  endModule: false,
  prevBtnDisabled: true,
  nextBtnDisabled: true,
  videoClips: [],
  currentVideoClipIndex: 0,
  palpatedCells: [],
  tumors: [
    {
      x: 2,
      y: 5,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [40, 41, 42, 48, 49, 50, 56, 57, 58],
      id: 1,
    },
    {
      x: 4,
      y: 1,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [52, 53, 54, 60, 61, 62, 68, 69, 70],
      id: 2,
    },
    {
      x: 6,
      y: 5,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [72, 73, 74, 80, 81, 82, 88, 89, 90],
      id: 3,
    },
  ],
  rotatedTumors: [
    {
      x: 2,
      y: 52,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [37, 38, 39, 45, 46, 47, 53, 54, 55],
      id: 1,
    },
    {
      x: 4,
      y: 6,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [57, 58, 59, 65, 66, 67, 73, 74, 75],
      id: 2,
    },
    {
      x: 6,
      y: 2,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [69, 70, 71, 77, 78, 79, 85, 86, 87],
      id: 3,
    },
  ],
  showGrid: true,
  startExam: false,
  results: null,
  passConditions: {
    totalTumors: 3,
    falsePositivesAllowed: 2,
    percentCoverage: 0.9,
  },
  languages: [
    {
      lng: "en",
      title: "Module 3 Practice",
      label: "Module 3 Practice",
      instructions:
        "Place the model labeled Module 3 on the P.A.D. with the label at the top.",
      completionTime: "<10 minutes",
      img: "",
    },
    {
      lng: "fr",
      title: "Module 3 Pratique",
      label: "Module 3 Pratique",
      instructions:
        "Placez le modèle étiqueté Module 3 sur le P.A.D. avec l'étiquette en haut.",
      completionTime: "<10 minutes",
      img: "",
    },
  ],
  rotatedLanguages: [
    {
      lng: "en",
      title: "Module 3 Practice",
      label: "Module 3 Practice",
      instructions:
        "Place the model labeled Module 3 on the P.A.D. with the label at the bottom.",
      completionTime: "<10 minutes",
      img: "",
    },
    {
      lng: "fr",
      title: "Module 3 Pratique",
      label: "Module 3 Pratique",
      instructions:
        "Placez le modèle étiqueté Module 3 sur le P.A.D. avec l'étiquette en bas.",
      completionTime: "<10 minutes",
      img: "",
    },
  ],
};

export const MODULE4_CONFIGS = {
  module: 4,
  message: "",
  playerUrl: "",
  playing: true,
  played: 0,
  loaded: 0,
  volume: 0.8,
  autoPlay: false,
  isPaused: false,
  videoNotReady: true,
  videoStatus: "",
  currentRequirement: null,
  currentRequirementMet: false,
  endModule: false,
  prevBtnDisabled: true,
  nextBtnDisabled: true,
  videoClips: [],
  currentVideoClipIndex: 0,
  palpatedCells: [],
  tumors: [
    {
      x: 2,
      y: 3,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [38, 39, 40, 46, 47, 48, 54, 55, 56],
      id: 1,
    },
    {
      x: 5,
      y: 2,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [61, 62, 63, 69, 70, 71, 77, 78, 79],
      id: 2,
    },
    {
      x: 4,
      y: 6,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [57, 58, 59, 65, 66, 67, 73, 74, 75],
      id: 3,
    },
  ],
  rotatedTumors: [
    {
      x: 4,
      y: 1,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [52, 53, 54, 60, 61, 62, 68, 69, 70],
      id: 1,
    },
    {
      x: 3,
      y: 5,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [48, 49, 50, 56, 57, 58, 64, 65, 66],
      id: 2,
    },
    {
      x: 7,
      y: 4,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [79, 80, 81, 87, 88, 89, 95, 96, 97],
      id: 3,
    },
  ],
  showGrid: true,
  startExam: false,
  results: null,
  passConditions: {
    totalTumors: 3,
    falsePositivesAllowed: 1,
    percentCoverage: 0.9,
  },
  languages: [
    {
      lng: "en",
      title: "Module 4 Practice",
      label: "Module 4 Practice",
      instructions:
        "Place the model labeled Module 4 on the P.A.D. with the label at the top.",
      completionTime: "<10 minutes",
      img: "",
    },
    {
      lng: "fr",
      title: "Module 4 Pratique",
      label: "Module 4 Pratique",
      instructions:
        "Placez le modèle étiqueté Module 4 sur le P.A.D. avec l'étiquette en haut.",
      completionTime: "<10 minutes",
      img: "",
    },
  ],
  rotatedLanguages: [
    {
      lng: "en",
      title: "Module 4 Practice",
      label: "Module 4 Practice",
      instructions:
        "Place the model labeled Module 4 on the P.A.D. with the label at the bottom.",
      completionTime: "<10 minutes",
      img: "",
    },
    {
      lng: "fr",
      title: "Module 4 Pratique",
      label: "Module 4 Pratique",
      instructions:
        "Placez le modèle étiqueté Module 4 sur le P.A.D. avec l'étiquette en bas.",
      completionTime: "<10 minutes",
      img: "",
    },
  ],
};

export const PRETRAININGMODULE_CONFIGS = {
  module: 0,
  palpatedCells: [],
  tumors: [
    {
      x: 2,
      y: 5,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [40, 41, 42, 48, 49, 50, 56, 57, 58],
      id: 1,
    },
    {
      x: 4,
      y: 1,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [52, 53, 54, 60, 61, 62, 68, 69, 70],
      id: 2,
    },
    {
      x: 6,
      y: 5,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [72, 73, 74, 80, 81, 82, 88, 89, 90],
      id: 3,
    },
  ],
  showGrid: true,
  startExam: false,
  results: null,
  passConditions: {
    totalTumors: 3,
    falsePositivesAllowed: 2,
    percentCoverage: 0.9,
  },
  languages: [
    {
      lng: "en",
      title: "PreTraining Module Practice",
      label: "PreTraining Module Practice",
      instructions:
        "Place the model labeled Pre-Training Model on the P.A.D. with the label at the top.",
      completionTime: "<10 minutes",
      img: "",
    },
    {
      lng: "fr",
      title: "Pratique du module de pré-formation",
      label: "Pratique du module de pré-formation",
      instructions:
        "Veuillez avoir les éléments ci-dessous. Connectez le P.A.D. à votre ordinateur. Si le message ci-dessous ne devient pas vert, actualisez la page Web.",
      completionTime: "<10 minutes",
      img: "",
    },
  ],
};

export const POSTTRAININGMODULE_CONFIGS = {
  module: 0,
  palpatedCells: [],
  tumors: [
    {
      x: 2,
      y: 2,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [37, 38, 39, 45, 46, 47, 53, 54, 55],
      id: 1,
    },
    {
      x: 4,
      y: 6,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [57, 58, 59, 65, 66, 67, 73, 74, 75],
      id: 2,
    },
    {
      x: 6,
      y: 2,
      minPressure: PRESSURES.MEDPRESSUREMIN,
      detected: false,
      totalDetections: 0,
      targetMidiNums: [69, 70, 71, 77, 78, 79, 85, 86, 87],
      id: 3,
    },
  ],
  showGrid: true,
  startExam: false,
  results: null,
  passConditions: {
    totalTumors: 3,
    falsePositivesAllowed: 2,
    percentCoverage: 0.9,
  },
  languages: [
    {
      lng: "en",
      title: "PostTraining Module Practice",
      label: "PostTraining Module Practice",
      instructions:
        "Place the model labeled Post-Training on the P.A.D. with the label at the top.",
      completionTime: "<10 minutes",
      img: "",
    },
    {
      lng: "fr",
      title: "Pratique du module post-formation",
      label: "Pratique du module post-formation",
      instructions:
        "Veuillez avoir les éléments ci-dessous. Connectez le P.A.D. à votre ordinateur. Si le message ci-dessous ne devient pas vert, actualisez la page Web.",
      completionTime: "<10 minutes",
      img: "",
    },
  ],
};
