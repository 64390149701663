import React, { useState, useEffect, Fragment } from "react";
import { withFirebase } from "../Firebase";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ModalForm from "../CRUDForm/ModalForm";

import ceuModels from "./images/ceuModelsAndPaper.jpg";
import manualGrid from "./images/simplemanualresults.png";
import combo from "./images/comboOnPalpationPad.jpg";
import distanceLearningVideo from "./images/distanceLearningVideoPic.png";
import cbeTeletrainingVideoPic from "./images/cbeTeletrainingVideoPic.png";
import katieBSE from "./images/katie-bse2.jpg";
import katieBSE_mobile from "./mobileImages/katie-bse2_mobile.jpg";
import breakDown from "./images/breakdownTable.png";
import breakDown_mobile from "./mobileImages/breakdownTable_mobile.png";
import breakDown2 from "./images/breakdownTable2.png";
import breakDown2_mobile from "./mobileImages/breakdownTable2_mobile.png";
import breakDown3 from "./images/breakdownTable3.png";
import breakDown3_mobile from "./mobileImages/breakdownTable3_mobile.png";
import breakDown4 from "./images/breakdownTable4.png";
import breakDown4_mobile from "./mobileImages/breakdownTable4_mobile.png";
import trainingSteps from "./images/trainingSteps.jpg";
import trainingSteps_mobile from "./mobileImages/trainingSteps_mobile.jpg";
import newPalpations from "./images/newPalpation.png";
import newPalpations_mobile from "./mobileImages/newPalpation_mobile.png";
import katieTeletraining from "./images/Katie_Teletraining";
import katieTeletraining_mobile from "./mobileImages/katieTeletraining_mobile.jpg";
import mamma from "./images/MAMMA.jpeg";
import mamma_mobile from "./mobileImages/mamma_mobile.jpg";
import dualPalpation_mobile from "./mobileImages/dualPalpation_mobile.jpg";
import comboMaroon from "./images/combo-on-maroon-palpation-pad.JPG";
import comboMaroonMobile from "./mobileImages/combo-on-maroon-palpation-pad-mobile.png";
import participatingUniversities from "../Tour/participating universities 8.17.20 v2.jpg";
import nsfLogo from "./images/nsf-logo-small.png";
import journalMedEd from "../Tour/journal-grad-medical-ed.gif";
import namrata from "./images/namrata.png";
import s2OpaqueModel from "./images/s2opaquebluepad.jpg";
import comboBluePad from "./images/combobluepad.jpg";

import universityApplication from "../FormJSON/university-application.json";
import universityQuestion from "../FormJSON/university-question.json";
import simulatorApplication from "../FormJSON/simulator-application.json";
import simulatorQuestion from "../FormJSON/simulator-question.json";
import teletrainingApplication from "../FormJSON/teletraining-application.json";
import teletrainingQuestion from "../FormJSON/teletraining-question.json";
import ceQuestion from "../FormJSON/ce-question.json";
import bseQuestion from "../FormJSON/bse-question.json";
import bseApplication from "../FormJSON/bse-application.json";
import essentialsQuestion from "../FormJSON/essentials-question.json";
import essentialsApplication from "../FormJSON/essentials-application.json";

import {
  makeStyles,
  withStyles,
  fade,
  AppBar,
  Button,
  Paper,
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Collapse,
  Drawer,
  CssBaseline,
  Toolbar,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  Slide,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";

import Tabs from "./Tabs";
import TabContainer from "./TabContainer";
import { CourseListing, MobileCourseListing } from "./CourseListing";
import { SimulatorPreview, MobileSimulatorPreview } from "../Tour";
import DynamicForm from "../Generic/DynamicForm";
import MammaSpinner from "../Generic/MammaSpinner";
import { DefaultContent, MobileDefaultContent } from "./DefaultContent";

const MobileActivityCard = styled.div`
  text-align: center;
  color: #000000;
  background-color: #ffffff;
  padding: 0 10px;
`;

const Div = styled.div`
  background-color: #ffffff;
  padding: 20px;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const MobileDiv = styled.div`
  background-color: #ffffff;
  padding-bottom: 5px;
  color: white;
`;

const H1 = styled.div`
  // text-align: center;
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
  background-color: #680000;
  padding: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 1000;
  width: 100%;
`;

const MobileH1 = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 1em;
  font-weight: bold;
  background-color: #680000;
  padding: 5px 0;
`;

const ActivityCard = styled.div`
  text-align: center;
  color: #000000;
  background-color: #ffffff;
  padding: 20px;
`;

const MammaButton = styled.button`
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-weight: bold;
  background-color: #680000;
  border-radius: 10px;
  color: white;
  font-weight: bold;
`;

const P = styled.p`
  margin: 0;
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
`;

const AltP = styled.p`
  margin: 0;
  padding: 10px;
  font-size: 20px;
`;

const MammaAppBar = withStyles((theme) => ({
  root: {
    backgroundColor: "#680000",
    color: "white",
  },
}))(AppBar);

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#680000",
    color: "#ffffff",
    border: "1px solid #680000",
    "&:hover": {
      color: "white",
      backgroundColor: "#680000",
    },
  },
}))(Button);

const MobileColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#680000",
    color: "#ffffff",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #680000",
    minWidth: "170px",
    "&:hover": {
      color: "white",
      backgroundColor: "#680000",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  //offset: theme.mixins.toolbar,
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 0,
    textAlign: "center",
    borderBottom: "1px solid #680000",
  },
  mobileHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    //paddingLeft: 0,
    margin: "0 2.5%",
    textAlign: "center",
    borderBottom: "1px solid #680000",
  },
  titleDivider: {
    margin: theme.spacing(1, 0, 2, 0),
    color: "#680000",
  },
  panel: {
    textAlign: "left",
  },
  testimonialText: {
    fontStyle: "oblique",
  },
  testimonialName: {
    fontWeight: "bold",
  },
  mobileTestimonialName: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(12),
  },
  imageGrid: {
    marginTop: "30px",
  },
  imageGridItem: {
    display: "flex",
    justifyContent: "center",
  },
  videoGridItem: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2, 0),
  },
  btnGridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: theme.spacing(0.5, 0),
  },
  drawer: {
    width: "350px",
    flexShrink: 0,
    textAlign: "center",
    zIndex: theme.zIndex.appBar - 1,
    overflow: "hidden",
  },
  drawerPaper: {
    width: "350px",
    overflow: "auto",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    "&:selected": {
      color: "#757575",
      backgroundColor: "#757575",
      border: "1px solid #494949",
    },
  },
  parent: {
    color: "#680000",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(18),
  },
  flatPanel: {
    border: "1px solid #ccc",
    backgroundColor: "#f5f5f5",
    zIndex: 1000,
  },
  biggerText: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

const Landing = (props) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [form, setForm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorPresent, setErrorPresent] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(<DefaultContent />);
  const [currentComponentId, setCurrentComponentId] = useState("default");

  const params = Object.values(props.match.params);

  const toggleModal = async (formName) => {
    console.log("trying to toggle modal?");
    let form = null;
    switch (formName) {
      case "university-application":
        form = await JSON.parse(JSON.stringify(universityApplication));
        setForm(form);
        setModalOpen(true);
        break;
      case "university-question":
        form = await JSON.parse(JSON.stringify(universityQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "simulator-demo":
        form = await JSON.parse(JSON.stringify(simulatorApplication));
        setForm(form);
        setModalOpen(true);
        break;
      case "simulator-question":
        form = await JSON.parse(JSON.stringify(simulatorQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "teletraining-application":
        form = await JSON.parse(JSON.stringify(teletrainingApplication));
        setForm(form);
        setModalOpen(true);
        break;
      case "teletraining-question":
        form = await JSON.parse(JSON.stringify(teletrainingQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "ce-question":
        form = await JSON.parse(JSON.stringify(ceQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "bse-question":
        form = await JSON.parse(JSON.stringify(bseQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "bse-application":
        form = await JSON.parse(JSON.stringify(bseApplication));
        setForm(form);
        setModalOpen(true);
        break;
      case "essentials-question":
        form = await JSON.parse(JSON.stringify(essentialsQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "essentials-application":
        form = await JSON.parse(JSON.stringify(essentialsApplication));
        setForm(form);
        setModalOpen(true);
        break;
      default:
        setModalOpen(false);
        break;
    }
  };

  const onComplete = async (title, data) => {
    console.log("onComplete called....");
    console.log(data);

    setIsLoading(true);
    setForm(null);

    const emailData = retrieveNamesAndValues(data);
    console.log("emailData: ", emailData);

    let response = await props.firebase
      .sendEmail(
        form.emails,
        `${title} Form Submission from ${emailData.raw.email}`,
        emailData.text
      )
      .catch(async (e) => {
        console.log("in catch block: ", e.message);
        await props.firebase.handleErrorLogging(
          "medium",
          "Unable to send Landing Form Submission email.  ",
          e.message
        );
        setErrorPresent(
          "An error occurred while attempting to contact MammaCare. Please send your inquiry to training@mammacare.org."
        );
        setIsLoading(false);
        return {
          status: "error",
          message: "Unable to send Landing Form Submission email. " + e.message,
        };
      });
    if (response.status === "success") {
      setIsLoading(false);
      setModalOpen(false);
    }
  };

  const retrieveNamesAndValues = (data) => {
    let str = "";
    let obj = {};
    //console.log("formatting data: ", Object.values(data));

    for (let index = 0; index < data.length; index++) {
      str += data[index].label + ": " + data[index].value + "<br />";
    }

    Object.values(data).forEach((el) => {
      obj[el.name] = el.value;
    });

    console.log("results: ", obj);
    return { text: str, raw: obj };
  };

  const containerContents = [
    {
      id: "/university/",
      category: "university",
      title: "University Courses",
      courses: [
        {
          id: "/university/essentials",
          title: "Essentials of Breast Examination (Undergraduate Nursing)",
          videos: [
            // {
            //   img: distanceLearningVideo,
            //   url: "https://vimeo.com/432876140/50e143e969",
            // },
          ],
          headingImages: [{ img: comboMaroon, xs: 8, width: 350 }],
          kicker: (
            <>
              <p className={classes.biggerText}>
                This self-study course introduces undergraduate nursing students
                to the elements of breast examination. Breast health and
                awareness content, authored by academic and clinical experts, is
                synchronized with hands-on exercises. The online program guides
                students through the course and practice on standard MammaCareⓇ
                Teaching Breast Models. Faculty receives each student quiz and
                practice results.
              </p>
              <p className={classes.biggerText}>
                Student course fee: $75.00 + free shipping direct to students or
                university
              </p>
              <p style={{ marginBottom: "0px" }} className={classes.biggerText}>
                Fee includes:
              </p>
              <ul style={{ marginTop: "0px", marginLeft: "16px" }}>
                <li>
                  <p className={classes.biggerText}>
                    Online course access with breast health tutorials,
                    instructional videos, and quizzes
                  </p>
                </li>
                <li>
                  <p className={classes.biggerText}>
                    MammaCare
                    <sup>&reg;</sup> Teaching Breast Model synchronized with
                    online, interactive video practice tools
                  </p>
                </li>
                <li>
                  <p className={classes.biggerText}>Palpation pad</p>
                </li>
              </ul>
            </>
          ),
          description: (
            <>
              {/* <p>
                Introduction to Clinical Breast Exam (CBE) Standards and
                Practice is an e-learning course that introduces students’ hands
                and eyes to the elements of clinical breast examination. Each
                student receives a standard set of MammaCare® breast models
                synchronized with exercises that teach proper palpation
                techniques. Instructional videos and knowledge-based content
                guide students through the course. Instructors receive students'
                completed results.
              </p> */}
            </>
          ),
          images: [
            // { img: combo, xs: 4, height: 200 },
            // { img: ceuModels, xs: 4, height: 200 },
            { img: breakDown4, xs: 8, width: 650 },
          ],
          buttons: [
            // {
            //   btn: (
            //     <>
            //       <ColorButton
            //         variant="outlined"
            //         onClick={() => toggleModal("essentials-application")}
            //       >
            //         Get Started
            //       </ColorButton>
            //     </>
            //   ),
            //   desc: <P></P>,
            // },
            {
              btn: (
                <ColorButton
                  variant="outlined"
                  onClick={() => toggleModal("essentials-question")}
                >
                  Ask a Question
                </ColorButton>
              ),
              desc: <P></P>,
            },
          ],
        },
        {
          id: "/university/introduction",
          title:
            "Introduction to Clinical Breast Exam Standards and Practice (Graduate Nursing and Midwifery Students)",
          videos: [
            // {
            //   img: distanceLearningVideo,
            //   url: "https://vimeo.com/432876140/50e143e969",
            // },
          ],
          // headingImages: [{ img: trainingSteps, xs: 12, width: 512 }],
          headingImages: [
            { img: comboBluePad, height: 250 },
            { img: s2OpaqueModel, xs: 3, height: 250 },
            { img: manualGrid, height: 250 },
          ],
          // kicker: (
          //   <>
          //     <p>
          //       <b>Enroll:</b> Direct your students to sign up with MammaCare.
          //     </p>
          //     <p>
          //       <b>Ship:</b> MammaCare ships course materials direct to
          //       students.
          //     </p>
          //     <p>
          //       <b>Train:</b> Students learn and practice in this three-hour
          //       self-paced, online course.
          //     </p>
          //   </>
          // ),
          kicker: (
            <>
              <p className={classes.biggerText}>
                MammaCare's <b>NEW Lump Discovery Tool</b> verifies your
                students' breast exam skills.
              </p>
              <p className={classes.biggerText}>
                Introduction to Clinical Breast Exam (CBE) Standards and
                Practice introduces students’ hands and eyes to the elements and
                techniques of proficient clinical breast examination. Each
                student receives two MammaCareⓇ Teaching Breast Models
                synchronized with content and exercises that teach proper
                palpation and search techniques.
              </p>

              <p className={classes.biggerText}>
                Students first learn to discriminate normal, healthy breast
                tissue from suspicious lumps using the transparent breast model
                before practicing search skills to detect small suspicious,
                tactually accurate hidden tumors in the opaque model. Online
                knowledge-based content, instructional videos and quizzes guide
                students through the course with extensive feedback. Faculty
                receive students' results.
              </p>
              <p className={classes.biggerText}>
                Student course fee: $125.00 + free shipping direct to students
                or university
              </p>
              <p style={{ marginBottom: "0px" }} className={classes.biggerText}>
                Fee includes:
              </p>
              <ul style={{ marginTop: "0px", marginLeft: "16px" }}>
                <li>
                  <p className={classes.biggerText}>
                    Online course access with breast health tutorials,
                    instructional videos, and quizzes
                  </p>
                </li>
                <li>
                  <p className={classes.biggerText}>
                    MammaCare
                    <sup>&reg;</sup> Teaching Breast Model synchronized with
                    online, interactive video practice tools
                  </p>
                </li>
                <li>
                  <p className={classes.biggerText}>Palpation pad</p>
                </li>
              </ul>
            </>
          ),
          description: (
            <>
              {/* <p>
                Introduction to Clinical Breast Exam (CBE) Standards and
                Practice is an e-learning course that introduces students’ hands
                and eyes to the elements of clinical breast examination. Each
                student receives a standard set of MammaCare® breast models
                synchronized with exercises that teach proper palpation
                techniques. Instructional videos and knowledge-based content
                guide students through the course. Instructors receive students'
                completed results.
              </p> */}
            </>
          ),
          feedback: [
            {
              text: "Should be core requirement for all NP and medical students.",
              name: "– University of Vermont student",
            },
            {
              text: "This course was excellent! I loved the quizzes throughout to assess knowledge. I LOVED the two simulation models. It was so helpful to have a model that was translucent to practice on and one that was opaque to assess my skills. I would recommend this to every new women’s health practitioner!",
              name: "– Vanderbilt University student",
            },
            {
              text: "This coupled with real bedside education really helped.",
              name: "– University of San Francisco student",
            },
          ],
          images: [
            // { img: comboBluePad, height: 250 },
            // { img: s2OpaqueModel, xs: 3, height: 250 },
            // { img: manualGrid, height: 250 },
            { img: breakDown, xs: 12, width: 650 },
          ],
          buttons: [
            // {
            //   btn: (
            //     <>
            //       <ColorButton
            //         variant="outlined"
            //         onClick={() => toggleModal("university-application")}
            //       >
            //         Get Started
            //       </ColorButton>
            //     </>
            //   ),
            //   desc: <P></P>,
            // },
            {
              btn: (
                <ColorButton
                  variant="outlined"
                  onClick={() => toggleModal("university-question")}
                >
                  Ask a Question
                </ColorButton>
              ),
              desc: <P></P>,
            },
          ],
        },
        {
          id: "/university/simulator",
          title:
            "MammaCare Clinical Breast Exam Simulator-Trainer (Graduate Medicine and Nursing)",
          videos: [
            { img: dualPalpation_mobile, url: "https://vimeo.com/437984486" },
          ],
          kicker: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p className={classes.biggerText}>
                The MammaCare CBE Simulator-Trainer is a hands-on,
                self-administered computer-guided breast exam trainer. The
                system produces and validates the essential clinical skills that
                are required to detect small suspicions tumors while decreasing
                false positives. The technology monitors exam sensitivity and
                specificity during practice on progressively more complex breast
                models. A digital “clinical instructor” guides students through
                the training modules, assessing progress and providing
                corrective feedback in real time.
              </p>
              {/* <ColorButton
                variant="outlined"
                onClick={() => props.history.push("/preview/cbesimulator")}
                style={{ margin: "0 auto" }}
              >
                Preview Training Features
              </ColorButton> */}
            </div>
          ),
          description: (
            <>
              {/* <p className={classes.biggerText}>
              The MammaCare CBE Simulator-Trainer is a hands-on, self-administered computer- guided breast exam trainer. The system produces and validates the essential clinical skills that are required to detect small suspicions tumors while decreasing false positives. The technology monitors exam sensitivity and specificity during practice on progressively more complex breast models. A digital “clinical instructor” guides students through the training modules, assessing progress and providing corrective feedback in real time.
              </p> */}
            </>
          ),
          // images: [{ img: newPalpations, xs: 8 }],
          preview: <SimulatorPreview />,
          feedback: [
            {
              text: "Getting practice on these models is MUCH more helpful than just learning about [clinical breast exam]! You never really know what “lumps” feel like until you get a chance to touch them.",
              name: "– Vanderbilt student",
            },
            {
              text: "Great module/way to learn! Definitely recommend to clinicians just starting out and those of all levels to enhance CBE skills.",
              name: "– University of Vermont student",
            },
            {
              text: "Love the testing program. It was super helpful in determining the difference between lumps and nodularities, as well as ensuring I was actually covering all of the breast tissue.",
              name: "– University of San Francisco student",
            },
          ],
          buttons: [
            {
              btn: (
                <ColorButton
                  variant="outlined"
                  onClick={() => toggleModal("simulator-demo")}
                >
                  Request a Demo
                </ColorButton>
              ),
              desc: <P></P>,
            },
            {
              btn: (
                <ColorButton
                  variant="outlined"
                  onClick={() => toggleModal("simulator-question")}
                >
                  Ask a Question
                </ColorButton>
              ),
              desc: <P></P>,
            },
          ],
        },
      ],
    },
    {
      id: "/certifications/",
      category: "certifications",
      title: "Certification Courses",
      courses: [
        {
          id: "/certifications/teletraining",
          defaultOpen: false,
          title:
            "MammaCare Clinical Breast Examiner Certification via Teletraining",
          videos: [
            {
              img: cbeTeletrainingVideoPic,
              url: "https://vimeo.com/432572177",
            },
          ],
          // headingImages: [{ img: mamma, xs: 12, height: 300 }],
          kicker: (
            <>
              <p>
                MammaCare certification is the recognized standard for
                performing a clinical breast examination (CBE). The MammaCare
                Clinical Breast Examiner via Teletraining Course is a one-on-one
                program conducted with a live instructor.
              </p>
            </>
          ),
          description: (
            <>
              <p>
                Trainees learn to reliably detect breast lesions using
                MammaCare’s patented training technology. Training includes
                practice with surrogate patients.
              </p>
              <p>
                Graduates meet performance standards for sensitivity,
                specificity and thoroughness to earn certification as a
                MammaCare Clinical Breast Examiner.
              </p>
            </>
          ),
          images: [
            { img: katieTeletraining, xs: 6 },
            { img: breakDown3, xs: 8 },
          ],
          feedback: [
            {
              text: "The increased ease of accessing the materials online and the trainer were invaluable to me and my team. Taking time away from patient care is basically eliminated with this method",
              name: "– Certified Clinical Breast Examiner",
            },
            {
              text: "What I liked best about the course was the learning environment and the adaptability of the course offering (Teletraining works well)",
              name: "– Certified Clinical Breast Examiner",
            },
            {
              text: "The instructor was just SPECTACULAR!!! She made me feel at ease and confident. The course was so well organized with plenty of time to learn properly. I honestly loved everything about it!!",
              name: "– Certified Clinical Breast Examiner",
            },
          ],
          buttons: [
            {
              btn: (
                <ColorButton
                  variant="outlined"
                  onClick={() => toggleModal("teletraining-application")}
                >
                  Apply for Certification
                </ColorButton>
              ),
              desc: <P></P>,
            },
            {
              btn: (
                <ColorButton
                  variant="outlined"
                  onClick={() => toggleModal("teletraining-question")}
                >
                  Ask a Question
                </ColorButton>
              ),
              desc: <P></P>,
            },
          ],
        },
        {
          id: "/certifications/bse",
          defaultOpen: false,
          title: "MammaCare Breast Self-Examination Instructor",
          headingImages: [{ img: katieBSE, xs: 12, height: 450 }],
          kicker: (
            <>
              <p>
                The new MammaCare BSE Instructor Course is conducted via
                teletraining. It is designed for women’s healthcare
                professionals who plan to teach women how best to perform
                self-exams and to conduct community workshops.
              </p>
            </>
          ),
          description: (
            <>
              <p>
                The program contains a series of online breast health tutorials,
                correct self-exam procedures, palpation exercises, and teaching
                sessions. The nine-hour course is divided into two training
                modules: self-study and a one-to-one video conference with a
                Certified MammaCare Instructor.
              </p>
              <p>
                The MammaCare BSE Instructor certificate is awarded after
                successful completion of all training requirements.
                Certification is valid for three years*. Continuing education
                contact hours are available for nurses.
              </p>
              <p>Course Fee: $1250</p>
              <p>Fee includes workshop and one-to-one teaching tools:</p>
              <ul>
                <li>MammaCare Professional Learning System (MLS-1)</li>
                <li>Six additional tactually accurate breast models</li>
                <li>
                  Online course includes breast health tutorials, quizzes, and
                  resources
                </li>
                <li>
                  BSE Workshop PowerPoint presentation, teaching cards, and
                  training materials
                </li>
                <li>MammaCare BSE Instructor certificate</li>
                <li>BSE Instructor Pin</li>
              </ul>
            </>
          ),
          images: [],
          feedback: [
            {
              text: "The training was great! I feel like I finally know how to do a self breast exam. And even better I can now teach other women to do it. This is a huge wonderful tool I’ve been given.",
              name: "– course graduate",
            },
          ],
          buttons: [
            {
              btn: (
                <ColorButton
                  variant="outlined"
                  onClick={() => toggleModal("bse-application")}
                >
                  Apply for Certification
                </ColorButton>
              ),
              desc: <P></P>,
            },
            {
              btn: (
                <ColorButton
                  variant="outlined"
                  onClick={() => toggleModal("bse-question")}
                >
                  Ask a Question
                </ColorButton>
              ),
              desc: <P></P>,
            },
          ],
        },
      ],
    },
    {
      id: "/ceu/",
      category: "ceu",
      title: "CE Courses",
      courses: [
        {
          id: "/ceu/cbe",
          title:
            "Introduction to Clinical Breast Examination Standards With Hands-On Practice",
          // headingImages: [
          //   { img: trainingSteps, xs: 12, height: 200, width: 512 },
          // ],
          headingImages: [
            { img: combo, xs: 4, height: 200 },
            { img: ceuModels, xs: 4, height: 200 },
          ],
          kicker: (
            <>
              <p>
                Introduction to Clinical Breast Examination Standards with
                Hands-On Practice is a new self-study education course, which
                includes hands-on training with tactually accurate breast
                models. Course enrollees receive standardized breast models,
                learn essential breast exam skills and the basics of breast
                health, disease and methods of detection in a self-paced, online
                learning program. The National Cancer Institute (NCI) and the
                National Science Foundation (NSF) supported research that
                resulted in MammaCare, the recognized standard for teaching and
                performing clinical breast examination.
              </p>
              <p>
                Course Fee: $125.00 USD and free shipping in continental US.
                Please contact us by clicking the "Ask a Question" below or at{" "}
                <b>accounts@mammacare.org</b>.
              </p>
            </>
          ),
          description: (
            <></>
            // <>
            //   <p>
            //     Introduction to Clinical Breast Examination Standards with
            //     Hands-On Practice is a new self-study education course, which
            //     includes hands-on training with tactually accurate breast
            //     models. Course enrollees receive standardized breast models,
            //     learn essential breast exam skills and the basics of breast
            //     health, disease and methods of detection in a self-paced, online
            //     learning program. The National Cancer Institute (NCI) and the
            //     National Science Foundation (NSF) supported research that
            //     resulted in MammaCare, the recognized standard for teaching and
            //     performing clinical breast examination.
            //   </p>
            // </>
          ),
          images: [{ img: breakDown, xs: 12, width: 650 }],
          buttons: [
            {
              btn: (
                <Link
                  to="/signupalt/ceu/2020"
                  style={{ textDecoration: "none" }}
                >
                  <ColorButton variant="outlined">Sign Up</ColorButton>
                </Link>
              ),
              desc: <P></P>,
            },
            {
              btn: (
                <ColorButton
                  variant="outlined"
                  onClick={() => toggleModal("ce-question")}
                >
                  Ask a Question
                </ColorButton>
              ),
              desc: <P></P>,
            },
          ],
        },
      ],
    },
    // {
    //   id: "/bcccp/",
    //   category: "bcccp",
    //   title: "BCCCP Courses",
    //   courses: [
    //     {
    //       id: "/bcccp/intro",
    //       title:
    //         "BCCCP Introduction to Clinical Breast Examination Standards With Hands-On Practice",
    //       // headingImages: [
    //       //   { img: trainingSteps, xs: 12, height: 200, width: 512 },
    //       // ],
    //       headingImages: [],
    //       kicker: (
    //         <>
    //           <p>
    //             Introduction to Clinical Breast Examination Standards with
    //             Hands-On Practice is a new self-study education course, which
    //             includes hands-on training with tactually accurate breast
    //             models. Course enrollees receive standardized breast models,
    //             learn essential breast exam skills and the basics of breast
    //             health, disease and methods of detection in a self-paced, online
    //             learning program. The National Cancer Institute (NCI) and the
    //             National Science Foundation (NSF) supported research that
    //             resulted in MammaCare, the recognized standard for teaching and
    //             performing clinical breast examination.
    //           </p>
    //         </>
    //       ),
    //       description: <></>,
    //       images: [
    //         { img: combo, xs: 4, height: 200 },
    //         { img: ceuModels, xs: 4, height: 200 },
    //         { img: breakDown2, xs: 8, width: 650 },
    //       ],
    //       buttons: [
    //         {
    //           btn: (
    //             <Link
    //               to="/signupalt/ceu/2020"
    //               style={{ textDecoration: "none" }}
    //             >
    //               <ColorButton variant="outlined">Sign Up</ColorButton>
    //             </Link>
    //           ),
    //           desc: <P></P>,
    //         },
    //         {
    //           btn: (
    //             <ColorButton
    //               variant="outlined"
    //               onClick={() => toggleModal("ce-question")}
    //             >
    //               Ask a Question
    //             </ColorButton>
    //           ),
    //           desc: <P></P>,
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];

  useEffect(() => {
    const setPageContent = () => {
      console.log(params);

      //console.log(parseInt(params[1]));
      if (params[0] !== undefined) {
        if (!isNaN(parseInt(params[1]))) {
          let category = containerContents.filter(
            (el) => el.category === params[0]
          )[0];
          let course =
            category.courses[parseInt(params[1])] || category.courses[0];
          let idString = course.id;
          selectPageContent(idString);
        } else {
          let idString = `/${params.join("/")}`;
          console.log(idString);
          selectPageContent(idString);
        }
      }
    };

    setPageContent();
  }, []);

  const selectPageContent = (id) => {
    let content = {};

    if (id === "default") {
      setCurrentComponentId("default");
      setCurrentComponent(<DefaultContent />);
      props.history.replace(`/`);
      return;
    }

    props.history.replace(`/page${id}`);
    //check if a parent matches the currentComponent value
    let selectedParent = containerContents.filter((el) => el.id == id)[0];
    console.log(selectedParent);
    //check if a child matches the currentComponent value
    if (selectedParent) {
      setCurrentComponentId(id);
      setCurrentComponent(
        selectedParent.courses.map((course) => {
          return (
            <CourseListing
              key={course.title}
              course={course}
              isParent={true}
              open={true}
              mobile={false}
              selectPageContent={selectPageContent}
            />
          );
        })
      );
    } else {
      containerContents.filter((category) => {
        let course = category.courses.filter((course) => course.id === id)[0];
        if (course) {
          setCurrentComponentId(id);
          setCurrentComponent(
            <CourseListing
              key={course.title}
              course={course}
              isParent={false}
              open={true}
              mobile={false}
            />
          );
        }
      });
    }
  };

  console.log("IS FORM NULL?", form);
  console.log("IS MODAL OPEN?", modalOpen);

  //console.log("SELECTED COURSE: ",selectedCourse);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* <LandingDrawer
        selectPageContent={selectPageContent}
        contents={containerContents}
        selectedItem={currentComponentId}
      /> */}
      {/* <div style={{ flexGrow: 1 }}>{currentComponent}</div> */}
      {/* <ModalForm
        open={modalOpen}
        handleClose={toggleModal}
        title={form ? form.title : null}
        loading={isLoading}
        maxWidth="sm"
        fullWidth={true}
      >
        {form ? (
          <DynamicForm
            key={form.title}
            json={form.elements}
            onComplete={onComplete}
            title={form.title}
            showButton={true}
          />
        ) : errorPresent ? (
          <p>{errorPresent}</p>
        ) : (
          <div
            style={{
              minWidth: "250px",
              minHeight: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MammaSpinner loading={isLoading} message="Please wait..." />
          </div>
        )}
      </ModalForm> */}
      <h1 style={{ color: "red" }}>
        This website has been deprecated and is no longer in use.
      </h1>
      <h1>
        Please visit{" "}
        <a href="https://learn.mammacare.org/home">learn.mammacare.org</a>
      </h1>
      <h2>
        To learn more about the MammaCare<sup>&reg;</sup> Foundation visit{" "}
        <a target="_blank" href="https://mammacare.org/">
          mammacare.org
        </a>
      </h2>
    </div>
  );
};

const LandingDrawer = (props) => {
  const classes = useStyles();
  const { contents, selectPageContent, selectedItem } = props;
  const [open, setOpen] = useState([]);
  const handleClick = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((el) => el !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div>
        <Toolbar />
        <List>
          {contents.map(
            (cat, index) => {
              return (
                <Fragment key={cat.id}>
                  <ListItem
                    button
                    onClick={() => selectPageContent(cat.id)}
                    selected={selectedItem === cat.id}
                    disableRipple
                    divider={true}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          className={classes.parent}
                          style={
                            selectedItem === cat.id
                              ? { fontWeight: 600 }
                              : { fontWeight: 500 }
                          }
                        >
                          {cat.title}
                        </Typography>
                      }
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => handleClick(cat.id)}>
                        {open.includes(cat.id) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Collapse
                    in={
                      open.includes(cat.id) ||
                      cat.courses
                        .map((course) => course.id)
                        .includes(selectedItem)
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {cat.courses.map((course) => {
                        return (
                          <ListItem
                            button
                            className={classes.nested}
                            onClick={() => selectPageContent(course.id)}
                            key={course.id}
                            selected={selectedItem === course.id}
                            disableRipple
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  style={
                                    selectedItem === course.id
                                      ? { fontWeight: "bold" }
                                      : { fontWeight: "normal" }
                                  }
                                >
                                  {course.title}
                                </Typography>
                              }
                            ></ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </Fragment>
              );
            },
            [contents]
          )}
          <ListItem
            button
            onClick={() => selectPageContent("default")}
            selected={selectedItem === "default"}
            disableRipple
            divider={true}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography
                  className={classes.parent}
                  style={
                    selectedItem === "default"
                      ? { fontWeight: "bold" }
                      : { fontWeight: "normal" }
                  }
                >
                  Taught Here
                </Typography>
              }
            ></ListItemText>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

const MobileLanding = (props) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [form, setForm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorPresent, setErrorPresent] = useState(null);
  const [open, setOpen] = useState([]);
  const [currentComponent, setCurrentComponent] = useState(
    <MobileDefaultContent />
  );
  const [currentComponentId, setCurrentComponentId] = useState("default");

  const params = Object.values(props.match.params);

  const toggleModal = async (formName) => {
    let form = null;
    switch (formName) {
      case "university-application":
        form = await JSON.parse(JSON.stringify(universityApplication));
        setForm(form);
        setModalOpen(true);
        break;
      case "university-question":
        form = await JSON.parse(JSON.stringify(universityQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "simulator-demo":
        form = await JSON.parse(JSON.stringify(simulatorApplication));
        setForm(form);
        setModalOpen(true);
        break;
      case "simulator-question":
        form = await JSON.parse(JSON.stringify(simulatorQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "teletraining-application":
        form = await JSON.parse(JSON.stringify(teletrainingApplication));
        setForm(form);
        setModalOpen(true);
        break;
      case "teletraining-question":
        form = await JSON.parse(JSON.stringify(teletrainingQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "ce-question":
        form = await JSON.parse(JSON.stringify(ceQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "bse-question":
        form = await JSON.parse(JSON.stringify(bseQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "bse-application":
        form = await JSON.parse(JSON.stringify(bseApplication));
        setForm(form);
        setModalOpen(true);
        break;
      case "essentials-question":
        form = await JSON.parse(JSON.stringify(essentialsQuestion));
        setForm(form);
        setModalOpen(true);
        break;
      case "essentials-application":
        form = await JSON.parse(JSON.stringify(essentialsApplication));
        setForm(form);
        setModalOpen(true);
        break;
      default:
        setModalOpen(false);
        break;
    }
  };

  const onComplete = async (title, data) => {
    console.log("onComplete called....");
    console.log(data);

    setIsLoading(true);
    setForm(null);

    const emailData = retrieveNamesAndValues(data);
    console.log("emailData: ", emailData);

    let response = await props.firebase
      .sendEmail(form.emails, title + " Form Submission", emailData)
      .catch(async (e) => {
        console.log("in catch block: ", e.message);
        await props.firebase.handleErrorLogging(
          "medium",
          "Unable to send Landing Form Submission email.  ",
          e.message
        );
        setErrorPresent(
          "An error occurred while attempting to contact MammaCare. Please send your inquiry to training@mammacare.org."
        );
        setIsLoading(false);
        return {
          status: "error",
          message: "Unable to send Landing Form Submission email. " + e.message,
        };
      });
    if (response.status === "success") {
      setIsLoading(false);
      setModalOpen(false);
    }
  };

  const retrieveNamesAndValues = (data) => {
    let results = "";
    console.log("formatting data: ", data);

    for (let index = 0; index < data.length; index++) {
      let label = data[index].emaillabel
        ? data[index].emaillabel
        : data[index].label;
      results += label + ": " + data[index].value + "<br />";
    }

    console.log("results: ", results);
    return results;
  };

  const containerContents = [
    {
      id: "/university/",
      category: "university",
      title: "University Courses",
      category: "university",
      courses: [
        {
          id: "/university/essentials",
          title: "Essentials of Breast Examination",
          videos: [
            // {
            //   img: distanceLearningVideo,
            //   url: "https://vimeo.com/432876140/50e143e969",
            // },
          ],
          headingImages: [{ img: comboMaroonMobile, xs: 12, width: "90%" }],
          kicker: (
            <>
              <p>
                This self-study course introduces undergraduate nursing students
                to the elements of breast examination. Breast health and
                awareness content, authored by academic and clinical experts, is
                synchronized with hands-on exercises. The online program guides
                students through the course and practice on standard MammaCareⓇ
                Teaching Breast Models. Faculty receives each student quiz and
                practice results.
              </p>
              <p>
                Student course fee: $75.00 + free shipping direct to students or
                university
              </p>
              <p style={{ marginBottom: "0px" }}>Fee includes:</p>
              <ul style={{ marginTop: "0px", marginLeft: "16px" }}>
                <li>
                  <p>
                    Online course access with breast health tutorials,
                    instructional videos, and quizzes
                  </p>
                </li>
                <li>
                  <p>
                    MammaCare
                    <sup>&reg;</sup> Teaching Breast Model synchronized with
                    online, interactive video practice tools
                  </p>
                </li>
                <li>
                  <p>Palpation pad</p>
                </li>
              </ul>
            </>
          ),
          description: (
            <>
              {/* <p>
                Introduction to Clinical Breast Exam (CBE) Standards and
                Practice is an e-learning course that introduces students’ hands
                and eyes to the elements of clinical breast examination. Each
                student receives a standard set of MammaCare® breast models
                synchronized with exercises that teach proper palpation
                techniques. Instructional videos and knowledge-based content
                guide students through the course. Instructors receive students'
                completed results.
              </p> */}
            </>
          ),
          images: [
            // { img: combo, xs: 4, height: 200 },
            // { img: ceuModels, xs: 4, height: 200 },
            { img: breakDown4_mobile, xs: 12, width: 400 },
          ],
          buttons: [
            // {
            //   btn: (
            //     <>
            //       <ColorButton
            //         variant="outlined"
            //         onClick={() => toggleModal("essentials-application")}
            //       >
            //         Get Started
            //       </ColorButton>
            //     </>
            //   ),
            //   desc: <P></P>,
            // },
            {
              btn: (
                <ColorButton
                  variant="outlined"
                  onClick={() => toggleModal("essentials-question")}
                >
                  Ask a Question
                </ColorButton>
              ),
              desc: <P></P>,
            },
          ],
        },
        {
          id: "/university/introduction",
          title:
            "Introduction to Clinical Breast Exam (CBE) Standards and Practice (Graduate Nursing and Midwifery Students)",
          videos: [
            {
              img: distanceLearningVideo,
              url: "https://vimeo.com/432876140/50e143e969",
            },
          ],
          // headingImages: [{ img: trainingSteps_mobile, xs: 12, width: "95%" }],
          headingImages: [],
          // kicker: (
          //   <>
          //     <p>
          //       <b>Enroll:</b> Direct your students to sign up with MammaCare.
          //     </p>
          //     <p>
          //       <b>Ship:</b> MammaCare ships course materials direct to
          //       students.
          //     </p>
          //     <p>
          //       <b>Train:</b> Students learn and practice in this three-hour
          //       self-paced, online course.
          //     </p>
          //   </>
          // ),
          kicker: null,
          description: (
            <>
              <p>
                Introduction to Clinical Breast Exam (CBE) Standards and
                Practice introduces students’ hands and eyes to the elements and
                techniques of proficient clinical breast examination. Each
                student receives two MammaCareⓇ Teaching Breast Models
                synchronized with content and exercises that teach proper
                palpation and search techniques.
              </p>

              <p>
                Students first learn to discriminate normal, healthy breast
                tissue from suspicious lumps using the transparent breast model
                before practicing search skills to detect small suspicious,
                tactually accurate hidden tumors in the opaque model. Online
                knowledge-based content, instructional videos and quizzes guide
                students through the course with extensive feedback. Faculty
                receive students' results.
              </p>
              <p>
                Student course fee: $125.00 + free shipping direct to students
                or university
              </p>
              <p style={{ marginBottom: "0px" }}>Fee includes:</p>
              <ul style={{ marginTop: "0px", marginLeft: "16px" }}>
                <li>
                  <p>
                    Online course access with breast health tutorials,
                    instructional videos, and quizzes
                  </p>
                </li>
                <li>
                  <p>
                    MammaCare
                    <sup>&reg;</sup> Teaching Breast Model synchronized with
                    online, interactive video practice tools
                  </p>
                </li>
                <li>
                  <p>Palpation pad</p>
                </li>
              </ul>
            </>
          ),
          feedback: [
            {
              text: "Should be core requirement for all NP and medical students.",
              name: "University of Vermont student",
            },
            {
              text: "This course was excellent! I loved the quizzes throughout to assess knowledge. I LOVED the two simulation models. It was so helpful to have a model that was translucent to practice on and one that was opaque to assess my skills. I would recommend this to every new women’s health practitioner!",
              name: "Vanderbilt University student",
            },
            {
              text: "This coupled with real bedside education really helped.",
              name: "University of San Francisco student",
            },
          ],
          images: [
            { img: combo, xs: 12, width: "90%" },
            { img: ceuModels, xs: 12, width: "90%" },
            { img: breakDown_mobile, xs: 12, width: "95%" },
          ],
          buttons: [
            // {
            //   btn: (
            //     <MobileColorButton
            //       variant="outlined"
            //       onClick={() => toggleModal("university-application")}
            //     >
            //       Get Started
            //     </MobileColorButton>
            //   ),
            // },
            {
              btn: (
                <MobileColorButton
                  variant="outlined"
                  onClick={() => toggleModal("university-question")}
                >
                  Ask a Question
                </MobileColorButton>
              ),
            },
          ],
        },
        {
          id: "/university/simulator",
          title: "MammaCare Clinical Breast Exam Simulator-Trainer",
          videos: [
            { img: dualPalpation_mobile, url: "https://vimeo.com/437984486" },
          ],
          kicker: (
            <>
              <p>
                The MammaCare CBE Simulator-Trainer is a self-administered,
                palpation training platform that produces and validates breast
                examination skills.
              </p>
            </>
          ),
          description: (
            <>
              <p>
                The CBE Simulator-Trainer is a hands-on, self-administered
                computer- guided breast exam trainer. The system produces and
                validates the essential clinical skills that are required to
                detect small suspicions tumors while decreasing false positives.
                The technology monitors exam sensitivity and specificity during
                practice on progressively more complex breast models. A digital
                “clinical instructor” guides students through the training
                modules, assessing progress and providing corrective feedback in
                real time.
              </p>

              {/* <Typography className={classes.mobileHeading}>
                Selected and Annotated Published Reports
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "90%",
                  margin: "0 auto",
                }}
              >
                <p>
                  <a
                    target="_blank"
                    href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C10&q=mammacare+clinical&btnG="
                  >
                    Clinical Breast Exam Competencies
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    href="https://www.thieme-connect.com/products/ejournals/abstract/10.1055/s-0032-1313415"
                  >
                    Abstract: Improved Clinical Breast Examination Competencies
                  </a>
                </p>

                <a
                  target="_blank"
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4477569/"
                >
                  <img src={journalMedEd} width="99%" />
                </a>
                <p>
                  An Effective Multimodal Curriculum to Teach Internal Medicine
                  Residents Evidence-Based Breast Health
                  <br />
                  <br />
                  Jennifer Corbelli, MD, MS, Rachel Bonnema, MD, MS, Doris
                  Rubio, PhD, Diane Comer, BS, and Melissa McNeil, MD, MPH
                  <br />
                  <a
                    target="_blank"
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4477569/"
                  >
                    J Grad Med Educ. 2014 Dec; 6(4): 721–725.
                  </a>
                </p>
              </div> */}
            </>
          ),
          preview: <MobileSimulatorPreview />,
          images: [{ img: newPalpations_mobile, xs: 12, width: "95%" }],
          feedback: [
            {
              text: "Getting practice on these models is MUCH more helpful than just learning about [clinical breast exam]! You never really know what “lumps” feel like until you get a chance to touch them.",
              name: "Vanderbilt student",
            },
            {
              text: "Great module/way to learn! Definitely recommend to clinicians just starting out and those of all levels to enhance CBE skills.",
              name: "University of Vermont student",
            },
            {
              text: "Love the testing program. It was super helpful in determining the difference between lumps and nodularities, as well as ensuring I was actually covering all of the breast tissue.",
              name: "University of San Francisco student",
            },
          ],
          buttons: [
            {
              btn: (
                <MobileColorButton
                  variant="outlined"
                  onClick={() => toggleModal("simulator-demo")}
                >
                  Request Hands-On Demo
                </MobileColorButton>
              ),
            },
            {
              btn: (
                <MobileColorButton
                  variant="outlined"
                  onClick={() => toggleModal("simulator-question")}
                >
                  Ask a Question
                </MobileColorButton>
              ),
            },
          ],
        },
      ],
    },
    {
      id: "/certifications/",
      category: "certifications",
      title: "Certification Courses",
      courses: [
        {
          id: "/certifications/teletraining",
          defaultOpen: false,
          title:
            "MammaCare Clinical Breast Examiner Certification via Teletraining",
          videos: [
            {
              img: cbeTeletrainingVideoPic,
              url: "https://vimeo.com/432572177",
            },
          ],
          kicker: (
            <>
              <p>
                MammaCare certification is the recognized standard for
                performing a clinical breast examination (CBE). The MammaCare
                Clinical Breast Examiner via Teletraining Course is a one-on-one
                program conducted with a live instructor.
              </p>
            </>
          ),
          description: (
            <>
              <p>
                Trainees learn to reliably detect breast lesions using
                MammaCare’s patented training technology. Training includes
                practice with surrogate patients.
              </p>
              <p>
                Graduates meet performance standards for sensitivity,
                specificity and thoroughness to earn certification as a
                MammaCare Clinical Breast Examiner.
              </p>
            </>
          ),
          images: [
            { img: katieTeletraining_mobile, xs: 12 },
            { img: breakDown3_mobile, xs: 8 },
          ],
          feedback: [
            {
              text: "The increased ease of accessing the materials online and the trainer were invaluable to me and my team. Taking time away from patient care is basically eliminated with this method",
              name: "– Certified Clinical Breast Examiner",
            },
            {
              text: "What I liked best about the course was the learning environment and the adaptability of the course offering (Teletraining works well)",
              name: "– Certified Clinical Breast Examiner",
            },
            {
              text: "The instructor was just SPECTACULAR!!! She made me feel at ease and confident. The course was so well organized with plenty of time to learn properly. I honestly loved everything about it!!",
              name: "– Certified Clinical Breast Examiner",
            },
          ],
          buttons: [
            {
              btn: (
                <MobileColorButton
                  variant="outlined"
                  onClick={() => toggleModal("teletraining-application")}
                >
                  Apply for Certification
                </MobileColorButton>
              ),
              desc: <P></P>,
            },
            {
              btn: (
                <MobileColorButton
                  variant="outlined"
                  onClick={() => toggleModal("teletraining-question")}
                >
                  Ask a Question
                </MobileColorButton>
              ),
              desc: <P></P>,
            },
          ],
        },
        {
          id: "/certifications/bse",
          defaultOpen: false,
          title: "MammaCare Breast Self-Examination Instructor",
          headingImages: [{ img: katieBSE_mobile, xs: 12, width: "95%" }],
          kicker: (
            <>
              <p>
                The new MammaCare BSE Instructor Course is conducted via
                teletraining. It is designed for women’s healthcare
                professionals who plan to teach women how best to perform
                self-exams and to conduct community workshops.
              </p>
            </>
          ),
          description: (
            <>
              <p>
                The program contains a series of online breast health tutorials,
                correct self-exam procedures, palpation exercises, and teaching
                sessions. The nine-hour course is divided into two training
                modules: self-study and a one-to-one video conference with a
                Certified MammaCare Instructor.
              </p>
              <p>
                The MammaCare BSE Instructor certificate is awarded after
                successful completion of all training requirements.
                Certification is valid for three years*. Continuing education
                contact hours are available for nurses.
              </p>
              <p>Course Fee: $1250</p>
              <p>Fee includes workshop and one-to-one teaching tools:</p>
              <ul>
                <li>MammaCare Professional Learning System (MLS-1)</li>
                <li>Six additional tactually accurate breast models</li>
                <li>
                  Online course includes breast health tutorials, quizzes, and
                  resources
                </li>
                <li>
                  BSE Workshop PowerPoint presentation, teaching cards, and
                  training materials
                </li>
                <li>MammaCare BSE Instructor certificate</li>
                <li>BSE Instructor Pin</li>
              </ul>
            </>
          ),
          images: [],
          feedback: [
            {
              text: "The training was great! I feel like I finally know how to do a self breast exam. And even better I can now teach other women to do it. This is a huge wonderful tool I’ve been given.",
              name: "– course graduate",
            },
          ],
          buttons: [
            {
              btn: (
                <MobileColorButton
                  variant="outlined"
                  onClick={() => toggleModal("bse-application")}
                >
                  Apply for Certification
                </MobileColorButton>
              ),
              desc: <P></P>,
            },
            {
              btn: (
                <MobileColorButton
                  variant="outlined"
                  onClick={() => toggleModal("bse-question")}
                >
                  Ask a Question
                </MobileColorButton>
              ),
              desc: <P></P>,
            },
          ],
        },
      ],
    },
    {
      id: "/ceu/",
      category: "ceu",
      title: "CE Courses",
      courses: [
        {
          id: "/ceu/cbe",
          title:
            "Introduction to Clinical Breast Examination Standards With Hands-On Practice",
          //headingImages: [{ img: trainingSteps_mobile, xs: 12, width: "95%" }],
          headingImages: [],
          kicker: (
            <>
              <p>
                Introduction to Clinical Breast Examination Standards with
                Hands-On Practice is a new self-study education course, which
                includes hands-on training with tactually accurate breast
                models. Course enrollees receive standardized breast models,
                learn essential breast exam skills and the basics of breast
                health, disease and methods of detection in a self-paced, online
                learning program. The National Cancer Institute (NCI) and the
                National Science Foundation (NSF) supported research that
                resulted in MammaCare, the recognized standard for teaching and
                performing clinical breast examination.
              </p>
              <p>Course Fee: $125.00 plus shipping.</p>
              <p>
                For international orders, please contact us to discuss pricing
                options. Additional fees will apply for international shipping
                and training.
              </p>
            </>
          ),
          description: (
            <></>
            // <>
            //   <p>
            //     Introduction to Clinical Breast Examination Standards with
            //     Hands-On Practice is a new self-study education course, which
            //     includes hands-on training with tactually accurate breast
            //     models. Course enrollees receive standardized breast models,
            //     learn essential breast exam skills and the basics of breast
            //     health, disease and methods of detection in a self-paced, online
            //     learning program. The National Cancer Institute (NCI) and the
            //     National Science Foundation (NSF) supported research that
            //     resulted in MammaCare, the recognized standard for teaching and
            //     performing clinical breast examination.
            //   </p>
            // </>
          ),
          images: [
            { img: combo, xs: 12, width: "90%" },
            { img: ceuModels, xs: 12, width: "90%" },
            { img: breakDown_mobile, xs: 12, width: "95%" },
          ],
          buttons: [
            {
              btn: (
                <Link
                  to="/signupalt/ceu/2020"
                  style={{ textDecoration: "none" }}
                >
                  <MobileColorButton variant="outlined">
                    Sign Up
                  </MobileColorButton>
                </Link>
              ),
              desc: <P></P>,
            },
            {
              btn: (
                <MobileColorButton
                  variant="outlined"
                  onClick={() => toggleModal("ce-question")}
                >
                  Ask a Question
                </MobileColorButton>
              ),
            },
          ],
        },
      ],
    },
    // {
    //   id: "/bcccp/",
    //   category: "bcccp",
    //   title: "BCCCP Courses",
    //   courses: [
    //     {
    //       id: "/bcccp/intro",
    //       title:
    //         "BCCCP Introduction to Clinical Breast Examination Standards With Hands-On Practice",
    //       // headingImages: [
    //       //   { img: trainingSteps, xs: 12, height: 200, width: 512 },
    //       // ],
    //       headingImages: [],
    //       kicker: (
    //         <>
    //           <p>
    //             Introduction to Clinical Breast Examination Standards with
    //             Hands-On Practice is a new self-study education course, which
    //             includes hands-on training with tactually accurate breast
    //             models. Course enrollees receive standardized breast models,
    //             learn essential breast exam skills and the basics of breast
    //             health, disease and methods of detection in a self-paced, online
    //             learning program. The National Cancer Institute (NCI) and the
    //             National Science Foundation (NSF) supported research that
    //             resulted in MammaCare, the recognized standard for teaching and
    //             performing clinical breast examination.
    //           </p>
    //         </>
    //       ),
    //       description: <></>,
    //       images: [
    //         { img: combo, xs: 4, height: 200 },
    //         { img: ceuModels, xs: 4, height: 200 },
    //         { img: breakDown2, xs: 8, width: 650 },
    //       ],
    //       buttons: [
    //         {
    //           btn: (
    //             <Link
    //               to="/signupalt/ceu/2020"
    //               style={{ textDecoration: "none" }}
    //             >
    //               <ColorButton variant="outlined">Sign Up</ColorButton>
    //             </Link>
    //           ),
    //           desc: <P></P>,
    //         },
    //         {
    //           btn: (
    //             <ColorButton
    //               variant="outlined"
    //               onClick={() => toggleModal("ce-question")}
    //             >
    //               Ask a Question
    //             </ColorButton>
    //           ),
    //           desc: <P></P>,
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];

  useEffect(() => {
    const setPageContent = () => {
      console.log(params);

      //console.log(parseInt(params[1]));
      if (params[0] !== undefined) {
        if (!isNaN(parseInt(params[1]))) {
          let category = containerContents.filter(
            (el) => el.category === params[0]
          )[0];
          let course =
            category.courses[parseInt(params[1])] || category.courses[0];
          let idString = course.id;
          selectPageContent(idString);
        } else {
          let idString = `/${params.join("/")}`;
          console.log(idString);
          selectPageContent(idString);
        }
      }
    };

    setPageContent();
  }, []);

  const selectPageContent = (id) => {
    let content = {};

    console.log(id);
    if (id === "default") {
      setCurrentComponentId("default");
      setCurrentComponent(<MobileDefaultContent />);
      props.history.replace(`/`);
      return;
    }

    props.history.replace(`/page${id}`);
    //check if a parent matches the currentComponent value
    let selectedParent = containerContents.filter((el) => el.id == id)[0];
    console.log(selectedParent);
    //check if a child matches the currentComponent value
    if (selectedParent) {
      setCurrentComponentId(id);
      setCurrentComponent(
        selectedParent.courses.map((course) => {
          return (
            <MobileCourseListing
              key={course.title}
              course={course}
              isParent={true}
              open={true}
              mobile={false}
              selectPageContent={selectPageContent}
            />
          );
        })
      );
    } else {
      containerContents.filter((category) => {
        let course = category.courses.filter((course) => course.id === id)[0];
        if (course) {
          setCurrentComponentId(id);
          setCurrentComponent(
            <MobileCourseListing
              key={course.title}
              course={course}
              isParent={false}
              open={true}
              mobile={false}
            />
          );
        }
      });
    }
  };

  return (
    <>
      <SelectionDialog
        contents={containerContents}
        selectPageContent={selectPageContent}
      />
      {currentComponent}
      <ModalForm
        open={modalOpen}
        handleClose={toggleModal}
        title={form ? form.title : null}
        loading={isLoading}
      >
        {form ? (
          <DynamicForm
            key={form.title}
            json={form.elements}
            onComplete={onComplete}
            title={form.title}
          />
        ) : errorPresent ? (
          <p>{errorPresent}</p>
        ) : (
          <div
            style={{
              minWidth: "250px",
              minHeight: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MammaSpinner loading={isLoading} message="Please wait..." />
          </div>
        )}
      </ModalForm>
    </>
  );
};

const SelectionDialog = (props) => {
  const classes = useStyles();
  const { contents, selectPageContent } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState([]);

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);
  const navigate = (id) => {
    selectPageContent(id);
    handleClose();
  };

  const handleClick = (id) => {
    if (collapseOpen.includes(id)) {
      setCollapseOpen(collapseOpen.filter((el) => el !== id));
    } else {
      setCollapseOpen([...collapseOpen, id]);
    }
  };

  return (
    <>
      <Paper
        elevation={0}
        className={classes.flatPanel}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px 10px",
        }}
        square={true}
        onClick={handleOpen}
      >
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Menu
        </Typography>
        <MenuIcon />
      </Paper>
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={handleClose}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "down" }}
      >
        <MammaAppBar elevation={0}>
          <Toolbar>
            <IconButton edge="start" onClick={handleClose}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
            <Typography variant="h6">Our Courses</Typography>
          </Toolbar>
        </MammaAppBar>
        <Toolbar />
        <List>
          {contents.map((cat) => {
            return (
              <Fragment key={cat.id}>
                <ListItem button onClick={() => navigate(cat.id)} disableRipple>
                  <ListItemText className={classes.parent}>
                    {cat.title}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => handleClick(cat.id)}>
                      {collapseOpen.includes(cat.id) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse
                  in={collapseOpen.includes(cat.id)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {cat.courses.map((course) => {
                      return (
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={() => navigate(course.id)}
                          key={course.id}
                          disableRipple
                        >
                          <ListItemText>{course.title}</ListItemText>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </Fragment>
            );
          })}
          <ListItem button onClick={() => navigate("default")}>
            <ListItemText className={classes.parent}>Taught Here</ListItemText>
          </ListItem>
        </List>
      </Dialog>
    </>
  );
};

const MobileLandingPage = withFirebase(MobileLanding);
const LandingPage = withFirebase(Landing);

export { MobileLandingPage, LandingPage };
