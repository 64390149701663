import React, { useState, useEffect } from "react";
import styles from "./videoAnalyzer.module.css";
import { VideoStatTracker } from "./videoStatTrackerClass";

class VideoAnalyzer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { videoTrackerInstance: null, dealersOverallTotal: "pizza" };
  }

  componentDidMount() {
    // console.log("PROPS STATS: ",this.props.stats)
    const vi = new VideoStatTracker(this.props.stats);
    console.log("pre state:", this.state);
    this.setState({ videoTrackerInstance: vi }, () => {
      console.log("VI Finally Set...", this.state);
    });
    this.setState({ dealersOverallTotal: Date.now() }, () => {
      console.log(this.state.dealersOverallTotal, "dealersOverallTotal1");
    });
  }

  formatTime = (time) => {
    let duration = Math.round(time);
    if (duration >= 60) {
      let minutes = Math.floor(duration / 60);
      let seconds = Math.round(duration % 60);
      return `${minutes}m ${seconds > 9 ? seconds : "0" + seconds}s`;
    } else {
      let seconds = Math.round(duration);
      return `${seconds} seconds`;
    }
  };

  formatList = (title, list) => {
    if (list.length > 0) {
      list = list.map(function (item, i) {
        if (!isNaN(item)) return <li key={i}>{Math.round(item, 0)}</li>;

        return <li key={i}>{item}</li>;
      }, this);

      return (
        <>
          <p>
            <b>{title}</b>
          </p>
          <ul>{list}</ul>
        </>
      );
    } else {
      return null;
      // return (<>
      //   <p><b>{title}</b></p>
      //   {list}
      // </>)
    }
  };

  render() {
    console.log("dealersOverallTotal", this.state.dealersOverallTotal);
    if (!this.state.videoTrackerInstance) return null;

    console.log("Time to render VI: ", this.state.videoTrackerInstance);

    let calibrations = null;

    // let calibrations = this.state.videoTrackerInstance.onProgressEvents && this.state.videoTrackerInstance.onProgressEvents.length && this.state.videoTrackerInstance.onProgressEvents.map(function(item, i) {
    // 	if(item.value > 0) {
    // 		return (
    // 			<div className={styles.graduation} style={{'color': item.color, 'width': 1 + '%'}}  key={i}>
    // 				<span>|</span>
    // 			</div>
    // 		)
    // 	}
    // }, this);

    let watchedPercent = 0;

    if (this.state.videoTrackerInstance.onProgressEvents.length > 0)
      watchedPercent = Math.round(
        (this.state.videoTrackerInstance.onProgressEvents.filter(
          (x) => x.name == "Watched"
        ).length /
          this.state.videoTrackerInstance.onProgressEvents.length) *
          100,
        2
      );

    const rawAnalysis = this.state.videoTrackerInstance.AnalyzeStats();
    const reasons = this.formatList("Reasons", rawAnalysis.reasons);

    let bars =
      this.state.videoTrackerInstance.onProgressEvents &&
      this.state.videoTrackerInstance.onProgressEvents.length &&
      this.state.videoTrackerInstance.onProgressEvents.map(function (item, i) {
        if (item.value > 0) {
          return (
            <div
              className={styles.bar}
              style={{ backgroundColor: item.color, width: 1 + "%" }}
              key={i}
            ></div>
          );
        }
      }, this);

    let uniqueLegendItems = [];

    if (this.state.videoTrackerInstance.onProgressEvents.length > 0) {
      uniqueLegendItems = [
        ...new Map(
          this.state.videoTrackerInstance.onProgressEvents.map((obj) => [
            `${obj.name}`,
            obj,
          ])
        ).values(),
      ];
    }

    let legends = [];

    if (uniqueLegendItems) {
      legends = uniqueLegendItems.map(function (item, i) {
        if (item.value > 0) {
          return (
            <div className={styles.legend} key={i}>
              <span className={styles.dot} style={{ color: item.color }}>
                ●
              </span>
              <span className={styles.label}>{item.name}</span>
            </div>
          );
        }
      }, this);
    }

    return (
      <div className={styles.multicolor_bar}>
        <div>
          <h2>Video Analysis</h2>
        </div>
        <div className={styles.scale}>
          {calibrations == "" ? "" : calibrations}
        </div>
        <div className={styles.bars}>{bars == "" ? "" : bars}</div>
        <div className={styles.legends}>{legends == "" ? "" : legends}</div>
        <div>
          <b>Total video length:</b>{" "}
          {this.formatTime(this.state.videoTrackerInstance.videoLength)}
        </div>
        <div>
          <b>Percent of video watched:</b> {watchedPercent}%
        </div>

        <div>
          <b>Analysis:</b> {rawAnalysis.errorType}
          {/* {reasons} */}
        </div>
        <div>
          {/* <table>
            <tr><th>Stat</th>
            <th>Count</th></tr>

            <tr><td>onReady</td><td>{parent.stats.onReadyCounts}</td></tr>
            <tr><td>onStart</td><td>{parent.stats.onStartCounts}</td></tr>
            <tr><td>onPlay</td><td>{parent.stats.onPlayCounts}</td></tr>
            <tr><td>onPause</td><td>{parent.stats.onPauseCounts}</td></tr>
            <tr><td>onBuffer</td><td>{parent.stats.onBufferCounts}</td></tr>
            <tr><td>onBufferEnd</td><td>{parent.stats.onBufferEndCounts}</td></tr>
            <tr><td>onSeek</td><td>{this.formatList("Skipped to Second(s)",parent.stats.onSeekEvents)}</td></tr>
            <tr><td>onError</td><td>{parent.stats.onErrorEvents.toString()}</td></tr>
          </table> */}
        </div>
      </div>
    );
  }
}

// const VideoAnalyzer = (props) => {
//   const [vt, setVT] = useState(null);

//   useEffect(() => {
//     console.log(props.stats);
//   }, [props]);

//   return <p>This is the video tracker</p>;
// };

export default VideoAnalyzer;
