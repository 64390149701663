import { useState, useEffect } from "react";

// Hook
const useKeyPress = (targetKey, direction, callback) => {
  // State for keeping track of whether key is pressed
  // const [keyPressed, setKeyPressed] = useState(false);
  // const [keyReleased, setKeyReleased] = useState(false);
  const [fireHandler, setFireHandler] = useState(false);

  // If pressed key is our target key then set to true
  // function downHandler(event) {
  //   const key = event.code;
  //   console.log(key, targetKey);
  //   if (key === targetKey) {
  //     console.log("keydown!");
  //     setFireHandler(true)
  //   }
  // }

  // function upHandler(event) {
  //   if (key === targetKey) {
  //     setKeyReleased(true);
  //   }
  // }

  const handler = (event) => {
    const key = event.code;
    if (key === targetKey) {
      setFireHandler(true);
    }
  };

  // Add event listeners
  useEffect(() => {
    if (direction === "down") document.addEventListener("keydown", handler);
    else if (direction === "up") document.addEventListener("keyup", handler);
    // console.log("hook is mounted");
    // console.log(targetKey, handler);
    // Remove event listeners on cleanup
    return () => {
      direction === "down"
        ? document.removeEventListener("keydown", handler)
        : document.removeEventListener("keyup", handler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  useEffect(() => {
    if (fireHandler) {
      callback();
      setFireHandler(false);
    }
  }, [fireHandler]);

  return null;
};

export default useKeyPress;
