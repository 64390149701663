import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#680000",
    color: theme.palette.common.white,
    border: 0,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    margin: "0 auto",
  },
  Typography: {
    padding: 0,
    fontSize: "1.25em",
  },
}));

const GradeBook = (props) => {
  console.log("GRADEBOOK PROPS: ", props);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell />
              <StyledTableCell>
                <Typography className={classes.Typography}>
                  {t("grades.activity")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography className={classes.Typography}>
                  {t("grades.grade")}
                </Typography>
              </StyledTableCell>
              {props.showCell ? <StyledTableCell /> : null}
              {props.showContext ? <StyledTableCell /> : null}
            </StyledTableRow>
          </TableHead>
          <TableBody>{props.children}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const condition = (authUser) => !!authUser;

// export default compose(
//   withEmailVerification,
//   withAuthorization(condition)
// )(GradeBook);
export default GradeBook;
