export const COORDS = [
    { midiNum: 28, x: 0, y: 0 },
    { midiNum: 29, x: 0, y: 1 },
    { midiNum: 30, x: 0, y: 2 },
    { midiNum: 31, x: 0, y: 3 },
    { midiNum: 32, x: 0, y: 4 },
    { midiNum: 33, x: 0, y: 5 },
    { midiNum: 34, x: 0, y: 6 },
    { midiNum: 35, x: 0, y: 7 },

    { midiNum: 36, x: 1, y: 0 },
    { midiNum: 37, x: 1, y: 1 },
    { midiNum: 38, x: 1, y: 2 },
    { midiNum: 39, x: 1, y: 3 },
    { midiNum: 40, x: 1, y: 4 },
    { midiNum: 41, x: 1, y: 5 },
    { midiNum: 42, x: 1, y: 6 },
    { midiNum: 43, x: 1, y: 7 },

    { midiNum: 44, x: 2, y: 0 },
    { midiNum: 45, x: 2, y: 1 },
    { midiNum: 46, x: 2, y: 2 },
    { midiNum: 47, x: 2, y: 3 },
    { midiNum: 48, x: 2, y: 4 },
    { midiNum: 49, x: 2, y: 5 },
    { midiNum: 50, x: 2, y: 6 },
    { midiNum: 51, x: 2, y: 7 },

    { midiNum: 52, x: 3, y: 0 },
    { midiNum: 53, x: 3, y: 1 },
    { midiNum: 54, x: 3, y: 2 },
    { midiNum: 55, x: 3, y: 3 },
    { midiNum: 56, x: 3, y: 4 },
    { midiNum: 57, x: 3, y: 5 },
    { midiNum: 58, x: 3, y: 6 },
    { midiNum: 59, x: 3, y: 7 },

    { midiNum: 60, x: 4, y: 0 },
    { midiNum: 61, x: 4, y: 1 },
    { midiNum: 62, x: 4, y: 2 },
    { midiNum: 63, x: 4, y: 3 },
    { midiNum: 64, x: 4, y: 4 },
    { midiNum: 65, x: 4, y: 5 },
    { midiNum: 66, x: 4, y: 6 },
    { midiNum: 67, x: 4, y: 7 },

    { midiNum: 68, x: 5, y: 0 },
    { midiNum: 69, x: 5, y: 1 },
    { midiNum: 70, x: 5, y: 2 },
    { midiNum: 71, x: 5, y: 3 },
    { midiNum: 72, x: 5, y: 4 },
    { midiNum: 73, x: 5, y: 5 },
    { midiNum: 74, x: 5, y: 6 },
    { midiNum: 75, x: 5, y: 7 },

    { midiNum: 76, x: 6, y: 0 },
    { midiNum: 77, x: 6, y: 1 },
    { midiNum: 78, x: 6, y: 2 },
    { midiNum: 79, x: 6, y: 3 },
    { midiNum: 80, x: 6, y: 4 },
    { midiNum: 81, x: 6, y: 5 },
    { midiNum: 82, x: 6, y: 6 },
    { midiNum: 83, x: 6, y: 7 },

    { midiNum: 84, x: 7, y: 0 },
    { midiNum: 85, x: 7, y: 1 },
    { midiNum: 86, x: 7, y: 2 },
    { midiNum: 87, x: 7, y: 3 },
    { midiNum: 88, x: 7, y: 4 },
    { midiNum: 89, x: 7, y: 5 },
    { midiNum: 90, x: 7, y: 6 },
    { midiNum: 91, x: 7, y: 7 },

    { midiNum: 92, x: 8, y: 0 },
    { midiNum: 93, x: 8, y: 1 },
    { midiNum: 94, x: 8, y: 2 },
    { midiNum: 95, x: 8, y: 3 },
    { midiNum: 96, x: 8, y: 4 },
    { midiNum: 97, x: 8, y: 5 },
    { midiNum: 98, x: 8, y: 6 },
    { midiNum: 99, x: 8, y: 7 },
]