import React, { Fragment } from "react";

import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const StyledTabs = withStyles({
  root: {
    width: "100%",
    backgroundColor: "#680000",
    borderRadius: "5px",
    margin: "5px 0",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    marginBottom: "5px",
    "& > div": {
      maxWidth: 100,
      width: "100%",
      backgroundColor: "#fff",
    },
  },
})((props) => (
  <Tabs
    centered={props.variant === "scrollable" ? false : true}
    {...props}
    TabIndicatorProps={{ children: <div /> }}
  />
));

const StyledTab = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(18),
    textTransform: "none",
    color: "#fff",
    fontWeight: "bold",
    marginRight: theme.spacing(1),
    opacity: 1,
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const MobileStyledTab = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(16),
    textTransform: "none",
    color: "#fff",
    fontWeight: "bold",
    margin: theme.spacing(0, 1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function CenteredTabs(props) {
  const handleChange = (event, newValue) => {
    if (props.tabs.length === 1) return;
    props.setTab(newValue);
  };

  return (
    <>
      {props.displayStuff ? (
        <StyledTabs
          value={props.tab}
          onChange={handleChange}
          variant={props.displayStuff.variant}
          scrollButtons={props.displayStuff.scrollButtons}
        >
          {props.tabs.map((tab, index) => (
            <MobileStyledTab key={index} label={tab} />
          ))}
        </StyledTabs>
      ) : (
        <StyledTabs value={props.tab} onChange={handleChange}>
          {props.tabs.map((tab, index) => (
            <StyledTab key={index} label={tab} style={{ margin: "0 auto" }} />
          ))}
        </StyledTabs>
      )}
    </>
  );
}
