import React, { Component } from "react";
import {
  Typography,
  makeStyles,
  withStyles,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Tooltip,
  Box,
} from "@material-ui/core";
//import sampleSmallTumor from "./sampleSmallTumor.png";

import missed1cm from "./Missed_Tumor_1cm.png";
import found1cm from "./Found_Tumor_1cm.png";
import found7mm from "./Found_Tumor_7mm.png";
import missed7mm from "./Missed_Tumor_7mm.png";
import found5mm from "./Found_Tumor_5mm.png";
import missed5mm from "./Missed_Tumor_5mm.png";
import found3mm from "./Found_Tumor_3mm.png";
import missed3mm from "./Missed_Tumor_3mm.png";

const FoundTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#4286f4",
    color: "white",
    margin: 0,
    width: "fit-content",
  },
}))(Tooltip);

const MissedTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#888",
    color: "white",
    margin: 0,
    width: "fit-content",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  found: {
    backgroundColor: "#4286f4",
    color: "white",
  },
  missed: {
    background: "#888",
    color: "white",
  },
}));

/*
	pretraining:{
		1: 5mm
		2: 7mm
		3: 3mm
	}
	posttraining:{
		1: 3mm
		2: 7mm
		3: 5mm
	}
	module 2:{
		1: 5mm
		2: 1cm
	}
	module 3:{
		1: 5mm
		2: 7mm
		3: 3mm
	}
	module 4:{
		1: 5mm
		2: 5mm
		3: 3mm
	}
*/

class Tumor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cell_size: 50,
      found: "#4286f4",
      missed: "#888 ",
    };
  }

  render() {
    const classes = useStyles;
    //console.log(this.props);
    const cell = this.props;

    //get correct tumor size by module and tumor id
    const sizes = {
      p2Pretraining: [
        { id: 1, size: 5 },
        { id: 2, size: 7 },
        { id: 3, size: 3 },
      ],
      p2PostTraining: [
        { id: 1, size: 3 },
        { id: 2, size: 7 },
        { id: 3, size: 5 },
      ],
      2: [
        { id: 1, size: 5 },
        { id: 2, size: 10 },
      ],
      3: [
        { id: 1, size: 5 },
        { id: 2, size: 7 },
        { id: 3, size: 3 },
      ],
      4: [
        { id: 1, size: 5 },
        { id: 2, size: 5 },
        { id: 3, size: 3 },
      ],
    };

    let tumor = null;

    const moduleTumor = sizes[this.props.moduleId.toString()];
    const size = moduleTumor[this.props.tumorId - 1].size;
    //console.log(size);
    switch (size) {
      case 10:
        tumor = <Tumor10mm found={this.props.found} />;
        break;
      case 7:
        tumor = <Tumor7mm found={this.props.found} />;
        break;
      case 5:
        tumor = <Tumor5mm found={this.props.found} />;
        break;
      case 3:
        tumor = <Tumor3mm found={this.props.found} />;
        break;
      default:
        tumor = null;
        break;
    }

    return (
      <div
        style={{
          width: "49px",
          height: "49px",
          left: `${this.state.cell_size * cell.y + 1}px`,
          top: `${this.state.cell_size * cell.x + 1}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
        }}
      >
        {this.props.found ? (
          <FoundTooltip
            title={
              <>
                <Typography variant="body1">Lump Found</Typography>
                <Typography>Size: {size}mm</Typography>
              </>
            }
            open={this.props.open}
            arrow
          >
            <Box>{tumor}</Box>
          </FoundTooltip>
        ) : (
          <MissedTooltip
            title={
              <>
                <Typography>Lump Missed</Typography>
                <Typography>Size: {size}mm</Typography>
              </>
            }
            open={this.props.open}
            arrow
          >
            <Box>{tumor}</Box>
          </MissedTooltip>
        )}
      </div>
    );
  }
}

const Tumor10mm = (props) => {
  return props.found ? <img src={found1cm} /> : <img src={missed1cm} />;
};

const Tumor7mm = (props) => {
  return props.found ? <img src={found7mm} /> : <img src={missed7mm} />;
};

const Tumor5mm = (props) => {
  return props.found ? <img src={found5mm} /> : <img src={missed5mm} />;
};

const Tumor3mm = (props) => {
  console.log("rendering 3mm tumor ", props);
  return props.found ? <img src={found3mm} /> : <img src={missed3mm} />;
};

export { Tumor3mm, Tumor5mm, Tumor7mm, Tumor10mm };

export default Tumor;
