export class VideoStatTracker {
  constructor(props) {
    if (props) {
      // instantiate from firestore doc values
      for (let p in props) {
        this[p] = props[p];
      }
    } else {
      this.onReadyCounts = 0;
      this.onStartCounts = 0;
      this.onPlayCounts = 0;
      this.onPauseCounts = 0;
      this.onBufferCounts = 0;
      this.onBufferEndCounts = 0;
      this.onSeekEvents = [];
      this.onProgressEvents = [];
      this.onErrorEvents = [];
      this.onEndCounts = 0;
      this.videoLength = 0;
    }
  }

  incOnReadyCounts(increment_by) {
    this.onReadyCounts += increment_by;
  }

  incOnStartCounts(increment_by) {
    this.onStartCounts += increment_by;
  }

  incOnPlayCounts(increment_by) {
    this.onPlayCounts += increment_by;
  }

  incOnPauseCounts(increment_by) {
    this.onPauseCounts += increment_by;
  }

  incOnBufferCounts(increment_by) {
    this.onBufferCounts += increment_by;
  }

  incOnBufferEndCounts(increment_by) {
    this.onBufferEndCounts += increment_by;
  }

  addOnSeekEvents(second) {
    this.onSeekEvents.push(Math.round(second, 0));
  }

  incOnEndCounts(increment_by, error) {
    this.onEndCounts += increment_by;
  }

  addOnErrorEvents(error) {
    this.onErrorEvents.push(error);
  }

  addOnProgressEvents(events) {
    this.onProgressEvents = [...events];
  }

  isSystemFailure = () => {
    const result = { probOfSystemFailure: 0, reasons: [] };

    // definite system failure if errors present
    if (this.onErrorEvents.length > 0) {
      result.probOfSystemFailure = 1;
      result.errorType = "System error(s) detected.";
      result.reasons.push(this.onErrorEvents.toString());
      return result;
    }

    // probability increases if there is buffering
    if (this.onBufferCounts > 0 && this.onBufferEndCounts > 1) {
      result.probOfSystemFailure += 0.3;
      result.errorType = "System error(s) detected.";
      result.reasons.push(
        `Excessive buffering (${this.onBufferCounts}) occurred`
      );
    }

    // probability increases if plays != pauses
    if (this.onPlayCounts != this.onPauseCounts) {
      result.probOfSystemFailure += 0.3;
      result.errorType = "System error(s) detected.";
      result.reasons.push(
        `Unsynchronized play (${this.onPlayCounts}) /pause (${this.onPauseCounts}) events occurred`
      );
    }

    if (result.probOfSystemFailure == 0 && result.reasons.length == 0) {
      result.reasons.push("No system errors detected.");
    }

    return result;
  };

  analzyeBehavior = () => {
    const result = { probOfHumanError: 0, probOfSystemError: 0, reasons: [] };

    const oddsOfSystemError = this.isSystemFailure();

    // quit if system error already detected
    if (oddsOfSystemError.probOfSystemFailure == 1) {
      return oddsOfSystemError;
    }
    // check user behavior in relation to some system errors
    else if (
      oddsOfSystemError.probOfSystemFailure > 0 &&
      oddsOfSystemError.reasons.length > 0
    ) {
      // check if user skips around because of system errors
      if (this.onSeekEvents.length > 0)
        result.probOfSystemError = oddsOfSystemError.probOfSystemFailure;
      result.reasons.push(...oddsOfSystemError.reasons);
      result.errorType =
        "User deviation detected, possibly the result of system errors.";
      result.reasons.push(
        `User triggered ${
          this.onSeekEvents.length
        } seek event(s) at the following second(s) ${this.onSeekEvents.toString()}`
      );
    }
    // only user behavior
    else if (this.onSeekEvents.length > 0) {
      result.errorType = "User deviation detected without known system errors.";
      result.reasons.push(
        `User triggered ${
          this.onSeekEvents.length
        } seek event(s) at the following second(s): ${this.onSeekEvents.toString()}`
      );
    } else result.errorType = "Normal behavior detected.";

    return result;
  };

  AnalyzeStats = () => {
    // const isSysFailure = this.isSystemFailure()
    const analysis = this.analzyeBehavior();

    console.log("analysis: ", analysis);

    return analysis;
  };

  toString() {
    return `Video Tracker Stats: 
      \n video length: ${this.videoLength}
      \n onReadyCounts: ${this.onReadyCounts}
      \n onStartCounts: ${this.onStartCounts}
      \n onPlayCounts: ${this.onPlayCounts}
      \n onPauseCounts: ${this.onPauseCounts}
      \n onBufferCounts: ${this.onBufferCounts}
      \n onBufferEndCounts: ${this.onBufferEndCounts}
      \n onSeekEvents: ${this.onSeekEvents}
      \n onErrorEvents: ${this.onErrorEvents}
      \n onProgressEvents: ${this.onProgressEvents}
      `;
  }
}
