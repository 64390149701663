import React, { useState, useContext } from "react";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import { useTranslation } from "react-i18next";
import i18n, { toggleLanguage, availableLanguages } from "../../i18n";
import {
  Menu,
  MenuItem,
  makeStyles,
  fade,
  ListItemIcon,
  ListItemText,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import TranslateIcon from "@material-ui/icons/Translate";

const useStyles = makeStyles((theme) => ({
  highlighted: {
    backgroundColor: `${fade(theme.palette.warning.light, 0.3)} !important`,
  },
}));

const LanguageSelector = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const authUser = useContext(AuthUserContext);
  //console.log(availableLanguages);
  //console.log(i18n);

  const [currentLng, setCurrentLng] = useState(i18n.language);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
    props.handleParentClose();
  };
  const toggle = (lng) => {
    if (authUser)
      props.firebase.user(authUser.uid).update({ preferredLng: lng });
    toggleLanguage(lng);
    handleClose();
  };

  const onChange = (e) => {
    console.log(e.target.value);
    setCurrentLng(e.target.value);
    toggleLanguage(e.target.value);
  };

  return props.component === "menu" ? (
    <>
      <MenuItem onClick={handleMenu}>
        <ListItemIcon>
          <TranslateIcon style={{ color: "#680000" }} />
        </ListItemIcon>
        <ListItemText primary={t("common.changeLanguage")} />
      </MenuItem>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
      >
        {availableLanguages.map((lng, index) => (
          <MenuItem
            onClick={() => toggle(lng)}
            key={index}
            className={lng === i18n.language ? classes.highlighted : null}
          >
            {t(`common.${lng}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : (
    <FormControl size="small" variant="outlined" style={{ width: "150px" }}>
      <InputLabel>{t("common.changeLanguage")}</InputLabel>
      <Select
        native
        label={t("common.changeLanguage")}
        value={currentLng}
        onChange={onChange}
      >
        {availableLanguages.map((lng, index) => (
          <option value={lng} key={index}>
            {t(`common.${lng}`)}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default withFirebase(LanguageSelector);
