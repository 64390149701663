import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { fade, Typography, Paper } from "@material-ui/core"
import styled from "styled-components"
import useWindowSize from "../Generic/useWindowSize"

const Message = styled.div`
  text-align: center;
  color: #680000;
  font-size: 2em;
  font-weight: bold;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  border: 2px solid #680000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`

const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  textFormControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectFormControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  outerBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `6px solid ${fade("#680000", 0.8)}`,
    width: "100%",
    height: "100%",
  },
  innerBox: {
    width: `calc(100% - 32px)`,
    height: `calc(100% - 32px)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `6px solid ${fade("#680000", 0.5)}`,
    margin: "10px",
  },
  header: {
    fontFamily: "Georgia, serif",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    marginTop: "20px",
    fontSize: "40px",
    fontFamily: "Georgia, serif",
    color: "#680000",
  },
  nameLine: {
    width: "80%",
    borderBottom: "1px solid black",
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
  },
  name: {
    fontFamily: "Georgia, serif",
    fontSize: "48px",
  },
  body: {
    fontFamily: "Georgia, serif",
  },
}))
export const GradebookCertificate = ({ name, state, licenseNum }) => {
  const classes = useStyles()
  const [windowWidth, windowHeight] = useWindowSize()
  return (
    <Paper
      elevation={2}
      square={true}
      style={{
        height: `calc(${windowHeight}px * 0.75)`,
        width: `calc(${windowHeight}px * 0.75 * 1.29)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
      }}
    >
      <div
        className={classes.column}
        style={{
          width: "90%",
          height: "90%",
          border: "5px solid #680000",
        }}
      >
        <Typography variant="h2">MammaCare&reg;</Typography>
        <Typography variant="h2" style={{ color: "#680000", margin: "100px" }}>
          Certificate of Attendance
        </Typography>
        <Typography
          variant="h4"
          style={{
            borderBottom: "2px solid black",
            width: "60%",
            textAlign: "center",
          }}
        >
          {name}
        </Typography>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "space-between",
            marginTop: "60px",
          }}
        >
          <Typography style={{ borderBottom: "1px solid black", width: "45%" }}>
            License #: <b>{licenseNum}</b>
          </Typography>
          <Typography style={{ borderBottom: "1px solid black", width: "45%" }}>
            State: <b>{state}</b>
          </Typography>
        </div>
        <Typography
          variant="h4"
          style={{
            width: "80%",
            marginTop: "80px",
            textAlign: "center",
            textDecoration: "underline",
          }}
        >
          This is only a preview. The final appearance of your certificate will
          look different.
        </Typography>
      </div>
    </Paper>
  )
}

export const GAGradebookCertificate = ({ name, creds }) => {
  const [windowWidth, windowHeight] = useWindowSize()
  const classes = useStyles()
  return (
    <Paper
      elevation={2}
      square={true}
      style={{
        height: `calc(${windowHeight}px * 0.75)`,
        width: `calc(${windowHeight}px * 0.75 * 1.29)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
      }}
    >
      <div
        className={classes.column}
        style={{
          width: "90%",
          height: "90%",
          border: "5px solid #680000",
        }}
      >
        <Typography variant="h2">MammaCare&reg;</Typography>
        <Typography variant="h2" style={{ color: "#680000", margin: "100px" }}>
          Certificate of Attendance
        </Typography>
        <Typography
          variant="h4"
          style={{
            borderBottom: "2px solid black",
            width: "60%",
            textAlign: "center",
          }}
        >
          {name}
          {creds ? `, ${creds}` : ""}
        </Typography>
        <Typography
          variant="h4"
          style={{
            width: "80%",
            marginTop: "80px",
            textAlign: "center",
            textDecoration: "underline",
          }}
        >
          This is only a preview. The final appearance of your certificate will
          look different.
        </Typography>
      </div>
    </Paper>
  )
}
