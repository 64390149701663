import React, { Fragment, useState, useMemo, useEffect } from "react";
import moment from "moment";
import useInterval from "./useInterval";

import { Typography } from "@material-ui/core";

const CountDown = ({
  duration,
  onFinish = () => {},
  format = "mm:ss",
  updateInterval = 1000,
  message,
  updateElapsedTime,
  hideTimer,
  countDownMessage,
  countDownThreshold,
  validityThreshold,
  validityAction,
}) => {
  const [interval, setInterval] = useState(updateInterval);
  const finalDate = useMemo(() => moment().add(duration, "minutes"), [
    duration,
  ]);
  const [startTime] = useState(moment());
  const [clock, setClock] = useState();
  const [countdownSeconds, setCountdownSeconds] = useState(null);
  const [remaining, setRemaining] = useState();

  const getRemainingTime = () => {
    return finalDate.diff(moment());
  };

  const getFormatedClock = (remaining) => {
    return moment(remaining).format(format);
  };

  const checkValidity = (action) => {
    //console.log(countdownSeconds <= duration * 60 - validityThreshold);
    if (countdownSeconds <= duration * 60 - validityThreshold) {
      action();
    }
  };

  const handleUpdateClock = () => {
    const remaining = getRemainingTime();
    setCountdownSeconds(parseInt(remaining / 1000 + 1));
    if (validityThreshold) {
      checkValidity(validityAction);
    }
    console.log(countdownSeconds);
    if (updateElapsedTime) {
      updateElapsedTime(moment().diff(startTime));
    }
    //console.log(remaining);
    if (remaining > 0) {
      setClock(getFormatedClock(remaining));
      setRemaining(remaining);
    } else {
      onFinish();
      setInterval();
    }
  };

  if (clock === undefined) {
    setClock(getFormatedClock(getRemainingTime() - 100));
  }

  useInterval(handleUpdateClock, interval);

  return hideTimer ? (
    countdownSeconds && countdownSeconds <= countDownThreshold ? (
      <Typography variant="h5" gutterBottom>
        {countDownMessage} in {countdownSeconds} seconds
      </Typography>
    ) : null
  ) : (
    <>
      <Typography variant="h5" gutterBottom>
        {message}
      </Typography>
      <Typography variant="h2" gutterBottom>
        {clock}
      </Typography>
    </>
  );
};

export default CountDown;
