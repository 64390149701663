import React, { Component, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Typography,
  withStyles,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  FormControl,
  Select,
  InputLabel,
  Fade,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectFormControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

const Expansion = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(Accordion);

const ExpansionSummary = withStyles((theme) => ({
  content: {
    margin: 0,
    display: "flex",
  },
}))(AccordionSummary);

const CourseInfo = (props) => {
  const classes = useStyles();
  console.log("USER INFO PROPS: ", props);
  const { avgGrade, cohort, cohortList, canAccessDropdown } = props;
  const [differentCohort, setDifferentCohort] = useState(cohort.id);

  const handleChange = (event) => {
    setDifferentCohort(event.target.value);
  };

  const onSubmit = () => {
    let selected = cohortList.filter((el) => el.id === differentCohort)[0];
    props.onSelectedDifferentCohort(selected);
  };

  const { t } = useTranslation();

  return (
    <Expansion style={{ margin: "0 auto ", width: "99%" }} square={true}>
      <ExpansionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel7bh-content"
        id="panel7bh-header"
        style={{ margin: 0 }}
      >
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          {t("grades.courseAverage")}: {avgGrade}
          {avgGrade !== "--" ? "%" : null}
        </Typography>
      </ExpansionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <List dense={true} style={{ width: "100%" }}>
            <ListItem divider>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    {t("common.courseCode")}
                  </Typography>
                }
                secondary={
                  <Typography variant="h5">{cohort.courseCode}</Typography>
                }
              />
            </ListItem>
            <ListItem divider={cohortList.length > 1 && canAccessDropdown}>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    {t("common.term")}
                  </Typography>
                }
                secondary={<Typography variant="h5">{cohort.term}</Typography>}
              />
            </ListItem>
            {cohortList.length > 1 && canAccessDropdown ? (
              <ListItem disableGutters>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={classes.selectFormControl}
                    style={{ width: "100%" }}
                  >
                    <InputLabel>{t("grades.selectDifferentCourse")}</InputLabel>
                    <Select
                      native
                      value={differentCohort}
                      onChange={handleChange}
                      label={t("grades.selectDifferentCourse")}
                      inputProps={{
                        name: "differentCohort",
                      }}
                    >
                      {cohortList.map((c, index) => (
                        <option key={index} value={c.id}>
                          {c.courseCode} {c.term}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {differentCohort !== cohort.id ? (
                    <button onClick={onSubmit}>{t("common.select")}</button>
                  ) : null}
                </div>
              </ListItem>
            ) : null}
          </List>
        </div>
      </AccordionDetails>
    </Expansion>
  );
};

export default CourseInfo;
