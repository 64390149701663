import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import "./ResultsGrid.css";
import Cell from "./Cell";
import Tumor, { Tumor3mm, Tumor5mm, Tumor7mm, Tumor10mm } from "./Tumor";
import styles from "./ResultsGrid.css";
import Sound from "react-sound";
import styled from "styled-components";
import {
  Typography,
  makeStyles,
  withStyles,
  Tooltip,
  Fab,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  legend: {
    height: "fit-content",
    marginLeft: "1%",
  },
}));

const LegendText = withStyles((theme) => ({
  root: {
    height: "fit-content",
    marginLeft: theme.spacing(1),
  },
}))(Typography);

const MammaButton = styled.button`
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-weight: bold;
  background-color: #680000;
  border-radius: 10px;
  margin: 10px auto;
  width: 125%;
  color: white;
  font-weight: bold;
`;

class ResultsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cols: 8,
      rows: 9,
      width: 400,
      height: 450,
      detectionOutput: "100% Coverage Results Grid",
      playing: Sound.status.PLAYING,
      currentAudioIndex: 0,
    };
  }

  // componentDidMount() {
  //   if (!this.props.noScroll) this.scrollToBottom();
  //   console.log(this.props);
  // }

  scrollToBottom = () => {
    console.log("scrolling...");
    this.resultsEnd.scrollIntoView({ behavior: "smooth" });
  };

  onAudioEnded = () => {
    console.log(
      "onAudioEnded: " +
        this.state.currentAudioIndex +
        1 +
        " < " +
        this.props.audio.length
    );
    if (this.state.currentAudioIndex + 1 < this.props.audio.length) {
      this.setState({
        playing: Sound.status.PLAYING,
        currentAudioIndex: this.state.currentAudioIndex + 1,
      });
    } else
      this.setState({
        playing: Sound.status.STOPPED,
        currentAudioIndex: -1,
      });
  };

  getBackgroundColor = (passed, valid, moduleId) => {
    console.log(this.props);
    console.log(passed, valid, moduleId);
    if (moduleId === "p2Pretraining") {
      return;
    }
    if (valid) {
      if (passed) {
        return "#4caf50";
      } else {
        return "#d32f2f";
      }
    } else {
      return "#f57c00";
    }
  };

  render() {
    //console.log("Rendering results grid...");
    //console.log("props palpatedCells length: "+this.props.palpatedCells.length);
    console.log(this.props);
    const myStyles = useStyles;
    var styles1 = {
      textAlign: "center",
    };

    let cells = null;
    let tumorLocations = null;

    let time = this.props.examTime;
    //console.log("TIME:  ", time);
    //console.log(typeof time);

    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time - hours * 3600) / 60);
    let seconds = Math.floor(time - hours * 3600 - minutes * 60);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    //console.log("HOURS:  ", hours);
    //console.log("MINUTES:  ", minutes);
    //console.log("SECONDS:  ", seconds);

    let flattenedCellsArray = this.props.palpatedCells.flat();

    //console.log("FLATTENED CELLS ARRAY: ", flattenedCellsArray);

    cells = flattenedCellsArray.map((cell) => {
      return (
        <Cell
          x={cell.x}
          y={cell.y}
          key={cell.midiNum}
          light={cell.light}
          medium={cell.medium}
          deep={cell.deep}
          midiNum={cell.midiNum}
        />
      );
    });

    tumorLocations = this.props.tumors.map((tumor) => {
      return (
        <Tumor
          key={[tumor.x, tumor.y]}
          x={tumor.x}
          y={tumor.y}
          found={tumor.detected}
          moduleId={this.props.moduleId}
          tumorId={tumor.id}
        />
      );
    });

    const percentCoverage = (this.props.percentCoverage * 100).toFixed(2);
    //console.log("CELLS: ",cells);
    //

    let restart = (
      <button
        disabled={this.state.examComplete == false}
        onClick={this.props.reset}
        style={{ margin: "10px", fontSize: "125%", backgroundColor: "red" }}
      >
        {this.props.t("modules.restartExam")}
      </button>
    );
    return (
      <div>
        {this.props.audio === null || this.props.audio === undefined ? null : (
          <Sound
            url={
              this.props.audio != null && this.state.currentAudioIndex !== -1
                ? this.props.audio[this.state.currentAudioIndex]
                : null
            }
            playStatus={this.state.playing}
            onFinishedPlaying={this.onAudioEnded}
          />
        )}
        <div>
          <table style={{ margin: 0 }}>
            <tbody>
              <tr>
                <th
                  colSpan={this.props.completed ? 1 : 2}
                  style={{
                    backgroundColor: this.getBackgroundColor(
                      this.props.passed,
                      this.props.isValidAttempt,
                      this.props.moduleId
                    ),
                  }}
                >
                  <Typography>
                    {this.props.moduleId === "p2Pretraining"
                      ? null
                      : this.props.passed
                      ? this.props.t("resultsGrid.passed")
                      : this.props.t("resultsGrid.failed")}
                  </Typography>
                </th>
                {this.props.completed ? (
                  <th
                    style={{
                      backgroundColor: this.getBackgroundColor(
                        this.props.passed,
                        this.props.isValidAttempt,
                        this.props.moduleId
                      ),
                    }}
                  >
                    <Typography>{this.props.completed}</Typography>
                  </th>
                ) : null}
              </tr>
              <tr>
                <td>
                  <Typography>
                    {this.props.t("resultsGrid.examTime")}
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {hours}:{minutes}:{seconds}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>
                    {this.props.t("resultsGrid.totalFalsePositives")}
                  </Typography>
                </td>
                <td>
                  <Typography>{this.props.falsePositives}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>
                    {this.props.t("resultsGrid.tissueCoverage")}{" "}
                    {percentCoverage}%
                  </Typography>
                  <div
                    className={"BlackBoard " + styles.inline}
                    style={{
                      width: this.state.width,
                      height: this.state.height,
                      backgroundSize: `${50}px ${50}px`,
                    }}
                  >
                    {cells}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: `${50 / 3}px`,
                          height: `50px`,
                          background: `#000000`,
                          border: `1px solid black`,
                        }}
                      />
                      <LegendText>
                        {this.props.t("resultsGrid.missedPalpation")}
                      </LegendText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: `${50 / 3}px`,
                          height: `50px`,
                          background: `#33cc33`,
                          border: `1px solid black`,
                        }}
                      />
                      <LegendText>
                        {this.props.t("resultsGrid.lightPressure")}
                      </LegendText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: `${50 / 3}px`,
                          height: `50px`,
                          background: `#ebf442`,
                          border: `1px solid black`,
                        }}
                      />
                      <LegendText>
                        {this.props.t("resultsGrid.mediumPressure")}
                      </LegendText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: `${50 / 3}px`,
                          height: `50px`,
                          background: `#c11707`,
                          border: `1px solid black`,
                        }}
                      />
                      <LegendText>
                        {this.props.t("resultsGrid.deepPressure")}
                      </LegendText>
                    </div>
                  </div>
                </td>
                <td>
                  <Typography>
                    {this.props.t("resultsGrid.tumorLocations")}
                  </Typography>
                  <div className="container">
                    <div
                      className={"TransparentBoard"}
                      style={{
                        width: this.state.width,
                        height: this.state.height,
                        backgroundSize: `${50}px ${50}px`,
                      }}
                    >
                      {tumorLocations}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: `${50 / 3}px`,
                            height: `50px`,
                            background: `#4286f4`,
                            border: `1px solid black`,
                          }}
                        />
                        <LegendText>
                          {this.props.t("resultsGrid.tumorFound")}
                        </LegendText>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: `${50 / 3}px`,
                            height: `50px`,
                            background: `#888 `,
                            border: `1px solid black`,
                          }}
                        />
                        <LegendText>
                          {this.props.t("resultsGrid.tumorMissed")}
                        </LegendText>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tumor3mm found={true} />
                        </div>
                        <LegendText>
                          {this.props.t("resultsGrid.threeMm")}
                        </LegendText>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tumor5mm found={true} />
                        </div>
                        <LegendText>
                          {this.props.t("resultsGrid.fiveMm")}
                        </LegendText>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tumor7mm found={true} />
                        </div>
                        <LegendText>
                          {this.props.t("resultsGrid.sevenMm")}
                        </LegendText>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tumor10mm found={true} />
                        </div>
                        <LegendText>
                          {this.props.t("resultsGrid.tenMm")}
                        </LegendText>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            this.resultsEnd = el;
          }}
        />
        {/* <div style={{ position: "relative" }}>
          <Tooltip title="Scroll to the bottom" arrow>
            <Fab
              style={{
                backgroundColor: "#680000",
                color: "#ffffff",
                float: "right",
                marginBottom: "20px",
                position: "absolute",
                right: "-100px",
              }}
              onClick={this.scrollToBottom}
            >
              <ArrowForwardIosIcon style={{ transform: "rotate(90deg)" }} />
            </Fab>
          </Tooltip>
        </div> */}
      </div>
    );
  }
}

export default withTranslation()(ResultsGrid);
