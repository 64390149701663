import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { useTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import MammaSpinner from "../Generic/MammaSpinner";
import {
  TextField,
  IconButton,
  Typography,
  withStyles,
  fade,
  makeStyles,
  InputAdornment,
  Tooltip,
  Button,
  Paper,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
} from "@material-ui/core";
import LanguageSelector from "../Generic/LanguageSelector";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { t } from "i18next";
import i18n from "../../i18n";
//import classes from "./SignUp.module.css";

const LinkText = withStyles((theme) => ({
  root: {
    width: "fit-content",
    margin: "0 auto",
    fontSize: theme.typography.pxToRem(16),
    textDecoration: "underline",
    color: "#680000",
    border: "1px solid white",
    padding: "5px",
    "&:hover": {
      color: "#fff",
      backgroundColor: fade("#680000", 0.7),
      borderRadius: "5px",
      border: "1px solid #680000",
    },
  },
}))(Typography);

const Div = styled.div`
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  margin-left: 10%;
  margin-right: 10%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: 000000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const H1 = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
  background-color: #680000;
  padding: 20px;
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const useStyles = makeStyles({
  textFormControl: {
    margin: "8px",
    minWidth: 120,
  },
  root: {
    "& > *": {
      margin: "8px",
      width: "25ch",
    },
  },
  mammaSelect: {},
});

const CustomTextField = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    "& label.Mui-focused": {
      color: "#680000",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#680000",
      },
      "&:hover fieldset": {
        borderColor: "#680000",
      },
    },
  },
}))(TextField);

const ColorRadio = withStyles({
  root: {
    "&$checked": {
      color: "#680000",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#680000",
    border: "1.5px solid #680000",
    fontWeight: 550,
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(1, 0),
    "&:hover": {
      backgroundColor: "#680000",
      color: "#ffffff",
    },
  },
}))(Button);

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      passwordOne: "",
      passwordTwo: "",
      courseCode: "",
      term: "",
      warned: false,
      error: null,
      submitted: false,
      showForm: true,
      tab: 0,
      showPassword: false,
      open: true,
      ceu: false,
      licenseType: null,
      licenseNum: "",
      state: "",
      getLicense: false,
      certified: false,
      facultyCode: "",
      phone: "",
    };
  }

  componentDidMount() {
    if (this.props.match.params.courseCode) {
      if (this.props.match.params.term) {
        this.setState({
          courseCode: this.props.match.params.courseCode,
          term: this.props.match.params.term,
          ceu: this.props.match.params.courseCode === "ceu" ? true : false,
          facultyCode: this.props.match.params.facultyCode,
        });
      } else {
        this.setState({
          courseCode: this.props.match.params.courseCode,
          ceu: this.props.match.params.courseCode === "ceu" ? true : false,
        });
      }
    }
  }

  enrollUser = async (
    firstName,
    lastName,
    email,
    domain,
    passwordOne,
    facultyCode,
    licenseType,
    licenseNum,
    phone,
    state,
    courseCode,
    userTerm,
    cohortObj,
    submitButton
  ) => {
    console.log("USER FACULTY CODE: ", facultyCode, typeof facultyCode);
    console.log(cohortObj);

    const timestamps = await this.props.firebase.getServerTimestamp();

    let emailRecipients = [];
    if (cohortObj.enrollmentEmailRecipients != null) {
      cohortObj.enrollmentEmailRecipients.forEach((rec) => {
        emailRecipients.push(rec.email);
      });
      console.log(emailRecipients);
    }

    let authUser = null;
    //store createdAt timestampsInSnapshots
    const signupDate = new Date(timestamps.firebaseTimestampDate);
    const signupYear = signupDate.getFullYear();

    const course = await this.props.firebase
      .course(cohortObj.id, cohortObj.templateId)
      .get();

    if (course.exists) {
      let extras = {};
      let isFaculty = false;
      let facCode = cohortObj.facultyCode;
      let tempFacCode = cohortObj.tempFacultyCode;

      if ((facCode || tempFacCode) && facultyCode && facultyCode !== "") {
        if (
          facultyCode &&
          facultyCode.length > 0 &&
          [facCode, tempFacCode].includes(facultyCode)
        ) {
          isFaculty = true;
          if (facultyCode === tempFacCode) {
            extras["roles"] = ["FACULTY"];
            extras["facultyFor"] = [cohortObj.id];
            extras["deactivateBy"] = timestamps.unixTimestampSeconds + 259200;
          } else {
            extras["roles"] = ["FACULTY"];
            extras["facultyFor"] = [cohortObj.id];
          }
        } else {
          this.setState({
            loading: false,
            message: this.props.t("signupPage.facultyCodeInvalid"),
            showForm: true,
            submitted: false,
          });
          return;
        }
      }

      //create a new account in firebase
      let trimmedEmail = email.trim();
      let trimmedPassword = passwordOne.trim();
      authUser = await this.props.firebase
        .doCreateUserWithEmailAndPassword(trimmedEmail, trimmedPassword)
        .catch((error) => {
          let message = "";
          console.log(error.code);
          switch (error.code) {
            case "auth/email-already-in-use":
              message = this.props.t("signupPage.emailInUse");
              break;
          }
          this.setState({
            loading: false,
            message: error.message,
            showForm: true,
            submitted: false,
          });
          submitButton.disabled = false;
          console.log("last: ", error);
        });

      console.log(authUser);

      if (authUser) {
        if (licenseType && licenseType !== "") {
          extras["licenseType"] = licenseType;
        }
        if (licenseNum && licenseNum !== "") {
          extras["licenseNum"] = licenseNum;
        }
        if (state && state !== "") {
          extras["state"] = state;
        }
        if (phone && phone !== "") {
          extras["phone"] = phone;
        }

        await this.props.firebase
          .user(authUser.user.uid)
          .set({
            approvedDomain: cohortObj.domain, //could be * or another approved domain value
            domain: domain, //this is the user's actual email address domain
            cohortHistory: [cohortObj.id],
            cohortId: cohortObj.id,
            courseCode: courseCode,
            templateId: cohortObj.templateId,
            term: userTerm,
            signupYear: signupYear,
            email: trimmedEmail,
            firstName: firstName,
            lastName: lastName,
            roles: [],
            courses: [
              {
                cohort: cohortObj.id,
                templateId: cohortObj.templateId,
                courseCode: courseCode,
                term: userTerm,
                enrolledAt: timestamps.unixTimestampSeconds,
                courseLastUpdatedAt: timestamps.unixTimestampSeconds,
                sections: [],
              },
            ],
            createdAt: timestamps.unixTimestampSeconds,
            module1VideoIndex: 0,
            module2VideoIndex: 0,
            module3VideoIndex: 0,
            module4VideoIndex: 0,
            nextActivityIndex: 0,
            isEnrolled: true,
            hasToPay: cohortObj.directPayment == true ? true : false,
            hasToShip: cohortObj.directShipment == true ? true : false,
            homeStatus: "enrolling",
            preferredLng: i18n.language,
            ...extras,
          })
          .catch((error) => {
            this.setState({
              loading: false,
              message: error.message,
              showForm: true,
              submitted: false,
            });
            submitButton.disabled = false;
          });

        let cohortRef = await this.props.firebase.getDocByIdFrom(
          "cohorts",
          cohortObj.id
        );
        let cohortDoc = await cohortRef.get();
        console.log(cohortDoc);

        if (isFaculty) {
          cohortRef.update({
            faculty: this.props.firebase.fieldValue.arrayUnion({
              email: email,
              id: authUser.user.uid,
            }),
          });
        } else {
          cohortRef.update(
            cohortDoc.data().availableSeats
              ? {
                  signUps: this.props.firebase.fieldValue.increment(1),
                  availableSeats: this.props.firebase.fieldValue.increment(-1),
                }
              : {
                  signUps: this.props.firebase.fieldValue.increment(1),
                }
          );
        }

        await this.props.firebase.writeLog("Created account.");
        await this.props.firebase.doSendEmailVerification();
        if (emailRecipients.length > 0) {
          let response = await this.props.firebase.sendResults(
            emailRecipients,
            `${email} signed up for ${cohortObj.id}`,
            "",
            authUser.user.uid
          );
        }
        this.setState({
          loading: false,
          message: (
            <VerifyEmail resend={this.props.firebase.doSendEmailVerification} />
          ),
        });
      }
    } else {
      this.setState({
        loading: false,
        message: this.props.t("signupPage.noCourseExists"),
        showForm: true,
        submitted: false,
      });
      submitButton.disabled = false;
      console.log("No such course exists!");
    }
  };

  onSubmit = async (event) => {
    console.log("calling onSubmit");
    this.setState({ loading: true, showForm: false, message: null });
    const submitButton = event.target;
    submitButton.disabled = true;

    const {
      firstName,
      lastName,
      email,
      passwordOne,
      passwordTwo,
      courseCode,
      term,
      facultyCode,
      licenseType,
      licenseNum,
      phone,
      state,
    } = this.state;

    const domain = this.getDomainFromEmail(email);

    if (passwordOne === passwordTwo) {
      if (courseCode === "ceu" && !licenseType) {
        this.setState({
          loading: false,
          message:
            "You must provide the relevant license information to enroll in this course.",
          showForm: true,
          submitted: false,
        });
      } else {
        const userTerm = term.trim().toLowerCase();
        const userCourseCode = courseCode.trim();
        const potentialCohort = domain + "_" + userCourseCode + "_" + userTerm;

        let cohorts = null;
        try {
          cohorts = await this.props.firebase.getCohortsByCourseCodeAndTerm(
            userCourseCode,
            userTerm
          );
          console.log(cohorts);
        } catch (error) {
          console.error(error);
        }

        if (!cohorts) {
          console.error(
            "Failed to fetch list of available cohorts from database."
          );
          this.setState({
            loading: false,
            message: this.props.t("signupPage.dbError"),
            showForm: true,
            submitted: false,
          });
          return;
        }

        switch (cohorts.length) {
          case 0:
            this.setState({
              loading: false,
              message: this.props.t("signupPage.noCourseMatch"),
              showForm: true,
              submitted: false,
            });
            break;
          case 1:
            console.log("Found a single matching cohort: ", cohorts[0]);
            if (cohorts[0].domain == "*") {
              //chec if there is a seat available
              if (
                this.seatAvailable(cohorts[0]) &&
                this.cohortIsActive(cohorts[0])
              )
                this.enrollUser(
                  firstName,
                  lastName,
                  email,
                  domain,
                  passwordOne,
                  facultyCode,
                  licenseType,
                  licenseNum,
                  phone,
                  state,
                  userCourseCode,
                  userTerm,
                  cohorts[0],
                  submitButton
                );
              else {
                this.setState({
                  loading: false,
                  message: !this.cohortIsActive(cohorts[0])
                    ? this.props.t("signupPage.noActiveCourseMatch")
                    : this.props.t("signupPage.courseFull"),
                  showForm: true,
                  submitted: false,
                });
                submitButton.disabled = false;
              }
            } else if (cohorts[0].id == potentialCohort) {
              if (
                this.seatAvailable(cohorts[0]) &&
                this.cohortIsActive(cohorts[0])
              )
                this.enrollUser(
                  firstName,
                  lastName,
                  email,
                  domain,
                  passwordOne,
                  facultyCode,
                  licenseType,
                  licenseNum,
                  phone,
                  state,
                  userCourseCode,
                  userTerm,
                  cohorts[0],
                  submitButton
                );
              else {
                this.setState({
                  loading: false,
                  message: !this.cohortIsActive(cohorts[0])
                    ? this.props.t("signupPage.noActiveCourseMatch")
                    : this.props.t("signupPage.courseFull"),
                  showForm: true,
                  submitted: false,
                });
                submitButton.disabled = false;
              }
            } else {
              this.setState({
                loading: false,
                message: this.props.t("signupPage.invalidEmailDomain"),
                showForm: true,
                submitted: false,
              });
              submitButton.disabled = false;
            }
            break;
          default:
            let cohortIndex = this.getCohortIndex(cohorts, potentialCohort);
            console.log(cohortIndex);

            if (cohortIndex == -1) {
              const asteriskCohortIndex = this.getCohortIndex(
                cohorts,
                "*_" + courseCode + "_" + term
              );
              if (asteriskCohortIndex !== -1) {
                if (
                  this.seatAvailable(cohorts[asteriskCohortIndex]) &&
                  this.cohortIsActive(cohorts[asteriskCohortIndex])
                ) {
                  this.enrollUser(
                    firstName,
                    lastName,
                    email,
                    domain,
                    passwordOne,
                    facultyCode,
                    licenseType,
                    licenseNum,
                    phone,
                    state,
                    userCourseCode,
                    userTerm,
                    cohorts[asteriskCohortIndex],
                    submitButton
                  );
                } else {
                  this.setState({
                    loading: false,
                    message: !this.cohortIsActive(cohorts[asteriskCohortIndex])
                      ? this.props.t("signupPage.noActiveCourseMatch")
                      : this.props.t("signupPage.courseFull"),
                    showForm: true,
                    submitted: false,
                  });
                }
              } else {
                this.setState({
                  loading: false,
                  message: this.props.t("signupPage.invalidEmailDomain"),
                  showForm: true,
                  submitted: false,
                });
                submitButton.disabled = false;
              }
            } else {
              if (
                this.seatAvailable(cohorts[cohortIndex]) &&
                this.cohortIsActive(cohorts[cohortIndex])
              ) {
                this.enrollUser(
                  firstName,
                  lastName,
                  email,
                  domain,
                  passwordOne,
                  facultyCode,
                  licenseType,
                  licenseNum,
                  phone,
                  state,
                  userCourseCode,
                  userTerm,
                  cohorts[cohortIndex],
                  submitButton
                );
              } else {
                this.setState({
                  loading: false,
                  message: !this.cohortIsActive(cohorts[cohortIndex])
                    ? this.props.t("signupPage.noActiveCourseMatch")
                    : this.props.t("signupPage.courseFull"),
                  showForm: true,
                  submitted: false,
                });
              }
            }
        }
      }
    } else {
      this.setState({
        loading: false,
        message: this.props.t("signupPage.passwordsDontMatch"),
        showForm: true,
        submitted: false,
      });
      submitButton.disabled = false;
    }
  };

  seatAvailable = (cohortObj) => {
    console.log("Checking available seats: ", cohortObj.availableSeats);
    if (cohortObj.availableSeats === undefined) return true;

    if (cohortObj.availableSeats > 0) return true;
    else return false;
  };

  cohortIsActive = (cohortObj) => {
    if (cohortObj.disabled) return false;
    return true;
  };

  getCohortIndex = (cohorts, potentialCohort) => {
    let index = cohorts.findIndex((cohort) => cohort.id == potentialCohort);
    return index;
  };

  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  onChange = (event) => {
    const field = event.target.name;
    let eventValue = event.target.value;

    if (field === "courseCode") {
      if (eventValue === "ceu") {
        this.setState({ [field]: eventValue, ceu: true });
      } else {
        this.setState({ [field]: eventValue, ceu: false });
      }
    } else if (field === "licenseType") {
      console.log(eventValue);
      let licenseType = eventValue;
      let getLicense = false;
      switch (eventValue) {
        case null:
          getLicense = false;
          break;
        case "RN":
          getLicense = true;
          break;
        case "PN":
          getLicense = true;
          break;
        case "CNP":
          getLicense = true;
          break;
        case "CNRA":
          getLicense = true;
          break;
        case "CNS":
          getLicense = true;
          break;
        case "CNM":
          getLicense = true;
          break;
        case "RT":
          getLicense = true;
          break;
        default:
          getLicense = false;
          break;
      }
      this.setState({
        [field]: licenseType,
        getLicense: getLicense,
        certified: false,
      });
    } else if (field === "facultyCode") {
      this.setState({ facultyCode: eventValue });
    } else if (
      !field.includes("Name") &&
      !field.includes("password") &&
      field !== "facultyCode"
    )
      //do not lowercase first and last name fields, passwords, or faculty code
      this.setState({ [field]: eventValue.toLowerCase() });
    else this.setState({ [field]: eventValue });
  };

  checkWarned = () => {
    this.setState({ warned: !this.state.warned });
  };

  getDomainFromEmail = (email) => {
    return email.split("@")[1];
  };

  setTab = (value) => this.setState({ tab: value });

  render() {
    const classes = useStyles;
    //console.log(classes);
    const {
      firstName,
      lastName,
      email,
      courseCode,
      term,
      passwordOne,
      passwordTwo,
      warned,
      error,
      phone,
      state,
      licenseType,
      licenseNum,
      getLicense,
    } = this.state;

    console.log(this.state);

    let isInvalid = true;
    if (getLicense) {
      isInvalid =
        passwordOne === "" ||
        passwordTwo === "" ||
        email === "" ||
        firstName === "" ||
        lastName === "" ||
        courseCode === "" ||
        term === "" ||
        state === "" ||
        licenseNum === "" ||
        !licenseType;
    } else {
      isInvalid =
        passwordOne === "" ||
        passwordTwo === "" ||
        email === "" ||
        firstName === "" ||
        lastName === "" ||
        courseCode === "" ||
        term === "" ||
        (courseCode === "ceu" && !licenseType);
    }

    let message =
      this.state.loading && !this.state.message ? (
        <MammaSpinner
          loading={this.state.loading}
          message={this.props.t("common.pleaseWait")}
        />
      ) : (
        this.state.message
      );

    return (
      <>
        <H1>{this.props.t("signupPage.signup")}</H1>
        <Div>
          <div style={{ paddingBottom: "15px", fontWeight: "bold" }}>
            {message}
          </div>
          {!this.state.submitted && this.state.showForm ? (
            <div>
              <LanguageSelector component="select" />
              {this.state.ceu ? (
                <div style={{ paddingBottom: "15px", fontWeight: "bold" }}>
                  {this.props.t("signupPage.outsideUs")}
                </div>
              ) : null}
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                  margin: "15px auto",
                }}
              >
                <CustomTextField
                  required
                  label={this.props.t("signupPage.email")}
                  variant="outlined"
                  size="small"
                  value={this.state.email}
                  onChange={this.onChange}
                  name="email"
                />
                <CustomTextField
                  required
                  label={this.props.t("signupPage.firstName")}
                  variant="outlined"
                  size="small"
                  value={this.state.firstName}
                  onChange={this.onChange}
                  name="firstName"
                />
                <CustomTextField
                  required
                  label={this.props.t("signupPage.lastName")}
                  variant="outlined"
                  size="small"
                  value={this.state.lastName}
                  onChange={this.onChange}
                  name="lastName"
                />
                <CustomTextField
                  required
                  label={this.props.t("signupPage.createPassword")}
                  variant="outlined"
                  size="small"
                  value={this.state.passwordOne}
                  onChange={this.onChange}
                  name="passwordOne"
                  type={this.state.showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            this.state.showPassword ? (
                              <>{this.props.t("signupPage.hidePassword")}</>
                            ) : (
                              <>{this.props.t("signupPage.showPassword")}</>
                            )
                          }
                        >
                          <IconButton
                            tabIndex="-1"
                            onClick={() =>
                              this.setState({
                                showPassword: !this.state.showPassword,
                              })
                            }
                            style={{ padding: 0 }}
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomTextField
                  required
                  label={this.props.t("signupPage.confirmPassword")}
                  variant="outlined"
                  size="small"
                  value={this.state.passwordTwo}
                  onChange={this.onChange}
                  name="passwordTwo"
                  type={this.state.showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            this.state.showPassword
                              ? "Hide password"
                              : "Show password"
                          }
                        >
                          <IconButton
                            tabIndex="-1"
                            onClick={() =>
                              this.setState({
                                showPassword: !this.state.showPassword,
                              })
                            }
                            style={{ padding: 0 }}
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomTextField
                  required
                  label={this.props.t("common.courseCode")}
                  variant={
                    this.props.match.params.courseCode ? "filled" : "outlined"
                  }
                  size="small"
                  value={this.state.courseCode}
                  onChange={this.onChange}
                  name="courseCode"
                  InputProps={{
                    readOnly: !!this.props.match.params.courseCode,
                  }}
                />
                <CustomTextField
                  required
                  label={this.props.t("common.term")}
                  variant={this.props.match.params.term ? "filled" : "outlined"}
                  size="small"
                  value={this.state.term}
                  onChange={this.onChange}
                  name="term"
                  InputProps={{
                    readOnly: !!this.props.match.params.term,
                  }}
                />
                {this.state.ceu ? (
                  <>
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.selectFormControl}
                    >
                      <InputLabel>
                        {this.props.t("signupPage.licenseType")}
                      </InputLabel>
                      <Select
                        native
                        value={this.state.licenseType}
                        onChange={this.onChange}
                        label={this.props.t("signupPage.licenseType")}
                        name="licenseType"
                      >
                        <option value={"--"}>
                          {this.props.t("signupPage.selectLicenseType")}
                        </option>
                        <option value={"RN"}>RN</option>
                        <option value={"PN"}>PN</option>
                        <option value={"CNP"}>CNP</option>
                        <option value={"CRNA"}>CRNA</option>
                        <option value={"CNS"}>CNS</option>
                        <option value={"CNM"}>CNM</option>
                        <option value={"RT"}>RT</option>
                      </Select>
                    </FormControl>
                    {this.state.getLicense ? (
                      <>
                        <CustomTextField
                          required
                          label={this.props.t("signupPage.phone")}
                          variant="outlined"
                          size="small"
                          value={this.state.phone}
                          onChange={this.onChange}
                          name="phone"
                          style={{ marginLeft: "5%" }}
                        />
                        <CustomTextField
                          required
                          label={this.props.t("signupPage.state")}
                          variant="outlined"
                          size="small"
                          value={this.state.state}
                          onChange={this.onChange}
                          name="state"
                          style={{ marginLeft: "5%" }}
                        />
                        <CustomTextField
                          required
                          label={this.props.t("signupPage.licenseNumber")}
                          variant="outlined"
                          size="small"
                          value={this.state.licenseNum}
                          onChange={this.onChange}
                          name="licenseNum"
                          style={{ marginLeft: "5%" }}
                        />
                      </>
                    ) : null}
                  </>
                ) : // <>
                //   {this.state.courseCode &&
                //   this.state.courseCode !== "" &&
                //   this.state.courseCode.length >= 2 ? (
                //     <CustomTextField
                //       label={this.props.t("signupPage.facultyCodeOptional")}
                //       variant={
                //         this.props.match.params.facultyCode
                //           ? "filled"
                //           : "outlined"
                //       }
                //       size="small"
                //       value={this.state.facultyCode}
                //       onChange={this.onChange}
                //       name="facultyCode"
                //       InputProps={{
                //         readOnly: !!this.props.match.params.facultyCode,
                //       }}
                //     />
                //   ) : null}
                // </>
                null}
              </div>
              <ColorButton
                variant="outlined"
                onClick={this.onSubmit}
                disabled={isInvalid}
              >
                {this.props.t("signupPage.signup")}
              </ColorButton>
            </div>
          ) : null}
        </Div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0",
          }}
        >
          <LanguageSelector component="select" />
        </div> */}
      </>
    );
  }
}

const SignUpLink = () => {
  const { t } = useTranslation();
  return <Link to="/signup">{t("signupPage.signup")}</Link>;
};

const VerifyEmail = ({ resend }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <p style={{ margin: 0 }}>{t("signupPage.verifyEmail")}</p>
      <p style={{ margin: 0 }}>{t("signupPage.or")}</p>
      <ColorButton onClick={() => resend()} style={{ width: "fit-content" }}>
        {t("signupPage.resendVerification")}
      </ColorButton>
    </div>
  );
};

const MobileSignUpPage = () => {
  const { t } = useTranslation();
  return (
    <div style={{ width: "90%", margin: "20px auto" }}>
      <Typography variant="h5">
        {t("signupPage.mobileSignUpMessage")}
      </Typography>
    </div>
  );
};

const SignUpForm = withRouter(withFirebase(withTranslation()(SignUpFormBase)));
export { SignUpForm, MobileSignUpPage };
export { SignUpLink };
