import React, { useState, useEffect } from "react";
import gridStyles from "./ManualGrid.module.css";
import ConfirmationDialog from "../Generic/ConfirmationDialog";

import MissedLumpForm from "../FormJSON/manualExamLumpsReview.json";

import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import {
  Avatar,
  fade,
  Typography,
  Button,
  withStyles,
  makeStyles,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tab,
  Tabs,
  lighten,
  Fade,
  Tooltip,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

import MammaPDF from "../MammaPDF";

const useStyles = makeStyles((theme) => ({
  tp: {
    border: "1px solid #4286f4",
    backgroundColor: "#4286f4",
    fontSize: theme.typography.pxToRem(20),
    width: "calc(100% - 62px)",
    height: "30px",
    marginBottom: "5px",
    textAlign: "center",
  },
  tpChip: {
    border: "1px solid #4286f4",
    backgroundColor: "#4286f4",
    fontSize: theme.typography.pxToRem(20),
    textAlign: "center",
    margin: theme.spacing(1),
    minWidth: "250px",
  },
  tpAvatar: {
    border: "1px solid #4286f4",
    backgroundColor: "#4286f4",
    color: "#ffffff",
  },
  fp: {
    border: "1px solid #ff0000",
    backgroundColor: "#ff0000",
    fontSize: theme.typography.pxToRem(20),
    width: "calc(100% - 62px)",
    textAlign: "center",
    height: "30px",
    marginBottom: "5px",
  },
  fpChip: {
    border: "1px solid #ff0000",
    backgroundColor: "#ff0000",
    fontSize: theme.typography.pxToRem(20),
    textAlign: "center",
    margin: theme.spacing(1),
    minWidth: "250px",
  },
  again: {
    border: "1px solid #4286f4",
    backgroundColor: lighten("#4286f4", 0.8),
    fontSize: theme.typography.pxToRem(20),
    width: "calc(100% - 62px)",
    textAlign: "center",
    height: "30px",
    marginBottom: "5px",
  },
  againChip: {
    border: "1px solid #4286f4",
    backgroundColor: lighten("#4286f4", 0.8),
    fontSize: theme.typography.pxToRem(20),
    textAlign: "center",
    margin: theme.spacing(1),
    minWidth: "250px",
  },
  missed: {
    border: "1px solid #888",
    backgroundColor: "#888",
    fontSize: theme.typography.pxToRem(20),
    textAlign: "center",
    margin: theme.spacing(1),
    minWidth: "250px",
  },
  tableCell: {
    border: "none",
  },
  tableRow: {
    borderBottom: "1px solid #ccc",
  },
  alert: {
    backgroundColor: fade(theme.palette.warning.light, 0.9),
    margin: "30px 15px",
    padding: "5px 15px",
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#680000",
    borderColor: "#680000",
    fontSize: theme.typography.pxToRem(16),
    margin: "15px auto",
    width: "fit-content",
    "&:hover": {
      backgroundColor: "#680000",
    },
  },
}))(Button);

const AntTabs = withStyles({
  root: {
    marginBottom: "20px",
  },
  indicator: {
    backgroundColor: "#680000",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    color: "black",
    opacity: 0.85,
    textTransform: "none",
    minWidth: 150,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    // marginLeft: theme.spacing(4),
    // marginRight: theme.spacing(4),
    "&:hover": {
      color: lighten("#680000", 0.1),
      opacity: 1,
    },
    "&$selected": {
      color: "#680000",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#680000",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const ManualGrid = (props) => {
  console.log("rendering ManualGrid", props);
  const classes = useStyles();
  const [selectedCell, setSelectedCell] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gridCells, setGridCells] = useState([]);
  const [detections, setDetections] = useState([]);
  const [tumors, setTumors] = useState([]);
  const [detectionEvent, setDetectionEvent] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [dialogContents, setDialogContents] = useState(null);
  const [funcToConfirm, setFuncToConfirm] = useState(null);

  useEffect(() => {
    // const setJson = () => {
    //   let json = JSON.parse(JSON.stringify(MissedLumpForm));
    //   setMissedLumpFormJson(json);
    //   json = JSON.parse(JSON.stringify(falsePositiveFormJson));
    //   setfalsePositiveFormJson(json);
    // };

    const initTumors = () => {
      //add some additional fields for stats
      let lumps = props.tumors.map((obj) => ({
        ...obj,
        totalDetections: 0,
        detected: false,
      }));
      setTumors(lumps);
    };

    const initCells = () => {
      let cells = [];
      for (let i = 0; i < 81; i++) {
        cells.push({
          id: i,
          isSelected: false,
          hasDetection: false,
        });
      }

      console.log("init cells", cells);
      setGridCells(cells);
    };

    console.log("re-running props useEffect in ManualGrid");
    //setJson();
    initCells();
    initTumors();
  }, [props]);

  useEffect(() => {
    const updateGrid = () => {
      let updatedGrid = gridCells.map((cell) => {
        return {
          ...cell,
          isSelected:
            cell.id === selectedCell && !cell.isSelected ? true : false,
        };
      });
      setGridCells(updatedGrid);
      console.log(gridCells);

      console.log("selectedCell: ", selectedCell);

      //setShowForm(selectedCell !== null ? true : false);
      if (tumors.length > 0)
        handleDetection({
          id: selectedCell,
        });
    };
    if (gridCells.length > 0) updateGrid();
  }, [selectedCell]);

  const handleCellClick = (cell) => {
    //console.log("Clicked cell: ", cell, selectedCell);
    if (selectedCell === null) {
      setSelectedCell(cell);
    } else {
      if (selectedCell === cell) {
        setSelectedCell(null);
      } else {
        setSelectedCell(cell);
      }
    }
  };

  const openDialog = (props) => {
    setFuncToConfirm(props.func);
    setDialogContents({ title: props.title, text: props.text });
    setConfirm(true);
  };
  const closeDialog = () => {
    setFuncToConfirm(null);
    setDialogContents(null);
    setConfirm(false);
  };

  const handleDetection = (detection) => {
    //console.log("New detection: ", detection);

    const detectionResult = checkTumorDetection(detection);

    //determine correct feedback

    if (detectionResult.type === "fp") {
      setDetectionEvent(detectionResult);
    } else if (detectionResult.type === "invalid") {
      alert("Invalid detection...tumors must not be configured!");
    } else {
      //check if this is the first detection
      let foundTumor = tumors.filter((tumor) => {
        return tumor.id == detectionResult.tumorIdFound;
      })[0];
      const isFirstDetection = checkFirstDetection(foundTumor);

      if (isFirstDetection) {
        foundTumor = { ...foundTumor, detected: true, totalDetections: 1 };
        let copyTumors = [...tumors];

        copyTumors[detectionResult.tumorIdFoundIndex] = foundTumor;

        //console.log("COPY TUMORS: ",copyTumors);
        setTumors(copyTumors);
        setDetectionEvent(detectionResult);
      } else {
        foundTumor = {
          ...foundTumor,
          detected: true,
          totalDetections:
            tumors[detectionResult.tumorIdFoundIndex].totalDetections + 1,
        };
        let copyTumors = [...tumors];

        copyTumors[detectionResult.tumorIdFoundIndex] = foundTumor;

        //console.log("COPY TUMORS: ", copyTumors);
        setTumors(copyTumors);
        detectionResult.type = "fa";
        setDetectionEvent(detectionResult);
      }
    }

    //console.log("Detection Result: ",detectionResult);

    const updatedDetections = [
      ...detections,
      { ...detectionResult, ...detection },
    ];
    setDetections(updatedDetections);

    let updatedCells = gridCells.map((element) =>
      element.id == selectedCell
        ? {
            ...element,
            hasDetection: true,
            detectionType: detectionResult.type,
            tumorId: detectionResult.tumorIdFound,
          }
        : element
    );
    setGridCells(updatedCells);
  };

  const checkTumorDetection = (cell) => {
    //console.log("Checking for tumor detection on cell id",cell, tumors);
    if (!tumors || tumors.length == 0) {
      console.log("No tumors set for this model.");
      return { type: "invalid", id: -1 };
    }

    //console.log("tumors, cell: ",tumors, cell);
    let tumorIdFound = -1;
    let tumorIdFoundIndex = -1;
    for (let i = 0; i < tumors.length; i++) {
      if (tumors[i].centroid.includes(cell.id)) {
        tumorIdFound = tumors[i].id;
        tumorIdFoundIndex = i;
        break;
      }
    }

    if (tumorIdFound != -1)
      return {
        type: "tp",
        tumorIdFound: tumorIdFound,
        tumorIdFoundIndex: tumorIdFoundIndex,
      };
    else return { type: "fp", tumorIdFound: null };
  };

  const checkFirstDetection = (tumor) => {
    //console.log("Checking for first tumor detection on tumor id",tumor);
    if (tumor.detected === false) return true;
    else return false;
  };

  const endExam = () => {
    //add any missed tumors to detections
    let missedTumors = [];
    //prep missed tumors if any
    for (let i = 0; i < tumors.length; i++) {
      if (tumors[i].detected == false) {
        missedTumors.push({
          ...gridCells[tumors[i].id],
          type: "missed",
        });
      }
    }

    const updatedDetections = [...detections, ...missedTumors];
    //setDetections(updatedDetections);
    props.onExamCompleted(updatedDetections, gridCells, tumors);
  };

  const grid =
    gridCells.length > 0 ? (
      gridCells.map((cell) => {
        return (
          <Cell
            key={cell.id}
            id={cell.id}
            detected={cell.hasDetection}
            handleClick={handleCellClick}
            isHighlighted={cell.isSelected}
            detectionType={cell.detectionType}
            showExamFeedback={props.showExamFeedback}
          />
        );
      })
    ) : (
      <p>No data to render grid</p>
    );

  let feedback = <div style={{ height: "30px" }}></div>;
  if (detectionEvent)
    switch (detectionEvent.type) {
      case "tp":
        feedback = <div className={classes.tp}>Good, you found a lump!</div>;
        break;
      case "fa":
        feedback = (
          <div className={classes.again}>You found this lump before!</div>
        );
        break;
      case "fp":
        feedback = <div className={classes.fp}>That is not a lump!</div>;
        break;
      default:
        feedback = <div style={{ height: "30px" }}>Continue Examining...</div>;
        break;
    }

  return (
    <>
      <MammaPDF pdf={props.pdf} />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {props.showExamFeedback ? feedback : null}
          <div className={gridStyles.Grid}>{grid}</div>

          <ColorButton
            onClick={() =>
              openDialog({
                title: "Confirm Exam Submission",
                text: `Are you sure you want to submit this exam? This will move you on to the next step and you won't be able to come back.`,
                func: () => endExam,
              })
            }
          >
            Submit the exam
          </ColorButton>

          {/* {!selectedCell ? (
            <ColorButton onClick={endExam}>End the exam</ColorButton>
          ) : (
            <ColorButton onClick={() => setSelectedCell(null)}>
              Cancel
            </ColorButton>
          )} */}
        </div>
      </div>
      {dialogContents ? (
        <ConfirmationDialog
          open={confirm}
          contents={dialogContents}
          handleClose={closeDialog}
          funcToConfirm={funcToConfirm}
        />
      ) : null}
    </>
  );
};

const ManualGridResults = (props) => {
  console.log("rendering ManualGridResults");
  console.log(props);
  const [tabValue, setTabValue] = useState(0);
  const [content, setContent] = useState(<BasicResults {...props} />);
  const [show, setShow] = useState(true);

  const classes = useStyles();

  const onChange = (e, nv) => {
    setShow(false);
    setTabValue(nv);
    setTimeout(() => {
      if (nv === 1) setContent(<AdvancedResults {...props} />);
      else setContent(<BasicResults {...props} />);

      setShow(true);
    }, 350);
  };

  return (
    <>
      <AntTabs value={tabValue} onChange={onChange}>
        <AntTab label="Basic" />
        <AntTab label="Advanced" />
      </AntTabs>
      <ScopedCssBaseline />
      <Fade in={show} timeout={350}>
        <div>{content}</div>
      </Fade>
    </>
  );
};

const BasicResults = (props) => {
  const [gridCells, setGridCells] = useState([]);
  const [detections, setDetections] = useState([]);
  const [sensitivityScore, setSensitivityScore] = useState("");
  const classes = useStyles();

  useEffect(() => {
    const updateCells = () => {
      console.log("updating data: ", props.data);

      //update detectionType on cells that rep a tumor center
      let copyGridCells = [...props.resultsGrid];
      console.log(props.tumors);
      let detected = props.tumors.filter((t) => t.detected);
      let sensitivityScore = `${
        (detected.length / props.tumors.length) * 100
      }%`;

      for (let i = 0; i < props.tumors.length; i++) {
        if (props.tumors[i].detected == false) {
          copyGridCells[props.tumors[i].id] = {
            ...copyGridCells[props.tumors[i].id],
            detectionType: "missed",
          };
        }
      }
      console.log(copyGridCells);
      setGridCells(copyGridCells);
      setSensitivityScore(sensitivityScore);
    };

    const updateDetections = () => {
      console.log("updating detections: ", props.detections);
      setDetections([...props.detections]);
    };

    updateCells();
    updateDetections();
  }, [props]);

  //console.log("# gridCells in results", gridCells.length);

  const grid = gridCells.map((cell) => {
    console.log(cell.detectionType);
    return cell.detectionType === "fp" || cell.detectionType === "fa" ? (
      <ResultsCell
        key={cell.id}
        id={cell.id}
        detected={cell.hasDetection}
        detectionType={""}
      />
    ) : (
      <ResultsCell
        key={cell.id}
        id={cell.id}
        detected={cell.hasDetection}
        detectionType={cell.detectionType}
      />
    );
  });

  const getClassName = (type) => {
    switch (type) {
      case "tp":
        return classes.tpChip;
      case "fp":
        return classes.fpChip;
      case "fa":
        return classes.againChip;
    }
  };

  const getAvatarClassName = (type) => {
    switch (type) {
      case "tp":
        return classes.tpAvatar;
      case "fp":
        return classes.fpAvatar;
      case "fa":
        return classes.againAvatar;
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div
        style={{
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={gridStyles.Grid}>{grid}</div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Typography variant="h5">
          Initial Detections: {sensitivityScore}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3 style={{ margin: 0 }}>Color Legend</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "10px",
              paddingBottom: "10px",
              alignItems: "center",
              //justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: `40px`,
                  height: "40px",
                  minWidth: "40px",
                  background: `#4286f4`,
                  border: `1px solid black`,
                  marginRight: "10px",
                }}
              />

              <p style={{ margin: 0, padding: 0 }}>Lump Found</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "20px",
              }}
            >
              <div
                style={{
                  width: `40px`,
                  height: "40px",
                  minWidth: "40px",
                  background: `#888`,
                  border: `1px solid black`,
                  marginRight: "10px",
                }}
              />

              <p style={{ margin: 0, padding: 0 }}>Lump Missed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdvancedResults = (props) => {
  console.log(props);
  const [gridCells, setGridCells] = useState([]);
  const [detections, setDetections] = useState([]);
  const [tableData, setTableData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const updateCells = () => {
      console.log("updating data: ", props.data);

      //update detectionType on cells that rep a tumer center
      let copyGridCells = [...props.resultsGrid];

      for (let i = 0; i < props.tumors.length; i++) {
        if (props.tumors[i].detected == false) {
          console.log(props.tumors[i]);
          copyGridCells[props.tumors[i].id] = {
            ...copyGridCells[props.tumors[i].id],
            detectionType: "missed",
            size: props.tumors[i].size,
            depth: props.tumors[i].depth,
          };
        }
      }

      console.log(copyGridCells);
      setGridCells(copyGridCells);
    };

    const updateDetections = () => {
      console.log("updating detections: ", props.detections);
      let tableData = props.detections.map((det) => {
        let index = props.formResults.findIndex((el) => el.id === det.id);
        if (index !== -1) {
          return { ...det, ...props.formResults[index] };
        } else {
          return { ...det, pressure: "--", size: "--", firmness: "--" };
        }
      });

      setTableData(tableData);
      setDetections([...props.detections]);
    };

    updateCells();
    updateDetections();
  }, [props]);

  console.log("# gridCells in results", gridCells);

  const grid = gridCells.map((cell) => {
    let detectionIndex = props.detections.findIndex((el) => el.id === cell.id);
    let tumor = null;
    if (props.detections[detectionIndex])
      console.log(
        (tumor =
          props.tumors[props.detections[detectionIndex].tumorIdFoundIndex])
      );

    return (
      <ResultsCell
        key={cell.id}
        id={cell.id}
        detectionIndex={detectionIndex !== -1 ? detectionIndex + 1 : null}
        detected={cell.hasDetection}
        detectionType={cell.detectionType}
        size={tumor ? tumor.size : cell.size}
        depth={tumor ? tumor.depth : cell.depth}
      />
    );
  });

  const getClassName = (type) => {
    switch (type) {
      case "tp":
        return classes.tpChip;
      case "fp":
        return classes.fpChip;
      case "fa":
        return classes.againChip;
    }
  };

  const getAvatarClassName = (type) => {
    switch (type) {
      case "tp":
        return classes.tpAvatar;
      case "fp":
        return classes.fpAvatar;
      case "fa":
        return classes.againAvatar;
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div
        style={{
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={gridStyles.Grid}>{grid}</div>
      </div>
      <div
        style={{
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <div>
          <TableContainer>
            <Table size="small" style={{ margin: 0 }}>
              <TableBody>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell} align="center">
                    Detection Order
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Size
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Hardness
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Pressure
                  </TableCell>
                </TableRow>
                {tableData.map((det, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableCell} align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {det.size}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {det.hardness || det.firmness}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {det.pressure}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "25px",
            width: "100%",
          }}
        >
          <h3>Color Legend</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px",
              alignItems: "center",
              //justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "40%",
              }}
            >
              <div
                style={{
                  width: `40px`,
                  height: "40px",
                  minWidth: "40px",
                  background: `#4286f4`,
                  border: `1px solid black`,
                  marginRight: "10px",
                }}
              />

              <p style={{ margin: 0, padding: 0 }}>Lump Found</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "20px",
                width: "50%",
              }}
            >
              <div
                style={{
                  width: `40px`,
                  height: "40px",
                  minWidth: "40px",
                  background: `${lighten("#4286f4", 0.8)}`,
                  border: `1px solid black`,
                  marginRight: "10px",
                }}
              />

              <p style={{ margin: 0, padding: 0 }}>Lump Found Again</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px",
              alignItems: "center",
              //justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "40%",
              }}
            >
              <div
                style={{
                  width: `40px`,
                  height: "40px",
                  minWidth: "40px",
                  background: `#888`,
                  border: `1px solid black`,
                  marginRight: "10px",
                }}
              />

              <p style={{ margin: 0, padding: 0 }}>Lump Missed</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "20px",
                width: "50%",
              }}
            >
              <div
                style={{
                  width: `40px`,
                  height: "40px",
                  minWidth: "40px",
                  background: "#ff0000",
                  border: `1px solid black`,
                  marginRight: "10px",
                }}
              />
              <p style={{ margin: 0, padding: 0 }}>False Positive Detection</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Cell = (props) => {
  //console.log("CELL props",props);
  let content = null;

  let bgColor = "";
  let borderColor = `1px solid ${fade("#000000", 0.05)}`;

  if (props.detected || props.isHighlighted) {
    console.log("render detection type:", props);
    switch (props.detectionType) {
      case "tp":
        bgColor = "#4286f4";
        //content = props.id;
        break;
      case "fa":
        bgColor = fade("#4286f4", 0.5);
        //content = props.id;
        break;
      case "fp":
        bgColor = "#ff0000";
        //content = props.id;
        break;
      // default:
      //   bgColor = "#4286f4";
      //   break;
    }
    //override bgColor of feedback is disabled
    if (!props.showExamFeedback) bgColor = "#4286f4";
  }

  if (props.isHighlighted) borderColor = "3px solid blue";

  return (
    <div
      style={{
        width: props.isHighlighted ? "34px" : "38px",
        height: props.isHighlighted ? "34px" : "38px",
        backgroundColor: bgColor,
        // opacity: 0.05,
        border: borderColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={props.detected ? null : () => props.handleClick(props.id)}
    >
      {content}
    </div>
  );
};

const ResultsCell = (props) => {
  //console.log(props.id + " CELL PROPS", props);
  let content = null;
  // content = props.detected ? <Tumor /> : <p>{props.id}</p>;
  content = props.detectionIndex ? (
    <Typography
      style={{
        margin: 0,
        padding: 0,
        color: "white",
        fontSize: "18px",
        fontWeight: "bold",
      }}
    >
      {props.detectionIndex}
    </Typography>
  ) : null;

  let bgColor = "";
  let borderColor = `1px solid ${fade("#000000", 0.05)}`;

  switch (props.detectionType) {
    case "tp":
      console.log("TP CELL", props.id);
      bgColor = "#4286f4";
      break;
    case "fa":
      bgColor = `${fade("#4286f4", 0.5)}`;
      break;
    case "fp":
      bgColor = "#ff0000";
      break;
    case "missed":
      bgColor = "#888";
      break;
    default:
      bgColor = "transparent";
      break;
  }

  if (props.isHighlighted && props.detectionType !== "fp")
    borderColor = "3px solid blue";
  if (props.isHighlighted && props.detectionType == "fp")
    borderColor = "3px solid white";

  return props.size ? (
    <Tooltip
      arrow
      title={
        <>
          <Typography>This is tumor is {props.size}</Typography>
        </>
      }
    >
      <div
        style={{
          width: props.isHighlighted ? "34px" : "38px",
          height: props.isHighlighted ? "34px" : "38px",
          backgroundColor: bgColor,
          border: borderColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {content}
      </div>
    </Tooltip>
  ) : (
    <div
      style={{
        width: props.isHighlighted ? "34px" : "38px",
        height: props.isHighlighted ? "34px" : "38px",
        backgroundColor: bgColor,
        border: borderColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {content}
    </div>
  );
};

export { ManualGrid, ManualGridResults };
