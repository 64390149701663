import React, { useState, useContext, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import { useTranslation } from "react-i18next";
import * as PRESSURES from "../../constants/pressures";
import { COORDS } from "../../constants/coords";
import jszip from "jszip";
import Tumor from "../ResultsGrid/Tumor.js";

import {
  Button,
  Paper,
  Slider,
  Grid,
  Chip,
  List,
  ListItem,
  Avatar,
  Typography,
  Fade,
} from "@material-ui/core";
import { withStyles, lighten, makeStyles } from "@material-ui/core/styles";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

import styles from "./ExamGrid.module.css";
import Cell from "./Cell";

import Countdown from "../Generic/countdown";
import useInterval from "../Generic/useInterval";
import useKeyPress from "../Generic/useKeyPress";
import MammaSpinner from "../Generic/MammaSpinner";
import ConfirmationDialog from "../Generic/ConfirmationDialog";

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#680000",
    color: "#ffffff",
    border: "1px solid #680000",
    "&:hover": {
      color: "white",
      backgroundColor: "#680000",
    },
  },
}))(Button);

const MammaSlider = withStyles((theme) => ({
  root: {
    color: lighten("#680000", 0.15),
    height: 4,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -4,
    marginLeft: -6,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {},
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    height: 4,
    borderRadius: 2,
  },
  mark: {
    height: 16,
    width: 2,
    marginTop: -6,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
  markLabel: {
    fontSize: theme.typography.pxToRem(16),
  },
}))(Slider);

const TimingSlider = withStyles((theme) => ({
  root: {
    height: 2,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: "currentColor",
    border: "2px solid currentColor",
    marginTop: -4,
    marginLeft: -5,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {},
  track: {
    height: 2,
    borderRadius: 1,
  },
  rail: {
    height: 4,
    borderRadius: 1,
  },
  mark: {
    height: 12,
    width: 2,
    marginTop: -4,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
  markLabel: {
    fontSize: theme.typography.pxToRem(16),
  },
}))(Slider);

const useStyles = makeStyles((theme) => ({
  tp: {
    border: "1px solid #005e05",
    backgroundColor: lighten("#005e05", 0.5),
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(0.5),
  },
  tpBanner: {
    backgroundColor: lighten("#005e05", 0.5),

    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fp: {
    border: "1px solid #9c0000",
    backgroundColor: lighten("#9c0000", 0.5),
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(0.5),
  },
  fpBanner: {
    backgroundColor: lighten("#9c0000", 0.5),
    fontSize: theme.typography.pxToRem(16),
    width: "100%",
    height: "100%",
  },
  again: {
    border: "1px solid #005e05",
    backgroundColor: lighten("#005e05", 0.8),
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(0.5),
  },
  againBanner: {
    backgroundColor: lighten("#005e05", 0.8),
    fontSize: theme.typography.pxToRem(16),
    width: "100%",
    height: "100%",
  },
  invalidBanner: {
    backgroundColor: "#ffab91",
    fontSize: theme.typography.pxToRem(16),
    width: "100%",
    height: "100%",
  },
  invalid: {
    border: "1px solid #ff5722",
    backgroundColor: "#ffab91",
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(0.5),
  },
  output: {
    width: "400px",
    height: "30px",
  },
  outputText: {
    fontSize: theme.typography.pxToRem(16),
  },
}));

const ReplayExamGrid = (props) => {
  let context = useContext(AuthUserContext);
  const classes = useStyles();
  const cols = 8;
  const rows = 9;
  const width = 400;
  const height = 450;
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [replayInterval, setReplayInterval] = useState(90);
  const [detections, setDetections] = useState([]);
  const [marks, setMarks] = useState(null);
  const [detectionOutput, setDetectionOutput] = useState(null);
  const [finalGrid, setFinalGrid] = useState(null);
  //const [currentPlayBackIteration, setCurrentPlaybackIteration] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [tumors, setTumors] = useState(null);
  const [timingMarks, setTimingMarks] = useState([
    { value: 0, label: "0.25x" },
    { value: 1, label: "0.5x" },
    { value: 2, label: "1x" },
    { value: 3, label: "2x" },
    { value: 4, label: "3x" },
  ]);
  const [timingValue, setTimingValue] = useState(2);

  let board = useRef(null);
  let palpationHistory = useRef([]);
  let activeCells = useRef([]);
  let elapsedTime = useRef(null);
  let pollingCounter = useRef(0);
  let currentPlayBackIteration = useRef(0);
  let startTime = useRef(null);
  let endTime = useRef(null);
  let currentDetectionIndex = useRef(0);
  let currentPalpation = useRef([]);

  const { t } = useTranslation();

  useInterval(
    () => {
      //console.log("rendering?");
      reRenderPalpationStream();
    },
    isPlaying ? replayInterval : null
  );

  useEffect(() => {
    //console.log("ReplayExamGrid useEffect NEW props: ", props);
    const intializeGrid = () => {
      //console.log("initializing board");
      const grid = [];
      let midiNum = 28;
      for (let x = 0; x < rows; x++) {
        grid[x] = [];
        for (let y = 0; y < cols; y++) {
          grid[x][y] = {
            x: x,
            y: y,
            active: false,
            midiNum: midiNum,
            light: false,
            medium: false,
            deep: false,
            velocity: 0,
          };
          midiNum++;
        }
      }
      board.current = [...grid];
      //console.log(board);

      const tumorLocations = props.tumors.map((tumor, index) => {
        //console.log(tumor);
        return (
          <Tumor
            key={tumor.id}
            x={tumor.x}
            y={tumor.y}
            found={tumor.detected}
            tumorId={tumor.id}
            moduleId={props.moduleId}
          />
        );
      });
      setTumors(tumorLocations);
    };
    const downloadPalpationStream = () => {
      setLoading(true);
      if (!props.examPath) {
        alert("Replay file not available!");
        return;
      }

      //console.log("Download exam.json");

      let childRef = props.firebase.storage.ref(props.examPath);

      childRef.getDownloadURL().then((url) => {
        fetch(url)
          .then((response) => response.blob())
          .then((file) => {
            //console.log(file);
            let zip = new jszip();
            zip.loadAsync(file).then((extracted) => {
              //console.log(extracted);
              extracted
                .file("exam.json")
                .async("string")
                .then((str) => {
                  // jsonData is parsed json object received from url
                  let json = JSON.parse(str);
                  //console.log("parsing json palpation history");
                  //console.log(json);

                  if (json.current) palpationHistory.current = json.current;
                  //handles noFeedback useRef structure
                  else if (json.palpationStream)
                    palpationHistory.current = json.palpationStream;
                  else palpationHistory.current = json; //allows us to replay old exams prior to adding detections

                  if (json.detections) {
                    setDetections(json.detections);
                    let marks = json.detections.map((el) => {
                      return {
                        label: el.type.toUpperCase(),
                        value: Math.floor(
                          (el.pollingPosition /
                            (palpationHistory.current.length / 72)) *
                            100
                        ),
                      };
                    });
                    //console.log(json.detections);
                    //console.log(marks);
                    setMarks(marks);
                  }

                  // console.log(
                  //   "PALPATION HISTORY LENGTH ",
                  //   palpationHistory.current.length
                  // );

                  //currentPlayBackIteration.current = 0;
                  pollingCounter.current = palpationHistory.current.length / 72;
                  //console.log("POLLING COUNTER ", pollingCounter.current);

                  startTime.current = new Date().getTime();
                  //console.log("START TIME:", startTime.current);
                  //setReplayInterval(360);
                  setLoading(false);
                  setIsPlaying(true);
                })
                .catch((error) => {
                  // handle your errors here
                  //console.error(error);
                });
            });
          });
      });
    };
    intializeGrid();
    downloadPalpationStream();
  }, [props]);

  useEffect(() => {
    const updateCounter = () => {
      //   currentPlayBackIteration.current += 1;
      //console.log(currentPlayBackIteration + 1);
      //setCurrentPlaybackIteration(currentPlayBackIteration + 1);
      currentPlayBackIteration.current = currentPlayBackIteration.current + 1;
    };
    if (finalGrid) updateCounter();
  }, [finalGrid]);

  useEffect(() => {
    const updateSlider = () => {
      //console.log("updating slider");
      setSliderValue(
        (currentPlayBackIteration.current / pollingCounter.current) * 100
      );
    };
    updateSlider();
  }, [currentPlayBackIteration.current]);

  // const startReplay = () => {
  //   this.playbackInterval = setInterval(
  //     this.reRenderPalpationStream.bind(this),
  //     90
  //   );
  // };

  const stopReplay = () => {
    //console.log("clearing interval");
    //currentPlayBackIteration.current = 0;
    //setCurrentPlaybackIteration(0);
    currentPlayBackIteration.current = currentPlayBackIteration.current + 1;
    //clearInterval(this.playbackInterval);
    setIsPlaying(false);
  };

  const reRenderPalpationStream = () => {
    //console.log("ReRendering palpation stream .");
    if (currentPlayBackIteration.current >= pollingCounter.current) {
      endTime.current = new Date().getTime();
      //   console.log("END TIME: ", this.endTime);
      //   console.log("EXECUTION:", (this.endTime - this.startTime) / 1000);
      stopReplay();
      return;
    }

    //rebuild board state
    for (
      let i = 72 * currentPlayBackIteration.current;
      i < 72 * currentPlayBackIteration.current + 72;
      i++
    ) {
      let cell = palpationHistory.current[i];
      cell.maxPalpationVelocity = cell.velocity;
      board.current[cell.x][cell.y] = cell;
    }

    //get all active cells within the board for rendering
    let cells = [];
    for (let x = 0; x < rows; x++) {
      for (let y = 0; y < cols; y++) {
        if (board.current[x][y].active) {
          cells.push({
            active: board.current[x][y].active,
            midiNum: board.current[x][y].midiNum,
            x: x,
            y: y,
            light: board.current[x][y].light,
            medium: board.current[x][y].medium,
            deep: board.current[x][y].deep,
            velocity: board.current[x][y].velocity,
            maxPalpationVelocity: board.current[x][y].maxPalpationVelocity,
          });
        }
      }
    }

    // if (
    //   cells.filter((el) => el.velocity >= PRESSURES.MEDPRESSUREMIN).length === 0
    // ) {
    //   props.pressureUpdated(currentPalpation.current);

    //   // if (this.currentPalpation.length > 0) {
    //   //   const deepCells = this.currentPalpation.filter(
    //   //     (c) => c.velocity >= PRESSURES.DEEPPRESSUREMIN
    //   //   );
    //   //   console.log("deepCells: ", deepCells);

    //   //   // v2 calc center of cluster
    //   //   let xSum = Object.entries(deepCells).reduce(function (total, props) {
    //   //     //console.log("props", props[1].x)
    //   //     return total + props[1].x;
    //   //   }, 0);

    //   //   let ySum = Object.entries(deepCells).reduce(function (total, props) {
    //   //     //console.log("props", props[1].x)
    //   //     return total + props[1].y;
    //   //   }, 0);

    //   //   console.log("xSum: ", xSum, " ySum: ", ySum);
    //   //   if (deepCells.length > 0) {
    //   //     //invalid palpation otherwise
    //   //     const xCenter = xSum == 0 ? 0 : xSum / deepCells.length;
    //   //     const yCenter = ySum == 0 ? 0 : ySum / deepCells.length;
    //   //     const centroid = {
    //   //       x: xCenter,
    //   //       y: yCenter,
    //   //       text: this.state.centroids.length + 1,
    //   //     };
    //   //     console.log("Centroid: ", centroid);
    //   //     this.setState([...this.state.centroids, centroid]);
    //   //     this.setState((state) => {
    //   //       const centroids = [...state.centroids, centroid];

    //   //       return {
    //   //         centroids,
    //   //       };
    //   //     });
    //   //   }
    //   // }
    //   currentPalpation.current = [];
    // } else {
    //   console.log("in else in ReplayExamGrid: ", currentPalpation.current);
    //   currentPalpation.current = [...currentPalpation.current, ...cells].reduce(
    //     (newArray, item) => {
    //       let index = newArray.findIndex((el) => el.midiNum === item.midiNum);
    //       if (index !== -1) {
    //         if (newArray[index].velocity < item.velocity) {
    //           newArray[index].velocity = item.velocity;
    //           return newArray;
    //         } else {
    //           return newArray;
    //         }
    //       } else {
    //         return [...newArray, item];
    //       }
    //     },
    //     []
    //   );
    // }

    activeCells.current = cells;

    let boardStyles = styles.BlackBoard;

    let grid = null;
    grid = (
      <div className={styles.p1Grid}>
        <div
          key={Date.now()}
          className={styles.TransparentBoard}
          style={{
            width: width,
            height: height,
            backgroundSize: `${50}px ${50}px`,
          }}
        >
          {tumors}
          {cells.map((cell, index) => (
            <Cell key={index} cell={cell} />
          ))}
        </div>
      </div>
    );

    // console.log(
    //   detections.filter(
    //     (el) => el.pollingPosition === currentPlayBackIteration.current
    //   ).length
    // );
    if (
      detections.filter(
        (el) => el.pollingPosition === currentPlayBackIteration.current
      ).length >= 1
    ) {
      let index = detections.findIndex(
        (el) => el.pollingPosition === currentPlayBackIteration.current
      );
      //console.log("TIME TO RENDER DETECTION", detections[index]);
      let output = "";
      switch (detections[index].type) {
        case "fp":
          //output = "That is not a lump!";
          output = (
            <div className={classes.fpBanner}>
              <Typography variant="h6">{t("modules.notALump")}</Typography>
            </div>
          );
          break;
        case "tp":
          //output = "Good, you found a lump!";
          output = (
            <div className={classes.tpBanner}>
              <Typography variant="h6">{t("modules.foundLump")}</Typography>
            </div>
          );
          break;
        case "again":
          //output = "You found this lump before!";
          output = (
            <div className={classes.againBanner}>
              <Typography variant="h6">{t("modules.foundBefore")}</Typography>
            </div>
          );
          break;
        case "iv":
          //output = "You found this lump before!";
          output = (
            <div className={classes.invalidBanner}>
              <Typography variant="h6">
                {t("modules.invalidDetection")}
              </Typography>
            </div>
          );
          break;
        default:
          // output =
          //   "Unknown detection type: " +
          //   detections[currentDetectionIndex.current].type;
          output = (
            <div>
              {t("replayExam.unknownDetectionType")}: {detections[index].type}
            </div>
          );
          break;
      }
      setDetectionOutput(output);
      currentDetectionIndex.current = currentDetectionIndex.current + 1;
      //console.log(currentDetectionIndex.current);
      resetDetectionOutput();
    }
    setFinalGrid(grid);
  };

  const resetDetectionOutput = () => {
    setTimeout(() => {
      setDetectionOutput(null);
    }, replayInterval * 25);
  };

  const handleSliderChange = (event, value) => {
    setIsPlaying(false);
    setSliderValue(value);
  };

  const handleTimingSliderChange = (event, value) => {
    setIsPlaying(false);
    setTimingValue(value);
  };

  const handleChange = (event, value) => {
    currentPlayBackIteration.current = Math.floor(
      pollingCounter.current * (value / 100)
    );
    //console.log(pollingCounter.current * (value / 100));

    // setCurrentPlaybackIteration(
    //   Math.floor(pollingCounter.current * (value / 100))
    // );
    setIsPlaying(true);
  };

  const handleTimingChange = (event, value) => {
    let interval = 90;
    switch (value) {
      case 0:
        interval = 360;
        break;
      case 1:
        interval = 180;
        break;
      case 2:
        interval = 90;
        break;
      case 3:
        interval = 45;
        break;
      case 4:
        interval = 30;
        break;
      default:
        interval = 90;
        break;
    }
    setReplayInterval(interval);
    setIsPlaying(true);
  };

  const updateCurrentPlaybackIteration = (val) => {
    currentPlayBackIteration.current = val - 10;
  };

  const getChipLabel = (type) => {
    switch (type) {
      case "tp":
        return t("replayExam.truePositive");
      case "fp":
        return t("replayExam.falsePositive");
      case "again":
        return t("replayExam.repeatDetection");
      case "iv":
        return t("replayExam.invalidDetection");
    }
  };

  const getClassName = (type) => {
    switch (type) {
      case "tp":
        return classes.tp;
      case "fp":
        return classes.fp;
      case "again":
        return classes.again;
      case "iv":
        return classes.invalid;
    }
  };

  return (
    <>
      {/* <h2>Replay Exam</h2> */}

      {loading ? (
        <MammaSpinner loading={loading} />
      ) : (
        <>
          {props.statistics && props.tumorDisplay ? (
            <Grid container spacing={3}>
              <Grid item container justify="center" xs={3}>
                {props.statistics}
              </Grid>
              <Grid item container justify="center" xs={6}>
                <div className={classes.output}>
                  <Fade in={!!detectionOutput} timeout={150}>
                    <div>{detectionOutput}</div>
                  </Fade>
                </div>
                {finalGrid}
              </Grid>
              <Grid item container justify="center" xs={3}>
                {props.tumorDisplay}
              </Grid>
            </Grid>
          ) : (
            <>
              {detectionOutput}
              {finalGrid}
            </>
          )}
          <div style={{ margin: "15px" }}>
            <ColorButton onClick={() => setIsPlaying(!isPlaying)}>
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </ColorButton>
          </div>

          <div
            style={{
              width: "30%",
              margin: "30px auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography>Playback Speed</Typography>
            <TimingSlider
              track={false}
              min={0}
              max={4}
              value={timingValue}
              step={null}
              valueLabelDisplay="off"
              marks={timingMarks}
              onChange={handleTimingSliderChange}
              onChangeCommitted={handleTimingChange}
            />
          </div>

          <div style={{ margin: "15px 45px" }}>
            <MammaSlider
              marks={marks}
              value={sliderValue}
              onChange={handleSliderChange}
              onChangeCommitted={handleChange}
            />
          </div>
          <Paper elevation={0}>
            <Typography variant="h6">
              {t("replayExam.detectionEvents")}
            </Typography>

            <Paper
              elevation={0}
              component="ul"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                margin: "15px",
              }}
            >
              {detections.map((el, index) => (
                <li key={index}>
                  <Chip
                    key={index}
                    label={getChipLabel(el.type)}
                    className={getClassName(el.type)}
                    avatar={
                      <Avatar>
                        {el.type === "again" ? "A" : el.type.toUpperCase()}
                      </Avatar>
                    }
                    onClick={() =>
                      updateCurrentPlaybackIteration(el.pollingPosition)
                    }
                  />
                </li>
              ))}
            </Paper>
          </Paper>
        </>
      )}
    </>
  );
};

export default withFirebase(ReplayExamGrid);
