import React, { useContext, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import { withAuthentication, AuthUserContext } from "../Session";
import i18n, { toggleLanguage } from "../../i18n";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Nav, MobileNav } from "../Navigation/index";
import { LandingPage, MobileLandingPage } from "../Landing";
import { SignUpForm, MobileSignUpPage } from "../SignUp";
import AltSignUpPage from "../SignUpAlt";
import { SignInPage, MobileSignInPage } from "../SignIn";

import MammaSpinner from "../Generic/MammaSpinner";
//const MammaSpinner = lazy(() => import("../Generic/MammaSpinner"));

//import PasswordForgetPage from "../PasswordForget";
const PasswordForgetPage = lazy(() => import("../PasswordForget"));

//import HomePage from "../Home";
const HomePage = lazy(() => import("../Home"));

//import AccountPage from "../Account";
const AccountPage = lazy(() => import("../Account"));

//import AdminPage from "../Admin";
const AdminPage = lazy(() => import("../Admin"));

//import ReportPage from "../Report";
const ReportPage = lazy(() => import("../Report"));

//import PerformanceReportPage from "../PerformanceReport";
const PerformanceReportPage = lazy(() => import("../PerformanceReport"));

//import PretrainingModule from "../PretrainingModule";
const PretrainingModule = lazy(() => import("../PretrainingModule"));

//import PostTrainingModule from "../PostTrainingModule";
const PostTrainingModule = lazy(() => import("../PostTrainingModule"));

//import Module1 from "../Module1";
const Module1 = lazy(() => import("../Module1"));

//import Module2 from "../Module2";
const Module2 = lazy(() => import("../Module2"));

//import Module3 from "../Module3";
const Module3 = lazy(() => import("../Module3"));

//import Module4 from "../Module4";
const Module4 = lazy(() => import("../Module4"));

//import ExamPage from "../ExamPage";
const ExamPage = lazy(() => import("../ExamPage"));

//import ViewModule2Results from "../ViewModule2Results";
const ViewModule2Results = lazy(() => import("../ViewModule2Results"));

//import ViewModule3Results from "../ViewModule3Results";
const ViewModule3Results = lazy(() => import("../ViewModule3Results"));

//import ViewModule4Results from "../ViewModule4Results";
const ViewModule4Results = lazy(() => import("../ViewModule4Results"));

//import ModuleErrorsPage from "../ModuleErrors";
const ModuleErrorsPage = lazy(() => import("../ModuleErrors"));

//import CourseActivities from "../CourseActivities/courseActivities";
const CourseActivities = lazy(() =>
  import("../CourseActivities/courseActivities")
);

//import ShowActivity from "../CourseActivities/showActivity";
const ShowActivity = lazy(() => import("../CourseActivities/showActivity"));

//import EditActivity from "../CourseActivities/editActivity";
const EditActivity = lazy(() => import("../CourseActivities/editActivity"));

//import CourseResources from "../CourseResources/courseResources";
const CourseResources = lazy(() =>
  import("../CourseResources/courseResources")
);

//import ShowResource from "../CourseResources/showResource";
const ShowResource = lazy(() => import("../CourseResources/showResource"));

//import EditResource from "../CourseResources/editResource";
const EditResource = lazy(() => import("../CourseResources/editResource"));

//import MyResources from "../CourseResources/myResources";
const MyResources = lazy(() => import("../CourseResources/myResources"));

//import CourseTemplates from "../CourseTemplates/courseTemplates";
const CourseTemplates = lazy(() =>
  import("../CourseTemplates/courseTemplates")
);

//import ShowCourseTemplate from "../CourseTemplates/showCourseTemplate";
const ShowCourseTemplate = lazy(() =>
  import("../CourseTemplates/showCourseTemplate")
);

//import CoursePreview from "../CoursePreview/coursePreview";
const CoursePreview = lazy(() => import("../CoursePreview/coursePreview"));

//import Grades from "../Grades";
const Grades = lazy(() => import("../Grades"));

//import RawOutput from "../RawOutput/RawOutput";
const RawOutput = lazy(() => import("../RawOutput/RawOutput"));

//import ManageUsers from "../ManageUsers";
const ManageUsers = lazy(() => import("../ManageUsers"));

//import EditUser from "../ManageUsers/editUser";
const EditUser = lazy(() => import("../ManageUsers/editUser"));

//import ManageCohorts from "../ManageCohorts";
const ManageCohorts = lazy(() => import("../ManageCohorts"));

//import MyCohorts from "../MyCohorts";
const MyCohorts = lazy(() => import("../MyCohorts"));

//import ExportReport from "../ExportReport";
const ExportReport = lazy(() => import("../ExportReport"));

//import ContactForm from "../ContactForm/ContactForm";
const ContactForm = lazy(() => import("../ContactForm/ContactForm"));

//import ReplayExamPage from "../ReplayExamPage";
const ReplayExamPage = lazy(() => import("../ReplayExamPage"));

//import DocEditorPage from "../DocEditor";

//import ManualGridPage from "../ManualGridPage";
const ManualGridPage = lazy(() => import("../ManualGridPage"));

const PracticeExamModule = lazy(() => import("../PracticeExam"));

const AnalyticsPage = lazy(() => import("../Analytics"));

const TeletrainingPage = lazy(() => import("../TeletrainingLaunchPoint"));

const RatingsPage = lazy(() => import("../Rating"));

const QRModelScannerPage = lazy(() => import("../QRModelScanner"));
const QRGeneratorPage = lazy(() => import("../QRGenerator"));
const ModelCheckIn = lazy(() => import("../ModelCheckIn"));
const CertificatePreview = lazy(() =>
  import("../MammaCareCertificate/CertificatePreview")
);
const GACertificatePreview = lazy(() =>
  import("../MammaCareCertificate/GACertificatePreview")
);

const LicenseManager = lazy(() => import("../LicenseManager"));

const App = () => {
  const authUser = useContext(AuthUserContext);
  console.log("authUser: ", authUser);
  const mobile = useMediaQuery("(max-width: 500px)");
  const ipadMobile = useMediaQuery("(max-width: 1024px)");
  const history = useHistory();
  console.log("history: ", history);

  useEffect(() => {
    //console.log("APP.js CONTEXT: ", authUser);

    const initLanguage = () => {
      if (authUser.preferredLng && authUser.preferredLng !== i18n.language) {
        toggleLanguage(authUser.preferredLng);
      }
    };

    if (authUser) initLanguage();
  }, [authUser]);

  return (
    <Router>
      <Suspense fallback={<MammaSpinner />}>
        <>
          {mobile ? <MobileNav /> : <Nav />}

          <Switch>
            <Route
              exact
              path={ROUTES.LANDING}
              component={mobile ? MobileLandingPage : LandingPage}
            />
            {/* <Route
              path={ROUTES.SIGN_UP}
              component={mobile ? MobileSignUpPage : SignUpForm}
            />
            <Route path={ROUTES.SIGN_UP_ALT} component={AltSignUpPage} /> */}
            <Route
              path={ROUTES.SIGN_IN}
              component={mobile ? MobileSignInPage : SignInPage}
            />
            {/* <Route
              path={ROUTES.PASSWORD_FORGET}
              component={PasswordForgetPage}
            /> */}
            <Route path={ROUTES.HOME} component={HomePage} />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
            <Route path={ROUTES.REPORT} component={ReportPage} />
            <Route
              path={ROUTES.PRETRAININGMODULE}
              component={PretrainingModule}
            />
            <Route
              path={ROUTES.POSTTRAININGMODULE}
              component={PostTrainingModule}
            />
            <Route path={ROUTES.MODULE1} component={Module1} />
            <Route path={ROUTES.MODULE2} component={Module2} />
            <Route path={ROUTES.MODULE3} component={Module3} />
            <Route path={ROUTES.MODULE4} component={Module4} />
            <Route path={ROUTES.EXAMPAGE} component={ExamPage} />
            <Route
              path={ROUTES.VIEWMODULE2RESULTS}
              component={ViewModule2Results}
            />
            <Route
              path={ROUTES.VIEWMODULE3RESULTS}
              component={ViewModule3Results}
            />
            <Route
              path={ROUTES.VIEWMODULE4RESULTS}
              component={ViewModule4Results}
            />
            <Route path={ROUTES.MODULEERRORS} component={ModuleErrorsPage} />
            <Route
              path={ROUTES.COURSEACTIVITIES}
              component={CourseActivities}
            />
            <Route path={ROUTES.SHOWACTIVITY} component={ShowActivity} />
            <Route path={ROUTES.EDITACTIVITY} component={EditActivity} />
            <Route path={ROUTES.COURSERESOURCES} component={CourseResources} />
            <Route path={ROUTES.SHOWRESOURCE} component={ShowResource} />
            <Route path={ROUTES.EDITRESOURCE} component={EditResource} />
            <Route path={ROUTES.MYRESOURCES} component={MyResources} />
            <Route path={ROUTES.COURSETEMPLATES} component={CourseTemplates} />
            <Route
              path={ROUTES.SHOWCOURSETEMPLATE}
              component={ShowCourseTemplate}
            />
            <Route path={ROUTES.MANAGEUSERS} component={ManageUsers} />
            <Route path={ROUTES.EDITUSER} component={EditUser} />
            <Route path={ROUTES.GRADES} component={Grades} />
            <Route path={ROUTES.RAWOUTPUT} component={RawOutput} />
            <Route path={ROUTES.MANAGECOHORTS} component={ManageCohorts} />
            <Route path={ROUTES.MYCOHORTS} component={MyCohorts} />
            <Route path={ROUTES.EXPORTREPORT} component={ExportReport} />
            <Route
              path={ROUTES.PERFORMANCEREPORT}
              component={PerformanceReportPage}
            />
            <Route path={ROUTES.COURSEPREVIEW} component={CoursePreview} />
            <Route path={ROUTES.CONTACTFORM} component={ContactForm} />
            <Route path={ROUTES.REPLAYEXAM} component={ReplayExamPage} />

            <Route path={ROUTES.MANUALGRID} component={ManualGridPage} />
            <Route path={ROUTES.PRACTICEEXAM} component={PracticeExamModule} />
            <Route path={ROUTES.ANALYTICS} component={AnalyticsPage} />
            <Route
              path={ROUTES.TELETRAININGLAUNCHPOINT}
              component={TeletrainingPage}
            />
            <Route path={ROUTES.RATINGSPAGE} component={RatingsPage} />
            <Route
              path={ROUTES.QRMODELSCANNERPAGE}
              component={QRModelScannerPage}
            />
            <Route path={ROUTES.QRGENERATOR} component={QRGeneratorPage} />
            <Route path={ROUTES.MODELCHECKIN} component={ModelCheckIn} />
            <Route
              path={ROUTES.CERTIFICATEPREVIEW}
              component={CertificatePreview}
            />
            <Route
              path={ROUTES.GACERTIFICATEPREVIEW}
              component={GACertificatePreview}
            />
            <Route path={ROUTES.LICENSEMANAGER} component={LicenseManager} />
            <Route component={mobile ? MobileLandingPage : LandingPage} />
          </Switch>
        </>
      </Suspense>
    </Router>
  );
};

export default withAuthentication(App);
