import React, { useRef, useState, useEffect, useContext } from "react";
import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import modelImg from "../ExamGrid/P1.jpg";
import useWindowSize from "../Generic/useWindowSize";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  canvas: {
    width: "calc(100% - 2px)",
    border: "1px solid black",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundImage: `url(${modelImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  wrapper: {
    position: "relative",
    width: "100%",
    maxHeight: "75%",
  },
}));

function drawPalpation(ctx, length, palpation, index) {
  const cell_size = 50;
  const x = palpation.y * cell_size + 25;
  const y = palpation.x * cell_size + 25;
  //console.log("drawing on canvas at loc: ", y, x);

  if (!x || !y) return;

  var text = palpation.text;

  switch (index) {
    case 0:
      ctx.fillStyle = "green";
      break;
    case length - 1:
      ctx.fillStyle = "red";
      break;
    default:
      ctx.fillStyle = "purple";
      break;
  }
  ctx.beginPath();
  var radius = 10; // for example
  ctx.arc(x, y, radius, 0, Math.PI * 2);
  ctx.closePath();
  ctx.fill();
  ctx.fillStyle = "white"; // font color to write the text with
  var font = "bold 8px sans-serif";
  ctx.font = font;
  //Move it down by half the text height and left by half the text width
  var width = ctx.measureText(text).width;
  var height = ctx.measureText("w").width; // this is a GUESS of height
  ctx.fillText(text, x - width / 2, y + height / 2);
}

// define a vec2 class to make vector maths easier (simpler to read)
function vec2(x, y) {
  this.length = function () {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  };
  this.normalize = function () {
    var scale = this.length();
    this.x /= scale;
    this.y /= scale;
  };
  this.x = x;
  this.y = y;
}

function drawLine(
  ctx,
  center1_x,
  center1_y,
  radius1,
  center2_x,
  center2_y,
  radius2
) {
  const cell_size = 50;
  center1_x = center1_x * cell_size + 25;
  center1_y = center1_y * cell_size + 25;
  center2_x = center2_x * cell_size + 25;
  center2_y = center2_y * cell_size + 25;

  var betweenVec = new vec2(center2_x - center1_x, center2_y - center1_y);
  betweenVec.normalize();

  var p1x = center1_x + radius1 * betweenVec.x;
  var p1y = center1_y + radius1 * betweenVec.y;

  var p2x = center2_x - radius2 * betweenVec.x;
  var p2y = center2_y - radius2 * betweenVec.y;

  ctx.beginPath();
  ctx.moveTo(p1x, p1y);
  ctx.lineTo(p2x, p2y);
  ctx.stroke();
}

function PatternCanvas(props) {
  const classes = useStyles();
  const context = useContext(AuthUserContext);
  const { pattern } = props;
  const canvasRef = useRef(null);
  const windowSize = useWindowSize();
  const [height, setHeight] = useState(0);
  let timer = null;

  useEffect(() => {
    clearTimeout(timer);
    if (canvasRef.current) {
      timer = setTimeout(() => {
        setHeight(`${canvasRef.current.getBoundingClientRect().height}px`);
      }, 500);
    }
  }, [windowSize]);

  useEffect(() => {
    if (pattern && pattern.length > 0) {
      console.log("PALPATIONS: ", pattern);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, "400px", "450px");
      pattern.forEach((location, index) =>
        drawPalpation(ctx, pattern.length, location, index)
      );

      // draw lines
      if (pattern.length > 1)
        for (let index = 0; index < pattern.length - 1; index++) {
          const palp1 = pattern[index];
          const palp2 = pattern[index + 1];
          drawLine(ctx, palp1.y, palp1.x, 20, palp2.y, palp2.x, 20);
        }
    }
  }, [pattern]);

  return (
    <div className={classes.wrapper}>
      <canvas
        ref={canvasRef}
        width={"400px"}
        height={"450px"}
        className={classes.canvas}
      />
      <div style={{ height: height }} />
    </div>
  );
}

export default withFirebase(PatternCanvas);
